import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, InputProps } from '@any-ui-react/core';
import classNames from 'classnames';

import { CurrencyCode, GraphQlMoney, ProductVariantStatus } from '~anyx/shared/graphql';
import { Table, TableLayout, Image, NoResult, InputCurrency } from '~anyx/shared/ui';
import { EMPTY_DEFAULT, ObjectUtils } from '~anyx/shared/utils';

import { ProductVariantStatusBadge } from '../product-variant-status-badge';

export type ProductVariantInput = {
  media?: string;
  values?: string[] | null;
  productCode?: string | null;
  status?: ProductVariantStatus;
  name?: string | null;
  id?: string;
  price?: GraphQlMoney | null;
  unitCost?: GraphQlMoney | null;
};

export interface ProductVariantsInputProps extends InputProps {
  variants: ProductVariantInput[];
  loading?: boolean;
  onChange: (products: ProductVariantInput[]) => void;
  onSelect: (product: ProductVariantInput) => void;
}

export const ProductVariantsInput = forwardRef<HTMLInputElement, ProductVariantsInputProps>(
  ({ variants = [], onChange, onSelect, loading, ...rest }, ref) => {
    const { t } = useTranslation();
    const layout = useMemo(
      () =>
        [
          {
            accessor: 'name',
            maxWidth: '25rem',
            minWidth: '15rem',
            truncated: false,
            tdClassName: 'z-10',
            headerRender: () =>
              t('logistic.component.productVariantInput.table.column.name', { ns: 'logistic' }),
            cellRender: ({ values, media, name, ...rest }) => {
              return (
                <div
                  className="flex w-full items-center justify-between truncate py-2"
                  title={name || ''}
                >
                  <div className="flex flex-1 items-center">
                    <Image
                      alt={t('shared.action.image', {
                        ns: 'shared',
                        entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
                      })}
                      className="mr-2 h-10 w-10"
                      noImageClassNameWrapper="mr-2 h-10 w-10"
                      src={media}
                    />
                    <button
                      type="button"
                      className="group-hover:text-link hover:text-link line-clamp-2 hover:underline"
                      onClick={() => onSelect({ values, media, ...rest })}
                    >
                      {name || EMPTY_DEFAULT}
                    </button>
                  </div>
                </div>
              );
            },
          },
          {
            accessor: 'status',
            headerRender: () => (
              <div className="flex">
                {t('logistic.component.productVariantInput.table.column.status', {
                  ns: 'logistic',
                })}
              </div>
            ),
            cellRender: ({ status }) => <ProductVariantStatusBadge status={status} />,
          },
          {
            accessor: 'productCode',
            headerRender: () =>
              t('logistic.component.productVariantInput.table.column.code', { ns: 'logistic' }),
            cellRender: ({ productCode }) => {
              return productCode || EMPTY_DEFAULT;
            },
          },
          {
            accessor: 'price',
            minWidth: '15rem',
            headerRender: () => (
              <div className="flex">
                {t('logistic.component.productVariantInput.table.column.price', { ns: 'logistic' })}
              </div>
            ),
            cellRender: ({ price }, i) => {
              return (
                <InputCurrency
                  ref={i === 0 ? ref : undefined}
                  value={price}
                  max={4294967295}
                  clearable={false}
                  min={0}
                  currencies={ObjectUtils.arrayFromEnum<CurrencyCode>(CurrencyCode)}
                  onChange={(e) => {
                    const newValues = [...(variants || [])];
                    newValues[i] = {
                      ...newValues[i],
                      price: {
                        ...e,
                        currency: e.currency,
                      },
                    };
                    onChange(newValues);
                  }}
                />
              );
            },
          },
          {
            accessor: 'unitCost',
            minWidth: '15rem',
            headerRender: () =>
              t('logistic.component.productVariantInput.table.column.unitCost', { ns: 'logistic' }),
            cellRender: ({ unitCost }, i) => {
              return (
                <InputCurrency
                  value={unitCost}
                  max={4294967295}
                  min={0}
                  currencies={ObjectUtils.arrayFromEnum<CurrencyCode>(CurrencyCode)}
                  onChange={(e) => {
                    const newValues = [...(variants || [])];
                    newValues[i] = {
                      ...newValues[i],
                      unitCost: {
                        ...e,
                        currency: e.currency,
                      },
                    };
                    onChange(newValues);
                  }}
                />
              );
            },
          },
        ] as TableLayout<ProductVariantInput>[],
      [t, onSelect, ref, variants, onChange]
    );

    return (
      <Input.Wrapper {...rest.wrapperProps} error={rest?.error} className={rest.className}>
        <Table<ProductVariantInput>
          layout={layout}
          data={variants || []}
          rowClassName="h-14"
          loading={loading}
          wrapperClassName={classNames('border-x border-y shadow-none rounded-t', {
            'border-gray-2': !rest?.error,
            'border-failure': rest?.error,
          })}
          emptyContainerClassName="text-center"
          notFoundTemplate={
            <NoResult
              title={t('shared.table.empty', {
                ns: 'shared',
                element: t('shared.entity.variant', { ns: 'shared', count: 1 }),
              })}
            />
          }
        />
      </Input.Wrapper>
    );
  }
);
