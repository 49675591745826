import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@any-ui-react/core';
import classNames from 'classnames';

import { IntegrationRoutePath, StoreRoutePath } from '~anyx/common/routing';
import { WorkspaceMode, useWorkspaceCtx } from '~anyx/common/workspace';

export const NoSalesChannelConnection = ({
  wrapperClassName = '',
}: {
  wrapperClassName?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspace, mode } = useWorkspaceCtx();

  const integrationLink =
    mode === WorkspaceMode.ON
      ? IntegrationRoutePath({ singleStoreMode: true, storeId: workspace?.storeId }).list().path
      : StoreRoutePath().list().path;

  return (
    <div
      className={classNames(
        'group absolute top-0 flex h-full w-full flex-col items-center justify-center rounded-none px-6 text-center lg:rounded ',
        wrapperClassName
      )}
    >
      <div className="delay-50 mt-2 opacity-0 transition duration-200 ease-in-out group-hover:-translate-y-2 group-hover:opacity-100">
        <p className="text-gray-9 pb-4">
          {t('core.page.home.noConnectionGuide.salesChannel.content', { ns: 'core' })}
        </p>
        <Button type="button" className="shadow" onClick={() => navigate(integrationLink)}>
          {t('shared.entity.integration', { ns: 'shared', count: 1 })}
        </Button>
      </div>
    </div>
  );
};
