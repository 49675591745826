import { ReactNode } from 'react';

import Cookies from 'js-cookie';

import { FetchContext } from './FetchContext';

export function FetchProvider({ baseURL, children }: { children: ReactNode; baseURL: string }) {
  const fetcher = async (url: string, options: RequestInit = {}) => {
    // Get the authorization token from cookies
    const token = Cookies.get('authorization');

    // Set the Authorization header if the token exists
    const headers = {
      ...options.headers,
      Authorization: token ? `Bearer ${token}` : '',
      Accept: 'application/json, text/plain, */*',
    };

    const config = {
      ...options,
      headers,
    };

    return fetch(`${baseURL}${url}`, config);
  };

  return <FetchContext.Provider value={{ fetcher }}>{children}</FetchContext.Provider>;
}
