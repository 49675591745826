import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';

const TikTokAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.TikTokAddPage }))
);

const TikTokEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.TikTokEditPage }))
);

const TikTokManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.TikTokManualCostEditPage,
  }))
);

const TikTokManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.TikTokManualCostAddPage,
  }))
);

const TikTokTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.TikTokTemplate }))
);

export const tikTokRouting = [
  {
    path: MarketplaceSaleChannelType.TikTok,
    element: <TikTokTemplate />,
    children: [
      {
        index: true,
        element: <TikTokAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <TikTokManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <TikTokManualCostEditPage />,
          },
          {
            index: true,
            element: <TikTokEditPage />,
          },
        ],
      },
    ],
  },
];
