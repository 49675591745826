import { DdiHomeFilterInputFilterType } from '../components';
import { useDdiHomeReportSummaryQuery } from '../graphql';

interface UseDdiHomeReportSummaryProps {
  filters: DdiHomeFilterInputFilterType;
  skip: boolean;
}

export const useDdiHomeReportSummary = ({ filters, skip }: UseDdiHomeReportSummaryProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useDdiHomeReportSummaryQuery({
    variables: {
      input: filterInput,
    },
    skip,
  });

  return {
    reportSummary: data?.ddiHomeReportSummary,
    loading,
    error,
    refetch,
  };
};
