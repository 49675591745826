import { useMemo } from 'react';

import { useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';

import { getNoWorkspaceLinks } from './NoWorkspaceLinks';
import { useWorkspacePermissions } from './workspace-permissions';
import { getWorkspaceLinks } from './WorkspaceLinks';

export const useNavigationLinks = () => {
  const { mode } = useWorkspaceCtx();
  const permissions = useWorkspacePermissions();

  const links = useMemo(() => {
    if (mode === WorkspaceMode.ON) {
      return getWorkspaceLinks(permissions.withWorkspace());
    } else {
      return getNoWorkspaceLinks(permissions.noWorkspace());
    }
  }, [permissions, mode]);

  return {
    links,
  };
};
