import { useState } from 'react';

import { GqlProvince, MdCountryEnum } from '~anyx/shared/graphql';
import { AnyXLanguage } from '~anyx/shared/utils';

import { useMdProvincesQuery } from '../graphql';

interface UseProvinceOptions {
  useRegionId?: boolean;
  country?: MdCountryEnum | string;
  language?: AnyXLanguage;
}

type ProvinceOption = {
  label: string;
  value: string;
};

export const useProvinceOptions = ({
  useRegionId,
  country,
  language = AnyXLanguage.EN_US,
}: UseProvinceOptions = {}): { provinceOptions: ProvinceOption[] } => {
  const [provinceOptions, setProvinceOptions] = useState<ProvinceOption[]>([]);

  useMdProvincesQuery({
    skip: !country,
    variables: {
      filter: {
        countryId: country,
      },
    },
    onCompleted: ({ MdProvinces }) => {
      setProvinceOptions(
        MdProvinces.map(({ name, lname, id, country }) => {
          const provinceName = GqlProvince.localizeProvince({ country, lname, name }, language);
          return {
            label: provinceName,
            value: useRegionId ? id : provinceName,
          };
        })
      );
    },
  });

  return { provinceOptions };
};
