import { createPath } from '../../../utils';

export const PolicyModuleRoutesPaths = () => {
  const root = createPath('/policy');

  return {
    root: () => root,
    terms: () => createPath(`${root.path}/terms`),
    privacy: () => createPath(`${root.path}/privacy-policy`),
  };
};
