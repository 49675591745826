import { useTranslation } from 'react-i18next';

import { ModalsProvider as AnyUIModalsProvider } from '@any-ui-react/modals';

export const ModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('shared');

  return (
    <AnyUIModalsProvider
      modalProps={{ centered: true }}
      labels={{
        cancel: t('shared.button.cancel'),
        confirm: t('shared.button.confirm'),
      }}
    >
      {children}
    </AnyUIModalsProvider>
  );
};
