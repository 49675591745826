import classNames from 'classnames';

import { NumberWithFormat, SkeletonLoader } from '../../atoms';

interface OverviewCardProps {
  className?: string;
  selected: boolean;
  loading?: boolean;
  title: React.ReactNode;
  value?: number;
  onSelect: () => void;
}

export const OverviewCard = ({
  loading,
  selected,
  title,
  value,
  onSelect,
  className = '',
}: OverviewCardProps) => {
  return (
    <button
      type="button"
      onClick={() => onSelect()}
      className={classNames(
        'border-gray-2 hover:bg-gray-0 border font-semibold lg:p-1 lg:hover:bg-transparent',
        {
          [className]: true,
          'bg-white': !selected,
          'bg-gray-0 lg:bg-transparent': selected,
        }
      )}
    >
      <div
        className={classNames('lg:hover:bg-gray-0 flex flex-col items-center rounded lg:p-1', {
          'lg:bg-gray-0': selected,
        })}
      >
        <div className="text-2xs lg:text-sm">
          <SkeletonLoader loading={loading} className="mb-2 mt-0.5 lg:mt-1" width="4rem">
            {title}
          </SkeletonLoader>
        </div>
        <div className="text-base">
          <SkeletonLoader loading={loading} className="mb-0.5 h-4 lg:mb-1" width="2rem">
            <NumberWithFormat
              amount={value || 0}
              option={{ compactDisplay: 'short', notation: 'compact' }}
            />
          </SkeletonLoader>
        </div>
      </div>
    </button>
  );
};
