import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/**
 *
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const SupplierRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/supplier' : '/supplier', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    add: () => createPath(`${root.path}/add`),
    edit: ({ supplierId = '' } = {}) =>
      createPath(`${root.path}/:supplierId`, { supplierId }, ['supplierId']),
    user: () => ({
      list: ({ supplierId = '' } = {}) =>
        createPath(`${root.path}/:supplierId/list`, { supplierId }, ['supplierId']),
      add: ({ supplierId = '' } = {}) =>
        createPath(`${root.path}/:supplierId/add`, { supplierId }, ['supplierId']),
      edit: ({ supplierId = '', userId = '' } = {}) =>
        createPath(`${root.path}/:supplierId/:userId`, { supplierId, userId }, ['supplierId']),
    }),
  };
};
