import { type FB } from './fb.type';

export type WindowWithFB = Window &
  typeof globalThis & {
    fbAsyncInit?: () => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FB: FB | null;
  };

export const windowWithFB = window as WindowWithFB;
