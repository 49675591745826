import { createPath } from '../../../utils';

export const IntegrationRoutePath = ({ storeId = '', singleStoreMode = false } = {}) => {
  const root = createPath(
    singleStoreMode ? '/:storeId/store/integrations' : '/store/:storeId/integrations',
    { singleStoreMode, storeId },
    ['storeId']
  );

  return {
    root: () => root,
    list: () => root,
    ddiInventory: () => {
      const ddiInventoryRoot = createPath(`${root.path}/ddi-inventory`);

      return {
        root: () => ddiInventoryRoot,
        details: ({ wmsType = '' }) =>
          createPath(`${ddiInventoryRoot.path}/:wmsType`, { wmsType }, ['wmsType']),
      };
    },
    salesChannel: () => {
      const saleChannelRoot = createPath(`${root.path}/sales-channel`);

      return {
        root: () => saleChannelRoot,
        select: () => saleChannelRoot,
        details: ({ channelType = '', channelId = '' }) =>
          createPath(
            `${saleChannelRoot.path}/:channelType/:channelId`,
            { channelType, channelId },
            ['channelType']
          ),
        manualCost: ({ channelType = '', channelId = '' }) => {
          const manualCostRoot = createPath(
            `${saleChannelRoot.path}/:channelType/:channelId/manual-cost`,
            { channelType, channelId },
            ['channelType']
          );

          return {
            root: () => manualCostRoot,
            add: () => createPath(`${manualCostRoot.path}/add`),
            edit: ({ manualCostId = '' }: { manualCostId: string }) =>
              createPath(`${manualCostRoot.path}/:manualCostId`, { manualCostId }),
          };
        },
        adsMapping: ({ channelType = '', channelId = '' }) => {
          const adsMappingRoot = createPath(
            `${saleChannelRoot.path}/:channelType/:channelId/mapping`,
            { channelType, channelId },
            ['channelType']
          );

          return {
            root: () => adsMappingRoot,
            edit: () => createPath(`${adsMappingRoot.path}/edit`),
          };
        },
      };
    },
    logistics: () => {
      const logisticsPattern = `${root.path}/logistics`;
      const logisticsRoot = createPath(logisticsPattern);

      return {
        root: () => logisticsRoot,
        select: () => logisticsRoot,
        details: ({ wmsType = '', saleChannelId = '' }) =>
          createPath(`${logisticsRoot.path}/:wmsType/:saleChannelId`, { wmsType, saleChannelId }, [
            'wmsType',
          ]),
        request: ({ wmsType = '' }) =>
          createPath(`${logisticsRoot.path}/:wmsType/request`, { wmsType }, ['wmsType']),
        pending: ({ wmsType = '' }) =>
          createPath(`${logisticsRoot.path}/:wmsType/pending`, { wmsType }, ['wmsType']),
      };
    },
    analytics: () => {
      const analyticsRoot = createPath(`${root.path}/analytics-v2`);

      return {
        root: () => analyticsRoot,
        select: () => analyticsRoot,
        details: ({ datasourceId = '', analyticsType = '' }) =>
          createPath(
            `${analyticsRoot.path}/:analyticsType/:datasourceId`,
            { datasourceId, analyticsType },
            ['analyticsType']
          ),
      };
    },
    advertisement: () => {
      const advertisementRoot = createPath(`${root.path}/advertisement-v2`);

      return {
        root: () => advertisementRoot,
        select: () => advertisementRoot,
        details: ({ datasourceId = '', advertisementType = '' }) =>
          createPath(
            `${advertisementRoot.path}/:advertisementType/:datasourceId`,
            { datasourceId, advertisementType },
            ['advertisementType']
          ),
        mapping: ({ datasourceId = '', advertisementType = '' }) => {
          const mappingRoot = createPath(
            `${advertisementRoot.path}/:advertisementType/:datasourceId`,
            { datasourceId, advertisementType },
            ['advertisementType']
          );

          return {
            root: () => mappingRoot,
            campaign: ({ campaignId = '', adSetMappingCampaignId = '' }) => {
              const campaignRoot = createPath(
                `${mappingRoot.path}/campaign/:campaignId/adSet/:adSetMappingCampaignId`,
                { campaignId, adSetMappingCampaignId },
                ['campaignId', 'adSetMappingCampaignId']
              );

              return {
                root: () => campaignRoot,
                list: () => campaignRoot,
              };
            },
            edit: ({ campaignId = '', adSetMappingCampaignId = '' }) =>
              createPath(
                `${mappingRoot.path}/campaign/:campaignId/adset/:adSetMappingCampaignId/set`,
                { campaignId, adSetMappingCampaignId },
                ['campaignId', 'adSetMappingCampaignId']
              ),
          };
        },
      };
    },
    chat: () => {
      const chatRoot = createPath(`${root.path}/chat`);

      return {
        root: () => chatRoot,
        select: () => chatRoot,
        instagram: () => createPath(`${chatRoot.path}/instagram`),
        line: () => createPath(`${chatRoot.path}/line`),
        messenger: () => createPath(`${chatRoot.path}/messenger`),
        whatsapp: () => createPath(`${chatRoot.path}/whatsapp`),
        lazada: () => createPath(`${chatRoot.path}/lazada`),
        shopee: () => createPath(`${chatRoot.path}/shopee`),
        tiktokShop: () => createPath(`${chatRoot.path}/tiktokShop`),
      };
    },
  };
};
