import { ReactNode } from 'react';

import classNames from 'classnames';

import { ReportSupportedCurrency } from '~anyx/shared/graphql';
import { NumberWithPercentage, SkeletonLoader } from '~anyx/shared/ui';

interface ReportCardProps {
  item: {
    title: string;
    value: ReactNode;
    percentage?: string | null;
    reverseColor?: boolean;
  };
  currency?: ReportSupportedCurrency;
  displayMode?: 'card' | 'grid';
  theme?: 'light' | 'dark';
  wrapperClassName?: string;
  loading?: boolean;
}

export const ReportCard = ({
  item,
  displayMode = 'card',
  theme = 'light',
  loading,
  ...rest
}: ReportCardProps) => {
  return (
    <div
      className={classNames('items-start justify-start leading-none', {
        'card rounded-none px-2.5 py-4 lg:rounded lg:p-4': displayMode === 'card',
        'border-l px-2': displayMode === 'grid',
        'border-l-gray-2': theme === 'light' && displayMode === 'grid',
        'border-l-gray-0': theme === 'dark' && displayMode === 'grid',
      })}
      {...rest}
    >
      <div className="text-2xs mb-2 lg:mb-1.5">
        <SkeletonLoader loading={loading} variant={theme} className="h-3">
          {item.title}
        </SkeletonLoader>
      </div>

      <div className="flex flex-wrap items-end gap-1 break-all">
        <SkeletonLoader loading={loading} variant={theme} className="h-4">
          <span
            className={classNames('text-base font-semibold', {
              'text-gray-9': theme === 'light',
              'text-white': theme === 'dark',
            })}
          >
            {item.value}
          </span>
          {item.percentage && (
            <div
              className={classNames(
                'text-3xs flex w-fit items-start break-all font-semibold leading-5',
                {
                  'text-success': item.reverseColor ? +item.percentage < 0 : +item.percentage >= 0,
                  'text-failure': item.reverseColor ? +item.percentage >= 0 : +item.percentage < 0,
                }
              )}
            >
              {+item.percentage >= 0 ? (
                <>
                  +<NumberWithPercentage amount={item.percentage} />
                </>
              ) : (
                <NumberWithPercentage amount={item.percentage} />
              )}
            </div>
          )}
        </SkeletonLoader>
      </div>
    </div>
  );
};
