import { useTranslation } from 'react-i18next';

import { Badge, BadgeProps, MantineColor } from '@any-ui-react/core';

import { DeliveryStatus } from '~anyx/shared/graphql';

export interface DeliveryStatusBadgeProps extends Omit<BadgeProps, 'color' | 'children'> {
  status: DeliveryStatus;
}

const DeliveryStatusColor: Record<DeliveryStatus, MantineColor> = {
  [DeliveryStatus.PAYING]: 'yellow',
  [DeliveryStatus.PENDING]: 'gray',
  [DeliveryStatus.CANCELLED]: 'red',
  [DeliveryStatus.SHIPPED]: 'green',
  [DeliveryStatus.ALLOCATING]: 'blue',
  [DeliveryStatus.PREPARING]: 'cyan',
  [DeliveryStatus.WORKING]: 'grape',
};

export const DeliveryStatusBadge = ({ status, ...rest }: DeliveryStatusBadgeProps) => {
  const { t } = useTranslation('gql');

  return (
    <Badge color={DeliveryStatusColor[status]} {...rest}>
      {t('gql.enum.deliveryStatus', {
        status,
      })}
    </Badge>
  );
};
