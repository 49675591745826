import { createElement, forwardRef, ReactElement } from 'react';

import {
  components,
  GroupBase,
  MenuProps,
  AsyncPaginateSelect,
  AsyncPaginateSelectProps,
  SelectForwardRef,
} from '@any-ui-react/select';

import { Spinner } from '../../atoms/spinner';

const MenuV2 = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  isLoading,
  selectProps,
  ...props
}: MenuProps<Option, IsMulti, Group>) => {
  const { options = [] } = selectProps;

  return (
    <components.Menu isLoading={isLoading} selectProps={selectProps} {...props}>
      {children}
      {options.length > 0 && isLoading ? (
        <div className="flex items-center justify-center py-1">
          <Spinner />
        </div>
      ) : null}
    </components.Menu>
  );
};

export type LazySelectV2BaseType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
>(
  props: AsyncPaginateSelectProps<OptionType, Group, Additional, IsMulti> & {
    ref?: SelectForwardRef<OptionType, IsMulti, Group>;
  }
) => ReactElement;

export const LazySelectV2 = forwardRef(
  <OptionType, Group extends GroupBase<OptionType>, Additional, IsMulti extends boolean>(
    {
      debounceTimeout = 300,
      components: componentsProp,
      ...props
    }: AsyncPaginateSelectProps<OptionType, Group, Additional, IsMulti>,
    ref: SelectForwardRef<OptionType, IsMulti, Group>
  ) => {
    return (
      <AsyncPaginateSelect
        ref={ref}
        debounceTimeout={debounceTimeout}
        components={{
          ...componentsProp,
          Menu: MenuV2,
          Option: (optionProps) => {
            //Add new title tooltip props
            const { innerProps, label: title } = optionProps;
            return createElement(componentsProp?.Option || components.Option, {
              ...optionProps,
              innerProps: {
                ...innerProps,
                title,
              },
            });
          },
        }}
        {...props}
      />
    );
  }
) as LazySelectV2BaseType;
