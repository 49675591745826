import { useTranslation } from 'react-i18next';

import { AnyXLanguage, LanguageUtils } from '~anyx/shared/utils';

const HELP_LINK = {
  EN: 'https://anymind-group.gitbook.io/any-x/data-linkages/integrations-and-data-linkages/integrations-connecting-with-your-messaging-apps/troubleshooting',
  JA: 'https://anymind-group.gitbook.io/any-x/v/ja-jp/integrations/integrations/messjinguchaneru/toraburushtingu',
};

export const useHelpLink = () => {
  const { i18n } = useTranslation();
  const language = LanguageUtils.fromI18nLanguage(i18n.language);

  return language === AnyXLanguage.JA_JP ? HELP_LINK.JA : HELP_LINK.EN;
};
