import React from 'react';

import { anylogiIntegrationRoutes } from '~anyx/integrations/logistics';

const LogisticsChannelSelectPage = React.lazy(() =>
  import('./select').then((module) => ({ default: module.LogisticsChannelSelectPage }))
);

export const logisticIntegrationRoutes = {
  path: 'logistics',
  children: [
    {
      index: true,
      element: <LogisticsChannelSelectPage />,
    },
    ...anylogiIntegrationRoutes,
  ],
};
