import { MdAccountProduct } from '~anyx/shared/graphql';

export const getDomainProduct = () => {
  if (window.location.host.includes('anylogi.com')) {
    return MdAccountProduct.ANYLOGI;
  }
  if (window.location.host.includes('anychat.tech')) {
    return MdAccountProduct.ANYCHAT;
  }
  return MdAccountProduct.ANYX;
};
