import classNames from 'classnames';

import { EMPTY_DEFAULT, NumberUtils, CurrenciesEnum } from '~anyx/shared/utils';

interface NumberWithCurrencyProps {
  currency?: CurrenciesEnum | string | null;
  amount?: number | string | null;
  amountClassName?: string;
  currencyClassName?: string;
  containerClassName?: string;
}

export const NumberWithCurrency = ({
  amount,
  currency,
  amountClassName,
  currencyClassName,
  containerClassName = 'inline-flex items-baseline',
}: NumberWithCurrencyProps) => {
  return (
    <>
      {currency && NumberUtils.isNumber(amount) ? (
        <span className={classNames(containerClassName)}>
          <span className={classNames(amountClassName, 'tabular-nums')}>
            {amount < 0 && '-'}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
              currencyDisplay: 'code',
            })
              .format(Number(amount))
              .substring(4)
              .replace(/\s/g, '')}
          </span>
          <span className={classNames('ml-1', currencyClassName)}>{currency}</span>
        </span>
      ) : (
        EMPTY_DEFAULT
      )}
    </>
  );
};
