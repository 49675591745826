import { HomeRoutePath } from '~anyx/common/routing';
import { SmileIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

interface HomeLinksProps {
  canUseAnalytics?: boolean;
  storeId?: string;
}

export const getHomeLinks = ({ canUseAnalytics, storeId }: HomeLinksProps): RouteLinks[] => {
  if (!canUseAnalytics) {
    return [];
  }

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'core.page.home.title',
          options: { ns: 'core' },
        },
        icon: (props) => <SmileIcon {...props} />,
        path: HomeRoutePath({ workspaceStoreId: storeId }).root().path,
        activePath: HomeRoutePath({ workspaceStoreId: null }).root().path,
      },
    },
  ];
};

type HomeLinks = LinkAdapter<HomeLinksProps>;

export const homeLinks: HomeLinks = {
  noWorkspace: getHomeLinks,
  withWorkspace: getHomeLinks,
};
