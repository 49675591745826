import { useState } from 'react';

import { useWindowEvent } from '@mantine/hooks';

export function useKeyModifier(keys: string[]) {
  const [isPressed, setIsPressed] = useState(false);

  useWindowEvent('keydown', (event) => {
    if (keys.includes(event.code)) {
      setIsPressed(true);
    }
  });

  useWindowEvent('keyup', (event) => {
    if (keys.includes(event.code)) {
      setIsPressed(false);
    }
  });

  return { isPressed };
}
