import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const PickupRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/pickups' : '/pickups', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    add: () => createPath(`${root.path}/add`),
    edit: ({ pickupId = '' } = {}) =>
      createPath(
        `${root.path}/:pickupId`,
        {
          pickupId,
        },
        ['pickupId']
      ),
  };
};
