import React from 'react';

import { FeatureFlag } from '~anyx/external/firebase';
import { NotFoundMessage } from '~anyx/shared/ui';

const ChatIntegrationTemplate = React.lazy(() =>
  import('./shared').then((module) => ({ default: module.ChatIntegrationTemplate }))
);

const ChatChannelSelectPage = React.lazy(() =>
  import('./select').then((module) => ({ default: module.ChatChannelSelectPage }))
);

const LineIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.LineIntegrationPage }))
);

const InstagramIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.InstagramIntegrationPage }))
);

const MessengerIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.MessengerIntegrationPage }))
);

const WhatsappIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.WhatsappIntegrationPage }))
);

const LazadaIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.LazadaIntegrationPage }))
);

const ShopeeIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.ShopeeIntegrationPage }))
);

const TikTokShopIntegrationPage = React.lazy(() =>
  import('./channel').then((module) => ({ default: module.TikTokShopIntegrationPage }))
);

export const chatIntegrationRoutes = {
  path: 'chat',
  element: <ChatIntegrationTemplate />,
  children: [
    {
      index: true,
      element: <ChatChannelSelectPage />,
    },
    {
      path: 'line',
      element: <LineIntegrationPage />,
    },
    {
      path: 'instagram',
      element: <InstagramIntegrationPage />,
    },
    {
      path: 'messenger',
      element: <MessengerIntegrationPage />,
    },
    {
      path: 'whatsapp',
      element: <WhatsappIntegrationPage />,
    },
    {
      path: 'lazada',
      element: <LazadaIntegrationPage />,
    },
    {
      path: 'shopee',
      element: <ShopeeIntegrationPage />,
    },
    {
      path: 'tiktok-shop',
      element: (
        <FeatureFlag name="ac_tiktok_shop_mvp">
          {(flag) => (flag?.value ? <TikTokShopIntegrationPage /> : <NotFoundMessage />)}
        </FeatureFlag>
      ),
    },
  ],
};
