import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, SelectProps } from '@any-ui-react/core';

import { AddressPart, ObjectUtils } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';

export type CountryFieldProps = Partial<SelectProps>;

export const CountryField = ({ ...rest }: CountryFieldProps) => {
  const { t } = useTranslation();
  const { countries = [], mappings } = useAddressFormContext();
  const { control, setValue, getFieldState, clearErrors } = useFormContext();

  const sortedCountries = useMemo(() => {
    return ObjectUtils.alphabeticalOrder(
      countries.map((country) => ({
        label: t('shared.enum.country', { ns: 'shared', country }),
        value: country,
      })),
      'label'
    );
  }, [countries, t]);

  const fieldName = mappings[AddressPart.COUNTRY];
  if (!fieldName) {
    return;
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, ...props } }) => (
        <Select
          {...props}
          label={t('shared.field.countryRegion.name', { ns: 'shared' })}
          placeholder={t('shared.field.countryRegion.placeholder', { ns: 'shared' })}
          searchable
          clearable
          required
          data={sortedCountries}
          error={getFieldState(fieldName)?.error?.message?.toString()}
          onChange={(country) => {
            onChange(country);
            if (mappings[AddressPart.REGION]) {
              setValue(mappings[AddressPart.REGION], null);
            }
            if (mappings[AddressPart.POSTAL_CODE]) {
              setValue(mappings[AddressPart.POSTAL_CODE], null);
            }
            if (
              mappings[AddressPart.PHONE] &&
              !getFieldState(mappings[AddressPart.PHONE]).isDirty
            ) {
              setValue(mappings[AddressPart.PHONE], {
                number: '',
                countryCode: country,
              });
            }
            clearErrors();
          }}
          {...rest}
        />
      )}
    />
  );
};
