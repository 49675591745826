import { Accordion, CloseButton } from '@any-ui-react/core';
import classNames from 'classnames';

import styles from './setting-guide.module.css';
import { SettingGuideContent } from './SettingGuideContent';
import { SettingGuideHeader } from './SettingGuideHeader';
import { SettingGuideProps, useSettingGuideItems } from './useSettingGuideItems';

export const SettingGuide = ({
  wrapperClassName,
  hasSalesChannels,
  hasShopifyChannel,
  hasDatasourcesChannels,
  hasInputUnitCost,
  hasInputSaleChannelCostIndex,
  hideSettingGuide,
}: SettingGuideProps & {
  wrapperClassName: string;
  hideSettingGuide: () => void;
}) => {
  const { settingGuideItems } = useSettingGuideItems({
    hasSalesChannels,
    hasShopifyChannel,
    hasDatasourcesChannels,
    hasInputUnitCost,
    hasInputSaleChannelCostIndex,
  });

  const checkedItems = settingGuideItems.filter((item) => item.isChecked).length;

  const progressPercentage =
    settingGuideItems.length === checkedItems
      ? 100
      : Math.round(100 / settingGuideItems.length) * checkedItems;

  return (
    <div className={classNames('card mb-4', wrapperClassName)}>
      <Accordion defaultValue="setting-guide" className="relative">
        <Accordion.Item value="setting-guide" className="rounded border-0">
          <Accordion.Control className={styles['accordion-control']}>
            <SettingGuideHeader progressPercentage={progressPercentage} />
          </Accordion.Control>
          <Accordion.Panel className={styles['accordion-panel']}>
            <SettingGuideContent items={settingGuideItems} />
          </Accordion.Panel>
          <CloseButton className="absolute right-4 top-5" onClick={hideSettingGuide} />
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
