import { SkeletonLoader } from '~anyx/shared/ui';

export const ProductOverviewCardSkeleton = () => {
  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="col-span-4 flex items-center md:col-span-3">
        <SkeletonLoader loading className="mr-2 h-14 w-14" />
        <div className="inline-flex min-w-0 flex-col">
          <SkeletonLoader loading className="mb-2 h-3.5 w-36" />
          <div className="mb-1 inline-flex gap-2">
            <SkeletonLoader loading className="h-3 w-16" />
            <div className="bg-gray-2 w-px" />
            <SkeletonLoader loading className="h-3 w-36" />
          </div>
          <SkeletonLoader loading className="h-3 w-60" />
        </div>
      </div>
    </div>
  );
};
