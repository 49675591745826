import { useTranslation } from 'react-i18next';

import { AnyXError } from '~anyx/shared/utils';

import { PlatformType } from './types';

type TypeNames =
  | 'AccountAlreadyHaveConnection'
  | 'NoInstagramAccountConnectToFacebookError'
  | 'PageHaveConnectedToOtherStore'
  | 'UnknownErrorConnectToFacebookError';

interface ThrowConnectFBToAnyChatErrorProps {
  typeName?: TypeNames;
}

enum ErrorCodeType {
  NO_INSTAGRAM_ACCOUNT_CONNECTED = 'NO_INSTAGRAM_ACCOUNT_CONNECTED',
  PAGE_CONNECTED_TO_OTHER_STORE = 'PAGE_CONNECTED_TO_OTHER_STORE',
  FACEBOOK_CONNECTION_ERROR = 'FACEBOOK_CONNECTION_ERROR',
  ACCOUNT_ALREADY_HAVE_CONNECTION = 'ACCOUNT_ALREADY_HAVE_CONNECTION',
}

type ErrorContentType = {
  code: ErrorCodeType;
  message: string;
};

export const useConnectFBToAnyChatErrors = (platform: PlatformType) => {
  const { t } = useTranslation('crmIntegration');
  const genericErrorMessage = t('crmIntegration.shared.notify.connectFBToAnyChat.error', {
    platform,
  });

  const connectToFbErrors: {
    [key in TypeNames]: ErrorContentType;
  } = {
    NoInstagramAccountConnectToFacebookError: {
      code: ErrorCodeType.NO_INSTAGRAM_ACCOUNT_CONNECTED,
      message: t('crmIntegration.shared.notify.connectFBToAnyChat.noInstagramAccount'),
    },
    PageHaveConnectedToOtherStore: {
      code: ErrorCodeType.PAGE_CONNECTED_TO_OTHER_STORE,
      message: t('crmIntegration.shared.notify.connectFBToAnyChat.usedStore', { platform }),
    },
    UnknownErrorConnectToFacebookError: {
      code: ErrorCodeType.FACEBOOK_CONNECTION_ERROR,
      message: genericErrorMessage,
    },
    AccountAlreadyHaveConnection: {
      code: ErrorCodeType.ACCOUNT_ALREADY_HAVE_CONNECTION,
      message: genericErrorMessage,
    },
  };

  const getErrorData = (typeName?: TypeNames): ErrorContentType => {
    if (!typeName) return connectToFbErrors.UnknownErrorConnectToFacebookError;
    return connectToFbErrors[typeName];
  };

  const throwConnectFBToAnyChatError = ({ typeName }: ThrowConnectFBToAnyChatErrorProps) => {
    const { code, message } = getErrorData(typeName);
    throw new AnyXError(code, message);
  };

  return {
    throwConnectFBToAnyChatError,
  };
};
