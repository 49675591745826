import { useState } from 'react';

import {
  ComposedChart as RechartStackedBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Line,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';

import { DEFAULT_ANIMATION_DURATION, NumberUtils } from '~anyx/shared/utils';

import {
  DEFAULT_Y_AXIS_WIDTH,
  DEFAULT_MAX_BAR_SIZE,
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  getXAxisConfig,
} from './chart.config';
import styles from './chart.module.css';
import { style } from './chart.theme';

interface StackedChartProps<T extends Record<string, unknown>> {
  dataKeys?: Array<string>;
  colors: Record<string, string>;
  tooltip?: ContentType<ValueType, NameType>;
  data?: Array<T>;
  xAxisInterval?: number;
  yAxisWidth?: number;
  xAxisPadding?: { left?: number; right?: number };
  barSize?: number;
  maxBarSize?: number;
  width?: number;
  height?: number;
  theme?: 'light' | 'dark';
  isTimeScale?: boolean;
  legendVerticalAlign?: 'top' | 'middle' | 'bottom';
  legendAlign?: 'left' | 'center' | 'right';
  showTarget?: boolean;
  dataKey?: string;
  xTickFormatter?: (value: string) => string;
}
export const StackedBarChart = <T extends Record<string, unknown>>({
  data = [],
  dataKeys = [],
  colors,
  tooltip,
  xAxisInterval,
  xAxisPadding = { left: 20, right: 20 },
  xTickFormatter = (value) => value,
  yAxisWidth = DEFAULT_Y_AXIS_WIDTH,
  barSize,
  maxBarSize = DEFAULT_MAX_BAR_SIZE,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  theme = 'light',
  isTimeScale = true,
  showTarget = false,
  dataKey = 'key',
}: StackedChartProps<T>) => {
  const [activeIndex, setActiveIndex] = useState<number | null | undefined>(null);
  const [mouseLeave, setMouseLeave] = useState<boolean>(false);

  return (
    <div className={styles['chart-wrapper']}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartStackedBarChart
          width={width}
          height={height}
          data={data}
          stackOffset="sign"
          barSize={barSize}
          barCategoryGap={'20%'}
          maxBarSize={maxBarSize}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 0,
          }}
          onMouseMove={(e) => {
            if (e.isTooltipActive) {
              setActiveIndex(e.activeTooltipIndex);
              setMouseLeave(false);
            } else {
              setActiveIndex(null);
              setMouseLeave(true);
            }
          }}
          onMouseLeave={() => setActiveIndex(null)}
        >
          <CartesianGrid
            strokeDasharray="2 2"
            vertical={false}
            stroke={style[theme].cartesianGrid.stroke}
          />
          <XAxis
            dataKey={dataKey}
            {...getXAxisConfig(isTimeScale, xAxisInterval, theme)}
            tickLine={{ ...style[theme].xAxis.tickLine }}
            axisLine={{ ...style[theme].xAxis.axisLine }}
            padding={xAxisPadding}
            tickFormatter={xTickFormatter}
          />
          <YAxis
            type="number"
            domain={['dataMin', (dataMax: number) => dataMax * 1.2]}
            scale={'linear'}
            tickLine={false}
            tick={{ ...style[theme].yAxis.tick }}
            tickFormatter={(value) => NumberUtils.format(value, { notation: 'compact' }) || ''}
            axisLine={false}
            width={yAxisWidth}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none', zIndex: 10 }}
            content={tooltip}
            allowEscapeViewBox={{ x: false, y: true }}
            isAnimationActive={false}
            cursor={false}
          />
          {dataKeys.map((dataKey) => {
            return (
              <Bar
                key={dataKey}
                dataKey={dataKey}
                stackId="a"
                fill={colors[dataKey]}
                isAnimationActive={false}
                animationDuration={DEFAULT_ANIMATION_DURATION}
                barSize={barSize}
              >
                {data.map((_, idx) => (
                  <Cell
                    fill={colors[dataKey]}
                    key={idx}
                    fillOpacity={
                      activeIndex === null || idx === activeIndex || mouseLeave ? 1 : 0.3
                    }
                  />
                ))}
              </Bar>
            );
          })}
          {showTarget && (
            <Line
              dataKey="totalTarget"
              strokeDasharray="3 4"
              strokeWidth="2"
              dot={false}
              isAnimationActive={false}
              stroke="#00FFF0"
              activeDot={false}
            />
          )}
        </RechartStackedBarChart>
      </ResponsiveContainer>
    </div>
  );
};
