import { useWorkspaceCtx } from '~anyx/common/workspace';
import { CrossBorderCourierProvider } from '~anyx/shared/graphql';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const SettingsRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(
    workspaceStoreId ? '/:workspaceStoreId/shipping-settings' : '/shipping-settings',
    {
      workspaceStoreId,
    }
  );

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    edit: ({ accountId = '' } = {}) =>
      createPath(
        `${root.path}/:accountId`,
        {
          accountId,
        },
        ['accountId']
      ),
    shipper: ({ accountId = '' } = {}) => {
      const shipperRoot = createPath(`${root.path}/:accountId/shipper`, { accountId }, [
        'accountId',
      ]);

      return {
        root: () => shipperRoot,
        list: () => createPath(`${shipperRoot.path}/list`),
        add: () => createPath(`${shipperRoot.path}/add`),
        edit: ({ shipperId = '' } = {}) =>
          createPath(`${shipperRoot.path}/:shipperId`, { shipperId }, ['shipperId']),
      };
    },
    couriers: ({ accountId = '' } = {}) => {
      const couriersRoot = createPath(`${root.path}/:accountId/couriers`, { accountId }, [
        'accountId',
      ]);

      return {
        root: () => couriersRoot,
        select: () => createPath(`${couriersRoot.path}/select`),
        list: () => createPath(`${couriersRoot.path}/list`),
        add: ({ courierType }: { courierType: CrossBorderCourierProvider }) =>
          createPath(`${couriersRoot.path}/:courierType`, { courierType }, ['courierType']),
        // This route only work in all store view
        // For Single Store use CourierRoutePaths.edit().path
        edit: ({
          courierType,
          courierId = '',
        }: {
          courierType: CrossBorderCourierProvider;
          courierId: string;
        }) =>
          createPath(
            `${couriersRoot.path}/:courierType/:courierId`,
            {
              courierType,
              courierId,
            },
            ['courierId', 'courierType']
          ),
      };
    },
  };
};
