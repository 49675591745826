import classNames from 'classnames';

import { SkeletonLoader } from '~anyx/shared/ui';

import { getStickyClassName, StickyShadow } from './CompareTable';
import { useShadowContext } from './context';

const SKELETON_COLUMN_COUNT = 5;
const SKELETON_ROW_COUNT = 10;

const TableHeaderSkeleton = () => {
  const { direction } = useShadowContext();

  return (
    <tr>
      <td
        className={classNames(
          getStickyClassName(direction),
          'border-gray-1 min-w-44 border px-4 py-1 font-semibold md:min-w-64',
          {
            'border-l-0': direction === 'ltr',
            'border-r-0': direction === 'rtl',
          }
        )}
      >
        <StickyShadow />
      </td>
      {Array.from({ length: SKELETON_COLUMN_COUNT }).map((_, index) => (
        <td
          key={index}
          className="border-gray-1 w-40 border px-2 pb-3 pt-0 align-top last:border-r-0"
        >
          <div className="mb-2 h-5" />

          <SkeletonLoader loading className="mx-auto mb-2 h-36 w-36" />
          <div className="w-56">
            <SkeletonLoader loading className="h-4" />
          </div>
        </td>
      ))}
    </tr>
  );
};

export const TableRowSkeleton = () => {
  const { direction } = useShadowContext();

  return (
    <tr>
      <td
        className={classNames(
          getStickyClassName(direction),
          'border-gray-1 h-6 border-b px-4 py-0 font-semibold'
        )}
      >
        <SkeletonLoader loading className="h-4" />
        <StickyShadow />
      </td>
      {Array.from({ length: SKELETON_COLUMN_COUNT }).map((_, index) => (
        <td key={index} className="border-gray-1 border px-2 py-0 last:border-r-0">
          <SkeletonLoader loading className="h-4" />
        </td>
      ))}
    </tr>
  );
};

export const CompareTableSkeleton = () => {
  const { handleScroll, direction } = useShadowContext();

  return (
    <div className="w-full overflow-x-auto" onScroll={handleScroll} style={{ direction }}>
      <table className="text-2xs w-full border-collapse text-left">
        <tbody>
          <TableHeaderSkeleton />
          {Array.from({ length: SKELETON_ROW_COUNT }).map((_, index) => (
            <TableRowSkeleton key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
