import { useWorkspaceCtx } from '~anyx/common/workspace';
import { SkuType } from '~anyx/shared/graphql';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/**
 *
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const ProductRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/product' : '/product', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    bulkEdit: () => createPath(`${root.path}/bulk`),
    add: ({ type = SkuType.SINGLE }: { type?: SkuType } = {}) =>
      createPath(`${root.path}/add?type=${type.toLowerCase()}`),
    edit: ({ productId = '' } = {}) =>
      createPath(`${root.path}/:productId`, { productId }, ['productId']),
    productVariant: () => ({
      edit: ({ productId = '', variantId = '' } = {}) =>
        createPath(`${root.path}/:productId/:variantId`, { productId, variantId }, [
          'productId',
          'variantId',
        ]),
    }),
  };
};
