import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted } from '~anyx/common/permission';
import { ReportMarketplaceEventRoutePath } from '~anyx/common/routing';
import { MdUserRole } from '~anyx/shared/graphql';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const MarketplaceEventListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.MarketplaceEventListPage }))
);

const MarketplaceEventTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.MarketplaceEventTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'marketplace-event',
    element: (
      <Auth0Guard>
        <Restricted roles={[MdUserRole.ADMIN, MdUserRole.STAFF]} fallback={<UnauthorizedMessage />}>
          <MarketplaceEventTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <MarketplaceEventListPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportMarketplaceEventRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
