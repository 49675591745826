import { useTranslation } from 'react-i18next';

const NoOptionsMessage = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div
      {...props['innerProps']}
      className="text-gray-7 py-2 text-center"
      // style={props['getStyles']('noOptionsMessage', props)}
    >
      {t('shared.select.noOption', { ns: 'shared' })}
    </div>
  );
};

const ValueContainer = ({ ...props }) => {
  const { flexWrap: _flexWrap, ...styles } = props['getStyles']('valueContainer', props);
  return (
    <div {...props['innerProps']} style={{ ...styles, whiteSpace: 'nowrap' }}>
      {props['children']}
    </div>
  );
};
export const AnyXSelectComponentsV2 = { NoOptionsMessage, ValueContainer };
