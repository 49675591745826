import { VariantProps, tv } from 'tailwind-variants';

import { AlertIcon } from '~anyx/shared/icons';

import { type SlotsToClasses } from '../../../types';

const styles = tv({
  slots: {
    root: 'flex gap-x-2',
    icon: 'h-6 w-6',
  },
  variants: {
    type: {
      warning: {
        icon: 'text-warning',
      },
      error: {
        icon: 'text-failure',
      },
    },
  },
});

type ModalAlertTitleVariants = VariantProps<typeof styles>;
type ModalAlertTitleSlots = keyof ReturnType<typeof styles>;

interface ModalAlertTitleProps extends ModalAlertTitleVariants {
  children: React.ReactNode;
  className?: string;
  classNames?: SlotsToClasses<ModalAlertTitleSlots>;
}

export const ModalAlertTitle = ({
  children,
  type = 'warning',
  className,
  classNames,
}: ModalAlertTitleProps) => {
  const { root, icon } = styles({ type });

  return (
    <div className={root({ class: [className, classNames?.root] })}>
      <AlertIcon className={icon({ class: classNames?.icon })} />
      {children}
    </div>
  );
};
