import React, { useContext } from 'react';

export type OnboardingContextType = {
  loading: boolean;
  error: boolean;
  refetchOnboarding: () => void;
  state: {
    hasShopifyChannel: boolean;
    hasRegisteredOrder: boolean;
    hasInputUnitCost: boolean;
    hasConnectedSaleChannel: boolean;
    hasConnectedDatasourceChannel: boolean;
    hasSaleChannelCostIndex: boolean;
    hasAnalyticsReport: boolean;
    hasAdvertisingDatasource: boolean;
    inactiveSaleChannelCount: number;
  };
};

export const OnboardingContext = React.createContext<OnboardingContextType>({
  loading: false,
  error: false,
  refetchOnboarding: () => null,
  state: {
    hasShopifyChannel: true,
    hasRegisteredOrder: true,
    hasInputUnitCost: true,
    hasConnectedSaleChannel: true,
    hasConnectedDatasourceChannel: true,
    hasSaleChannelCostIndex: true,
    hasAnalyticsReport: true,
    hasAdvertisingDatasource: true,
    inactiveSaleChannelCount: 0,
  },
});

export const useOnboardingCtx = () => {
  const { ...context } = useContext(OnboardingContext);

  return {
    ...context,
  };
};
