import { useTranslation } from 'react-i18next';

import { InputSearch } from '~anyx/shared/ui';
import { FilterProps } from '~anyx/shared/utils';

import { ProductTagsSelect } from '../../../../product-tags-select';

import { VariantSelectorFiltersType } from './VariantSelector.filters';

export const VariantSelectorFilters = ({
  removeFilter,
  changeFilter,
  current,
}: FilterProps<VariantSelectorFiltersType>) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-5 gap-3 pb-4 pt-2 md:grid-cols-4 lg:gap-4">
      <InputSearch
        className="col-span-3"
        onClear={() => {
          removeFilter('keyword');
        }}
        onSearch={(text) => changeFilter('keyword', text)}
        placeholder={t('logistic.component.variantSelector.filter.placeholder.keyword', {
          ns: 'logistic',
        })}
        value={current.keyword}
      />
      <div className="col-span-2 md:col-span-1">
        <ProductTagsSelect
          storeIds={current.storeId ? [current.storeId] : []}
          placeholder={t('marketplace.page.product.list.filter.placeholder.tags', {
            ns: 'marketplace',
          })}
          value={(current.tags || []).map((v) => ({ label: v, value: v }))}
          onChange={(options) => {
            if (!options.length) {
              return removeFilter('tags');
            }
            changeFilter(
              'tags',
              options.map((option) => option.value)
            );
          }}
          isMulti={true}
          isClearable
        />
      </div>
    </div>
  );
};
