import { useEffect, useRef } from 'react';

import { ObjectUtils } from '../utils';

export const useDeepComparisonEffect = (callback: () => void, dependencies: unknown[]) => {
  const currentDependenciesRef = useRef<unknown[]>();

  useEffect(() => {
    if (!ObjectUtils.isEqual(currentDependenciesRef.current, dependencies)) {
      callback();
    }

    currentDependenciesRef.current = dependencies;
  }, [callback, dependencies]);
};
