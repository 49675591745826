import { CountriesEnum } from '../enums';

import { AnyXLanguage } from './language.utils';

export class CountryUtils {
  static getPreferredCountryFromLanguage = (
    language?: AnyXLanguage | null,
    defaultCountry: CountriesEnum = CountriesEnum.US
  ) => {
    const country = language?.split('-')[1];

    return this.isCountry(country) ? country : defaultCountry;
  };

  static isCountry = (country = ''): country is CountriesEnum => country in CountriesEnum;

  static asCountry = (country?: string | null) =>
    country && this.isCountry(country) ? country : undefined;
}
