export enum UploadDocumentType {
  CSV = 'text/csv',
  MS_EXCEL = 'application/vnd.ms-excel',
  OFFICE_DOCUMENT_SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum FileTypes {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export interface FileRestriction {
  types?: { [k: string]: string[] };
  size?: number;
  dimensions?: {
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
    minWidth?: number;
  };
  supportedExtensions?: string[];
}

export type FileContent = string | ArrayBuffer | null | undefined;
