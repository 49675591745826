import { HttpResponse, delay, graphql } from 'msw';

import { DdiHomeReportPieChartQuery } from '../../graphql';

export const DdiMockPieChart = {
  DEFAULT: {
    ddiHomeReportPieChart: {
      __typename: 'DdiHomeReportPieChartPayload',
      currency: 'USD',
      grossSales: [
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '1513704',
          saleChannelType: 'Shopify',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '272029',
          saleChannelType: 'Lazada',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '271015',
          saleChannelType: 'Rakuten',
        },
      ],
      netSales: [
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '1513704',
          saleChannelType: 'Shopify',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '272029',
          saleChannelType: 'Lazada',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '271015',
          saleChannelType: 'Rakuten',
        },
      ],
      orders: [
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '1513704',
          saleChannelType: 'Shopify',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '272029',
          saleChannelType: 'Lazada',
        },
        {
          __typename: 'DdiHomeReportPieChartSectionPayload',
          value: '271015',
          saleChannelType: 'Rakuten',
        },
      ],
    },
  },
} satisfies Record<string, DdiHomeReportPieChartQuery>;

export const DdiMockPieChartQuery = {
  default: () => {
    return graphql.query('PieChart', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: DdiMockPieChart.DEFAULT });
    });
  },
};
