import { FacebookLoginProps, LoginResponse, FacebookLoginClient } from '../../components';

import { fetchUserAccounts } from './api';
import { FbPageData } from './types';

interface UseFBLoginAnyChatProps {
  onGrant?: (fbPages: FbPageData[]) => void;
  configId: string;
  appId: string;
}

export const useFBLoginAnyChat = ({
  onGrant,
  appId,
  configId,
}: UseFBLoginAnyChatProps): {
  loginProps: FacebookLoginProps;
} => {
  const onSuccessLogin = async (res?: LoginResponse['authResponse']) => {
    const expiresIn = res?.expiresIn ? Number(res.expiresIn) : 0;

    if (expiresIn > 0) {
      const FB = FacebookLoginClient.getFB();

      if (FB) {
        const fbPages = await fetchUserAccounts();
        onGrant?.(fbPages);
      }
    }
  };

  return {
    loginProps: {
      appId,
      loginOptions: {
        config_id: configId,
      },
      onSuccess: onSuccessLogin,
    },
  };
};
