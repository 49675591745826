import { ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/react';

import {
  GetCsvExportJobResultDocument,
  GetCsvExportJobResultQuery,
  GetCsvExportJobResultQueryVariables,
} from '../graphql';
import { JobState } from '../index';

import { useWatchLazyQuery } from './useWatchLazyQuery';

interface DownloadDocumentProps {
  onProcessStart?: () => void;
  onSuccess?: () => void;
  onComplete?: () => void;
  onFailure?: (error?: unknown) => void;
  handleDocumentDownloadUrl: (url: string) => void;
  pollIntervalInMs?: number;
}

export function useDownloadAnylogiDocument({
  onProcessStart,
  onSuccess,
  onComplete,
  onFailure,
  handleDocumentDownloadUrl,
  pollIntervalInMs = 5000,
}: DownloadDocumentProps) {
  const onSuccessCallback = ({
    data: queryResult,
  }: ApolloQueryResult<GetCsvExportJobResultQuery>) => {
    const {
      getCsvExportJobResult: { jobId, state, url },
    } = queryResult;

    switch (state) {
      case JobState.SUCCESS:
        if (!url) {
          Sentry.captureException(
            `Encountered Success State with Empty URL for Document Id ${jobId}`
          );
          onFailure?.();
          return true;
        }

        handleDocumentDownloadUrl(url);
        onSuccess?.();
        return true;

      case JobState.FAILURE:
        onFailure?.();
        return true;

      default:
        return false;
    }
  };

  const onErrorCallback = (error: unknown) => {
    onFailure?.(error);
  };

  const { listenToWatchedLazyQuery } = useWatchLazyQuery<
    GetCsvExportJobResultQuery,
    GetCsvExportJobResultQueryVariables
  >({
    query: GetCsvExportJobResultDocument,
    options: {
      pollInterval: pollIntervalInMs,
    },
  });

  const handleDownloadDocument = (queryVariables: GetCsvExportJobResultQueryVariables) => {
    onProcessStart?.();
    listenToWatchedLazyQuery(queryVariables, onSuccessCallback, onErrorCallback, () => {
      onComplete?.();
    });
  };

  return {
    handleDownloadDocument,
  };
}
