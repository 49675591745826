interface TitledIconProps {
  icon: React.ReactNode;
  text?: string | null;
}

export const TitledIcon = ({ icon, text }: TitledIconProps) => {
  return (
    <div className="w-fit">
      {icon}
      {text && (
        <div className="bg-gray-7 text-4xs flex items-center justify-center rounded-xl font-bold uppercase text-white">
          {text.substring(0, 2)}
        </div>
      )}
    </div>
  );
};
