import { Loader } from '@any-ui-react/core';

import { useWorkspaceCtx } from '~anyx/common/workspace';
import { LoadWrapper } from '~anyx/shared/ui';

import { useGetInactiveIntegrations } from './operation';

export const InactiveStorePill = () => {
  const { workspace } = useWorkspaceCtx();
  const { inactiveIntregrationCount, loading } = useGetInactiveIntegrations({
    storeId: workspace?.storeId,
  });

  return inactiveIntregrationCount || loading ? (
    <LoadWrapper loading={loading} loadingTemplate={<Loader size="xs" />}>
      <div className="bg-failure text-3xs ml-auto flex h-4 items-center justify-center rounded-full px-1.5 text-white">
        {inactiveIntregrationCount > 20 ? '20+' : inactiveIntregrationCount}
      </div>
    </LoadWrapper>
  ) : null;
};
