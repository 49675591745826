import { AddressPart, CountriesEnum } from '~anyx/shared/utils';

import { AutoCompleteResult } from './InputPostalCode';

const GOOGLE_ADDRESS_BINDINGS: Record<string, Record<keyof AutoCompleteResult, string[]>> = {
  [CountriesEnum.JP]: {
    [AddressPart.POSTAL_CODE]: ['postal_code'],
    [AddressPart.REGION]: ['administrative_area_level_1'],
    [AddressPart.CITY]: ['locality'],
    [AddressPart.ADDRESS_FIRST]: ['sublocality', 'sublocality_level_1', 'sublocality_level_2'],
    [AddressPart.ADDRESS_SECOND]: [
      'sublocality_level_3',
      'sublocality_level_4',
      'sublocality_level_5',
    ],
  },
  [CountriesEnum.TW]: {
    [AddressPart.POSTAL_CODE]: ['postal_code'],
    [AddressPart.REGION]: ['administrative_area_level_1', 'administrative_area_level_2'],
    [AddressPart.CITY]: ['administrative_area_level_3'],
    [AddressPart.ADDRESS_FIRST]: ['administrative_area_level_4'],
    [AddressPart.ADDRESS_SECOND]: ['administrative_area_level_5'],
  },
  [CountriesEnum.TH]: {
    [AddressPart.POSTAL_CODE]: ['postal_code'],
    [AddressPart.REGION]: ['administrative_area_level_1'],
    [AddressPart.CITY]: [],
    [AddressPart.ADDRESS_FIRST]: [],
    [AddressPart.ADDRESS_SECOND]: [],
  },
};

const DEFAULT_BINDINGS = {
  [AddressPart.POSTAL_CODE]: ['postal_code'],
  [AddressPart.REGION]: ['administrative_area_level_1'],
  [AddressPart.CITY]: ['locality', 'administrative_area_level_2'],
  [AddressPart.ADDRESS_FIRST]: [
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
  ],
  [AddressPart.ADDRESS_SECOND]: [
    'sublocality_level_3',
    'sublocality_level_4',
    'sublocality_level_5',
  ],
};

export const buildAddress = (
  country: string,
  place: google.maps.places.PlaceResult
): AutoCompleteResult => {
  const components = place.address_components ?? [];
  const bindings = GOOGLE_ADDRESS_BINDINGS[country] || DEFAULT_BINDINGS;

  const completion: AutoCompleteResult = {
    [AddressPart.POSTAL_CODE]: '',
    [AddressPart.REGION]: '',
    [AddressPart.CITY]: '',
    [AddressPart.ADDRESS_FIRST]: '',
    [AddressPart.ADDRESS_SECOND]: '',
  };

  Object.keys(completion).forEach((field) => {
    const fieldKey = field as keyof AutoCompleteResult;
    const binding = bindings?.[fieldKey] ?? [];
    let matches = components.filter(({ types }) => types.some((type) => binding.includes(type)));
    matches = matches.sort((a, b) =>
      a['types'].toLocaleString() < b['types'].toLocaleString() ? -1 : 1
    );

    completion[fieldKey] = matches
      .reduce<string[]>((acc, { long_name: name }) => [...acc, name], [])
      .join(' ');
  });

  return completion;
};
