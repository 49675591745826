import { To, useNavigate } from 'react-router-dom';

import { Button } from '@any-ui-react/core';

import { ErrorCard } from '../../../molecules';

interface ErrorBoundaryMessageProps {
  titleText: string;
  messageText: string;
  btnText: string;
  redirectTo?: To;
  showDetails?: boolean;
  componentStack?: string;
  error?: unknown;
  resetError?: () => void;
}

export const ErrorBoundaryMessage = ({
  titleText = '',
  messageText = '',
  btnText = '',
  redirectTo = '',
  showDetails = false,
  componentStack = '',
  error,
  resetError,
}: ErrorBoundaryMessageProps) => {
  const navigate = useNavigate();
  return (
    <ErrorCard
      title={titleText}
      guideline={messageText}
      content={
        <>
          <Button
            variant="default"
            onClick={() => {
              resetError?.();
              if (redirectTo) {
                navigate(redirectTo);
              }
            }}
          >
            {btnText}
          </Button>
          {showDetails && (
            <div className="mt-4 border-t-2 text-center">
              <div className="py-3 text-lg font-semibold">{error?.toString()}</div>
              <div>{componentStack}</div>
            </div>
          )}
        </>
      }
    />
  );
};
