import { AnyXLanguage, LanguageUtils } from './language.utils';

export class NumberUtils {
  static toNumber(value: unknown) {
    return Number(value) || 0;
  }

  static isNumber(value: unknown, allowEmptyString = false): value is number {
    const isNumOrStr = typeof value === 'number' || typeof value === 'string';

    if (!isNumOrStr) {
      return false;
    }

    if (!allowEmptyString && value.toString().trim() === '') {
      return false;
    }

    return !isNaN(Number(value.toString()));
  }

  static format = (
    number: number | bigint | string,
    options?: Intl.NumberFormatOptions,
    language?: AnyXLanguage
  ): string => {
    return new Intl.NumberFormat(
      LanguageUtils.getTwoLettersCode(language || LanguageUtils.DEFAULT),
      options
    ).format(Number(number));
  };

  static parseInt(value: string) {
    return Number.parseInt(value, 10);
  }
}
