import { Text } from 'recharts';

import { LanguageUtils } from '~anyx/shared/utils';

import { style } from './chart.theme';

interface TickProps {
  x?: number;
  y?: number;
  payload?: { value?: number };
  theme?: 'light' | 'dark';
}

export const CustomXAxisTick = ({ x, y, payload, theme = 'light' }: TickProps) => {
  const formatChartDate = (date: number) => {
    // https://github.com/date-fns/date-fns/issues/1946
    // Always show localized month and date in simple format
    return new Date(date).toLocaleDateString(LanguageUtils.getCurrentLanguage(), {
      month: 'numeric',
      day: 'numeric',
    });
  };

  return x && y && payload ? (
    <Text
      fill={style[theme].xAxis.tick.text}
      lineHeight={12}
      fontSize={10}
      x={x}
      y={y}
      width={20}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {payload?.value && formatChartDate(payload?.value)}
    </Text>
  ) : null;
};
