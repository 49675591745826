export const ProgressBar = (props: {
  label?: string;
  value: number;
  minValue?: number;
  maxValue?: number;
}) => {
  const { label, value, minValue = 0, maxValue = 100 } = props;

  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;

  return (
    <div className="flex items-center">
      {label && <span className="text-success mr-2 font-semibold">{label}</span>}
      <div className="bg-gray-1 h-2 w-40 rounded-3xl">
        <div style={{ width: barWidth }} className="bg-progressBarGradient h-2 rounded-3xl" />
      </div>
    </div>
  );
};
