import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

export interface PwaStore {
  isReady: boolean;
  hasUpdate: boolean;
  setHasUpdate: (hasUpdate: boolean) => void;
  setIsReady: (isReady: boolean) => void;
}

export const pwaStore = createStore<PwaStore>()(
  devtools(
    immer((set) => ({
      isReady: false,
      hasUpdate: false,
      setHasUpdate: (hasUpdate) =>
        set(({ hasUpdate } = { hasUpdate: false }), hasUpdate, '[pwa] setHasUpdate'),
      setIsReady: (isReady) => set({ isReady }, false, '[pwa] setIsReady'),
    })),
    {
      name: 'Pwa',
    }
  )
);

export const usePwaStore = <T>(selector: (state: PwaStore) => T): T => useStore(pwaStore, selector);
