import { useEffect, useRef, useState } from 'react';

import { MediaQueryType, useScreenBreakpoint } from '~anyx/shared/utils';

export const useGradientScroll = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isUnderSmallScreen = useScreenBreakpoint('md', MediaQueryType.MAX);

  const [showGradient, setShowGradient] = useState(true);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        const hasOverflow = scrollHeight > clientHeight;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1; // -1 for rounding errors
        const showInitialGradient = scrollHeight === clientHeight && scrollTop === 0;
        setShowGradient(showInitialGradient ? true : hasOverflow && !isAtBottom);
      }
    };

    handleScroll();
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, []);

  return {
    showGradient: isUnderSmallScreen ? false : showGradient,
    scrollContainerRef,
  };
};
