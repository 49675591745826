/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

import { CountriesEnum } from '../enums';
import { Notation, AddressPart } from '../models/address.model';

import { CountryUtils } from './country.utils';

export class AddressUtils {
  static readonly formats = {
    [Notation.COMPACT]: {
      0: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      1: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      2: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      3: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      4: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      5: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      6: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      7: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      8: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      9: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      10: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      11: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      12: `${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.CITY}/${AddressPart.ADDRESS_FIRST}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      13: `${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      14: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      15: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      16: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      17: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      18: `${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      19: `${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      20: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      21: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      22: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      23: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      24: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      25: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      26: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      27: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      28: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      29: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      30: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      31: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      32: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      33: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
    },
    [Notation.FULL]: {
      0: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      1: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      2: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      3: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      4: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      5: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      6: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      7: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      8: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      9: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      10: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      11: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      12: `${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      13: `${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      14: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      15: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      16: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      17: `${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      18: `${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      19: `${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      20: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      21: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      22: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      23: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      24: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      25: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      26: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      27: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      28: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      29: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.REGION}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      30: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      31: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      32: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.LASTNAME}/${AddressPart.FIRSTNAME}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.BREAK}/${AddressPart.REGION}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
      33: `${AddressPart.COUNTRY}/${AddressPart.BREAK}/${AddressPart.POSTAL_CODE}/${AddressPart.CITY}/${AddressPart.BREAK}/${AddressPart.FIRSTNAME}/${AddressPart.LASTNAME}/${AddressPart.BREAK}/${AddressPart.COMPANY}/${AddressPart.BREAK}/${AddressPart.ADDRESS_FIRST}/${AddressPart.BREAK}/${AddressPart.ADDRESS_SECOND}/${AddressPart.BREAK}/${AddressPart.PHONE}`,
    },
  };

  static readonly countries = {
    [CountriesEnum.JP]: {
      format: 12,
      googleBindings: {
        [AddressPart.POSTAL_CODE]: ['postal_code'],
        [AddressPart.CITY]: ['locality'],
        [AddressPart.ADDRESS_FIRST]: [
          'sublocality_level_1',
          'sublocality_level_2',
          'sublocality_level_3',
          'sublocality_level_4',
          'sublocality_level_5',
        ],
        [AddressPart.REGION]: ['administrative_area_level_1'],
        [AddressPart.COUNTRY]: ['country'],
      },
    },
    [CountriesEnum.AF]: {
      format: 0,
    },
    [CountriesEnum.AX]: {
      format: 1,
    },
    [CountriesEnum.AL]: {
      format: 0,
    },
    [CountriesEnum.DZ]: {
      format: 1,
    },
    [CountriesEnum.AD]: {
      format: 1,
    },
    [CountriesEnum.AO]: {
      format: 2,
    },
    [CountriesEnum.AI]: {
      format: 2,
    },
    [CountriesEnum.AG]: {
      format: 2,
    },
    [CountriesEnum.AR]: {
      format: 3,
    },
    [CountriesEnum.AM]: {
      format: 1,
    },
    [CountriesEnum.AW]: {
      format: 2,
    },
    [CountriesEnum.AU]: {
      format: 4,
    },
    [CountriesEnum.AT]: {
      format: 1,
    },
    [CountriesEnum.AZ]: {
      format: 1,
    },
    [CountriesEnum.BS]: {
      format: 2,
    },
    [CountriesEnum.BH]: {
      format: 0,
    },
    [CountriesEnum.BD]: {
      format: 0,
    },
    [CountriesEnum.BB]: {
      format: 0,
    },
    [CountriesEnum.BY]: {
      format: 1,
    },
    [CountriesEnum.BE]: {
      format: 1,
    },
    [CountriesEnum.BZ]: {
      format: 2,
    },
    [CountriesEnum.BJ]: {
      format: 2,
    },
    [CountriesEnum.BM]: {
      format: 0,
    },
    [CountriesEnum.BT]: {
      format: 0,
    },
    [CountriesEnum.BO]: {
      format: 2,
    },
    [CountriesEnum.BA]: {
      format: 1,
    },
    [CountriesEnum.BW]: {
      format: 0,
    },
    [CountriesEnum.BV]: {
      format: 0,
    },
    [CountriesEnum.BR]: {
      format: 4,
    },
    [CountriesEnum.IO]: {
      format: 0,
    },
    [CountriesEnum.BN]: {
      format: 0,
    },
    [CountriesEnum.BG]: {
      format: 1,
    },
    [CountriesEnum.BF]: {
      format: 2,
    },
    [CountriesEnum.BI]: {
      format: 0,
    },
    [CountriesEnum.KH]: {
      format: 0,
    },
    [CountriesEnum.CA]: {
      format: 4,
    },
    [CountriesEnum.CV]: {
      format: 1,
    },
    [CountriesEnum.BQ]: {
      format: 0,
    },
    [CountriesEnum.KY]: {
      format: 0,
    },
    [CountriesEnum.CF]: {
      format: 0,
    },
    [CountriesEnum.TD]: {
      format: 2,
    },
    [CountriesEnum.CL]: {
      format: 5,
    },
    [CountriesEnum.CN]: {
      format: 4,
    },
    [CountriesEnum.CX]: {
      format: 0,
    },
    [CountriesEnum.CC]: {
      format: 0,
    },
    [CountriesEnum.CO]: {
      format: 4,
    },
    [CountriesEnum.KM]: {
      format: 0,
    },
    [CountriesEnum.CG]: {
      format: 0,
    },
    [CountriesEnum.CD]: {
      format: 0,
    },
    [CountriesEnum.CK]: {
      format: 0,
    },
    [CountriesEnum.CR]: {
      format: 0,
    },
    [CountriesEnum.HR]: {
      format: 1,
    },
    [CountriesEnum.CU]: {
      format: 1,
    },
    [CountriesEnum.CW]: {
      format: 2,
    },
    [CountriesEnum.CY]: {
      format: 1,
    },
    [CountriesEnum.CZ]: {
      format: 1,
    },
    [CountriesEnum.CI]: {
      format: 0,
    },
    [CountriesEnum.DK]: {
      format: 1,
    },
    [CountriesEnum.DJ]: {
      format: 2,
    },
    [CountriesEnum.DM]: {
      format: 1,
    },
    [CountriesEnum.DO]: {
      format: 1,
    },
    [CountriesEnum.EC]: {
      format: 1,
    },
    [CountriesEnum.EG]: {
      format: 4,
    },
    [CountriesEnum.SV]: {
      format: 0,
    },
    [CountriesEnum.GQ]: {
      format: 0,
    },
    [CountriesEnum.ER]: {
      format: 0,
    },
    [CountriesEnum.EE]: {
      format: 1,
    },
    [CountriesEnum.SZ]: {
      format: 0,
    },
    [CountriesEnum.ET]: {
      format: 0,
    },
    [CountriesEnum.FK]: {
      format: 0,
    },
    [CountriesEnum.FO]: {
      format: 1,
    },
    [CountriesEnum.FJ]: {
      format: 2,
    },
    [CountriesEnum.FI]: {
      format: 1,
    },
    [CountriesEnum.FR]: {
      format: 6,
    },
    [CountriesEnum.GF]: {
      format: 1,
    },
    [CountriesEnum.PF]: {
      format: 1,
    },
    [CountriesEnum.TF]: {
      format: 0,
    },
    [CountriesEnum.GA]: {
      format: 0,
    },
    [CountriesEnum.GM]: {
      format: 0,
    },
    [CountriesEnum.GE]: {
      format: 1,
    },
    [CountriesEnum.DE]: {
      format: 1,
    },
    [CountriesEnum.GH]: {
      format: 22,
    },
    [CountriesEnum.GI]: {
      format: 7,
    },
    [CountriesEnum.GR]: {
      format: 1,
    },
    [CountriesEnum.GL]: {
      format: 1,
    },
    [CountriesEnum.GD]: {
      format: 0,
    },
    [CountriesEnum.GP]: {
      format: 1,
    },
    [CountriesEnum.GT]: {
      format: 4,
    },
    [CountriesEnum.GG]: {
      format: 0,
    },
    [CountriesEnum.GN]: {
      format: 0,
    },
    [CountriesEnum.GW]: {
      format: 1,
    },
    [CountriesEnum.GY]: {
      format: 0,
    },
    [CountriesEnum.HT]: {
      format: 1,
    },
    [CountriesEnum.HM]: {
      format: 0,
    },
    [CountriesEnum.VA]: {
      format: 8,
    },
    [CountriesEnum.HN]: {
      format: 1,
    },
    [CountriesEnum.HK]: {
      format: 9,
    },
    [CountriesEnum.HU]: {
      format: 0,
    },
    [CountriesEnum.IS]: {
      format: 1,
    },
    [CountriesEnum.IN]: {
      format: 4,
    },
    [CountriesEnum.ID]: {
      format: 10,
    },
    [CountriesEnum.IR]: {
      format: 1,
    },
    [CountriesEnum.IQ]: {
      format: 0,
    },
    [CountriesEnum.IE]: {
      format: 4,
    },
    [CountriesEnum.IM]: {
      format: 0,
    },
    [CountriesEnum.IL]: {
      format: 1,
    },
    [CountriesEnum.IT]: {
      format: 11,
    },
    [CountriesEnum.JM]: {
      format: 2,
    },
    [CountriesEnum.JE]: {
      format: 0,
    },
    [CountriesEnum.JO]: {
      format: 0,
    },
    [CountriesEnum.KZ]: {
      format: 0,
    },
    [CountriesEnum.KE]: {
      format: 0,
    },

    [CountriesEnum.KI]: {
      format: 0,
    },
    [CountriesEnum.KP]: {
      format: 0,
    },
    [CountriesEnum.KW]: {
      format: 0,
    },
    [CountriesEnum.KG]: {
      format: 13,
    },
    [CountriesEnum.LA]: {
      format: 0,
    },
    [CountriesEnum.LV]: {
      format: 0,
    },
    [CountriesEnum.LB]: {
      format: 0,
    },
    [CountriesEnum.LS]: {
      format: 0,
    },
    [CountriesEnum.LR]: {
      format: 1,
    },
    [CountriesEnum.LY]: {
      format: 0,
    },
    [CountriesEnum.LI]: {
      format: 1,
    },
    [CountriesEnum.LT]: {
      format: 0,
    },
    [CountriesEnum.LU]: {
      format: 1,
    },
    [CountriesEnum.MO]: {
      format: 14,
    },

    [CountriesEnum.MG]: {
      format: 1,
    },

    [CountriesEnum.MW]: {
      format: 2,
    },

    [CountriesEnum.MY]: {
      format: 3,
    },
    [CountriesEnum.MV]: {
      format: 0,
    },
    [CountriesEnum.ML]: {
      format: 2,
    },
    [CountriesEnum.MT]: {
      format: 0,
    },
    [CountriesEnum.MQ]: {
      format: 1,
    },
    [CountriesEnum.MR]: {
      format: 0,
    },
    [CountriesEnum.MU]: {
      format: 0,
    },
    [CountriesEnum.YT]: {
      format: 1,
    },
    [CountriesEnum.MX]: {
      format: 3,
    },
    [CountriesEnum.MD]: {
      format: 1,
    },
    [CountriesEnum.MC]: {
      format: 1,
    },
    [CountriesEnum.MN]: {
      format: 0,
    },
    [CountriesEnum.ME]: {
      format: 1,
    },
    [CountriesEnum.MS]: {
      format: 0,
    },
    [CountriesEnum.MA]: {
      format: 1,
    },
    [CountriesEnum.MZ]: {
      format: 1,
    },
    [CountriesEnum.MM]: {
      format: 0,
    },
    [CountriesEnum.NA]: {
      format: 0,
    },
    [CountriesEnum.NR]: {
      format: 0,
    },
    [CountriesEnum.NP]: {
      format: 0,
    },
    [CountriesEnum.NL]: {
      format: 1,
    },
    [CountriesEnum.NC]: {
      format: 1,
    },
    [CountriesEnum.NZ]: {
      format: 4,
    },

    [CountriesEnum.NI]: {
      format: 15,
    },
    [CountriesEnum.NE]: {
      format: 1,
    },
    [CountriesEnum.NG]: {
      format: 4,
    },
    [CountriesEnum.NU]: {
      format: 0,
    },
    [CountriesEnum.NF]: {
      format: 0,
    },
    [CountriesEnum.MK]: {
      format: 1,
    },
    [CountriesEnum.NO]: {
      format: 1,
    },
    [CountriesEnum.OM]: {
      format: 15,
    },
    [CountriesEnum.PK]: {
      format: 0,
    },
    [CountriesEnum.PS]: {
      format: 0,
    },
    [CountriesEnum.PA]: {
      format: 9,
    },
    [CountriesEnum.PG]: {
      format: 0,
    },

    [CountriesEnum.PY]: {
      format: 1,
    },
    [CountriesEnum.PE]: {
      format: 4,
    },
    [CountriesEnum.PH]: {
      format: 5,
    },
    [CountriesEnum.PN]: {
      format: 16,
    },
    [CountriesEnum.PL]: {
      format: 1,
    },
    [CountriesEnum.PT]: {
      format: 17,
    },
    [CountriesEnum.QA]: {
      format: 2,
    },
    [CountriesEnum.CM]: {
      format: 0,
    },
    [CountriesEnum.RE]: {
      format: 1,
    },
    [CountriesEnum.RO]: {
      format: 3,
    },
    [CountriesEnum.RU]: {
      format: 4,
    },
    [CountriesEnum.RW]: {
      format: 0,
    },
    [CountriesEnum.BL]: {
      format: 1,
    },
    [CountriesEnum.SH]: {
      format: 0,
    },
    [CountriesEnum.KN]: {
      format: 0,
    },
    [CountriesEnum.LC]: {
      format: 0,
    },
    [CountriesEnum.MF]: {
      format: 1,
    },
    [CountriesEnum.PM]: {
      format: 1,
    },
    [CountriesEnum.WS]: {
      format: 0,
    },
    [CountriesEnum.SM]: {
      format: 1,
    },
    [CountriesEnum.ST]: {
      format: 0,
    },
    [CountriesEnum.SA]: {
      format: 0,
    },
    [CountriesEnum.SN]: {
      format: 1,
    },
    [CountriesEnum.RS]: {
      format: 1,
    },
    [CountriesEnum.SC]: {
      format: 0,
    },
    [CountriesEnum.SL]: {
      format: 2,
    },
    [CountriesEnum.SG]: {
      format: 7,
    },
    [CountriesEnum.SX]: {
      format: 0,
    },
    [CountriesEnum.SK]: {
      format: 1,
    },
    [CountriesEnum.SI]: {
      format: 1,
    },
    [CountriesEnum.SB]: {
      format: 0,
    },
    [CountriesEnum.SO]: {
      format: 0,
    },
    [CountriesEnum.ZA]: {
      format: 4,
    },
    [CountriesEnum.GS]: {
      format: 7,
    },
    [CountriesEnum.KR]: {
      format: 18,
    },
    [CountriesEnum.SS]: {
      format: 2,
    },
    [CountriesEnum.ES]: {
      format: 11,
    },
    [CountriesEnum.LK]: {
      format: 0,
    },
    [CountriesEnum.VC]: {
      format: 0,
    },
    [CountriesEnum.SD]: {
      format: 1,
    },
    [CountriesEnum.SR]: {
      format: 0,
    },
    [CountriesEnum.SJ]: {
      format: 1,
    },
    [CountriesEnum.SE]: {
      format: 1,
    },
    [CountriesEnum.CH]: {
      format: 1,
    },
    [CountriesEnum.SY]: {
      format: 0,
    },
    [CountriesEnum.TW]: {
      format: 0,
    },
    [CountriesEnum.TJ]: {
      format: 1,
    },
    [CountriesEnum.TZ]: {
      format: 1,
    },
    [CountriesEnum.TH]: {
      format: 4,
    },
    [CountriesEnum.TL]: {
      format: 0,
    },
    [CountriesEnum.TG]: {
      format: 2,
    },
    [CountriesEnum.TK]: {
      format: 0,
    },
    [CountriesEnum.TO]: {
      format: 2,
    },
    [CountriesEnum.TT]: {
      format: 2,
    },
    [CountriesEnum.TN]: {
      format: 1,
    },
    [CountriesEnum.TR]: {
      format: 1,
    },
    [CountriesEnum.TM]: {
      format: 19,
    },
    [CountriesEnum.TC]: {
      format: 0,
    },
    [CountriesEnum.TV]: {
      format: 2,
    },
    [CountriesEnum.UG]: {
      format: 2,
    },
    [CountriesEnum.UA]: {
      format: 0,
    },
    [CountriesEnum.AE]: {
      format: 9,
    },
    [CountriesEnum.GB]: {
      format: 0,
    },
    [CountriesEnum.US]: {
      format: 4,
    },
    [CountriesEnum.UM]: {
      format: 0,
    },
    [CountriesEnum.UY]: {
      format: 1,
    },
    [CountriesEnum.UZ]: {
      format: 0,
    },
    [CountriesEnum.VU]: {
      format: 2,
    },
    [CountriesEnum.VE]: {
      format: 0,
    },
    [CountriesEnum.VN]: {
      format: 0,
    },
    [CountriesEnum.VG]: {
      format: 0,
    },
    [CountriesEnum.WF]: {
      format: 1,
    },
    [CountriesEnum.EH]: {
      format: 0,
    },
    [CountriesEnum.YE]: {
      format: 2,
    },
    [CountriesEnum.ZM]: {
      format: 1,
    },
    [CountriesEnum.ZW]: {
      format: 2,
    },
  } as {
    [key in CountriesEnum]: {
      format: keyof typeof AddressUtils.formats.compact | keyof typeof AddressUtils.formats.full;
      googleBindings?: { [k in AddressPart]: string[] };
    };
  };

  static getLocaleFullAddress(country: CountriesEnum, format = Notation.COMPACT): AddressPart[] {
    const addressFormat =
      AddressUtils.formats[format][AddressUtils.getCountriesEnumFormatKey(country)];
    return addressFormat.split('/') as AddressPart[];
  }

  static getLocaleAddress(
    country: CountriesEnum | string,
    parts: AddressPart[] = [
      AddressPart.FIRSTNAME,
      AddressPart.LASTNAME,
      AddressPart.COUNTRY,
      AddressPart.POSTAL_CODE,
      AddressPart.COMPANY,
      AddressPart.REGION,
      AddressPart.CITY,
      AddressPart.PHONE,
      AddressPart.ADDRESS_FIRST,
      AddressPart.ADDRESS_SECOND,
    ],
    format = Notation.COMPACT
  ): AddressPart[] {
    if (!CountryUtils.isCountry(country)) {
      return [];
    }

    const fieldSep = '/';

    const requestedParts = new Set<AddressPart>(parts).add(AddressPart.BREAK);
    const addressFormat = AddressUtils.formats[format][
      AddressUtils.getCountriesEnumFormatKey(country)
    ].split(fieldSep) as AddressPart[];

    let inLine = false;
    const selection = new Array<AddressPart>();
    addressFormat.forEach((field) => {
      if (requestedParts.has(field)) {
        if (field !== AddressPart.BREAK) {
          inLine = true;
        }
        if (inLine) {
          selection.push(field);
        }
      }
      if (field === AddressPart.BREAK) {
        inLine = false;
      }
    });
    const end = selection.length - 1;
    if (end >= 0 && selection[end] === AddressPart.BREAK) {
      selection.pop();
    }
    return selection;
  }

  private static getCountriesEnumFormatKey(country: CountriesEnum) {
    return (
      AddressUtils.countries[country]?.format ?? AddressUtils.countries[CountriesEnum.JP].format
    );
  }

  static zipCodeLinkDetails(zipCode: string, country?: CountriesEnum) {
    switch (country) {
      case CountriesEnum.JP:
        return `https://www.post.japanpost.jp/cgi-zip/zipcode.php?zip=${zipCode.replace('-', '')}`;
      default:
        return '';
    }
  }
}
