import { ReactElement, useMemo } from 'react';
import { FieldValues, FormProvider, FormProviderProps } from 'react-hook-form';

import {
  AddressPart,
  AddressUtils,
  CountryUtils,
  LanguageUtils,
  ObjectUtils,
  CountriesEnum,
  AnyXLanguage,
} from '~anyx/shared/utils';

import { AddressFormContext } from './context';
import {
  AddressMappings,
  ComponentsOverride,
  ComponentsProps,
  FunctionalAddressPart,
  renderComponent,
} from './DynamicAddressFields.type';

export interface DynamicAddressFieldsProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues = TFieldValues
> {
  countries?: CountriesEnum[];
  language?: AnyXLanguage;
  mappings?: AddressMappings<TFieldValues>;
  loading?: boolean;
  componentProps?: ComponentsProps;
  useRegionId?: boolean;
  componentOverride?: ComponentsOverride;
  formContext: Omit<FormProviderProps<TFieldValues, TContext, TTransformedValues>, 'children'>;
}

export const DynamicAddressFields = <T extends FieldValues>({
  mappings = {
    [AddressPart.COUNTRY]: AddressPart.COUNTRY,
    [AddressPart.POSTAL_CODE]: AddressPart.POSTAL_CODE,
    [AddressPart.REGION]: AddressPart.REGION,
    [AddressPart.CITY]: AddressPart.CITY,
    [AddressPart.ADDRESS_FIRST]: AddressPart.ADDRESS_FIRST,
    [AddressPart.ADDRESS_SECOND]: AddressPart.ADDRESS_SECOND,
    [AddressPart.FIRSTNAME]: AddressPart.FIRSTNAME,
    [AddressPart.LASTNAME]: AddressPart.LASTNAME,
    [AddressPart.COMPANY]: AddressPart.COMPANY,
    [AddressPart.PHONE]: AddressPart.PHONE,
  } as AddressMappings<T>,
  componentProps,
  componentOverride,
  useRegionId = true,
  countries = ObjectUtils.arrayFromEnum<CountriesEnum>(CountriesEnum),
  language = LanguageUtils.getCurrentLanguage(),
  formContext,
}: DynamicAddressFieldsProps<T>) => {
  const country = formContext.watch(mappings.country);

  const addressFormFields = useMemo((): ReactElement[] => {
    const selectedCountry = CountryUtils.asCountry(country);

    const countrySelect = renderComponent(
      AddressPart.COUNTRY,
      {
        ...componentProps?.[AddressPart.COUNTRY],
      },
      componentOverride
    );

    if (!selectedCountry) {
      return [countrySelect];
    }

    const localizedAddress = AddressUtils.getLocaleAddress(
      selectedCountry,
      Object.keys(mappings) as FunctionalAddressPart[]
    );

    const otherComponents = localizedAddress.flatMap((part) => {
      if (part === AddressPart.BREAK) return [];
      if (part === AddressPart.COUNTRY) return [];

      return renderComponent(part, { ...componentProps?.[part] }, componentOverride);
    });

    return [countrySelect, ...otherComponents];
  }, [componentOverride, componentProps, country, mappings]);

  return (
    <FormProvider {...formContext}>
      <AddressFormContext.Provider
        value={{
          mappings,
          selectedCountry: country,
          language,
          countries,
          useRegionId,
        }}
      >
        {addressFormFields}
      </AddressFormContext.Provider>
    </FormProvider>
  );
};
