import classNames from 'classnames';

import { useLayoutStore, BrowserUtils } from '~anyx/shared/utils';

export interface AppLayoutProps {
  navigation: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
}

export const AppLayout = ({ navigation, content, footer }: AppLayoutProps) => {
  const contentClamped = useLayoutStore((state) => state.clamped);
  const withNavigation = useLayoutStore((state) => state.withNavigation);
  const withFooter = useLayoutStore((state) => state.withFooter);
  const fullPage = useLayoutStore((state) => state.fullPage);
  const header = useLayoutStore((state) => state.header);
  const isSafari = BrowserUtils.isSafari();

  return (
    <div className="flex min-h-dvh flex-col items-stretch lg:flex-row">
      {withNavigation && navigation}

      <div
        className={classNames('flex flex-1 flex-col', {
          'max-w-clamped lg:mx-auto': contentClamped,
          'overflow-scroll': isSafari,
          'overflow-auto': !isSafari,
        })}
      >
        {header()}
        <main
          className={classNames('relative flex w-full flex-1', {
            'lg:px-6 lg:pt-4': !fullPage,
          })}
        >
          {content}
        </main>
        {withFooter && footer}
      </div>
    </div>
  );
};
