export const style = {
  light: {
    linearGradientStart: {
      offset: '40%',
      stopColor: 'rgba(159, 209, 255, 0.89)',
      stopOpacity: 1,
    },
    linearGradientEnd: {
      offset: '90%',
      stopColor: 'rgba(182, 232, 254, 0)',
      stopOpacity: 1,
    },
    cartesianGrid: {
      stroke: '#DEE5EC',
    },
    activeDot: {
      fill: 'white',
      strokeWidth: 2,
    },
    xAxis: {
      tickLine: {
        stroke: '#DEE5EC',
        strokeWidth: 1,
        opacity: undefined,
      },
      axisLine: {
        stroke: '#DEE5EC',
        strokeWidth: 1,
        opacity: undefined,
      },
      tick: {
        text: '#27313B',
        fill: '#27313B',
        fontSize: 10,
      },
    },
    yAxis: {
      tick: {
        fill: '#27313B',
        fontSize: 10,
      },
    },
    tooltip: {
      cursor: {
        stroke: '#3892E5',
        strokeWidth: 1,
        opacity: 0.4,
      },
    },
    legend: {
      text: '#27313B',
    },
    line: {
      stroke: '#3892E5',
      activeDot: {
        stroke: '#3892E5',
      },
    },
    area: {
      stroke: '#45A5FF',
      strokeWidth: 2,
    },
  },
  dark: {
    linearGradientStart: {
      offset: '20%',
      stopColor: 'rgba(76, 156, 231, 0.89)',
      stopOpacity: 1,
    },
    linearGradientEnd: {
      offset: '90%',
      stopColor: 'rgba(128, 84, 223, 0)',
      stopOpacity: 1,
    },
    cartesianGrid: {
      stroke: 'rgba(238, 243, 247, 0.2)',
    },
    activeDot: {
      fill: '#27313b',
      strokeWidth: 2,
    },
    xAxis: {
      tickLine: {
        stroke: '#EEF3F7',
        strokeWidth: 1,
        opacity: 0.2,
      },
      axisLine: {
        stroke: '#EEF3F7',
        strokeWidth: 1,
        opacity: 0.2,
      },
      tick: {
        text: 'white',
        fill: 'white',
        fontSize: 10,
      },
    },
    yAxis: {
      tick: {
        fill: 'white',
        fontSize: 10,
      },
    },
    tooltip: {
      cursor: {
        stroke: '#EEF3F7',
        strokeWidth: 1,
        opacity: 0.2,
      },
    },
    legend: {
      text: 'white',
    },
    line: {
      stroke: '#3892E5',
      activeDot: {
        stroke: '#3892E5',
      },
    },
    area: {
      stroke: '#45A5FF',
      strokeWidth: 2,
    },
  },
};
