import { MdCountryEnum, Country, MarketplaceSaleChannelMarketplace } from '../index';

export class GqlCountry {
  static DEFAULT = MdCountryEnum.JP;
  static DEFAULT_LGS = Country.JP;
  static DEFAULT_MKP = MarketplaceSaleChannelMarketplace.JP;

  static isMdCountry = (country = ''): country is MdCountryEnum => country in MdCountryEnum;

  static asMdCountry = (country?: string | null) =>
    country && this.isMdCountry(country) ? country : GqlCountry.DEFAULT;

  static isLgsCountry = (country = ''): country is Country => country in Country;

  static asLgsCountry = (country?: string | null) =>
    country && this.isLgsCountry(country) ? country : Country.JP;

  static isMkpCountry = (country = ''): country is MarketplaceSaleChannelMarketplace =>
    country in MarketplaceSaleChannelMarketplace;

  static asMkpCountry = (country?: string | null) =>
    country && this.isMkpCountry(country) ? country : MarketplaceSaleChannelMarketplace.JP;
}
