import { useMemo } from 'react';

import {
  ATTENTION_COLOR,
  Notification,
  CheckCircleFill,
  CrossCircleFill,
  InfoCircleFill,
  WarningFill,
} from '@any-ui-react/core';
import { tv } from 'tailwind-variants';

import { type SlotsToClasses } from '../../../types';

const styles = tv({
  slots: {
    root: '',
    body: '',
    loader: '',
    icon: '',
    title: '',
    description: 'space-y-2',
  },
});

type AlertBannerSlots = keyof ReturnType<typeof styles>;

export interface AlertBannerProps {
  type: 'info' | 'success' | 'warning' | 'error';
  title?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  onClose?: () => void;
  withCloseButton?: boolean;
  className?: string;
  classNames?: SlotsToClasses<AlertBannerSlots>;
}

const classes = styles();

export const AlertBanner = ({
  title,
  children,
  loading,
  onClose,
  withCloseButton = false,
  type,
  className,
  classNames,
}: AlertBannerProps) => {
  const [color, Icon] = useMemo(() => {
    if (type === 'info') return [ATTENTION_COLOR.info, InfoCircleFill];
    if (type === 'success') return [ATTENTION_COLOR.success, CheckCircleFill];
    if (type === 'warning') return [ATTENTION_COLOR.warning, WarningFill];

    return [ATTENTION_COLOR.error, CrossCircleFill];
  }, [type]);

  return (
    <Notification
      color={color}
      icon={<Icon />}
      title={title}
      loading={loading}
      onClose={onClose}
      withCloseButton={withCloseButton}
      className={className}
      classNames={{
        root: classes.root({ class: classNames?.root }),
        body: classes.body({ class: classNames?.body }),
        loader: classes.loader({ class: classNames?.loader }),
        icon: classes.icon({ class: classNames?.icon }),
        title: classes.title({ class: classNames?.title }),
        description: classes.description({ class: classNames?.description }),
      }}
    >
      {children}
    </Notification>
  );
};
