import { useTranslation } from 'react-i18next';

import { MarketplaceChannelSelect } from '~anyx/feature/marketplace';
import { useReportSyncCalendar } from '~anyx/feature/report';
import { RangePicker } from '~anyx/shared/ui';
import { FilterProps, useDateRangeListOptions, useScreenBreakpoint } from '~anyx/shared/utils';

import { DdiHomeFilterInputFilterType } from './ddiHomePage.filters';

export const DdiHomeFilters = ({
  current,
  updateFilters,
  changeFilter,
}: FilterProps<DdiHomeFilterInputFilterType>) => {
  const isLargeScreen = useScreenBreakpoint('lg');
  const { t } = useTranslation();
  const { setRange } = useReportSyncCalendar();
  const rangeOptions = useDateRangeListOptions();

  return (
    <div data-test-id="report-filters" className="grid grid-cols-2 gap-2 p-2">
      <RangePicker
        popoverProps={{ align: 'start' }}
        wrapper={{ className: 'col-span-2 sm:col-span-1' }}
        value={{
          start: current.startDate,
          end: current.endDate,
        }}
        clearable={false}
        visibleDuration={{ months: isLargeScreen ? 2 : 1 }}
        onChange={(range) => {
          const dateFilters = {
            endDate: range?.end,
            startDate: range?.start,
          };
          updateFilters({ ...current, ...dateFilters });
          setRange(dateFilters);
        }}
        ranges={{
          label: t('shared.date.title', { ns: 'shared' }),
          available: rangeOptions,
        }}
      />
      <div className="col-span-2 sm:col-span-1">
        <MarketplaceChannelSelect
          isMulti
          isSearchable
          isClearable
          className="select-has-min-w-menu [&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
          storeIds={current.masterDataStoreIds}
          onChange={(options) => {
            changeFilter(
              'saleChannelTypes',
              options.map((option) => option.value)
            );
          }}
          placeholder={t('shared.entity.channel', { ns: 'shared', count: 2 })}
          value={current.saleChannelTypes.map((channel) => ({
            value: channel,
            label: t('gql.enum.channel', { ns: 'gql', channel }),
          }))}
        />
      </div>
    </div>
  );
};
