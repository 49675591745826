import { DeepPartial } from 'react-hook-form';

import { LanguageUtils } from '~anyx/shared/utils';

import { MdProvince, Region } from '..';

import { GqlLanguages } from './gql-language.utils';

export class GqlProvince {
  // anylogi Region type
  static localizeRegion(
    region?: Region | null,
    language = GqlLanguages.asLgsLanguage(LanguageUtils.getCurrentLanguage())
  ) {
    const regionName = region?.language === language ? region.localName : region?.name;
    return regionName || '';
  }

  static localizeProvince(
    province: DeepPartial<MdProvince> | null,
    language = LanguageUtils.getCurrentLanguage()
  ) {
    const provinceLanguage = LanguageUtils.fromI18nLanguage(province?.country?.languageId);
    const useLocalizedName = provinceLanguage === language;
    const provinceName = useLocalizedName ? province?.lname : province?.name;
    return provinceName || '';
  }
}
