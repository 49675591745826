import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';

const LazadaAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.LazadaAddPage }))
);

const LazadaEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.LazadaEditPage }))
);

const LazadaManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.LazadaManualCostEditPage,
  }))
);

const LazadaManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.LazadaManualCostAddPage,
  }))
);

const LazadaMappingEditPage = React.lazy(() =>
  import('./pages/mapping/set').then((module) => ({
    default: module.LazadaMappingEditPage,
  }))
);

const LazadaTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.LazadaTemplate }))
);

export const lazadaRouting = [
  {
    path: MarketplaceSaleChannelType.Lazada,
    element: <LazadaTemplate />,
    children: [
      {
        index: true,
        element: <LazadaAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <LazadaManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <LazadaManualCostEditPage />,
          },
          {
            path: 'mapping/edit',
            element: <LazadaMappingEditPage />,
          },
          {
            index: true,
            element: <LazadaEditPage />,
          },
        ],
      },
    ],
  },
];
