import { useState } from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';
import { useDeepComparisonEffect } from '~anyx/shared/utils';

import { useStoresChannels } from '../../../hooks';

export const useSaleChannelsSelect = ({
  storeIds = undefined,
  accountIds = undefined,
}: {
  storeIds?: string[];
  accountIds?: string[];
}) => {
  const [channels, setChannels] = useState<MarketplaceSaleChannelType[]>([]);
  const { getStoresChannelsTypes } = useStoresChannels();

  useDeepComparisonEffect(async () => {
    setChannels(
      await getStoresChannelsTypes({
        storeIds,
        accountIds,
      })
    );
  }, [storeIds, accountIds]);

  return { channels };
};
