import React from 'react';

import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { UnauthorizedMessage } from '~anyx/shared/ui';

import { advertisementIntegrationRoutes as advertisementIntegrationRoutesV2 } from './pages/edit/integrations/advertisement/routing';
import { analyticsIntegrationRoutes as analyticsIntegrationRoutesV2 } from './pages/edit/integrations/analytics/routing';
import { chatIntegrationRoutes } from './pages/edit/integrations/chat/routing';
import { inventoryIntegrationRoutes } from './pages/edit/integrations/ddi-inventory/routing';
import { logisticIntegrationRoutes } from './pages/edit/integrations/logistic/routing';
import { marketplaceIntegrationRoutes } from './pages/edit/integrations/sales-channel/routing';

const StoreIntegrationPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.StoreIntegrationPage }))
);

const StoreIntegrationEditTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.StoreIntegrationEditTemplate }))
);

export const integrationRoutings = [
  {
    path: 'integrations',
    element: (
      <Restricted
        permissions={[[Auth0Permissions.CAN_USE_DATASOURCE]]}
        fallback={<UnauthorizedMessage />}
      >
        <StoreIntegrationEditTemplate />
      </Restricted>
    ),
    children: [
      { ...marketplaceIntegrationRoutes },
      {
        ...logisticIntegrationRoutes,
      },
      {
        ...advertisementIntegrationRoutesV2,
      },
      {
        ...analyticsIntegrationRoutesV2,
      },
      {
        ...chatIntegrationRoutes,
      },
      { ...inventoryIntegrationRoutes },
      {
        index: true,
        element: <StoreIntegrationPage />,
      },
    ],
  },
];
