import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ChannelUtils } from '~anyx/feature/marketplace';
import { ReportChartTab, ReportSupportedCurrency, SaleChannelType } from '~anyx/shared/graphql';
import {
  NoResult,
  NumberFormat,
  PieChart,
  SkeletonChartWrapper,
  SkeletonLoader,
} from '~anyx/shared/ui';
import { FilterProps, NumberUtils } from '~anyx/shared/utils';

import { HomeReportPieChartData } from '../../type';
import { HomeFilterInputFilterType } from '../home-filters';
import { NoSalesChannelConnection } from '../no-sales-channel-connection';

export interface PieChartProps {
  filters: FilterProps<HomeFilterInputFilterType>;
  shouldShowMock: boolean;
  colors: Record<string, string>;
  dataKeys: Array<string | SaleChannelType>;
  loading?: boolean;
  data?: HomeReportPieChartData;
  currency?: ReportSupportedCurrency;
}

type PieData = {
  name: string;
  key: string | null;
  storeId?: string | null;
  value: number;
  numberFormat: NumberFormat;
  currency: ReportSupportedCurrency;
  channel?: SaleChannelType | null;
};

export const SalesChannelPieChart = ({
  filters,
  loading,
  shouldShowMock,
  data,
  colors,
  dataKeys,
  currency = ReportSupportedCurrency.USD,
}: PieChartProps) => {
  const { t } = useTranslation();
  const { chartTab } = filters.current;

  const storePieData = useMemo(
    () =>
      chartTab === ReportChartTab.STORE
        ? data?.map((item) => ({
            name: t('core.page.home.report.table.column.grossSales', { ns: 'core' }),
            key: item.storeName ?? t('shared.button.unknown', { ns: 'shared' }),
            storeId: item.masterDataStoreId,
            value: NumberUtils.toNumber(item.grossSales),
            numberFormat: NumberFormat.PRICE,
            channel: null,
            currency,
          }))
        : [],
    [chartTab, currency, data, t]
  );

  const channelPieData = useMemo(
    () =>
      chartTab === ReportChartTab.CHANNEL
        ? ChannelUtils.sortChannels({
            channels: data || [],
            byField: 'saleChannelType',
          }).map((item) => {
            return {
              name: t('core.page.home.report.table.column.grossSales', { ns: 'core' }),
              key: t('gql.enum.channel', { ns: 'gql', channel: item.saleChannelType }),
              storeId: null,
              value: NumberUtils.toNumber(item.grossSales),
              numberFormat: NumberFormat.PRICE,
              channel: item.saleChannelType,
              currency,
            };
          })
        : [],
    [chartTab, currency, data, t]
  );

  const pieData: PieData[] = (
    chartTab === ReportChartTab.CHANNEL ? channelPieData : storePieData || []
  ).map((item) => {
    return {
      name: item.name,
      key: item.key,
      storeId: item.storeId,
      value: item.value,
      numberFormat: item.numberFormat,
      currency: item.currency,
      channel: item.channel,
    };
  });
  return (
    <div
      data-test-id="report-sales-channel-pie-chart"
      className="relative flex h-full flex-col justify-between rounded-none lg:rounded"
    >
      <div className={classNames({ 'blur-sm': shouldShowMock })}>
        <div className="px-4 pt-4">
          <div className="mb-4 items-center">
            <SkeletonLoader loading={loading}>
              <div className="mr-4 font-semibold">
                {t('core.page.home.report.chart.pieChart.title', { ns: 'core', chartTab })}
              </div>
            </SkeletonLoader>
          </div>
          <SkeletonChartWrapper
            error={!data?.length}
            loading={loading}
            errorNode={
              <NoResult
                wrapperClassName="py-20 my-4 text-center !gap-2"
                titleClassName="text-sm"
                descriptionClassName="lg:!text-2xs"
                title={t('shared.table.empty', {
                  ns: 'shared',
                  element: t('shared.entity.data', { ns: 'shared', count: 1 }),
                })}
                description={t('shared.chart.guideline', { ns: 'shared' })}
              />
            }
          >
            <div className="h-64">
              <PieChart
                colors={colors}
                data={pieData}
                dataKeys={dataKeys}
                innerRadius={35}
                outerRadius={60}
              />
            </div>
          </SkeletonChartWrapper>
        </div>
      </div>
      {shouldShowMock && <NoSalesChannelConnection />}
    </div>
  );
};
