import { createContext, useContext } from 'react';

import { getLocalTimeZone } from '@any-ui-react/dates';

import { LogisticFeatureFlag, MdUserRole } from '~anyx/shared/graphql';
import { CurrencyUtils, LanguageUtils, TimezoneUtils } from '~anyx/shared/utils';

import { SelfDetailsQuery } from './graphql';

export type Self = Omit<SelfDetailsQuery['MdSelf'], '__typename'> & {
  readonly picture?: string;
  readonly name?: string;
  readonly highestLogisticFlag?: LogisticFeatureFlag;
};

export type SelfContextType = {
  readonly self: Self;
  refetch: () => void;
  isSet: boolean;
};

export const defaultSelf: SelfContextType = {
  self: {
    id: '',
    lastName: '',
    firstName: '',
    name: '',
    role: MdUserRole.MERCHANT,
    email: '',
    currencyId: CurrencyUtils.DEFAULT,
    languageId: navigator?.languages[0] || LanguageUtils.DEFAULT,
    timezoneId: getLocalTimeZone() || TimezoneUtils.DEFAULT,
    availableModules: [],
    canManageUsers: false,
    accessAllStores: false,
    accountIds: [],
    accounts: [],
    storeIds: [],
    highestLogisticFlag: LogisticFeatureFlag.NO_LOGISTICS,
  },
  refetch: () => null,
  isSet: false,
};

export const SelfContext = createContext<SelfContextType>(defaultSelf);

export const useSelfCtx = () => {
  const { ...self } = useContext(SelfContext);
  return self;
};

export const useUserLanguage = () => {
  const {
    self: { languageId },
  } = useSelfCtx();
  return LanguageUtils.fromI18nLanguage(languageId) || LanguageUtils.DEFAULT;
};

export const useUserLogisticPermission = () => {
  const {
    self: { highestLogisticFlag },
  } = useSelfCtx();
  return highestLogisticFlag || LogisticFeatureFlag.NO_LOGISTICS;
};

export const useUserCurrency = () => {
  const {
    self: { currencyId },
  } = useSelfCtx();

  return CurrencyUtils.asCurrency(currencyId);
};
