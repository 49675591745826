import { useTranslation } from 'react-i18next';

import { toast } from '~anyx/shared/ui';
import { AnyXError } from '~anyx/shared/utils';

import { FbPageData, useFacebookPageSelectModal } from '../components';

import { PlatformType, useConnectFBToAnyChat } from './useConnectFBToAnyChat';

interface UseSelectFBPageProps {
  onConnected?: () => void;
  platform: PlatformType;
  storeId: string;
}

export const useSelectFBPage = ({ onConnected, storeId, platform }: UseSelectFBPageProps) => {
  const { t } = useTranslation(['crmIntegration', 'shared']);
  const { openFacebookPageSelectModal } = useFacebookPageSelectModal();
  const { connectFBToAnyChat } = useConnectFBToAnyChat(platform);

  const selectFBPage = async (fbPages: FbPageData[]) => {
    openFacebookPageSelectModal({
      fbPages,
      platform,
      onSelectPage: async (data) => {
        try {
          await connectFBToAnyChat({
            pageId: data.id,
            accessToken: data.access_token,
            storeId,
            platform,
          });

          toast.success(t('crmIntegration.shared.notify.connectFBToAnyChat.success', { platform }));

          onConnected?.();
        } catch (error) {
          let message = t('crmIntegration.shared.notify.connectFBToAnyChat.error', { platform });

          if (error instanceof AnyXError) {
            message = error.message;
          }

          toast.error(message);

          throw error;
        }
      },
    });
  };

  return {
    selectFBPage,
  };
};
