import { Trans, useTranslation } from 'react-i18next';

import { Anchor } from '@any-ui-react/core';

import { AlertBanner } from '~anyx/shared/ui';

import { useHelpLink } from '../useHelpLink';

export interface MetaTokenRefreshBannerProps {
  className?: string;
  platform: 'INSTAGRAM' | 'MESSENGER';
}

export const MetaTokenRefreshBanner = ({ platform, className }: MetaTokenRefreshBannerProps) => {
  const { t } = useTranslation('crmIntegration');
  const helpLink = useHelpLink();

  return (
    <AlertBanner
      className={className}
      type="warning"
      title={t('crmIntegration.shared.metaTokenRefreshBanner.title', { platform })}
    >
      <Trans i18nKey="crmIntegration.shared.metaTokenRefreshBanner.desc" ns="crmIntegration">
        Please try to refresh permission. If the problem persists, please check our{' '}
        <Anchor href={helpLink} target="_blank">
          help page
        </Anchor>
        .
      </Trans>
    </AlertBanner>
  );
};
