import React, { createContext, useCallback, useContext, useState } from 'react';

import { Direction } from '@any-ui-react/table';

interface ShadowContextType {
  showShadow: boolean;
  direction: Direction;
  handleScroll: (e: React.UIEvent<HTMLElement>) => void;
}

const ShadowContext = createContext<ShadowContextType | undefined>(undefined);

export const useShadowContext = () => {
  const context = useContext(ShadowContext);
  if (!context) {
    throw new Error('useShadowContext must be used within a ShadowProvider');
  }
  return context;
};

export const ShadowProvider: React.FC<{ children: React.ReactNode; direction: Direction }> = ({
  children,
  direction,
}) => {
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const shouldShowShadow =
        (direction === 'ltr' && e.currentTarget.scrollLeft > 0) ||
        (direction === 'rtl' && e.currentTarget.scrollLeft < 0);
      setShowShadow(shouldShowShadow);
    },
    [direction]
  );

  return (
    <ShadowContext.Provider value={{ showShadow, direction, handleScroll }}>
      {children}
    </ShadowContext.Provider>
  );
};
