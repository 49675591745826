import { useCallback } from 'react';

import Cookies from 'js-cookie';

import { useApiTranslationMapper, AlertType } from '~anyx/shared/graphql';
import { toast } from '~anyx/shared/ui';

export const useLoginAiChat = () => {
  const { getErrorMessage, getTranslationKey } = useApiTranslationMapper();
  // TODO: Update once actual API is available
  const url = 'https://anyx-llm-report-dev-api-3cv3rgwpeq-ul.a.run.app/api/v1/login';

  const handleLogin = useCallback(async () => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: 'anyx_llm_mock_api',
          password: '298FC1C149AFbF4c',
        }),
      });

      if (response.ok) {
        const data = await response.json();
        Cookies.set('session_id', data.session_id);
      }
    } catch (e: unknown) {
      toast.error(
        getTranslationKey({
          namespace: 'gql',
          type: AlertType.ERROR,
          path: 'error',
          message: getErrorMessage(e),
        })
      );
    }
  }, [getErrorMessage, getTranslationKey]);

  return { handleLogin };
};
