import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopeeSalesChartQueryVariables = Types.Exact<{
  input: Types.ShopeeSalesChartInput;
}>;


export type ShopeeSalesChartQuery = { readonly shopeeSalesChart: { readonly __typename?: 'ShopeeSalesChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly orders: ReadonlyArray<Record<string, any>>, readonly sales: ReadonlyArray<Record<string, any>>, readonly salesPerOrder: ReadonlyArray<Record<string, any>>, readonly visitors: ReadonlyArray<Record<string, any>> } };


export const ShopeeSalesChartDocument = gql`
    query ShopeeSalesChart($input: ShopeeSalesChartInput!) {
  shopeeSalesChart(input: $input) {
    currency
    orders
    sales
    salesPerOrder
    visitors
  }
}
    `;

/**
 * __useShopeeSalesChartQuery__
 *
 * To run a query within a React component, call `useShopeeSalesChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopeeSalesChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopeeSalesChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopeeSalesChartQuery(baseOptions: Apollo.QueryHookOptions<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables> & ({ variables: ShopeeSalesChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>(ShopeeSalesChartDocument, options);
      }
export function useShopeeSalesChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>(ShopeeSalesChartDocument, options);
        }
export function useShopeeSalesChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>(ShopeeSalesChartDocument, options);
        }
export type ShopeeSalesChartQueryHookResult = ReturnType<typeof useShopeeSalesChartQuery>;
export type ShopeeSalesChartLazyQueryHookResult = ReturnType<typeof useShopeeSalesChartLazyQuery>;
export type ShopeeSalesChartSuspenseQueryHookResult = ReturnType<typeof useShopeeSalesChartSuspenseQuery>;
export type ShopeeSalesChartQueryResult = Apollo.QueryResult<ShopeeSalesChartQuery, ShopeeSalesChartQueryVariables>;
export function refetchShopeeSalesChartQuery(variables: ShopeeSalesChartQueryVariables) {
      return { query: ShopeeSalesChartDocument, variables: variables }
    }