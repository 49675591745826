import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import useAsyncEffect from 'use-async-effect';

import { useSelfCtx } from '~anyx/common/self';
import { useFirstWorkspace } from '~anyx/common/workspace';
import { MdUserRole } from '~anyx/shared/graphql';
import { LoadWrapper, Title } from '~anyx/shared/ui';
import { AsyncOperationState } from '~anyx/shared/utils';

export const OnboardingTemplate = () => {
  const navigate = useNavigate();

  const {
    self: { accountIds, role },
  } = useSelfCtx();

  const { getFirstWorkspace } = useFirstWorkspace({
    accountId: role === MdUserRole.MERCHANT ? accountIds[0] ?? undefined : undefined,
  });

  const [status, setStatus] = useState<AsyncOperationState>(AsyncOperationState.IDLE);
  useAsyncEffect(
    async (isMounted) => {
      setStatus(AsyncOperationState.LOADING);
      try {
        const workspace = await getFirstWorkspace();
        setStatus(AsyncOperationState.SUCCESS);

        if (!isMounted()) return;

        if (workspace) {
          navigate('/');
        }
      } catch {
        setStatus(AsyncOperationState.ERROR);
        if (!isMounted()) return;
        navigate('/error/unavailable');
      }
    },
    [getFirstWorkspace, navigate]
  );

  return (
    <div className="w-full">
      <Title />
      <LoadWrapper loading={status === AsyncOperationState.LOADING}>
        <Outlet />
      </LoadWrapper>
    </div>
  );
};
