import {
  AmazonSellerCentralMarketplace,
  MarketplaceSaleChannelType,
  SaleChannelType,
  ShopeeMarketplace,
  TikTokRegion,
} from '~anyx/shared/graphql';
import { ObjectUtils } from '~anyx/shared/utils';

export interface EventColorType {
  background: string;
  border: string;
  highlight: string;
}

export interface ChannelOption {
  label: JSX.Element | string;
  value: MarketplaceSaleChannelType;
}

const defaultEventColor = {
  background: '#EEF3F7',
  border: '#6e7c8933',
  highlight: '#6E7C89',
};

const SaleChannelEventColor: Record<string, EventColorType> = {
  [SaleChannelType.AmazonSellerCentral]: {
    background: '#FFF5E4',
    border: '#F7A2161A',
    highlight: '#F69B06',
  },
  [SaleChannelType.Shopee]: {
    background: '#FFE9E4',
    border: '#FFE2DB',
    highlight: '#EE4D2D',
  },
  [SaleChannelType.Lazada]: {
    background: '#FFEAF4',
    border: '#FFDCED',
    highlight: '#FD15CF',
  },
  [SaleChannelType.Shopify]: {
    background: '#EFFBDA',
    border: '#DEF1BC',
    highlight: '#95BF47',
  },
  [SaleChannelType.Rakuten]: {
    background: '#FFF1F1',
    border: '#BF00001A',
    highlight: '#BF0000',
  },
  [SaleChannelType.RakutenFashion]: {
    background: '#FFF1F1',
    border: '#BF00001A',
    highlight: '#BF0000',
  },
  [SaleChannelType.Yahoo]: {
    background: '#FFF1F1',
    border: '#BF00001A',
    highlight: '#FF0033',
  },
  [SaleChannelType.TikTok]: {
    background: '#F7F7F7',
    border: '#EBEBEB',
    highlight: '#BABABA',
  },
  [SaleChannelType.Manual]: {
    background: '#FFFFFF',
    border: '#EEF3F7',
    highlight: '#6E7C89',
  },
  [SaleChannelType.Tokopedia]: {
    background: '#EBFAEB',
    border: '#DBEFDB',
    highlight: '#4F9D4D',
  },
};
export const getSaleChannelEventColor = (saleChannel: SaleChannelType) =>
  SaleChannelEventColor[saleChannel] || defaultEventColor;

export const SaleChannelEventCountries: Record<string, string[]> = {
  [SaleChannelType.AmazonSellerCentral]: ObjectUtils.arrayFromEnum<AmazonSellerCentralMarketplace>(
    AmazonSellerCentralMarketplace
  ),
  [SaleChannelType.Shopee]: ObjectUtils.arrayFromEnum<ShopeeMarketplace>(ShopeeMarketplace),
  [SaleChannelType.TikTok]: ObjectUtils.arrayFromEnum<TikTokRegion>(TikTokRegion),
};

export const getSaleChannelEventCountries = (saleChannel: SaleChannelType) =>
  SaleChannelEventCountries?.[saleChannel] || [];
