import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, InputProps } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';
import { v4 as uuid } from 'uuid';

import { CardTitle } from '~anyx/shared/ui';

import { ProductQuantityTable } from './components';
import { ProductSetVariantsSelectorDialog } from './ProductSetVariantsSelector';
import { VariantSetDetail } from './type';

export interface ProductSetVariantsInputProps extends InputProps {
  className?: string;
  title: string;
  onChange: (value: VariantSetDetail[]) => void;
  accountId: string;
  storeId: string;
  currentProductVariantId?: string;
  value: VariantSetDetail[];
  loading?: boolean;
}

export const ProductSetVariantsInput = forwardRef<HTMLButtonElement, ProductSetVariantsInputProps>(
  (
    { value = [], currentProductVariantId, accountId, storeId, title, onChange, loading, ...rest },
    ref
  ) => {
    const modalId = uuid();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();
    const [selectedProducts, setSelectedProducts] = useState<VariantSetDetail[]>(value);

    useEffect(() => {
      onChange(selectedProducts);
    }, [onChange, selectedProducts]);

    return (
      <Input.Wrapper {...rest.wrapperProps} error={rest?.error} className={rest.className}>
        <div className="flex justify-between">
          <CardTitle>{title}</CardTitle>
          <Button
            ref={ref}
            disabled={!accountId || !storeId}
            onClick={() => {
              openModal({
                modalId,
                size: 'lg',
                title: t('logistic.component.productInput.dialog.title', {
                  ns: 'logistic',
                }),
                children: (
                  <ProductSetVariantsSelectorDialog
                    currentProductVariantId={currentProductVariantId}
                    storeId={storeId}
                    value={value}
                    onClose={() => closeModal(modalId)}
                    onSubmit={(selection) => {
                      setSelectedProducts(selection);
                      closeModal(modalId);
                    }}
                  />
                ),
              });
            }}
          >
            {t('shared.action.add', {
              ns: 'shared',
              entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
            })}
          </Button>
        </div>
        <ProductQuantityTable
          value={value}
          loading={loading}
          onRemoveProduct={(product) => {
            setSelectedProducts((prevValue) => [
              ...prevValue.filter(
                (prevProduct) => prevProduct.productVariantId !== product.productVariantId
              ),
            ]);
          }}
          error={!!rest?.error}
          onQuantityChange={(product) =>
            setSelectedProducts((prevValue) => [
              ...prevValue.map((prevProduct) => {
                if (prevProduct.productVariantId === product.productVariantId) {
                  return product;
                }
                return prevProduct;
              }),
            ])
          }
        />
      </Input.Wrapper>
    );
  }
);
