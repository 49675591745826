import { Loader, useMantineTheme } from '@any-ui-react/core';

interface SpinnerProps {
  spinner?: React.ReactNode;
}

export const Spinner = ({ spinner }: SpinnerProps) => {
  const { other } = useMantineTheme();

  return (
    <div className="flex flex-1 items-center justify-center">
      {spinner || other['spinner']?.() || <Loader />}
    </div>
  );
};
