import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

export const SettingGuideToggle = ({
  wrapperClassName,
  showSettingGuide,
}: {
  wrapperClassName?: string;
  showSettingGuide: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={classNames('text-link w-full border-t p-4 leading-8', wrapperClassName)}
      onClick={showSettingGuide}
      type="button"
    >
      {t('core.page.home.settingGuide.showSettingGuide', { ns: 'core' })}
    </button>
  );
};
