import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';

const ShopifyAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ShopifyAddPage }))
);

const ShopifyEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ShopifyEditPage }))
);

const ShopifyManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.ShopifyManualCostEditPage,
  }))
);

const ShopifyManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.ShopifyManualCostAddPage,
  }))
);

const ShopifyTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ShopifyTemplate }))
);

export const shopifyRouting = [
  {
    path: MarketplaceSaleChannelType.Shopify,
    element: <ShopifyTemplate />,
    children: [
      {
        index: true,
        element: <ShopifyAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <ShopifyManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <ShopifyManualCostEditPage />,
          },
          {
            index: true,
            element: <ShopifyEditPage />,
          },
        ],
      },
    ],
  },
];
