import { OrderRoutePath } from '~anyx/common/routing';
import { TagIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

export type OrderLinksProps = {
  canUseOrder: boolean;
  storeId?: string;
};

const getOrderLinks = ({ canUseOrder, storeId }: OrderLinksProps): RouteLinks[] => {
  if (!canUseOrder) return [];

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'shared.entity.order',
          options: { count: 2, ns: 'shared' },
        },
        icon: (props) => <TagIcon {...props} />,
        path: OrderRoutePath({ workspaceStoreId: storeId }).list().path,
        activePath: OrderRoutePath({ workspaceStoreId: null }).root().pattern,
      },
    },
  ];
};

type OrderLinks = LinkAdapter<OrderLinksProps>;

export const orderLinks: OrderLinks = {
  noWorkspace: getOrderLinks,
  withWorkspace: getOrderLinks,
};
