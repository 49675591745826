import { z } from 'zod';

export const WorkflowErrorCode = z.enum([
  //  Not Found (2001 - 2099)
  'USER_NOT_FOUND', // 2001
  'WORKFLOW_NOT_FOUND', // 2002
  'UNIT_MESSAGE_NOT_FOUND', // 2003
  'CUSTOMER_NOT_FOUND', // 2004
  'SHOPIFY_STORE_NOT_FOUND', // 2005
  'UNIT_MESSAGE_SEND_INFO_NOT_FOUND', // 2006
  'LINE_CHANNEL_NOT_FOUND', // 2007

  // Invalid Data (3000 - 3099)
  'INVALID_EMAIL', // 3001
  'INVALID_URL', // 3002
  'INITIAL_FLOW_BLOCK_NOT_FOUND', // 3003
  'INVALID_ACTION', // 3004
  'INVALID_LINE_SEND_MESSAGE_ACTION', // 3005
  'INVALID_CONDITION_FOR_ORDER', // 3006
  'INVALID_CONJUNCTION_FOR_NUMBER', // 3007
  'INVALID_CONJUNCTION_FOR_ENUM', // 3008
  'INVALID_FLOW_BLOCK', // 3009
  'INVALID_CONDITION_FOR_LINE_MESSAGE', // 3010
  'INVALID_CONJUNCTION_VALUE', // 3011
]);
export type WorkflowErrorCode = z.infer<typeof WorkflowErrorCode>;

export const WORKFLOW_ERROR_DESCRIPTION_MAP = WorkflowErrorCode.options.reduce(
  (acc, code) => ({
    ...acc,
    [code]: `workflow.gql.errors.${code}`,
  }),
  {} as Record<WorkflowErrorCode, string>
);
