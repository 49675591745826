import { useTranslation } from 'react-i18next';

import { useMultiSelect } from '@any-ui-react/select';

export const useTagsOptions = (tags?: readonly string[] | null) => {
  const { t } = useTranslation();
  const tagOptions = (tags || []).map((tag) => ({
    label: tag,
    value: tag,
  }));

  const getSelectedLabel = (selectedCount: number) => {
    if (selectedCount === tagOptions.length) {
      return t('shared.action.all', {
        ns: 'shared',
        entity: t('shared.entity.channel', { ns: 'shared', count: 2 }),
      });
    }
    return t('shared.select.optionSelected', { ns: 'shared', amount: selectedCount });
  };

  const tagSelectProps = useMultiSelect({
    options: tagOptions,
    getSelectedLabel,
  });

  return {
    tagOptions,
    tagSelectProps,
    getSelectedLabel,
  };
};
