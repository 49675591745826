import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { parseAbsolute, RangeDatePicker, RangeDatePickerProps } from '@any-ui-react/dates';

import { LocaleUtils, DateUtils, TimezoneUtils } from '~anyx/shared/utils';

export interface RangeDateValue {
  start: string | undefined;
  end: string | undefined;
}

export interface RangePickerProps extends Omit<RangeDatePickerProps, 'value' | 'onChange'> {
  value?: RangeDateValue | null;
  onChange?: (value: RangeDateValue | null | undefined) => void;
  timezone?: string;
  locale?: string;
}

export const RangePicker = forwardRef<HTMLDivElement, RangePickerProps>(
  (
    {
      value,
      onChange,
      timezone = TimezoneUtils.getCurrentTimezone(),
      locale = LocaleUtils.getCurrentLocale(),
      ranges,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <RangeDatePicker
        {...props}
        ref={ref}
        defaultTimezone={timezone}
        popoverButtonsText={{
          cancel: t('shared.button.cancel', { ns: 'shared' }),
          apply: t('shared.button.apply', { ns: 'shared' }),
        }}
        value={
          value && value.end && value.start
            ? {
                start: parseAbsolute(value.start, timezone),
                end: parseAbsolute(value.end, timezone),
              }
            : undefined
        }
        onChange={(range) => {
          if (range && range.start && range.end) {
            const start = DateUtils.toISOStringStartOfDay(range?.start, timezone);
            const end = DateUtils.toISOStringEndOfDay(range?.end, timezone);

            onChange?.({
              start,
              end,
            });
          } else {
            onChange?.(undefined);
          }
        }}
        locale={locale}
        {...(ranges && {
          ranges: { placeholder: t('shared.date.rangePlaceholder', { ns: 'shared' }), ...ranges },
        })}
      />
    );
  }
);
