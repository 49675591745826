import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';

import { Button } from '@any-ui-react/core';

import { ErrorCard } from '../../../molecules';

interface PageFailureMessageProps {
  /** retry - reload the page / home - redirect to "/" */
  action?: 'retry' | 'home';
}

export const PageFailureMessage = ({ action = 'retry' }: PageFailureMessageProps) => {
  const { t } = useTranslation('shared');

  const text = useMemo(() => {
    if (action === 'retry') return t('shared.button.retry', { ns: 'shared' });

    return t('shared.button.home');
  }, [action, t]);

  const linkProps = useMemo((): { to: To; reloadDocument?: boolean } => {
    if (action === 'retry') {
      return {
        to: '.',
        reloadDocument: true,
      };
    }

    return {
      to: '/',
    };
  }, [action]);

  return (
    <ErrorCard
      iconColor={'fill-failure'}
      title={t('shared.errorPageQuery.title')}
      guideline={t('shared.errorPageQuery.guideline')}
      content={
        <Button component={Link} {...linkProps}>
          {text}
        </Button>
      }
    />
  );
};
