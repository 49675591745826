import { RouteCategory } from '~anyx/shared/utils';

import { AccordionLink } from './AccordionLink';
import { CategoryLink } from './CategoryLink';
import { ExternalLink } from './ExternalLink';

interface NavigationLinkProps {
  category: RouteCategory;
  activePath: string;
  isActive: boolean;
  setActiveMenu: (value?: string) => void;
  onLinkClick: () => void;
}

export const NavigationLink = ({
  category,
  activePath,
  isActive,
  setActiveMenu,
  onLinkClick,
}: NavigationLinkProps) => {
  if (!category) {
    return null;
  }

  if (category.children) {
    return (
      <AccordionLink
        isActive={isActive}
        activePath={activePath}
        onLinkClick={onLinkClick}
        setActiveMenu={setActiveMenu}
        category={category}
      />
    );
  }

  if (category.path) {
    return <CategoryLink isActive={isActive} onLinkClick={onLinkClick} category={category} />;
  }

  if (category.href) {
    return <ExternalLink category={category} />;
  }

  return null;
};
