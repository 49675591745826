import { ReactNode, useCallback, useEffect } from 'react';

import { LoadWrapper } from '~anyx/shared/ui';

import { OnboardingContext } from './OnboardingContext';
import { useOnboarding } from './operations';

export enum OnboardingCheckType {
  STORE = 'STORE',
  DATASOURCE = 'DATASOURCE',
}

export const OnboardingProvider = ({
  storeId,
  skip = false,
  children,
}: {
  storeId?: string | null;
  skip?: boolean;
  children?: ReactNode;
}) => {
  const { onboarding, loading, error, refetch } = useOnboarding({ storeId, skip });

  const refetchOnboarding = useCallback(() => {
    if (!storeId) return;

    refetch();
  }, [refetch, storeId]);

  // TODO temporary until migration
  useEffect(() => {
    if (error) {
      console.error('Error fetching onboarding:', error);
    }
  }, [error]);

  return (
    <OnboardingContext.Provider
      value={{
        state: onboarding,
        loading: loading,
        error: !!error,
        refetchOnboarding,
      }}
    >
      <LoadWrapper loading={loading} loaderId="onboarding" className="flex h-screen">
        {children}
      </LoadWrapper>
    </OnboardingContext.Provider>
  );
};
