import { ReactNode } from 'react';

import { ActionIcon } from '@any-ui-react/core';

import { ChevronLeftIcon } from '~anyx/shared/icons';

import { SkeletonLoader } from '../../atoms';

export interface PageTitleProps {
  text?: string;
  aside?: () => ReactNode;
  onBack?: () => void;
  loading?: boolean;
}

export const PageTitle = ({ text = '', onBack, aside, loading }: PageTitleProps) => {
  return (
    <div className="flex min-h-[2rem] items-center justify-between p-4 lg:mb-4 lg:p-0">
      <div className="flex items-center truncate">
        <SkeletonLoader loading={loading} width="12rem" height="1.125rem">
          {onBack && (
            <ActionIcon
              className="card mr-4 shadow-none focus:outline-none"
              onClick={onBack}
              variant="default"
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </ActionIcon>
          )}
          {text && <h3 className="truncate text-lg font-semibold">{text}</h3>}
        </SkeletonLoader>
      </div>
      {aside?.()}
    </div>
  );
};
