import { useState } from 'react';

import Cookies from 'js-cookie';

import { useUserLanguage } from '~anyx/common/self';
import { LanguageUtils } from '~anyx/shared/utils';

export const useGoogleTranslate = () => {
  const language = useUserLanguage();
  const currentLanguage = LanguageUtils.getTwoLettersCode(language);
  const [isTranslated, setIsTranslated] = useState(true);

  const translate = async () => {
    try {
      Cookies.set('googtrans', `/auto/${currentLanguage}`);
      const googleTranslateSelect = document.querySelector('.goog-te-combo') as HTMLSelectElement;
      if (googleTranslateSelect) {
        googleTranslateSelect.value = currentLanguage || '';
        googleTranslateSelect.dispatchEvent(new Event('change'));
        setIsTranslated(true);
      }
    } catch (error) {
      console.error('Error translate content:', error);
    }
  };

  const reset = async () => {
    try {
      Cookies.remove('googtrans');
      const iframe = document.querySelector('#\\:1\\.container') as HTMLIFrameElement;
      const button = iframe?.contentDocument?.querySelector('#\\:1\\.restore') as HTMLButtonElement;
      if (button) {
        setIsTranslated(false);
        button.click();
      }
    } catch (error) {
      console.error('Error resetting content:', error);
    }
  };

  return { translate, reset, isTranslated };
};
