import { createPath } from '../../../utils';

export const ErrorsRoutePath = () => {
  const root = createPath('/error');

  return {
    root: () => root,
    notFound: () => createPath(`${root.path}/notfound`),
    unavailable: () => createPath(`${root.path}/unavailable`),
    unauthorized: () => createPath(`${root.path}/unauthorized`),
  };
};
