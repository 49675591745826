import { useTranslation } from 'react-i18next';

import { ActionIcon, NumberInput } from '@any-ui-react/core';

import { TrashIcon } from '~anyx/shared/icons';
import { Image } from '~anyx/shared/ui';

import { VariantSetDetail } from '../../type';

export const ProductQuantityTableMobileTable = ({
  product,
  onQuantityChange,
  onRemoveProduct,
  disabled,
}: {
  product: VariantSetDetail;
  onQuantityChange?: (product: VariantSetDetail) => void;
  onRemoveProduct?: (product: VariantSetDetail) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 truncate border-b p-2">
      <div className="flex flex-1">
        <Image
          alt={t('shared.action.image', {
            ns: 'shared',
            entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
          })}
          className="h-16 w-16"
          noImageClassNameWrapper="h-16 w-16"
          src={product?.media?.[0]}
        />
        <div className="ml-2 flex flex-1 flex-col justify-center truncate">
          <p className="truncate">{product?.productName}</p>
          <p className="text-2xs truncate">{product?.productCode}</p>
        </div>
      </div>

      <div className="flex items-end justify-end py-2 pl-2 md:justify-around">
        <NumberInput
          value={product?.quantity}
          max={4294967295}
          min={0}
          className="text-right [&_.input]:last:pr-3"
          disabled={disabled}
          label={t('shared.field.quantity.name', { ns: 'shared' })}
          onChange={(e) =>
            onQuantityChange?.({
              ...product,
              quantity: Number(e),
            })
          }
        />

        <div className="ml-2 flex flex-col">
          <ActionIcon
            className="card fill-gray-9 shadow-none"
            disabled={disabled}
            onClick={() => onRemoveProduct?.(product)}
            variant="default"
          >
            <TrashIcon className="h-4 w-4" />
          </ActionIcon>
        </div>
      </div>
    </div>
  );
};
