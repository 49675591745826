// Using the tailwind default font-sans, unable to show emoji like ☺
// Current Resolution: Added Twemoji Mozilla and Android Emoji in the list and changed the order

const fontSans = [
  'ui-sans-serif',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  "'Segoe UI'",
  "'Segoe UI Emoji'",
  "'Segoe UI Symbol'",
  "'Apple Color Emoji'",
  "'Twemoji Mozilla'",
  "'Noto Color Emoji'",
  "'Android Emoji'",
  'Roboto',
  "'Helvetica Neue'",
  'Arial',
  "'Noto Sans'",
  'sans-serif',
];

module.exports = {
  theme: {
    extend: {
      spacing: {
        5.5: '1.375rem' /* 22px */,
      },
      colors: {
        gray: {
          0: 'rgb(var(--ax-color-gray-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-gray-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-gray-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-gray-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-gray-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-gray-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-gray-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-gray-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-gray-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-gray-9) / <alpha-value>)',
        },
        red: {
          0: 'rgb(var(--ax-color-red-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-red-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-red-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-red-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-red-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-red-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-red-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-red-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-red-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-red-9) / <alpha-value>)',
        },
        blue: {
          0: 'rgb(var(--ax-color-blue-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-blue-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-blue-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-blue-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-blue-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-blue-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-blue-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-blue-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-blue-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-blue-9) / <alpha-value>)',
        },
        green: {
          0: 'rgb(var(--ax-color-green-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-green-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-green-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-green-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-green-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-green-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-green-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-green-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-green-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-green-9) / <alpha-value>)',
        },
        orange: {
          0: 'rgb(var(--ax-color-orange-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-orange-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-orange-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-orange-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-orange-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-orange-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-orange-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-orange-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-orange-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-orange-9) / <alpha-value>)',
        },
        cyan: {
          0: 'rgb(var(--ax-color-cyan-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-cyan-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-cyan-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-cyan-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-cyan-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-cyan-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-cyan-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-cyan-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-cyan-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-cyan-9) / <alpha-value>)',
        },
        yellow: {
          0: 'rgb(var(--ax-color-yellow-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-yellow-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-yellow-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-yellow-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-yellow-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-yellow-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-yellow-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-yellow-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-yellow-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-yellow-9) / <alpha-value>)',
        },
        lime: {
          0: 'rgb(var(--ax-color-lime-0) / <alpha-value>)',
          1: 'rgb(var(--ax-color-lime-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-lime-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-lime-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-lime-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-lime-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-lime-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-lime-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-lime-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-lime-9) / <alpha-value>)',
        },
        purple: {
          1: 'rgb(var(--ax-color-purple-1) / <alpha-value>)',
          2: 'rgb(var(--ax-color-purple-2) / <alpha-value>)',
          3: 'rgb(var(--ax-color-purple-3) / <alpha-value>)',
          4: 'rgb(var(--ax-color-purple-4) / <alpha-value>)',
          5: 'rgb(var(--ax-color-purple-5) / <alpha-value>)',
          6: 'rgb(var(--ax-color-purple-6) / <alpha-value>)',
          7: 'rgb(var(--ax-color-purple-7) / <alpha-value>)',
          8: 'rgb(var(--ax-color-purple-8) / <alpha-value>)',
          9: 'rgb(var(--ax-color-purple-9) / <alpha-value>)',
        },
        status: {
          red: 'rgb(var(--ax-color-status-red) / <alpha-value>)',
          yellow: 'rgb(var(--ax-color-status-yellow) / <alpha-value>)',
          green: 'rgb(var(--ax-color-status-green) / <alpha-value>)',
          mint: 'rgb(var(--ax-color-status-mint) / <alpha-value>)',
          blue: 'rgb(var(--ax-color-status-blue) / <alpha-value>)',
          purple: 'rgb(var(--ax-color-status-purple) / <alpha-value>)',
          pink: 'rgb(var(--ax-color-status-pink) / <alpha-value>)',
          grey: 'rgb(var(--ax-color-status-grey) / <alpha-value>)',
        },
        success: {
          DEFAULT: 'rgb(var(--ax-color-success) / <alpha-value>)',
        },
        failure: {
          DEFAULT: 'rgb(var(--ax-color-failure) / <alpha-value>)',
        },
        warning: {
          DEFAULT: 'rgb(var(--ax-color-warning) / <alpha-value>)',
        },
        info: {
          DEFAULT: 'rgb(var(--ax-color-info) / <alpha-value>)',
        },
        link: {
          DEFAULT: 'rgb(var(--ax-color-link) / <alpha-value>)',
        },
        brand: {
          DEFAULT: 'rgb(var(--ax-color-cyan-6) / <alpha-value>)',
          hover: 'rgb(var(--ax-color-cyan-3) / <alpha-value>)',
          active: 'rgb(var(--ax-color-cyan-8) / <alpha-value>)',
        },
        white: {
          DEFAULT: 'rgb(var(--ax-color-white) / <alpha-value>)',
        },
        black: {
          DEFAULT: 'rgb(var(--ax-color-black) / <alpha-value>)',
        },
      },
      textColor: {
        primary: 'rgb(var(--ax-color-text-primary) / <alpha-value>)',
        secondary: 'rgb(var(--ax-color-text-secondary) / <alpha-value>)',
        placeholder: 'rgb(var(--ax-color-text-placeholder) / <alpha-value>)',
        disabled: 'rgb(var(--ax-color-text-disabled) / <alpha-value>)',
      },
      backgroundColor: {
        primary: 'rgb(var(--ax-color-bg-primary) / <alpha-value>)',
        secondary: 'rgb(var(--ax-color-bg-secondary) / <alpha-value>)',
        muted: 'rgb(var(--ax-color-bg-muted) / <alpha-value>)',
        disabled: 'rgb(var(--ax-color-bg-disabled) / <alpha-value>)',
      },
      borderColor: {
        base: 'rgb(var(--ax-color-border-base) / <alpha-value>)',
        divide: 'rgb(var(--ax-color-border-divide) / <alpha-value>)',
        muted: 'rgb(var(--ax-color-border-muted) / <alpha-value>)',
        disabled: 'rgb(var(--ax-color-border-disabled) / <alpha-value>)',
      },
      borderWidth: {
        6: '0.375rem',
      },
      fontSize: {
        '6xs': '.5rem' /* 8px */,
        '5xs': '.5625rem' /* 9px */,
        '4xs': '.625rem' /* 10px */,
        '3xs': '0.6875rem' /* 11px */,
        '2xs': '.75rem' /* 12px */,
        xs: '0.8125rem' /* 13px */,
        sm: '.875rem' /* 14px */,
        base: '1rem' /* 16px */,
        lg: '1.125rem' /* 18px */,
        xl: '1.25rem' /* 20px */,
        '2xl': '1.5rem' /* 24px */,
        '3xl': '1.875rem' /* 30px */,
        '4xl': '2.25rem' /* 36px */,
      },
      gridTemplateRows: {
        7: 'repeat(7, minmax(0, 1fr))' /* Simple 7 row grid */,
        8: 'repeat(8, minmax(0, 1fr))' /* Simple 8 row grid */,
      },
      margin: {
        0.75: '0.1875rem' /* 3px */,
      },
      lineHeight: {
        3: '.625rem' /* 10px */,
        4: '0.6875rem' /* 11px */,
        5: '.75rem' /* 12px */,
        6: '0.8125rem' /* 13px */,
        7: '.875rem' /* 14px */,
        8: '1rem' /* 16px */,
        9: '1.125rem' /* 18px */,
        10: '1.25rem' /* 20px */,
        11: '1.5rem' /* 24px */,
        12: '1.75rem' /* 28px */,
        13: '2rem' /* 32px */,
      },
      screens: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
      },
      borderRadius: {
        none: '0',
        sm: '0.125rem' /* 2px */,
        DEFAULT: '0.1875rem' /* 3px */,
        md: '0.25rem' /* 4px */,
        lg: '0.3125rem' /* 5px */,
        xl: '0.375rem' /* 6px */,
      },
      boxShadow: {
        card: '0px 0px 1px rgba(110, 124, 137, 0.05), 0px 0px 2px rgba(110, 124, 137, 0.25)',
        tile: '0px 1px 3px rgba(0, 0, 0, 0.05)',
        tileHover: '0px 3px 10px rgba(0, 0, 0, 0.07)',
        dropdown: '0px 2px 5px #dee5ec,0px 1px 4px #dee5ec',
        banner: '0px 1px 4px rgba(222, 229, 236, 0.5), 0px 2px 5px #DEE5EC',
        accordion: '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05)',
        user: '0px -3px 5px rgba(0, 0, 0, 0.08)',
        tooltip: '0px 4px 6px rgba(0, 0, 0, 0.4)',
        indicator: '0px 1px 3px rgba(0, 0, 0, 0.5)',
      },
      fontFamily: {
        sans: [...fontSans],
        inter: ["'Inter'", ...fontSans],
        interVar: ["'Inter var'", ...fontSans],
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({
      strategy: 'class', // only generate classes
    }),
    require('tailwindcss-radix')(),
    function ({ addVariant }) {
      addVariant('first-child', '& > *:first-child');
    },
  ],
};
