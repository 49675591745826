import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { PolicyModuleRoutesPaths } from '~anyx/common/routing';

const PolicyTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.PolicyTemplate }))
);
const PrivacyPage = React.lazy(() =>
  import('./pages/privacy').then((module) => ({ default: module.PrivacyPage }))
);
const TermsPage = React.lazy(() =>
  import('./pages/terms').then((module) => ({ default: module.TermsPage }))
);
export const routes: RouteObject[] = [
  {
    path: 'policy',
    element: <PolicyTemplate />,
    children: [
      {
        path: 'terms',
        element: <TermsPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPage />,
      },
      {
        index: true,
        element: <Navigate to={PolicyModuleRoutesPaths().terms().path} replace={true} />,
      },
    ],
  },
];
