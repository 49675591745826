import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput, TextInputProps } from '@any-ui-react/core';

import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';

export type FirstNameFieldProps = Partial<TextInputProps>;

export const FirstNameField = ({ ...rest }: FirstNameFieldProps) => {
  const { t } = useTranslation();
  const { mappings } = useAddressFormContext();
  const { register, getFieldState } = useFormContext();

  const fieldName = mappings[AddressPart.FIRSTNAME];
  if (!fieldName) {
    return;
  }

  return (
    <TextInput
      {...register(fieldName)}
      label={t('shared.field.firstName.name', { ns: 'shared' })}
      placeholder={t('shared.field.firstName.placeholder', { ns: 'shared' })}
      required
      error={getFieldState(fieldName)?.error?.message?.toString()}
      {...rest}
    />
  );
};
