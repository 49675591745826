import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { WorkflowStore } from './types';

export const initWorkflowStoreState: WorkflowStore = {
  storeId: '',
  storeName: '',
};

export const useWorkflowStore = create<WorkflowStore>()(
  immer(
    devtools(() => initWorkflowStoreState, {
      name: 'WorkflowStore',
    })
  )
);
