import { SVGProps } from 'react';

import { AnyUIProviderProps, MantineTheme } from '@any-ui-react/core';

import { AnyXIcon } from '~anyx/shared/icons';

import { AnyXCSSSpinner } from '../components';

import { ThemeOtherProps } from './types';

export const ANYX_THEME: Omit<AnyUIProviderProps, 'children'> = {
  primaryColor: 'blue',
  theme: {
    components: {
      NavLink: {
        defaultProps: (theme: MantineTheme) => {
          return {
            color: theme.colors.blue?.[6],
            styles: {
              section: {
                color: theme.colors.blue?.[6],
              },
            },
          };
        },
      },
    },
  },
  other: {
    secondaryColor: 'blue',
    logo: (props: SVGProps<SVGSVGElement>) => <AnyXIcon {...props} />,
    spinner: () => <AnyXCSSSpinner />,
  } satisfies ThemeOtherProps,
};
