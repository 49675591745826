import { useCallback, useState } from 'react';

import Cookies from 'js-cookie';

import { AlertType, useApiTranslationMapper } from '~anyx/shared/graphql';
import { toast } from '~anyx/shared/ui';

import { AiResponse } from '../../../modules/ai/type';

export const useSendPrompt = () => {
  const { getErrorMessage, getTranslationKey } = useApiTranslationMapper();
  // TODO: Update once actual API is available
  const url = 'https://anyx-llm-report-dev-api-3cv3rgwpeq-ul.a.run.app/api/v1/analyze';
  const [loading, setLoading] = useState(false);

  const handleSendPrompt = useCallback(
    async (prompt: string): Promise<AiResponse | null> => {
      setLoading(true);
      try {
        const result = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': `${Cookies.get('session_id')}`,
          },
          body: JSON.stringify({
            prompt,
          }),
          credentials: 'include',
        });

        return await result.json();
      } catch (e: unknown) {
        toast.error(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path: 'error',
            message: getErrorMessage(e),
          })
        );

        return null;
      } finally {
        setLoading(false);
      }
    },
    [getErrorMessage, getTranslationKey]
  );

  return {
    handleSendPrompt,
    loading,
  };
};
