import {
  AdReportDimension,
  AdReportPlatform,
  ReportDimensions,
  SaleChannelType,
} from '~anyx/shared/graphql';
import { NumberFormat } from './format.config';

export enum ReportMetricMappingKey {
  GROSS_SALES = 'grossSales',
  MARGINAL_PROFIT = 'marginalProfit',
  MARGINAL_PROFIT_RATE = 'marginalProfitRate',
  ORDERS = 'orders',
  CVR = 'cvr',
  ROAS = 'roas',
  SESSIONS = 'sessions',
  GROSS_PROFIT = 'grossProfit',
  GROSS_PROFIT_RATE = 'grossProfitRate',
  AVG_ORDER_VALUES = 'avgOrderValues',
  ADVERTISING_COST = 'advertisingCost',
  NET_SALES = 'netSales',
}

export const DimensionOption = {
  DAY: ReportDimensions.DAY,
  WEEK: ReportDimensions.WEEK,
  MONTH: ReportDimensions.MONTH,
  DOW: ReportDimensions.DOW,
  CHANNELS: ReportDimensions.CHANNELS,
};

export const AdOverviewDimensionOption = {
  DAY: AdReportDimension.DAY,
  WEEK: AdReportDimension.WEEK,
  MONTH: AdReportDimension.MONTH,
};

export const AdDetailedDimensionOption = {
  NONE: AdReportDimension.NONE,
  DAY: AdReportDimension.DAY,
  WEEK: AdReportDimension.WEEK,
  MONTH: AdReportDimension.MONTH,
};

export const homeMetricList = {
  GROSS_SALES: {
    mappingKey: ReportMetricMappingKey.GROSS_SALES,
    numberFormat: NumberFormat.PRICE,
  },
  MARGINAL_PROFIT: {
    mappingKey: ReportMetricMappingKey.MARGINAL_PROFIT,
    numberFormat: NumberFormat.PRICE,
  },
  MARGINAL_PROFIT_RATE: {
    mappingKey: ReportMetricMappingKey.MARGINAL_PROFIT_RATE,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  ORDERS: {
    mappingKey: ReportMetricMappingKey.ORDERS,
    numberFormat: NumberFormat.NUMBER,
  },
  CVR: {
    mappingKey: ReportMetricMappingKey.CVR,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  ROAS: {
    mappingKey: ReportMetricMappingKey.ROAS,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  SESSIONS: {
    mappingKey: ReportMetricMappingKey.SESSIONS,
    numberFormat: NumberFormat.NUMBER,
  },
  GROSS_PROFIT: {
    mappingKey: ReportMetricMappingKey.GROSS_PROFIT,
    numberFormat: NumberFormat.PRICE,
  },
  GROSS_PROFIT_RATE: {
    mappingKey: ReportMetricMappingKey.GROSS_PROFIT_RATE,
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

interface MetricList {
  [key: string]: {
    mappingKey: ReportMetricMappingKey;
    numberFormat: NumberFormat;
  };
}
export const ddiHomeMetricList: MetricList = {
  GROSS_SALES: {
    mappingKey: ReportMetricMappingKey.GROSS_SALES,
    numberFormat: NumberFormat.PRICE,
  },
  ORDERS: {
    mappingKey: ReportMetricMappingKey.ORDERS,
    numberFormat: NumberFormat.NUMBER,
  },
  NET_SALES: {
    mappingKey: ReportMetricMappingKey.NET_SALES,
    numberFormat: NumberFormat.PRICE,
  },
};

export const HomeMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
  ORDERS: 'ORDERS',
  CVR: 'CVR',
  ROAS: 'ROAS',
  SESSIONS: 'SESSIONS',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_RATE: 'GROSS_PROFIT_RATE',
};
export type HomeMetricOption = (typeof HomeMetricOption)[keyof typeof HomeMetricOption];

export const DdiHomeMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  ORDERS: 'ORDERS',
  NET_SALES: 'NET_SALES',
};
export type DdiHomeMetricOption = (typeof DdiHomeMetricOption)[keyof typeof DdiHomeMetricOption];

export const analyticsMetricList = {
  GROSS_SALES: {
    mappingKey: ReportMetricMappingKey.GROSS_SALES,
    numberFormat: NumberFormat.PRICE,
  },
  SESSIONS: {
    mappingKey: ReportMetricMappingKey.SESSIONS,
    numberFormat: NumberFormat.NUMBER,
  },
  ORDERS: {
    mappingKey: ReportMetricMappingKey.ORDERS,
    numberFormat: NumberFormat.NUMBER,
  },
  CVR: {
    mappingKey: ReportMetricMappingKey.CVR,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  ROAS: {
    mappingKey: ReportMetricMappingKey.ROAS,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  AVG_ORDER_VALUE: {
    mappingKey: ReportMetricMappingKey.AVG_ORDER_VALUES,
    numberFormat: NumberFormat.PRICE,
  },
  GROSS_PROFIT: {
    mappingKey: ReportMetricMappingKey.GROSS_PROFIT,
    numberFormat: NumberFormat.PRICE,
  },
  GROSS_PROFIT_RATE: {
    mappingKey: ReportMetricMappingKey.GROSS_PROFIT_RATE,
    numberFormat: NumberFormat.PERCENTAGE,
  },
  MARGINAL_PROFIT: {
    mappingKey: ReportMetricMappingKey.MARGINAL_PROFIT,
    numberFormat: NumberFormat.PRICE,
  },
  MARGINAL_PROFIT_RATE: {
    mappingKey: ReportMetricMappingKey.MARGINAL_PROFIT_RATE,
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

export const ddiSalesAnalyticsMetricList = {
  GROSS_SALES: {
    mappingKey: ReportMetricMappingKey.GROSS_SALES,
    numberFormat: NumberFormat.PRICE,
  },
  NET_SALES: {
    mappingKey: ReportMetricMappingKey.NET_SALES,
    numberFormat: NumberFormat.PRICE,
  },
  ORDERS: {
    mappingKey: ReportMetricMappingKey.ORDERS,
    numberFormat: NumberFormat.NUMBER,
  },
};

export const DDISalesChannelAnalyticsMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  NET_SALES: 'NET_SALES',
  ORDERS: 'ORDERS',
};

export type DDISalesChannelAnalyticsMetricOption =
  (typeof DDISalesChannelAnalyticsMetricOption)[keyof typeof DDISalesChannelAnalyticsMetricOption];

export const AnalyticsMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  SESSIONS: 'SESSIONS',
  ORDERS: 'ORDERS',
  CVR: 'CVR',
  ROAS: 'ROAS',
  AVG_ORDER_VALUE: 'AVG_ORDER_VALUE',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_RATE: 'GROSS_PROFIT_RATE',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
};

export type AnalyticsMetricOption =
  (typeof AnalyticsMetricOption)[keyof typeof AnalyticsMetricOption];

export const DdiProductAnalyticsMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  NET_SALES: 'NET_SALES',
  UNIT_SOLD: 'UNIT_SOLD',
};

export type DdiProductAnalyticsMetricOption =
  (typeof DdiProductAnalyticsMetricOption)[keyof typeof DdiProductAnalyticsMetricOption];

export interface KeyAndNumberFormat {
  mappingKey: string;
  numberFormat: NumberFormat;
}

export const advertisingMetricList: Record<AdvertisingMetricOption, KeyAndNumberFormat> = {
  COST: {
    mappingKey: 'cost',
    numberFormat: NumberFormat.PRICE,
  },
  IMPRESSIONS: {
    mappingKey: 'impressions',
    numberFormat: NumberFormat.NUMBER,
  },
  CLICKS: {
    mappingKey: 'clicks',
    numberFormat: NumberFormat.NUMBER,
  },
  CONVERSIONS: {
    mappingKey: 'conversions',
    numberFormat: NumberFormat.NUMBER,
  },
  SALES: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  CPM: {
    mappingKey: 'cpm',
    numberFormat: NumberFormat.PRICE,
  },
  CTR: {
    mappingKey: 'ctr',
    numberFormat: NumberFormat.PERCENTAGE,
  },
  CPC: {
    mappingKey: 'cpc',
    numberFormat: NumberFormat.PRICE,
  },
  CVR: {
    mappingKey: 'cvr',
    numberFormat: NumberFormat.PERCENTAGE,
  },
  CPA: {
    mappingKey: 'cpa',
    numberFormat: NumberFormat.PRICE,
  },
  ROAS: {
    mappingKey: 'roas',
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

export const AdvertisingMetricOption = {
  COST: 'COST',
  IMPRESSIONS: 'IMPRESSIONS',
  CLICKS: 'CLICKS',
  CONVERSIONS: 'CONVERSIONS',
  SALES: 'SALES',
  CPM: 'CPM',
  CTR: 'CTR',
  CPC: 'CPC',
  CVR: 'CVR',
  CPA: 'CPA',
  ROAS: 'ROAS',
};

export type AdvertisingMetricOption =
  (typeof AdvertisingMetricOption)[keyof typeof AdvertisingMetricOption];

export const barChartDimensions: ReportDimensions[] = [
  ReportDimensions.DOW,
  ReportDimensions.CHANNELS,
];

export const lineChartDimensions: ReportDimensions[] = [
  ReportDimensions.DAY,
  ReportDimensions.WEEK,
  ReportDimensions.MONTH,
];

export const InventoryStockValueMetricOption = {
  INVENTORY_COUNT: 'INVENTORY_COUNT',
  DAYS_OF_INVENTORY: 'DAYS_OF_INVENTORY',
  INVENTORY_STOCK_VALUE: 'INVENTORY_STOCK_VALUE',
  AVAILABLE_TO_PURCHASE: 'AVAILABLE_TO_PURCHASE',
};

export type InventoryStockValueMetricOption =
  (typeof InventoryStockValueMetricOption)[keyof typeof InventoryStockValueMetricOption];

export const inventoryStockValueMap: Record<InventoryStockValueMetricOption, KeyAndNumberFormat> = {
  INVENTORY_COUNT: {
    mappingKey: 'stockOnHand',
    numberFormat: NumberFormat.NUMBER,
  },
  INVENTORY_STOCK_VALUE: {
    mappingKey: 'stockOnHandValue',
    numberFormat: NumberFormat.NUMBER,
  },
  AVAILABLE_TO_PURCHASE: {
    mappingKey: 'availableToPurchase',
    numberFormat: NumberFormat.PERCENTAGE,
  },
  DAYS_OF_INVENTORY: {
    mappingKey: 'daysOfInventory',
    numberFormat: NumberFormat.NUMBER,
  },
};

export interface AdMetricData {
  key: string;
  numberFormat: string;
  currency: string;
  name: string;
  total: number;
  [AdReportPlatform.FACEBOOK]: number;
  [AdReportPlatform.GOOGLE]: number;
  [AdReportPlatform.TIKTOK]: number;
  [AdReportPlatform.YAHOO_SEARCH]: number;
  [AdReportPlatform.YAHOO_DISPLAY]: number;
}

export enum AdChartType {
  STACKED_BAR = 'stacked-bar',
  LINE = 'line',
}

export const stackedBarChartMetrics: AdvertisingMetricOption[] = [
  AdvertisingMetricOption.COST,
  AdvertisingMetricOption.IMPRESSIONS,
  AdvertisingMetricOption.CLICKS,
  AdvertisingMetricOption.CONVERSIONS,
  AdvertisingMetricOption.SALES,
];

export const lineChartMetrics: AdvertisingMetricOption[] = [
  AdvertisingMetricOption.CPM,
  AdvertisingMetricOption.CTR,
  AdvertisingMetricOption.CPC,
  AdvertisingMetricOption.CVR,
  AdvertisingMetricOption.CPA,
  AdvertisingMetricOption.ROAS,
];

export const TokopediaSalesMetricOption = {
  SALES: 'SALES',
  ORDERS: 'ORDERS',
};

export const YahooSalesMetricOption = {
  SALES: 'SALES',
  ORDER_COUNT: 'ORDER_COUNT',
  UNIT_SOLD_COUNT: 'UNIT_SOLD_COUNT',
  AVG_SPEND_PER_CUSTOMER: 'AVG_SPEND_PER_CUSTOMER',
};

export const ShopeeSalesMetricOption = {
  SALES: 'SALES',
  ORDERS: 'ORDERS',
  SALES_PER_ORDER: 'SALES_PER_ORDER',
  VISITORS: 'VISITORS',
};

export const AmazonSalesMetricOption = {
  ORDERED_PRODUCT_SALES: 'ORDERED_PRODUCT_SALES',
  UNITS_ORDERED: 'UNITS_ORDERED',
  TOTAL_ORDER_ITEMS: 'TOTAL_ORDER_ITEMS',
  AVERAGE_SALES_PER_ORDER_ITEM: 'AVERAGE_SALES_PER_ORDER_ITEM',
  AVERAGE_UNITS_PER_ORDER_ITEM: 'AVERAGE_UNITS_PER_ORDER_ITEM',
  AVERAGE_SELLING_PRICE: 'AVERAGE_SELLING_PRICE',
};

export const TikTokSalesMetricOption = {
  INCOME: 'INCOME',
  UNIT_SALES: 'UNIT_SALES',
  ORDERS: 'ORDERS',
};

export const RakutenSalesMetricOption = {
  SALES: 'SALES',
  SESSIONS: 'SESSIONS',
  ORDER_COUNT: 'ORDER_COUNT',
  CONVERSION_RATE: 'CONVERSION_RATE',
};

export const LazadaSalesMetricOption = {
  REVENUE: 'REVENUE',
  VISITORS: 'VISITORS',
  ORDERS: 'ORDERS',
  UNITS_SOLD: 'UNITS_SOLD',
  CONVERSION_RATE: 'CONVERSION_RATE',
};

export const ShopifySalesMetricOption = {
  ORDERS: 'ORDERS',
  GROSS_SALES: 'GROSS_SALES',
  DISCOUNTS: 'DISCOUNTS',
  RETURNS: 'RETURNS',
  NET_SALES: 'NET_SALES',
  SHIPPING: 'SHIPPING',
  TAXES: 'TAXES',
  TOTAL_SALES: 'TOTAL_SALES',
};

export type TokopediaSalesMetricOption =
  (typeof TokopediaSalesMetricOption)[keyof typeof TokopediaSalesMetricOption];

export type YahooSalesMetricOption =
  (typeof YahooSalesMetricOption)[keyof typeof YahooSalesMetricOption];

export type ShopeeSalesMetricOption =
  (typeof ShopeeSalesMetricOption)[keyof typeof ShopeeSalesMetricOption];

export type AmazonSalesMetricOption =
  (typeof AmazonSalesMetricOption)[keyof typeof AmazonSalesMetricOption];

export type TikTokSalesMetricOption =
  (typeof TikTokSalesMetricOption)[keyof typeof TikTokSalesMetricOption];

export type RakutenSalesMetricOption =
  (typeof RakutenSalesMetricOption)[keyof typeof RakutenSalesMetricOption];

export type LazadaSalesMetricOption =
  (typeof LazadaSalesMetricOption)[keyof typeof LazadaSalesMetricOption];

export type ShopifySalesMetricOption =
  (typeof ShopifySalesMetricOption)[keyof typeof ShopifySalesMetricOption];

export const tokopediaSalesMetricList: Record<TokopediaSalesMetricOption, KeyAndNumberFormat> = {
  SALES: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  ORDERS: {
    mappingKey: 'orders',
    numberFormat: NumberFormat.NUMBER,
  },
};

export const yahooSalesMetricList: Record<YahooSalesMetricOption, KeyAndNumberFormat> = {
  SALES: {
    mappingKey: 'totalSales',
    numberFormat: NumberFormat.PRICE,
  },
  ORDER_COUNT: {
    mappingKey: 'orderCount',
    numberFormat: NumberFormat.NUMBER,
  },
  UNIT_SOLD_COUNT: {
    mappingKey: 'unitSoldCount',
    numberFormat: NumberFormat.NUMBER,
  },
  AVG_SPEND_PER_CUSTOMER: {
    mappingKey: 'avgSpendPerCustomer',
    numberFormat: NumberFormat.PRICE,
  },
};

export const shopeeSalesMetricList: Record<ShopeeSalesMetricOption, KeyAndNumberFormat> = {
  SALES: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  ORDERS: {
    mappingKey: 'orders',
    numberFormat: NumberFormat.NUMBER,
  },
  SALES_PER_ORDER: {
    mappingKey: 'salesPerOrder',
    numberFormat: NumberFormat.PRICE,
  },
  VISITORS: {
    mappingKey: 'visitors',
    numberFormat: NumberFormat.NUMBER,
  },
};

export const amazonSalesMetricList: Record<AmazonSalesMetricOption, KeyAndNumberFormat> = {
  ORDERED_PRODUCT_SALES: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  UNITS_ORDERED: {
    mappingKey: 'unitsOrdered',
    numberFormat: NumberFormat.NUMBER,
  },
  TOTAL_ORDER_ITEMS: {
    mappingKey: 'itemsOrdered',
    numberFormat: NumberFormat.NUMBER,
  },
  AVERAGE_SALES_PER_ORDER_ITEM: {
    mappingKey: 'itemAverageCost',
    numberFormat: NumberFormat.PRICE,
  },
  AVERAGE_UNITS_PER_ORDER_ITEM: {
    mappingKey: 'averageNumberOfUnitsPerOrderItem',
    numberFormat: NumberFormat.NUMBER,
  },
  AVERAGE_SELLING_PRICE: {
    mappingKey: 'unitAverageCost',
    numberFormat: NumberFormat.PRICE,
  },
};

export const tikTokSalesMetricList: Record<TikTokSalesMetricOption, KeyAndNumberFormat> = {
  INCOME: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  UNIT_SALES: {
    mappingKey: 'unitsOrdered',
    numberFormat: NumberFormat.NUMBER,
  },
  ORDERS: {
    mappingKey: 'itemsOrdered',
    numberFormat: NumberFormat.NUMBER,
  },
};

export const rakutenSalesMetricList: Record<RakutenSalesMetricOption, KeyAndNumberFormat> = {
  SALES: {
    mappingKey: 'totalSales',
    numberFormat: NumberFormat.PRICE,
  },
  SESSIONS: {
    mappingKey: 'sessions',
    numberFormat: NumberFormat.NUMBER,
  },
  ORDER_COUNT: {
    mappingKey: 'orderCount',
    numberFormat: NumberFormat.NUMBER,
  },
  CONVERSION_RATE: {
    mappingKey: 'conversionRate',
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

export const lazadaSalesMetricList: Record<LazadaSalesMetricOption, KeyAndNumberFormat> = {
  REVENUE: {
    mappingKey: 'sales',
    numberFormat: NumberFormat.PRICE,
  },
  VISITORS: {
    mappingKey: 'visitors',
    numberFormat: NumberFormat.NUMBER,
  },
  ORDERS: {
    mappingKey: 'orders',
    numberFormat: NumberFormat.NUMBER,
  },
  UNITS_SOLD: {
    mappingKey: 'unitsOrdered',
    numberFormat: NumberFormat.NUMBER,
  },
  CONVERSION_RATE: {
    mappingKey: 'conversionRate',
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

export const shopifySalesMetricList: Record<ShopifySalesMetricOption, KeyAndNumberFormat> = {
  ORDERS: {
    mappingKey: 'orderCount',
    numberFormat: NumberFormat.NUMBER,
  },
  GROSS_SALES: {
    mappingKey: 'grossSales',
    numberFormat: NumberFormat.PRICE,
  },
  DISCOUNTS: {
    mappingKey: 'discounts',
    numberFormat: NumberFormat.PRICE,
  },
  RETURNS: {
    mappingKey: 'returns',
    numberFormat: NumberFormat.PRICE,
  },
  NET_SALES: {
    mappingKey: 'netSales',
    numberFormat: NumberFormat.PRICE,
  },
  SHIPPING: {
    mappingKey: 'shipping',
    numberFormat: NumberFormat.PRICE,
  },
  TAXES: {
    mappingKey: 'taxes',
    numberFormat: NumberFormat.PRICE,
  },
  TOTAL_SALES: {
    mappingKey: 'totalSales',
    numberFormat: NumberFormat.PRICE,
  },
};

export const ProductSalesReportMetricOption = {
  GROSS_SALES: 'GROSS_SALES',
  ORDERS: 'ORDERS',
  UNIT_SOLD: 'UNIT_SOLD',
  AVG_ORDER_VALUE: 'AVG_ORDER_VALUE',
  ROAS: 'ROAS',
};

export type ProductSalesReportMetricOption =
  (typeof ProductSalesReportMetricOption)[keyof typeof ProductSalesReportMetricOption];

export const productSalesReportMetricList = {
  GROSS_SALES: {
    mappingKey: 'grossSales',
    numberFormat: NumberFormat.PRICE,
  },
  ORDERS: {
    mappingKey: 'orders',
    numberFormat: NumberFormat.NUMBER,
  },
  UNIT_SOLD: {
    mappingKey: 'unitSold',
    numberFormat: NumberFormat.NUMBER,
  },
  AVG_ORDER_VALUE: {
    mappingKey: 'avgOrderValues',
    numberFormat: NumberFormat.PRICE,
  },
  ROAS: {
    mappingKey: 'roas',
    numberFormat: NumberFormat.PERCENTAGE,
  },
};

export type ProductSalesMetricData = {
  [channel in SaleChannelType]?: string;
} & {
  key: string;
  total: string;
};
