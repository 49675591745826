import React from 'react';

import { WarehouseManagementSystem } from '~anyx/shared/graphql';

const AnchantoEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.AnchantoEditPage,
  }))
);

const AnchantoTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.AnchantoTemplate }))
);

export const anchantoRouting = {
  path: WarehouseManagementSystem.ANCHANTO,
  element: <AnchantoTemplate />,
  children: [
    {
      index: true,
      element: <AnchantoEditPage />,
    },
  ],
};
