import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { WorkspaceMode } from '~anyx/common/workspace';

import { useWorkspaceHomepage } from './useWorkspaceHomepage';

export const useSwitchWorkspace = () => {
  const navigate = useNavigate();
  const { homepageLink, getHomepageForMode } = useWorkspaceHomepage();

  const switchWorkspace = useCallback(
    ({ storeId, mode }: { storeId?: string; mode?: WorkspaceMode }) => {
      if (storeId) {
        return navigate(`/${storeId}${homepageLink}`);
      }

      if (mode === WorkspaceMode.ON) {
        return navigate('/');
      }

      return navigate(mode ? getHomepageForMode(mode) : homepageLink);
    },
    [getHomepageForMode, homepageLink, navigate]
  );

  return {
    switchWorkspace,
  };
};
