import { PropsWithChildren, ReactElement } from 'react';
import { Trans } from 'react-i18next';

import classNames from 'classnames';

import { ChatIcon } from '~anyx/shared/icons';

export interface CrmLayoutNoteProps {
  title?: string;
  className?: string;
  emphasis?: boolean;
  ns?: string;
  i18nKey?: string;
}

export function CrmLayoutNote({
  children,
  className,
  title,
}: PropsWithChildren<{ title?: string; className?: string }>): ReactElement;
export function CrmLayoutNote({
  emphasis,
  i18nKey,
  ns,
  children,
}: PropsWithChildren<{ emphasis?: true; ns?: string; i18nKey?: string }>): ReactElement;
export function CrmLayoutNote({
  children,
  title,
  className,
  emphasis = false,
  ...props
}: PropsWithChildren<CrmLayoutNoteProps>) {
  return (
    <div className="col-span-1 lg:col-span-2 2xl:col-span-3">
      <div
        className={classNames(
          'shadow-card flex flex-col bg-white px-4 py-6 text-sm lg:rounded lg:px-6',
          className
        )}
      >
        <div className="mb-6 flex items-stretch gap-x-2">
          <ChatIcon className="text-gray-5 mt-0.5 h-6 w-6 shrink-0" />
          {emphasis ? (
            <Trans
              {...props}
              className="text-gray-9 text-lg"
              components={[<span className="font-semibold" />]}
              parent="h3"
            />
          ) : (
            <h3 className="text-gray-9 text-lg font-semibold">{title}</h3>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default CrmLayoutNote;
