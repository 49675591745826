import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifySalesReportTableQueryVariables = Types.Exact<{
  input: Types.ShopifySalesReportTableInput;
}>;


export type ShopifySalesReportTableQuery = { readonly shopifySalesReportTable: { readonly __typename?: 'ShopifySalesReportTablePayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'ShopifySalesReportTablePayload', readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly discounts: string, readonly grossSales: string, readonly netSales: string, readonly orderCount: string, readonly returns: string, readonly shipping: string, readonly taxes: string, readonly totalSales: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'ShopifySalesReportTotalPayload', readonly discounts: string, readonly grossSales: string, readonly netSales: string, readonly orderCount: string, readonly returns: string, readonly shipping: string, readonly taxes: string, readonly totalSales: string } } };


export const ShopifySalesReportTableDocument = gql`
    query ShopifySalesReportTable($input: ShopifySalesReportTableInput!) {
  shopifySalesReportTable(input: $input) {
    items {
      currency
      date
      discounts
      grossSales
      netSales
      orderCount
      returns
      shipping
      taxes
      totalSales
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      discounts
      grossSales
      netSales
      orderCount
      returns
      shipping
      taxes
      totalSales
    }
  }
}
    `;

/**
 * __useShopifySalesReportTableQuery__
 *
 * To run a query within a React component, call `useShopifySalesReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifySalesReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifySalesReportTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifySalesReportTableQuery(baseOptions: Apollo.QueryHookOptions<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables> & ({ variables: ShopifySalesReportTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>(ShopifySalesReportTableDocument, options);
      }
export function useShopifySalesReportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>(ShopifySalesReportTableDocument, options);
        }
export function useShopifySalesReportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>(ShopifySalesReportTableDocument, options);
        }
export type ShopifySalesReportTableQueryHookResult = ReturnType<typeof useShopifySalesReportTableQuery>;
export type ShopifySalesReportTableLazyQueryHookResult = ReturnType<typeof useShopifySalesReportTableLazyQuery>;
export type ShopifySalesReportTableSuspenseQueryHookResult = ReturnType<typeof useShopifySalesReportTableSuspenseQuery>;
export type ShopifySalesReportTableQueryResult = Apollo.QueryResult<ShopifySalesReportTableQuery, ShopifySalesReportTableQueryVariables>;
export function refetchShopifySalesReportTableQuery(variables: ShopifySalesReportTableQueryVariables) {
      return { query: ShopifySalesReportTableDocument, variables: variables }
    }