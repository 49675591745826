import { z } from 'zod';

export enum SupportingDataType {
  TABLE = 'table',
  STACKED_COLUMN_CHART = 'stacked_column_chart',
}

export enum ChatEventRole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

const SupportingTableDataSchema = z.record(z.string(), z.unknown());

export type SupportingTableData = z.infer<typeof SupportingTableDataSchema>;

export const TableDataSchema = z.object({
  data: z.array(SupportingTableDataSchema),
  supporting_data_type: z.literal('table'),
});

export const StackedColumnChartDataSchema = z.object({
  data: z.array(z.record(z.string(), z.string().or(z.number()).or(z.null()))),
  supporting_data_type: z.literal('stacked_column_chart'),
});

export const SupportingDataSchema = z.discriminatedUnion('supporting_data_type', [
  TableDataSchema,
  StackedColumnChartDataSchema,
]);

export const InsightSchema = z.object({
  description: z.string(),
  supporting_data: SupportingDataSchema,
});

export type Insight = z.infer<typeof InsightSchema>;

export const UserChatBubbleSchema = z.object({
  role: z.literal('user'),
  prompt: z.string(),
  loading: z.boolean().optional(),
});

export const AiResponseSchema = z.object({
  summary: z.string(),
  insights: z.array(InsightSchema).catch([]),
  recommendations: z.array(z.string()).optional(),
});

export type AiResponse = z.infer<typeof AiResponseSchema>;

export const AiChatBubbleSchema = z.object({
  role: z.literal('assistant'),
  response: AiResponseSchema.optional(),
  loading: z.boolean().optional(),
  error: z
    .object({
      message: z.string(),
      suggestion: z.object({
        message: z.string(),
        items: z.array(z.string()),
      }),
    })
    .optional(),
});

export const ChatEventSchema = z.discriminatedUnion('role', [
  UserChatBubbleSchema,
  AiChatBubbleSchema,
]);

export type ChatEvent = z.infer<typeof ChatEventSchema>;
