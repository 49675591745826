import { ReactNode, useMemo } from 'react';

import { ApolloProvider as Provider, ApolloClient, InMemoryCache } from '@apollo/client';

import { environment } from '~anyx/app-core/env';

import { useAuthCtx } from '~anyx/common/auth';
import { possibleFragmentTypes } from '~anyx/shared/graphql';
import { Environment } from '~anyx/shared/utils';

import { getApolloLink } from './apolloLink';
import { typePolicies } from './typePolicies';

export function ApolloProvider({ children }: { children: ReactNode }) {
  const { silentLogin } = useAuthCtx();

  const client = useMemo(
    () =>
      new ApolloClient({
        connectToDevTools: environment.env !== Environment.PRODUCTION,
        link: getApolloLink({ silentLogin }),
        cache: new InMemoryCache({
          possibleTypes: possibleFragmentTypes.possibleTypes,
          typePolicies: typePolicies,
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <Provider client={client}>{children}</Provider>;
}
