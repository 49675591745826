import { t } from 'i18next';

import { MetricData, ddiHomeMetricList } from '~anyx/feature/report';
import { SaleChannelType } from '~anyx/shared/graphql';
import { NumberFormat } from '~anyx/shared/ui';
import { CurrenciesEnum, ObjectUtils } from '~anyx/shared/utils';

import { DdiHomeReportChartQuery } from '../graphql';

export const computeDDIChartData = ({
  metric,
  data,
}: {
  metric: string;
  data?: DdiHomeReportChartQuery['ddiHomeReportChart'];
}) => {
  const tranformChartData = ({
    data,
    currency,
    numberFormat,
  }: {
    data: MetricData;
    currency: string;
    numberFormat: string;
  }) => {
    return data?.map((item) => ({
      ...item,
      numberFormat,
      key: new Date(item['key']).getTime(),
      name: t('gql.enum.reportSummary', { ns: 'gql', metric }),
      currency,
    }));
  };

  const channelList = Object.keys(data?.grossSales?.[0] || {}).filter((channel) => {
    return ObjectUtils.arrayFromEnum(SaleChannelType).includes(channel);
  }) as SaleChannelType[];
  const mappingKey = ddiHomeMetricList?.[metric]
    ?.mappingKey as keyof DdiHomeReportChartQuery['ddiHomeReportChart'];

  const reportChannelChart = {
    [metric]: tranformChartData({
      data: data?.[mappingKey] as MetricData,
      numberFormat: ddiHomeMetricList?.[metric]?.numberFormat || NumberFormat.NUMBER,
      currency: data?.currency || CurrenciesEnum.IDR,
    }),
  };
  return { reportChannelChart, channelList };
};
