export enum Auth0Permissions {
  // AUTH0 Permission (https://manage.auth0.com/dashboard/jp/anyx/apis/62e0dd7815d6fcbe324399aa/permissions)
  // -----------------------------------------------------------------------
  // Prevent an user from seeing user lists
  RESTRICTION_SEE_USERS = 'restriction:see_users',

  // -----------------------------------------------------------------------

  // BE Permission (this should be put on auth0 but BE decided otherwise...)
  // -----------------------------------------------------------------------
  // BELOW https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/3115253761/Staff+User+Permission
  // Give user full access over an account and add edit users.
  CAN_SEE_CURRENT_AND_FUTURE_STORES = 'be:user:canAccessExistingAndFutureStores',
  // Allow a user to add / edit / delete users
  CAN_MANAGE_USERS = 'be:user:canManageUsers',

  // BELOW https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/3144450062/User+Permission+Preference#Adjust-module-visibility-at-user-level-(affects-all-user-types)
  // Visibility of Order pages
  CAN_USE_ORDER = 'be:user:order',
  // Visibility of Analytics pages
  CAN_USE_ANALYTICS = 'be:user:analytics',
  // Visibility of Product Review Analytics pages
  CAN_USE_PRODUCT_REVIEW_ANALYTICS = 'be:user:productReviewAnalytics',
  // Visibility of Products pages
  CAN_USE_PRODUCT = 'be:user:product',
  // Visibility of Inventory pages
  CAN_USE_INVENTORY = 'be:user:inventory',
  // Visibility of CRM pages
  CAN_USE_CRM = 'be:user:crm',
  // Visibility of Datasource pages
  CAN_USE_DATASOURCE = 'be:user:datasource',
  // Visibility of Shipping pages
  CAN_USE_SHIPPING = 'be:user:shipping',
  // Visibility of Shipping pages
  CAN_USE_WORKFLOW = 'be:user:workflow',
  // -----------------------------------------------------------------------
}
