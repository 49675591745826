import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const ShipperRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/shipper' : '/shipper', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    add: () => createPath(`${root.path}/add`),
    edit: ({ shipperId = '' } = {}) =>
      createPath(
        `${root.path}/:shipperId`,
        {
          shipperId,
        },
        ['shipperId']
      ),
  };
};
