import React from 'react';
import { RouteObject } from 'react-router-dom';

const MessengerBroadcastListPage = React.lazy(() => import('./pages/list'));
const MessengerBroadcastAddPage = React.lazy(() => import('./pages/add'));
const MessengerBroadcastEditPage = React.lazy(() => import('./pages/edit'));
const MessengerBroadcastConnectMessenger = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.MessengerBroadcastConnectMessenger }))
);

export const routes: RouteObject[] = [
  {
    path: 'broadcast',
    element: <MessengerBroadcastConnectMessenger />,
    children: [
      {
        index: true,
        element: <MessengerBroadcastListPage />,
      },
      {
        path: 'add',
        element: <MessengerBroadcastAddPage />,
      },
      {
        path: ':messengerBroadcastId',
        element: <MessengerBroadcastEditPage />,
      },
    ],
  },
];
