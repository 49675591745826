import z from 'zod';

import { WorkflowErrorCode } from './constants';

const errorCodeSchema = z
  .object({
    graphQLErrors: z
      .array(
        z.object({
          extensions: z.object({
            message: z.string(),
          }),
        })
      )
      .nonempty(),
  })
  .transform((obj) => obj.graphQLErrors[0].extensions.message);

export function isSupportedWorkflowErrorCode(code: string) {
  return WorkflowErrorCode.safeParse(code).success;
}

/**
 * use to extract error code from ApolloError
 */
export function getWorkflowErrorCode(error: unknown) {
  try {
    const code = errorCodeSchema.parse(error);

    return WorkflowErrorCode.parse(code);
  } catch (err) {
    return undefined;
  }
}
