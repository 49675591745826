import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { upsRoutes, dhlRoutes, fedexRoutes, japanPostRoutes } from '~anyx/integrations/couriers';

const CouriersListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.CouriersListPage }))
);

const CourierProviderSelectPage = React.lazy(() =>
  import('./pages/select').then((module) => ({ default: module.CourierProviderSelectPage }))
);

const CouriersTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.CouriersTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'couriers',
    element: (
      <Auth0Guard>
        <CouriersTemplate />
      </Auth0Guard>
    ),
    children: [
      ...upsRoutes,
      ...dhlRoutes,
      ...fedexRoutes,
      ...japanPostRoutes,
      {
        path: 'select',
        element: <CourierProviderSelectPage />,
      },
      {
        path: 'list',
        element: <CouriersListPage />,
      },
    ],
  },
];
