import { environmentStore } from '../states';

import { LanguageUtils } from './language.utils';

export class LocaleUtils {
  static setUserLocale = (language?: string) => {
    environmentStore.getState().setCurrentLocale(LanguageUtils.fromI18nLanguage(language));
  };

  static getCurrentLocale() {
    return environmentStore.getState().currentLocale;
  }
}
