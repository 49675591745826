/* eslint-disable @typescript-eslint/naming-convention */
import { setUserProperties, setUserId, logEvent } from 'firebase/analytics';

import { analyticInstance, app } from './factory';

export const setUser = (
  userId: string,
  extra: {
    role: string;
    isInternalTester: boolean;
    isInternalUser: boolean;
    account?: {
      accountId: string;
      name: string;
    };
  }
) => {
  if (!analyticInstance) return;

  const {
    role,
    account,
    isInternalTester: is_internal_tester,
    isInternalUser: is_internal_user,
  } = extra;

  const { accountId: account_id, name: account_name } = account || {};

  const customProperties = {
    role,
    is_internal_tester,
    is_internal_user,
    ...(account && { account_id, account_name }),
  };
  setUserId(analyticInstance, userId);
  setUserProperties(analyticInstance, customProperties);
};

export const logUniqueStoreSessionEvent = ({
  store_id,
  store_name,
  account_id,
  account_name,
}: {
  store_id: string;
  store_name: string;
  account_id: string;
  account_name: string;
}) => {
  if (!analyticInstance) return;

  logEvent(analyticInstance, 'unique_store_session', {
    store_id,
    store_name,
    account_id,
    account_name,
  });
};

export const logWorkspaceSwitchEvent = (workspace_mode: string) => {
  logEvent(analyticInstance, 'workspace_switch', {
    workspace_mode,
  });
};

export const getApp = () => {
  return app;
};

export const getAnalyticsInstance = () => {
  return analyticInstance;
};
