import { useOnboardingCtx } from '~anyx/common/onboarding';

export const useSettingGuide = () => {
  const {
    state: {
      hasConnectedSaleChannel,
      hasInputUnitCost,
      hasRegisteredOrder,
      hasConnectedDatasourceChannel,
      hasSaleChannelCostIndex,
      hasShopifyChannel,
    },
  } = useOnboardingCtx();

  const shouldShowMock = !hasConnectedSaleChannel && !hasRegisteredOrder;

  return {
    hasConnectedSaleChannel,
    hasInputUnitCost,
    hasRegisteredOrder,
    hasConnectedDatasourceChannel,
    hasSaleChannelCostIndex,
    hasShopifyChannel,
    shouldShowMock,
  };
};
