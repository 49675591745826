import { useValidatePermission } from '~anyx/common/permission';

import { PermissionContainer, WorkspacePermissions } from './types';

export const useBuildPermissions = () => {
  const { validatePermission } = useValidatePermission();

  const getValidatedPermissions = (permissions: PermissionContainer) => {
    return Object.entries(permissions).reduce((acc, permission) => {
      const [key, permissionCtx] = permission;

      return {
        ...acc,
        [key]: validatePermission(permissionCtx),
      };
    }, {} as WorkspacePermissions);
  };

  return { getValidatedPermissions };
};
