import { Trans, useTranslation } from 'react-i18next';

import { Anchor } from '@any-ui-react/core';

import { AlertBanner } from '~anyx/shared/ui';

import { useHelpLink } from '../useHelpLink';

export interface MetaPrimaryReceiverBannerProps {
  className?: string;
}

export const MetaPrimaryReceiverBanner = ({ className }: MetaPrimaryReceiverBannerProps) => {
  const { t } = useTranslation('crmIntegration');
  const helpLink = useHelpLink();

  return (
    <AlertBanner
      className={className}
      type="warning"
      title={t('crmIntegration.shared.metaPrimaryReceiverBanner.title')}
    >
      <Trans i18nKey="crmIntegration.shared.metaPrimaryReceiverBanner.desc" ns="crmIntegration">
        In order for AnyChat to send messages successfully, give the primary receiver access to the
        AnyChat app. Check the{' '}
        <Anchor href={helpLink} target="_blank">
          Help Page
        </Anchor>{' '}
        for more information.
      </Trans>
    </AlertBanner>
  );
};
