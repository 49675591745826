import { today } from '@any-ui-react/dates';
import { z } from 'zod';

import { HomeMetricOption } from '~anyx/feature/report';
import {
  ReportChartTab,
  ReportDimensions,
  ReportOrderStatus,
  ReportTab,
  SaleChannelReportTableFilterInput,
  SaleChannelType,
} from '~anyx/shared/graphql';
import { AdsMetricsType } from '~anyx/shared/graphql';
import { DateUtils } from '~anyx/shared/utils';

type Constraints = SaleChannelReportTableFilterInput & {
  metric: HomeMetricOption;
};

export const HomePageFiltersParser = z.object({
  endDate: z
    .string()
    .catch(
      DateUtils.toISOStringEndOfDay(
        today(Intl.DateTimeFormat().resolvedOptions().timeZone),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    ),
  startDate: z
    .string()
    .catch(
      DateUtils.toISOStringStartOfDay(
        today(Intl.DateTimeFormat().resolvedOptions().timeZone),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    ),
  dimensions: z.nativeEnum(ReportDimensions).catch(() => ReportDimensions.DAY),
  marketplaces: z.array(z.nativeEnum(SaleChannelType)).catch([]),
  masterDataAccountIds: z.array(z.string()).nullish().catch([]),
  masterDataStoreIds: z.array(z.string()).catch([]),
  orderStatus: z.nativeEnum(ReportOrderStatus).catch(ReportOrderStatus.ORDERED),
  tab: z.nativeEnum(ReportTab).catch(ReportTab.ALL),
  metric: z.nativeEnum(HomeMetricOption).catch(HomeMetricOption.GROSS_SALES),
  adsMetricsType: z.nativeEnum(AdsMetricsType).optional().catch(AdsMetricsType.GROSS),
  chartTab: z.nativeEnum(ReportChartTab).catch(ReportChartTab.CHANNEL),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type HomeFilterInputFilterType = z.infer<typeof HomePageFiltersParser>;
