import { useCallback, useEffect, useState } from 'react';

import { AppState, LogoutOptions, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import { LoadWrapper } from '~anyx/shared/ui';
import { AuthTokenUtils } from '~anyx/shared/utils';

import { AuthContext } from './AuthContext';

export const AuthProvider = ({
  loginOptions,
  logoutOptions,
  children,
}: {
  loginOptions?: RedirectLoginOptions<AppState>;
  logoutOptions?: LogoutOptions;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [isTokenSet, setIsTokenSet] = useState(false);
  const {
    isLoading,
    isAuthenticated,
    logout: auth0Logout,
    error,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const logout = useCallback(
    (options?: LogoutOptions) => {
      try {
        AuthTokenUtils.clear();
        auth0Logout({ ...logoutOptions, ...options });
        setIsTokenSet(false);
      } catch (e) {
        Sentry.captureException(new Error("AuthProvider : User can't be logged out"));
      }
    },
    [auth0Logout, logoutOptions]
  );

  const login = useCallback(
    (options?: RedirectLoginOptions<AppState>) => {
      try {
        AuthTokenUtils.clear();
        setIsTokenSet(false);
        loginWithRedirect({ ...loginOptions, ...options });
      } catch (e) {
        Sentry.captureException(new Error("AuthProvider : User can't be logged in"));
      }
    },
    [loginOptions, loginWithRedirect]
  );

  const setToken = useCallback(async () => {
    try {
      const authorization = await getAccessTokenSilently();
      const token = (await getIdTokenClaims()) || { __raw: '' };
      AuthTokenUtils.set(authorization, token);
      setIsTokenSet(true);

      return [authorization, token];
    } catch (error) {
      logout();
      return;
    }
  }, [getAccessTokenSilently, getIdTokenClaims, logout]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      setToken();
    }
  }, [isAuthenticated, isLoading, setToken]);

  const authenticating = isLoading || (isAuthenticated && !isTokenSet);
  const authenticated = isAuthenticated && isTokenSet;
  return (
    <AuthContext.Provider
      value={{
        logout,
        login,
        silentLogin: setToken,
        authenticating,
        authenticated,
        error,
        user,
      }}
    >
      <LoadWrapper
        loading={authenticating}
        loaderId="auth0"
        className="min-w-screen absolute right-0 top-0 z-20 flex min-h-screen min-w-full justify-center overflow-hidden bg-white"
      >
        {children}
      </LoadWrapper>
    </AuthContext.Provider>
  );
};
