import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RakutenSalesReportSummaryQueryVariables = Types.Exact<{
  input: Types.RakutenSalesReportSummaryInput;
}>;


export type RakutenSalesReportSummaryQuery = { readonly rakutenSalesReportSummary: { readonly __typename?: 'RakutenSalesReportSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly conversionRate: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly orderCount: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly sessions: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly totalSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const RakutenSalesReportSummaryDocument = gql`
    query RakutenSalesReportSummary($input: RakutenSalesReportSummaryInput!) {
  rakutenSalesReportSummary(input: $input) {
    conversionRate {
      diff
      diffPercentage
      value
    }
    currency
    orderCount {
      diff
      diffPercentage
      value
    }
    sessions {
      diff
      diffPercentage
      value
    }
    totalSales {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useRakutenSalesReportSummaryQuery__
 *
 * To run a query within a React component, call `useRakutenSalesReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRakutenSalesReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRakutenSalesReportSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRakutenSalesReportSummaryQuery(baseOptions: Apollo.QueryHookOptions<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables> & ({ variables: RakutenSalesReportSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>(RakutenSalesReportSummaryDocument, options);
      }
export function useRakutenSalesReportSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>(RakutenSalesReportSummaryDocument, options);
        }
export function useRakutenSalesReportSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>(RakutenSalesReportSummaryDocument, options);
        }
export type RakutenSalesReportSummaryQueryHookResult = ReturnType<typeof useRakutenSalesReportSummaryQuery>;
export type RakutenSalesReportSummaryLazyQueryHookResult = ReturnType<typeof useRakutenSalesReportSummaryLazyQuery>;
export type RakutenSalesReportSummarySuspenseQueryHookResult = ReturnType<typeof useRakutenSalesReportSummarySuspenseQuery>;
export type RakutenSalesReportSummaryQueryResult = Apollo.QueryResult<RakutenSalesReportSummaryQuery, RakutenSalesReportSummaryQueryVariables>;
export function refetchRakutenSalesReportSummaryQuery(variables: RakutenSalesReportSummaryQueryVariables) {
      return { query: RakutenSalesReportSummaryDocument, variables: variables }
    }