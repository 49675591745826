import { useMemo } from 'react';

import { MdAccountProduct } from '~anyx/shared/graphql';

import { getCoreAppDomain } from '../utils';

export const useCoreAppDomain = ({ product }: { product: MdAccountProduct }) => {
  const coreAppDomain = useMemo(() => {
    return getCoreAppDomain(product);
  }, [product]);

  return { coreAppDomain };
};
