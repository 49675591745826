import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted } from '~anyx/common/permission';
import { WarehouseRoutePath } from '~anyx/common/routing';
import { MdUserRole } from '~anyx/shared/graphql';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const WarehouseListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.WarehouseListPage }))
);
const WarehouseAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.WarehouseAddPage }))
);
const WarehouseEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.WarehouseEditPage }))
);
const WarehouseTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.WarehouseTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'warehouse',
    element: (
      <Auth0Guard>
        <Restricted roles={[MdUserRole.ADMIN]} fallback={<UnauthorizedMessage />}>
          <WarehouseTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <WarehouseListPage />,
      },
      {
        path: 'add',
        element: <WarehouseAddPage />,
      },
      {
        path: ':warehouseId',
        element: <WarehouseEditPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={WarehouseRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
