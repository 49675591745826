import { ReactNode, useCallback } from 'react';

import { GroupBase, LoadOptions, OptionsOrGroups } from '@any-ui-react/select';
import { LazyQueryExecFunction, OperationVariables, QueryHookOptions } from '@apollo/client';

import { ReportListMeta, VariantValuesInput } from '~anyx/shared/graphql';
import { DEFAULT_OPTION_SIZE } from '~anyx/shared/utils';

export type LoadOptionAdditional = {
  pageNumber: number;
};

export interface LoadOptionDefaultOption<T> {
  label: ReactNode;
  value: T;
}

interface RequiredResponse<I> {
  variantValues: {
    items: readonly {
      name: I;
    }[];
    meta: ReportListMeta;
  };
}

interface RequiredVariables {
  input: VariantValuesInput;
}

interface LoadSelectOptionsProps<I, V, LQ, LV extends OperationVariables> {
  labelFormatter: (item: I) => ReactNode;
  valueFormatter: (item: I) => V;
  optionMapper?: (item: I) => LoadOptionDefaultOption<V>;
  listQuery: {
    lazyQuery: LazyQueryExecFunction<LQ, LV>;
    options?: QueryHookOptions<LQ, LV>;
    variables?: Omit<LV, 'pageName' | 'pageSize'>;
    hasMoreChecker?: (
      loadedOptions: LoadOptionDefaultOption<V>[],
      options: OptionsOrGroups<LoadOptionDefaultOption<V>, GroupBase<LoadOptionDefaultOption<V>>>,
      data?: LQ
    ) => boolean;
  };
}

export const useLoadVariantOptions = <
  I, // Type used in formatter Functions,
  V, // Type used has select value
  LQ extends RequiredResponse<I>, // Type of List Query
  LV extends RequiredVariables // Variables of List Query
>({
  labelFormatter,
  valueFormatter,
  optionMapper = (item) => ({
    label: labelFormatter(item),
    value: valueFormatter(item),
  }),
  listQuery: {
    options: listOptions,
    variables: listVariables,
    // scope: listScope,
    lazyQuery: listQuery,
    hasMoreChecker = (loadedOptions, newOptions, data) => {
      return loadedOptions.length + newOptions.length < (data?.variantValues.meta.total || 0);
    },
  },
}: LoadSelectOptionsProps<I, V, LQ, LV>) => {
  const loadOptions: LoadOptions<
    LoadOptionDefaultOption<V>,
    GroupBase<LoadOptionDefaultOption<V>>,
    LoadOptionAdditional
  > = useCallback(
    async (name, loadedOptions = [], additional = { pageNumber: 1 }) => {
      try {
        const requiredVariables: Pick<LV, 'input'> = {
          input: {
            pageNumber: additional.pageNumber,
            pageSize: DEFAULT_OPTION_SIZE,
            name,
          },
        };

        const { data } = await listQuery({
          ...listOptions,
          variables: {
            ...listVariables,
            ...requiredVariables,
            input: {
              ...listVariables?.input,
              ...requiredVariables.input,
            },
          } as LV,
        });

        const newItems = data?.variantValues?.items || [];

        const mappedOptions = newItems.map((item) => optionMapper(item.name));
        const hasMore = hasMoreChecker(mappedOptions, loadedOptions, data);
        return {
          options: !additional.pageNumber ? [...mappedOptions] : mappedOptions,
          hasMore,
          additional: { pageNumber: Number(requiredVariables.input.pageNumber) + 1 },
        };
      } catch (_) {
        return {
          options: [],
          hasMore: false,
          additional,
        };
      }
    },
    [hasMoreChecker, listOptions, listQuery, listVariables, optionMapper]
  );

  return { loadOptions };
};
