import { useTranslation } from 'react-i18next';

import { HomeMetricOption, NumberFormat } from '~anyx/feature/report';
import { CartIcon, EyeIcon, TagThinIcon, ThunderIcon } from '~anyx/shared/icons';

import { HomeReportSummaryComparison } from '../type';

export const useMetricCardData = (data?: HomeReportSummaryComparison) => {
  const { t } = useTranslation();
  const groupedMetricCardData = data
    ? [
        {
          numberFormat: NumberFormat.PRICE,
          title: t('gql.enum.reportSummary', { ns: 'gql', metric: HomeMetricOption.GROSS_SALES }),
          value: {
            current: data.grossSales.current,
            percentage: data.grossSales.percentage,
          },
        },
        {
          numberFormat: NumberFormat.PRICE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.MARGINAL_PROFIT,
          }),
          value: {
            current: data.marginalProfit?.current,
            percentage: data.marginalProfit?.percentage,
          },
        },
        {
          numberFormat: NumberFormat.PERCENTAGE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.MARGINAL_PROFIT_RATE,
          }),
          value: {
            current: data.marginalProfitRate?.current,
            percentage: data.marginalProfitRate?.percentage,
          },
        },
        {
          numberFormat: NumberFormat.PRICE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.GROSS_PROFIT,
          }),
          value: {
            current: data.grossProfit?.current,
            percentage: data.grossProfit?.percentage,
          },
        },
        {
          numberFormat: NumberFormat.PERCENTAGE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.GROSS_PROFIT_RATE,
          }),
          value: {
            current: data.grossProfitRate?.current,
            percentage: data.grossProfitRate?.percentage,
          },
        },
      ]
    : new Array(5).fill({ title: '', value: 0 });

  const iconMetricCardData = data
    ? [
        {
          numberFormat: NumberFormat.NUMBER,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.SESSIONS,
          }),
          value: {
            current: data.sessions?.current,
            percentage: data.sessions?.percentage,
          },
          icon: (
            <div className="h-6 w-6 rounded-lg bg-[#E4EDF5] text-[#8AA7C1]">
              <EyeIcon className="p-1" />
            </div>
          ),
        },
        {
          numberFormat: NumberFormat.NUMBER,
          title: t('gql.enum.reportSummary', { ns: 'gql', metric: HomeMetricOption.ORDERS }),
          value: {
            current: data.orders.current,
            percentage: data.orders.percentage,
          },
          icon: (
            <div className="h-6 w-6 rounded-lg bg-[#FAE9DE] text-[#EC8F54]">
              <TagThinIcon className="p-1" />
            </div>
          ),
        },
        {
          numberFormat: NumberFormat.PERCENTAGE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.CVR,
          }),
          value: {
            current: data.cvr?.current,
            percentage: data.cvr?.percentage,
          },
          icon: (
            <div className="h-6 w-6 rounded-lg bg-[#D7F3F4] text-[#4BB1B7]">
              <CartIcon className="p-1" />
            </div>
          ),
        },
        {
          numberFormat: NumberFormat.PERCENTAGE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: HomeMetricOption.ROAS,
          }),
          value: {
            current: data.roas?.current,
            percentage: data.roas?.percentage,
          },
          icon: (
            <div className="h-6 w-6 rounded-lg bg-[#FEEFD1] text-[#F8B32D]">
              <ThunderIcon className="p-1" />
            </div>
          ),
        },
      ]
    : new Array(4).fill({ title: '', value: 0 });

  return {
    groupedMetricCardData,
    iconMetricCardData,
  };
};
