import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/**
 *
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const OauthRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId' : '/', {
    workspaceStoreId,
  });

  return {
    redirect: () => ({
      default: () => createPath(`${root.path}/integrations/oauth-redirect`),
      datasource: () => createPath(`${root.path}/integrations/datasource/oauth-redirect`),
      google: () => createPath(`${root.path}/integrations/datasource/google/oauth-redirect`),
      facebook: () => createPath(`${root.path}/integrations/datasource/facebook/oauth-redirect`),
      yahoo: () => createPath(`${root.path}/integrations/datasource/yahoo/oauth-redirect`),
      tikTok: () => createPath(`${root.path}/integrations/datasource/tikTok/oauth-redirect`),
      ups: () => createPath(`${root.path}/integrations/ups/oauth-redirect`),
    }),
  };
};
