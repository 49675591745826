import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { ReportEventCalendarRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const EventCalendarListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.EventCalendarListPage }))
);

const EventCalendarTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.EventCalendarTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'event-calendar',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<UnauthorizedMessage />}
        >
          <EventCalendarTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <EventCalendarListPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportEventCalendarRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
