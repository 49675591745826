import {
  getDate,
  getHours,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  set,
} from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';

export const mergeDateInTimeZone = ({
  currentDate,
  newDate,
  timezone,
  mergeValues,
}: {
  currentDate: string;
  newDate: string;
  timezone: string;
  mergeValues?: {
    year?: boolean;
    month?: boolean;
    date?: boolean;
    hours?: boolean;
    minutes?: boolean;
    seconds?: boolean;
    milliseconds?: boolean;
  };
}) => {
  const currentZoned = toZonedTime(new Date(currentDate), timezone);
  const newZoned = toZonedTime(new Date(newDate), timezone);

  const finalZoned = set(currentZoned, {
    year: mergeValues?.year ? getYear(newZoned) : undefined,
    month: mergeValues?.month ? getMonth(newZoned) : undefined,
    date: mergeValues?.date ? getDate(newZoned) : undefined,
    hours: mergeValues?.hours ? getHours(newZoned) : undefined,
    minutes: mergeValues?.minutes ? getMinutes(newZoned) : undefined,
    seconds: mergeValues?.seconds ? getSeconds(newZoned) : undefined,
    milliseconds: mergeValues?.milliseconds ? getMilliseconds(newZoned) : undefined,
  });

  return fromZonedTime(finalZoned, timezone).toISOString();
};
