import { Breadcrumbs, UnstyledButton } from '@any-ui-react/core';
import classNames from 'classnames';

export interface ChevronBreadcrumbsProps {
  className?: string;
  links: {
    title: string;
    onClick?: () => void;
    subTitle?: string;
  }[];
}

export const ChevronBreadcrumbs = ({ className, links }: ChevronBreadcrumbsProps) => {
  const items = links.map((item, index) => {
    return index !== 0 ? (
      <UnstyledButton
        onClick={item.onClick}
        key={index}
        className="m-l hover:bg-gray-0 after:hover:border-l-gray-0 group relative inline-flex h-full items-center border-white bg-white pl-10 pr-4 text-sm after:absolute after:-right-[1.75rem] after:top-0 after:border after:border-b-[1.6875rem] after:border-l-[1.6875rem] after:border-t-[1.6875rem] after:border-b-transparent after:border-l-inherit after:border-r-transparent after:border-t-transparent after:content-[''] last:translate-x-0 last:pl-4 hover:no-underline"
      >
        <div className="flex max-w-xs flex-col">
          <span className="text-gray-9 group-hover:text-blue-6">{item.title}</span>
          {item.subTitle && (
            <span className="text-gray-7 text-2xs mt-1 overflow-hidden text-ellipsis font-normal">
              {item.subTitle}
            </span>
          )}
        </div>
        <div className="border-l-gray-2 absolute right-0 top-0 inline-block h-full translate-x-[1.85rem] border border-b-[1.6875rem] border-l-[1.6875rem] border-t-[1.6875rem] border-b-transparent border-r-transparent border-t-transparent" />
      </UnstyledButton>
    ) : (
      <div
        key={index}
        className={classNames('relative inline-flex h-full items-center', {
          "border-white px-4 after:absolute after:-right-[1.75rem] after:top-0 after:border after:border-b-[1.6875rem] after:border-l-[1.6875rem] after:border-t-[1.6875rem] after:border-b-transparent after:border-l-inherit after:border-r-transparent after:border-t-transparent after:content-['']":
            links.length === 1,
          'pl-10': links.length !== 1,
        })}
      >
        <div className="flex max-w-xs flex-col">
          <span>{item.title}</span>
          {item.subTitle && (
            <span className="text-gray-7 text-2xs mt-1 overflow-hidden text-ellipsis font-normal">
              {item.subTitle}
            </span>
          )}
        </div>
        {links.length === 1 && (
          <div className="border-l-gray-2 absolute right-0 top-0 inline-block h-full translate-x-[1.85rem] border border-b-[1.6875rem] border-l-[1.6875rem] border-t-[1.6875rem] border-b-transparent border-r-transparent border-t-transparent" />
        )}
      </div>
    );
  });

  return (
    <Breadcrumbs
      separator=""
      className={classNames(
        className,
        'border-b-gray-2 flex h-[3.4375rem] flex-row-reverse justify-end border-b bg-white font-semibold [&_.mantine-Breadcrumbs-separator]:hidden'
      )}
    >
      {items}
    </Breadcrumbs>
  );
};
