import { useMemo } from 'react';

import { SaleChannelType } from '~anyx/shared/graphql';

export const usePrioritizedChannel = ({
  channels,
  channel,
}: {
  channels: SaleChannelType[];
  channel?: SaleChannelType;
}) => {
  const prioritizedChannel = useMemo(() => {
    if (channels.length === 0) {
      return SaleChannelType.Manual;
    }

    if (channel && channels.includes(channel)) {
      return channel;
    }

    const channelPriority = [
      SaleChannelType.Shopee,
      SaleChannelType.Lazada,
      SaleChannelType.TikTok,
      SaleChannelType.AmazonSellerCentral,
      SaleChannelType.Tokopedia,
      SaleChannelType.Rakuten,
      SaleChannelType.Yahoo,
      SaleChannelType.Qoo10,
      SaleChannelType.Shopify,
      SaleChannelType.Zozotown,
      SaleChannelType.FacebookShop,
      SaleChannelType.Stores,
      SaleChannelType.Magaseek,
      SaleChannelType.AuPayMarket,
      SaleChannelType.Momo,
      SaleChannelType.RakutenFashion,
      SaleChannelType.Shoplist,
      SaleChannelType.MakeshopByGmo,
      SaleChannelType.SuperDelivery,
      SaleChannelType.Manual,
    ];

    return channels.reduce((highest, current) =>
      channelPriority.indexOf(current) < channelPriority.indexOf(highest) ? current : highest
    );
  }, [channel, channels]);

  return {
    prioritizedChannel,
  };
};
