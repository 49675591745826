import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputPhone, InputPhoneProps } from '~anyx/shared/ui';
import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';

export type PhoneFieldProps = Partial<InputPhoneProps>;

export const PhoneField = ({ ...rest }: PhoneFieldProps) => {
  const { t } = useTranslation();
  const { mappings } = useAddressFormContext();
  const { control, getFieldState } = useFormContext();

  const fieldName = mappings[AddressPart.PHONE];
  if (!fieldName) {
    return;
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { ...props } }) => (
        <InputPhone
          {...props}
          required
          label={t('shared.field.phoneNumber.name', { ns: 'shared' })}
          placeholder={t('shared.field.phoneNumber.placeholder', { ns: 'shared' })}
          error={getFieldState(fieldName)?.error?.message?.toString()}
          {...rest}
        />
      )}
    />
  );
};
