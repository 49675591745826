import React, { useEffect, useLayoutEffect } from 'react';

import { DEFAULT_LAYOUT, PageLayout as Layout, useLayoutStore } from '~anyx/shared/utils';

import { CardLoader } from '../../molecules';
import { NotFoundMessage, PageFailureMessage } from '../error-message';

export interface PageLayoutProps extends Layout {
  loading?: boolean;
  error?: boolean;
  notFound?: boolean;
  loadingComponent: JSX.Element;
  errorComponent: JSX.Element;
  notFoundComponent: JSX.Element;
}

export function PageLayout({
  title = DEFAULT_LAYOUT.title,
  withNavigation = DEFAULT_LAYOUT.withNavigation,
  withFooter = DEFAULT_LAYOUT.withFooter,
  clamped = DEFAULT_LAYOUT.clamped,
  fullPage = DEFAULT_LAYOUT.fullPage,
  header = () => null,
  loading,
  error,
  notFound,
  children,
  loadingComponent = <CardLoader loading />,
  errorComponent = <PageFailureMessage />,
  notFoundComponent = <NotFoundMessage />,
}: React.PropsWithChildren<Partial<PageLayoutProps>>) {
  const setTitle = useLayoutStore((state) => state.setTitle);
  const setShowSidenav = useLayoutStore((state) => state.setShowSidenav);
  const setShowFooter = useLayoutStore((state) => state.setShowFooter);
  const setClampContent = useLayoutStore((state) => state.setClampContent);
  const setFullPage = useLayoutStore((state) => state.setFullPage);
  const setHeader = useLayoutStore((state) => state.setHeader);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  useEffect(() => {
    setShowSidenav(withNavigation);
  }, [withNavigation, setShowSidenav]);

  useEffect(() => {
    setClampContent(clamped);
  }, [clamped, setClampContent]);

  useEffect(() => {
    setFullPage(fullPage);
  }, [setFullPage, fullPage]);

  useEffect(() => {
    setHeader(header);
  }, [setHeader, header]);

  useEffect(() => {
    setShowFooter(withFooter);
  }, [setShowFooter, withFooter]);

  useLayoutEffect(() => window.scrollTo(0, 0), []);

  if (loading) {
    return loadingComponent;
  }

  if (error) {
    return errorComponent;
  }

  if (notFound) {
    return notFoundComponent;
  }

  return children;
}
