import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdUpdateSelfMutationVariables = Types.Exact<{
  input: Types.MdUpdateUserInput;
}>;


export type MdUpdateSelfMutation = { readonly MdUpdateUser: { readonly __typename?: 'MdUser', readonly accessAllStores: boolean, readonly storeIds: ReadonlyArray<string>, readonly accountIds: ReadonlyArray<string>, readonly auth0UserId?: string | null, readonly canManageUsers: boolean, readonly countryId?: string | null, readonly createdAt: string, readonly currencyId: string, readonly email: string, readonly firstName: string, readonly id: string, readonly languageId: string, readonly lastName?: string | null, readonly role: Types.MdUserRole, readonly timezoneId: string, readonly updatedAt: string } };


export const MdUpdateSelfDocument = gql`
    mutation mdUpdateSelf($input: MdUpdateUserInput!) {
  MdUpdateUser(input: $input) {
    accessAllStores
    storeIds
    accountIds
    auth0UserId
    canManageUsers
    countryId
    createdAt
    currencyId
    email
    firstName
    id
    languageId
    lastName
    role
    timezoneId
    updatedAt
  }
}
    `;
export type MdUpdateSelfMutationFn = Apollo.MutationFunction<MdUpdateSelfMutation, MdUpdateSelfMutationVariables>;

/**
 * __useMdUpdateSelfMutation__
 *
 * To run a mutation, you first call `useMdUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMdUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mdUpdateSelfMutation, { data, loading, error }] = useMdUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMdUpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<MdUpdateSelfMutation, MdUpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MdUpdateSelfMutation, MdUpdateSelfMutationVariables>(MdUpdateSelfDocument, options);
      }
export type MdUpdateSelfMutationHookResult = ReturnType<typeof useMdUpdateSelfMutation>;
export type MdUpdateSelfMutationResult = Apollo.MutationResult<MdUpdateSelfMutation>;
export type MdUpdateSelfMutationOptions = Apollo.BaseMutationOptions<MdUpdateSelfMutation, MdUpdateSelfMutationVariables>;