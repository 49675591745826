import { useCallback, useEffect, useRef } from 'react';

import { LogisticFeatureFlag } from '~anyx/shared/graphql';
import { ObjectUtils } from '~anyx/shared/utils';

import { useGetStoresLogisticConnectionFlagLazyQuery } from './graphql';
interface LogisticPermissionControlLazy {
  lazy?: false;
  skip?: boolean;
  storeIds: string[];
  requiredFlags?: LogisticFeatureFlag[];
}

interface LogisticPermissionControl {
  lazy?: true;
  skip?: false;
  storeIds?: string[];
  requiredFlags?: LogisticFeatureFlag[];
}

interface HookReturnType {
  hasRequiredFlags: boolean;
  logisticFeatureFlag: LogisticFeatureFlag;
  error: unknown;
  loading: boolean;
  fetchLogisticFlags: (storeIds: string[]) => Promise<{
    hasRequiredFlags: boolean;
    logisticFeatureFlag: LogisticFeatureFlag;
  }>;
}

function useStoreLogisticPermissionsCode(params: LogisticPermissionControlLazy): HookReturnType;
function useStoreLogisticPermissionsCode(params: LogisticPermissionControl): HookReturnType;
function useStoreLogisticPermissionsCode({
  lazy = false,
  skip = false,
  storeIds = [],
  requiredFlags,
}: LogisticPermissionControlLazy | LogisticPermissionControl): ReturnType<
  typeof useStoreLogisticPermissions
> {
  const [execute, { data, error, loading }] = useGetStoresLogisticConnectionFlagLazyQuery();
  const storeIdsRef = useRef(storeIds);

  useEffect(() => {
    if (!ObjectUtils.isEqual(storeIdsRef.current, storeIds)) {
      storeIdsRef.current = storeIds;
    }
  }, [storeIds]);

  useEffect(() => {
    const isEmpty =
      !storeIdsRef?.current ||
      storeIdsRef.current.length === 0 ||
      storeIdsRef.current.every((item) => item === '' || item === undefined);

    if (!skip && !lazy && !isEmpty) {
      execute({
        variables: {
          mdStoreIds: storeIdsRef.current,
        },
      });
    }
    // once set to lazy, this should remain lazy
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, storeIdsRef.current]);

  const fetchLogisticFlags = useCallback(
    async (storeIds: string[]) => {
      if (storeIds?.length === 0) {
        return {
          hasRequiredFlags: false,
          logisticFeatureFlag: LogisticFeatureFlag.NO_LOGISTICS,
        };
      }

      const { data: result } = await execute({
        variables: {
          mdStoreIds: storeIds,
        },
      });
      return {
        hasRequiredFlags:
          (result?.GetHighestLogisticConnectionType &&
            requiredFlags?.includes(result?.GetHighestLogisticConnectionType)) ??
          false,
        logisticFeatureFlag:
          result?.GetHighestLogisticConnectionType || LogisticFeatureFlag.NO_LOGISTICS,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute, requiredFlags]
  );

  const logisticFeatureFlag =
    data?.GetHighestLogisticConnectionType || LogisticFeatureFlag.NO_LOGISTICS;
  const hasRequiredFlags =
    (logisticFeatureFlag && requiredFlags?.includes(logisticFeatureFlag)) || false;

  return {
    hasRequiredFlags,
    logisticFeatureFlag,
    error,
    loading,
    fetchLogisticFlags,
  };
}

export const useStoreLogisticPermissions = useStoreLogisticPermissionsCode;
