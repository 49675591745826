import { useEffect } from 'react';

import { useAuthCtx } from '~anyx/common/auth';
import { LoadWrapper, PageLayout } from '~anyx/shared/ui';

export const ErrorUnauthorizedPage = () => {
  const { logout } = useAuthCtx();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      withNavigation={false}
      withFooter={false}
      fullPage={true}
      loading={true}
      loadingComponent={<LoadWrapper />}
    />
  );
};
