import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useModals } from '@any-ui-react/modals';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import * as Sentry from '@sentry/react';

import { OnboardingRoutePath } from '~anyx/common/routing';
import { useSelfCtx } from '~anyx/common/self';
import { useMdTimezonesQuery } from '~anyx/shared/graphql';
import { ModalAlertTitle, toast } from '~anyx/shared/ui';
import { RECOMMEND_TIMEZONE_STORAGE_KEY, TimezoneUtils } from '~anyx/shared/utils';

import { useUpdateSelf } from './operation';
import { RecommendedTimezoneDialog } from './RecommendedTimezoneDialog';

export const TimezoneCheck = () => {
  const { t } = useTranslation();
  const matchedWithMdStoreId = useMatch(
    `:workspaceMasterStoreDataId${OnboardingRoutePath().root().pattern}`
  );
  const matched = useMatch(OnboardingRoutePath().root().pattern);
  const isOnboardingPage = matchedWithMdStoreId || matched;
  const { openModal, closeModal } = useModals();
  const { self, refetch } = useSelfCtx();
  const { handleUpdateSelf } = useUpdateSelf();
  const timezone = TimezoneUtils.getCurrentTimezone();
  const [ignored, setIgnored] = useSessionStorage<boolean>({
    key: RECOMMEND_TIMEZONE_STORAGE_KEY,
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const [dismissed, setDismissed] = useLocalStorage<boolean>({
    key: RECOMMEND_TIMEZONE_STORAGE_KEY,
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const { data } = useMdTimezonesQuery({
    skip: !self.id,
  });
  const selfTimeZoneOffset = TimezoneUtils.getFormattedUTCTimezoneOffset(timezone);
  const deviceTimeZoneOffset = TimezoneUtils.getDeviceTimezoneOffset();

  const timezoneOptions = (data?.MdTimezones || []).map(({ name, id, offset }) => ({
    id,
    name,
    offset,
  }));

  const updateSelfTimezone = async (timezoneId: string) => {
    await handleUpdateSelf({
      ...self,
      lastName: self.lastName ?? '',
      timezoneId,
    });
    refetch();
  };

  useEffect(() => {
    if (
      !timezoneOptions.length ||
      dismissed ||
      ignored ||
      deviceTimeZoneOffset === selfTimeZoneOffset
    ) {
      return;
    }

    const recommended = TimezoneUtils.getRecommendedTimezones(
      deviceTimeZoneOffset,
      timezoneOptions
    );
    if (!recommended) {
      Sentry.captureException(
        new Error(
          `Recommended timezone is empty ${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          } (${deviceTimeZoneOffset})`
        )
      );
      return;
    }
    if (!selfTimeZoneOffset) {
      // if user doesn't have default timezone, help them set with the device one
      (async () => {
        await updateSelfTimezone(recommended.id);
        toast.success(null, { title: t('shared.timezone.syncedWithDevice', { ns: 'shared' }) });
      })();
      return;
    }

    if (!isOnboardingPage)
      openModal({
        modalId: RECOMMEND_TIMEZONE_STORAGE_KEY,
        withCloseButton: false,
        closeOnClickOutside: false,
        title: (
          <ModalAlertTitle>{t('shared.timezone.dialog.title', { ns: 'shared' })}</ModalAlertTitle>
        ),
        children: (
          <RecommendedTimezoneDialog
            deviceTimeZone={deviceTimeZoneOffset}
            selfTimeZone={selfTimeZoneOffset}
            onApply={async () => {
              await updateSelfTimezone(recommended.id);
              closeModal(RECOMMEND_TIMEZONE_STORAGE_KEY);
            }}
            onIgnoreOnce={() => {
              setIgnored(true);
              closeModal(RECOMMEND_TIMEZONE_STORAGE_KEY);
            }}
            onDismiss={() => {
              setDismissed(true);
              closeModal(RECOMMEND_TIMEZONE_STORAGE_KEY);
            }}
          />
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneOptions.length]);

  return null;
};
