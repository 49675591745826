import { useCallback, useEffect } from 'react';

import { useInterval } from '@mantine/hooks';
import { subMilliseconds } from 'date-fns';

import { useChannelSyncsLazyQuery } from '../graphql';

export const POLLING_INTERVAL = 5000;

export const useGetBannersInterval = ({ storeId }: { storeId: string }) => {
  const [getChannelSyncs, { data }] = useChannelSyncsLazyQuery();
  const fetchChannelSyncs = useCallback(() => {
    getChannelSyncs({
      variables: {
        after: subMilliseconds(new Date(), POLLING_INTERVAL).toISOString(),
        masterDataStoreId: storeId,
      },
    });
  }, [getChannelSyncs, storeId]);

  const interval = useInterval(() => fetchChannelSyncs(), POLLING_INTERVAL);

  useEffect(() => {
    fetchChannelSyncs();
    interval.start();
    return interval.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
  };
};
