import { useEffect, useState } from 'react';
import { useLocation, Location, useNavigationType } from 'react-router-dom';

import { LocationContext } from './LocationContext';
interface LocationState {
  previous: Location | undefined;
  current: Location;
}

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const [history, setHistory] = useState<LocationState>({ previous: undefined, current: location });

  useEffect(() => {
    setHistory((history) => {
      const newHistory: LocationState = { ...history, current: location };
      if (navigationType === 'REPLACE') {
        newHistory.previous = history.previous;
      } else if (navigationType === 'POP') {
        newHistory.previous = undefined;
      } else {
        newHistory.previous = history.current;
      }
      return newHistory;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <LocationContext.Provider value={{ ...history, historyAction: navigationType }}>
      {children}
    </LocationContext.Provider>
  );
};
