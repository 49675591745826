import { forwardRef } from 'react';

import {
  DateValue,
  SingleDatePicker,
  SingleDatePickerProps,
  parseAbsolute,
} from '@any-ui-react/dates';

import { LocaleUtils, TimezoneUtils } from '~anyx/shared/utils';

export interface DayPickerProps extends Omit<SingleDatePickerProps, 'value' | 'onChange'> {
  value?: string | null;
  onChange?: (value: string | undefined | null) => void;
  timezone?: string;
  locale?: string;
}

export const DayPicker = forwardRef<HTMLDivElement, DayPickerProps>(
  (
    {
      value,
      onChange,
      timezone = TimezoneUtils.getCurrentTimezone(),
      locale = LocaleUtils.getCurrentLocale(),
      ...props
    },
    ref
  ) => {
    return (
      <SingleDatePicker
        {...props}
        defaultTimezone={timezone}
        visibleDuration={{ months: 1 }}
        value={value ? parseAbsolute(value, timezone) : null}
        onChange={(date: DateValue) =>
          onChange?.(date ? date.toDate(timezone).toISOString() : null)
        }
        locale={locale}
        ref={ref}
      />
    );
  }
);
