import { useTranslation } from 'react-i18next';

import { TooltipAlert } from '../tooltip-alert';

export const NameWithFallback = ({
  name,
  entity,
  className,
}: {
  name: string;
  entity: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <TooltipAlert
      title={name || t('shared.action.unnamed.title', { ns: 'shared', entity })}
      titleClassName={className}
      tooltipContent={t('shared.action.unnamed.tooltip', { ns: 'shared', entity })}
      isTooltipVisible={!name}
    />
  );
};
