import { ICountryProvinceResult, ILocationServiceUtils } from '../types';

class GoogleLocationServiceUtils implements ILocationServiceUtils {
  static ENDPOINT = '';

  async fetchPredictions(
    country: string,
    input: string,
    language: string
  ): Promise<google.maps.places.AutocompletePrediction[] | undefined> {
    try {
      if (!input.length) {
        return [];
      }
      const params = new URLSearchParams({
        input,
        components: `country:${country}`,
        language,
        types: 'postal_code',
      });
      const response = await fetch(`${GoogleLocationServiceUtils.ENDPOINT}/autocomplete?${params}`);
      const { status, predictions } = await response.json();
      if (status !== 'OK') {
        throw new Error('Cannot find place autocomplete result');
      }
      return predictions as google.maps.places.AutocompletePrediction[];
    } catch (error) {
      return undefined;
    }
  }

  async handleAutoComplete(
    placeId: string,
    language: string
  ): Promise<google.maps.places.PlaceResult | undefined> {
    try {
      if (!placeId) {
        throw new Error('Missing placeId');
      }
      const params = new URLSearchParams({
        place_id: placeId,
        language,
        fields: 'name,formatted_address,address_component',
      });
      const response = await fetch(
        `${GoogleLocationServiceUtils.ENDPOINT}/place_details?${params}`
      );
      const { status, result } = await response.json();

      if (status !== 'OK') {
        throw new Error('Cannot find placeId result');
      }

      return result;
    } catch (error) {
      return undefined;
    }
  }

  async getProvinceOptions(countryCode: string): Promise<ICountryProvinceResult[]> {
    try {
      const response = await fetch(
        `${GoogleLocationServiceUtils.ENDPOINT}/country/${countryCode.toLowerCase()}`
      );
      const provinces = await response.json();

      if (!provinces) {
        throw new Error('Country not found or empty provinces');
      }

      return provinces;
    } catch (error) {
      return [];
    }
  }
}

export { GoogleLocationServiceUtils };
