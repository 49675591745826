import { RouteObject } from 'react-router-dom';

import { AccountModuleRoutes } from './modules/account';
import { OauthModuleRoutes } from './modules/integration';
import { BrandModuleRoutes } from './modules/store';
import { UserModuleRoutes } from './modules/user';

export const routes: RouteObject[] = [
  ...AccountModuleRoutes,
  ...BrandModuleRoutes,
  ...OauthModuleRoutes,
  ...UserModuleRoutes,
];
