import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { InboundRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const InboundListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.InboundListPage }))
);
const InboundAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.InboundAddPage }))
);
const InboundEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.InboundEditPage }))
);
const InboundTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.InboundTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'inbound',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_INVENTORY]]}
          fallback={<UnauthorizedMessage />}
        >
          <InboundTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <InboundListPage />,
      },
      {
        path: 'add',
        element: <InboundAddPage />,
      },
      {
        path: ':inboundId',
        element: <InboundEditPage />,
      },
      {
        index: true,
        element: (
          <Navigate to={InboundRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
