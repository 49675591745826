import { createRef, useCallback, useEffect, useState } from 'react';

import { ActionIcon, TextInput, TextInputProps } from '@any-ui-react/core';

import { ClearIcon, SearchIcon } from '~anyx/shared/icons';

export interface InputSearchProps extends Omit<TextInputProps, 'value'> {
  initialValue?: string | null;
  value?: string | null;
  searchOnType?: boolean;
  onClear?: () => void;
  onSearch?: (text: string) => void;
}

export const InputSearch = ({
  onClear,
  onSearch,
  initialValue,
  value,
  searchOnType = false,
  ...rest
}: InputSearchProps) => {
  const ref = createRef<HTMLInputElement>();
  const [searchValue, setSearchValue] = useState<string>(initialValue || '');

  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  const search = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const sValue = ref.current.value;
    setSearchValue(sValue);
    onSearch?.(sValue);
  }, [onSearch, ref]);

  useEffect(() => {
    if (ref?.current) {
      const input = ref.current;
      input.addEventListener('search', search, false);
      return () => {
        input.removeEventListener('search', search, false);
      };
    }

    return () => null;
  }, [search, ref]);

  const clear = () => {
    setSearchValue('');
    onClear?.();
  };

  return (
    <TextInput
      {...rest}
      type="search"
      onChange={(e) => {
        setSearchValue(e.target.value);
        if (searchOnType) {
          onSearch?.(e.target.value);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          search();
        }
      }}
      leftSection={<SearchIcon className="w-4" />}
      rightSection={
        searchValue && (
          <ActionIcon
            className="border-radius-none border-gray-2 border-b-0 border-r-0 border-t-0"
            onClick={clear}
            variant="transparent"
          >
            <ClearIcon className="fill-gray-5 h-4 w-4 cursor-pointer" />
          </ActionIcon>
        )
      }
      value={searchValue}
      ref={ref}
    />
  );
};
