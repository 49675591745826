import { GraphQLErrorExtensions, GraphQLFormattedError } from 'graphql';

export class GqlErrors {
  /**
   * Function to extract GraphQL errors of a specific type.
   * @param {GraphQLFormattedError} errors - Array of GraphQL errors.
   * @param {string} key - The key of the error to extract.
   * @returns {Array} - Array of errors of the specified type.
   */
  static extractGraphqlError(errors: readonly GraphQLFormattedError[] = [], key: string) {
    return (
      errors.find(({ message }) => {
        return message === key;
      }) || null
    );
  }

  /**
   * Function to ignore GraphQL errors of a specific type.
   * @param {Array} errors - Array of GraphQL errors.
   * @param {string[]} keys - The keys of errors to ignore.
   * @returns {Array} - Array of errors excluding the specified type.
   */
  static ignoreGraphqlErrors(errors: readonly GraphQLFormattedError[] = [], keys: string[]) {
    return errors.filter(({ message }) => {
      return !keys.includes(message);
    });
  }

  /**
   * Sort all extension error message by error type
   * @param {Array} errors - Array of GraphQL errors.
   * @returns {Record<string, any>} - Object representing all extension error inside a type
   */
  static sortExtensionErrors(errors: readonly GraphQLFormattedError[] = []) {
    let sortedErrors = {};
    errors.forEach(({ message, extensions }) => {
      const extensionErrors: GraphQLErrorExtensions[] = Array.isArray(extensions?.['errors'])
        ? extensions['errors']
        : [];
      sortedErrors = { [message]: extensionErrors, ...sortedErrors };
    });
    return sortedErrors;
  }
}
