import { CountriesEnum } from '../enums';

export enum AddressPart {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  COMPANY = 'company',
  COUNTRY = 'country',
  POSTAL_CODE = 'zipcode',
  REGION = 'province',
  CITY = 'cityName',
  ADDRESS_FIRST = 'address1',
  ADDRESS_SECOND = 'address2',
  PHONE = 'phone',
  BREAK = '_',
}

export interface Address {
  [AddressPart.ADDRESS_FIRST]?: string | null;
  [AddressPart.ADDRESS_SECOND]?: string | null;
  [AddressPart.CITY]?: string | null;
  [AddressPart.COMPANY]?: string | null;
  [AddressPart.COUNTRY]?: CountriesEnum | null;
  [AddressPart.FIRSTNAME]?: string;
  [AddressPart.LASTNAME]?: string;
  [AddressPart.PHONE]?: {
    number?: string | null;
    countryCode?: CountriesEnum | null;
    isd?: string | null;
  };
  [AddressPart.REGION]?: string | null;
  [AddressPart.POSTAL_CODE]?: string | null;
}

export enum Notation {
  COMPACT = 'compact',
  FULL = 'full',
}
