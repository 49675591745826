import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { FacebookFaWhiteIcon } from '~anyx/shared/icons';

interface FacebookLoginButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  withFbIcon?: boolean;
}

export const FacebookLoginButton = ({
  children,
  className,
  disabled,
  withFbIcon = true,
  ...props
}: FacebookLoginButtonProps) => {
  const { t } = useTranslation('crmIntegration');

  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        className,
        'text-2xs flex h-8 min-w-max items-center gap-x-2 rounded px-4 font-semibold text-white',
        {
          'bg-facebook-blue': !disabled,
          'bg-gray-2': disabled,
        }
      )}
    >
      {withFbIcon && <FacebookFaWhiteIcon className="h-6 w-6 shrink-0" />}
      <div className="mx-auto">
        {children
          ? children
          : t('crmIntegration.shared.facebookLogin', { defaultValue: 'Login with Facebook' })}
      </div>
    </button>
  );
};
