import { useMemo } from 'react';

import { getDomainProduct } from '../utils';

export const useDomainProduct = () => {
  const product = useMemo(() => {
    return getDomainProduct();
  }, []);

  return { product };
};
