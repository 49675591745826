import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { CrossBorderCourierProvider } from '~anyx/shared/graphql';

const UpsEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.UpsEditPage }))
);

const UpsAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.UpsAddPage }))
);

const UpsTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.UpsTemplate }))
);

export const upsRoutes: RouteObject[] = [
  {
    path: CrossBorderCourierProvider.UPS,
    element: (
      <Auth0Guard>
        <UpsTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        index: true,
        element: <UpsAddPage />,
      },
      {
        path: ':courierId',
        element: <UpsEditPage />,
      },
    ],
  },
];
