import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LazadaSalesDetailedReportQueryVariables = Types.Exact<{
  input: Types.LazadaSalesDetailedReportInput;
}>;


export type LazadaSalesDetailedReportQuery = { readonly lazadaSalesDetailedReport: { readonly __typename?: 'LazadaSalesDetailedReportPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'LazadaSalesDetailedReportPayload', readonly conversionRate: string, readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly orders: string, readonly sales: string, readonly unitsOrdered: string, readonly visitors: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'LazadaSalesDetailedReportTotalPayload', readonly conversionRate: string, readonly currency: Types.ReportSupportedCurrency, readonly orders: string, readonly sales: string, readonly unitsOrdered: string, readonly visitors: string } } };


export const LazadaSalesDetailedReportDocument = gql`
    query LazadaSalesDetailedReport($input: LazadaSalesDetailedReportInput!) {
  lazadaSalesDetailedReport(input: $input) {
    items {
      conversionRate
      currency
      date
      orders
      sales
      unitsOrdered
      visitors
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      conversionRate
      currency
      orders
      sales
      unitsOrdered
      visitors
    }
  }
}
    `;

/**
 * __useLazadaSalesDetailedReportQuery__
 *
 * To run a query within a React component, call `useLazadaSalesDetailedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useLazadaSalesDetailedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLazadaSalesDetailedReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLazadaSalesDetailedReportQuery(baseOptions: Apollo.QueryHookOptions<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables> & ({ variables: LazadaSalesDetailedReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>(LazadaSalesDetailedReportDocument, options);
      }
export function useLazadaSalesDetailedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>(LazadaSalesDetailedReportDocument, options);
        }
export function useLazadaSalesDetailedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>(LazadaSalesDetailedReportDocument, options);
        }
export type LazadaSalesDetailedReportQueryHookResult = ReturnType<typeof useLazadaSalesDetailedReportQuery>;
export type LazadaSalesDetailedReportLazyQueryHookResult = ReturnType<typeof useLazadaSalesDetailedReportLazyQuery>;
export type LazadaSalesDetailedReportSuspenseQueryHookResult = ReturnType<typeof useLazadaSalesDetailedReportSuspenseQuery>;
export type LazadaSalesDetailedReportQueryResult = Apollo.QueryResult<LazadaSalesDetailedReportQuery, LazadaSalesDetailedReportQueryVariables>;
export function refetchLazadaSalesDetailedReportQuery(variables: LazadaSalesDetailedReportQueryVariables) {
      return { query: LazadaSalesDetailedReportDocument, variables: variables }
    }