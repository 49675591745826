import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput, TextInputProps } from '@any-ui-react/core';

import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';

export type AddressFirstFieldProps = Partial<TextInputProps>;

export const AddressFirstField = ({ ...rest }: AddressFirstFieldProps) => {
  const { t } = useTranslation();
  const { mappings } = useAddressFormContext();
  const { register, getFieldState } = useFormContext();

  const fieldName = mappings[AddressPart.ADDRESS_FIRST];
  if (!fieldName) {
    return;
  }

  return (
    <TextInput
      {...register(fieldName)}
      label={t('shared.field.address1.name', { ns: 'shared' })}
      placeholder={t('shared.field.address1.placeholder', { ns: 'shared' })}
      error={getFieldState(fieldName)?.error?.message?.toString()}
      required
      {...rest}
    />
  );
};
