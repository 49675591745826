import { HttpResponse, delay, graphql } from 'msw';

import { SaleChannelType } from '~anyx/shared/graphql';

export const MockSalesChannels: SaleChannelType[] = [
  'AmazonSellerCentral',
  'Lazada',
  'Magaseek',
  'Manual',
  'Momo',
  'Qoo10',
  'Rakuten',
  'Shopee',
  'Shopify',
  'TikTok',
  'Tokopedia',
  'Yahoo',
  'Zozotown',
];

export const MockSalesChannelsQuery = {
  default: () => {
    return graphql.query('SalesChannels', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: MockSalesChannels });
    });
  },
};
