import { z } from 'zod';

import { useIsAdmin, useIsStaff, useIsUnderDDIAccount } from '~anyx/common/permission';
import { useSelfCtx } from '~anyx/common/self';
import { useWorkspaceCtx } from '~anyx/common/workspace';

import { NoWorkspaceLinkProps } from '../../types';
import {
  ANALYTICS_PERMISSION_PROPS,
  INVENTORY_PERMISSION_PROPS,
  PRODUCT_PERMISSION_PROPS,
  SHIPPING_PERMISSION_PROPS,
  USER_PERMISSION_PROPS,
  USE_ORDER_PERMISSION_PROPS,
} from '../permissions';
import { PermissionContainer } from '../types';
import { useBuildPermissions } from '../utils';

const NO_WORKSPACE_PERMISSION: PermissionContainer = {
  canSeeUserList: USER_PERMISSION_PROPS,
  canUseOrder: USE_ORDER_PERMISSION_PROPS,
  canUseAnalytics: ANALYTICS_PERMISSION_PROPS,
  canUseShipping: SHIPPING_PERMISSION_PROPS,
  canUseProduct: PRODUCT_PERMISSION_PROPS,
  canUseInventory: INVENTORY_PERMISSION_PROPS,
};

export const reportDdiFlagSchema = z.object({
  allowedAccountIds: z.array(z.string()),
});

export const usePermissionsNoWorkspace = () => {
  const { workspace } = useWorkspaceCtx();
  const { isStaff } = useIsStaff();
  const { isAdmin } = useIsAdmin();
  const { self } = useSelfCtx();
  const { getValidatedPermissions } = useBuildPermissions();
  const { isUnderDDIAccount } = useIsUnderDDIAccount(self.accountIds);

  const getPermissionsNoWorkplace = (): NoWorkspaceLinkProps => {
    const { canUseShipping, ...validatedPermissions } =
      getValidatedPermissions(NO_WORKSPACE_PERMISSION);

    return {
      workspaceAccountId: workspace?.accountId,
      showPathToList: isAdmin || isStaff,
      canUseShipping: canUseShipping,
      canSeeMarketplaceEvents: isAdmin || isStaff,
      canSeeInventoryTracker: isAdmin || (isStaff && isUnderDDIAccount),
      isAdmin,
      ...validatedPermissions,
    };
  };

  return { getPermissionsNoWorkplace };
};
