import { SaleChannelType } from '../index';

export class GqlCostIndexUtils {
  static RateInformationLinks = {
    [SaleChannelType.Yahoo]: {
      pointResourceBurdenRadioUrl: 'https://business-ec.yahoo.co.jp/shopping/cost/',
      transactionFeeUrl: 'https://business-ec.yahoo.co.jp/shopping/cost/ ',
    },
    [SaleChannelType.TikTok]: {
      commissionFeeRateUrl:
        'https://seller-ph.tiktok.com/university/article/agreement?identity=1&is_new_connect=0&is_new_user=0&knowledge_id=10001020',
      flatFeeRateUrl:
        'https://seller-ph.tiktok.com/university/article/agreement?identity=1&is_new_connect=0&is_new_user=0&knowledge_id=10001020',
    },
    [SaleChannelType.Shopee]: {
      commissionFeeRateUrl: 'https://seller.shopee.com.my/edu/article/6799',
      transactionFeeRateUrl: 'https://seller.shopee.com.my/edu/article/16038 ',
    },
    [SaleChannelType.Lazada]: {
      commissionFeeRateUrl:
        'https://sellercenter.lazada.sg/seller/helpcenter/marketplace-commission-rate-effective-18-july-2022-14498.html',
      transactionFeeRateUrl:
        'https://sellercenter.lazada.sg/seller/helpcenter/marketplace-commission-rate-effective-18-july-2022-14498.html',
    },
  };
}
