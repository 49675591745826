import React, { createContext, useEffect, useState } from 'react';

import { LoadWrapper } from '~anyx/shared/ui';
import { AsyncOperationState } from '~anyx/shared/utils';

import { app, initFirebase } from './factory';
import { FirebaseConfig, FirebaseRemoteConfig } from './types';

type UseFirebaseManagerResult = {
  ready: boolean;
} | null;

interface FirebaseProviderProps {
  config: FirebaseConfig;
  remoteConfig?: FirebaseRemoteConfig;
}

export const FirebaseContext = createContext<UseFirebaseManagerResult>(null);

function useFirebaseManager({ ready }: { ready: boolean }): UseFirebaseManagerResult {
  return {
    ready,
  };
}

export const FirebaseProvider = ({
  config,
  remoteConfig,
  children,
}: React.PropsWithChildren<FirebaseProviderProps>) => {
  const [ready, setReady] = useState(false);

  const value = useFirebaseManager({ ready });

  const [status, setStatus] = useState<AsyncOperationState>(AsyncOperationState.IDLE);

  useEffect(() => {
    if (app) return;
    setStatus(AsyncOperationState.LOADING);

    (async () => {
      const success = await initFirebase(config, {
        remoteConfig,
      });
      setReady(success);
      setStatus(success ? AsyncOperationState.SUCCESS : AsyncOperationState.ERROR);
    })();
  }, [config, remoteConfig]);

  if ([AsyncOperationState.LOADING, AsyncOperationState.IDLE].includes(status)) {
    return <LoadWrapper loading={true} className="flex h-screen" />;
  }

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};
