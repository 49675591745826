export class URLUtils {
  static isEncoded(url: string) {
    try {
      const decodedURL = decodeURIComponent(url);
      return decodedURL !== url;
    } catch (error) {
      return false;
    }
  }

  static encode(url: string) {
    const isEncoded = URLUtils.isEncoded(url);

    if (!isEncoded) {
      return encodeURI(url);
    }

    const decodedURL = decodeURIComponent(url);
    return encodeURI(decodedURL);
  }

  static decode(url: string) {
    const isEncoded = URLUtils.isEncoded(url);

    if (!isEncoded) return url;

    return decodeURIComponent(url);
  }
}
