import { RouteObject } from 'react-router-dom';

import {
  EventCalendarModuleRoutes,
  MarketplaceEventModuleRoutes,
  ProductModuleRoutes,
  SalesChannelModuleRoutes,
  AdvertisingModuleRoutes,
  InventoryStockValueModuleRoutes,
  InventoryTrackerModuleRoutes,
  SalesChannelAnalyticModuleRoutes,
  AiModuleRoutes,
} from './modules';

export const routes: RouteObject[] = [
  ...SalesChannelModuleRoutes,
  ...SalesChannelAnalyticModuleRoutes,
  ...ProductModuleRoutes,
  ...MarketplaceEventModuleRoutes,
  ...EventCalendarModuleRoutes,
  ...AdvertisingModuleRoutes,
  ...InventoryStockValueModuleRoutes,
  ...InventoryTrackerModuleRoutes,
  ...AiModuleRoutes,
];
