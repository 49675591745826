import React from 'react';

import { Tooltip } from '@any-ui-react/core';

import { AlertIcon } from '~anyx/shared/icons';

export const TooltipAlert = ({
  title,
  tooltipContent,
  isTooltipVisible = true,
  titleClassName,
}: {
  title: React.ReactNode;
  titleClassName?: string;
  tooltipContent: string;
  isTooltipVisible?: boolean;
}) => {
  return (
    <div className="flex items-center">
      {isTooltipVisible && (
        <Tooltip
          label={<div className="max-w-xs">{tooltipContent}</div>}
          position="bottom"
          multiline
          withinPortal
        >
          <span>
            <AlertIcon className="fill-gray-5 mr-1 h-4 w-4 cursor-pointer" />
          </span>
        </Tooltip>
      )}
      <p className={titleClassName}>{title}</p>
    </div>
  );
};
