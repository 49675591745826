import { z } from 'zod';

import { MarketplaceSaleChannelType, ProductVariantForSetFilterInput } from '~anyx/shared/graphql';
import { WithPagination } from '~anyx/shared/utils';

export type Constraints = WithPagination<ProductVariantForSetFilterInput>;

export const VariantSelectorFiltersParser = z.object({
  keyword: z.string().nullish().catch(null),
  storeId: z.string().catch(''),
  tags: z.array(z.string()).nullish().catch(null),
  saleChannelType: z.nativeEnum(MarketplaceSaleChannelType).nullish().catch(null),
  page: z.number().catch(1),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type VariantSelectorFiltersType = z.infer<typeof VariantSelectorFiltersParser>;
