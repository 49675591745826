import { useTranslation } from 'react-i18next';

import { Checkbox } from '@any-ui-react/core';

import { useTableSelectionCtx } from './TableSelectionContext';

export interface TableSelectionBarProps {
  aside: React.ReactNode;
  maxSelection: number;
  total: number;
  currentPageSelection: number;
  onToggleSelection: () => void;
  onToggleAllPages: () => void;
  allPagesSelectable?: boolean;
}

export function TableSelectionBar<T extends Record<string, unknown>>({
  aside,
  maxSelection,
  total,
  onToggleSelection,
  onToggleAllPages,
  currentPageSelection,
  allPagesSelectable = true,
}: TableSelectionBarProps) {
  const { t } = useTranslation();
  const { selection } = useTableSelectionCtx<T>();
  const allChecked = currentPageSelection === maxSelection;
  const indeterminateState = currentPageSelection !== maxSelection;

  return (
    <td className="bg-gray-9 flex h-full flex-row items-center">
      <div className="sticky left-0 flex flex-row items-center gap-x-4 whitespace-nowrap p-4">
        <Checkbox
          indeterminate={indeterminateState}
          checked={allChecked || indeterminateState}
          label={
            <span className="ml-1 w-full font-semibold text-white">
              {t('shared.table.selected', {
                ns: 'shared',
                count: selection?.allPages ? total : selection?.items.length,
              })}
            </span>
          }
          onChange={onToggleSelection}
        />
        {allPagesSelectable && (
          <button onClick={onToggleAllPages} className="text-white underline">
            {selection?.allPages
              ? t('shared.button.deselectAllPages', { ns: 'shared' })
              : t('shared.button.selectAllPages', { ns: 'shared' })}
          </button>
        )}
        {aside}
      </div>
    </td>
  );
}
