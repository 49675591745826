import { ReportMarketplaceEventRoutePath } from '~anyx/common/routing';
import { ScheduleIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

export type MarketplaceEventLinksProps = {
  canSeeMarketplaceEvents: boolean;
};

const getMarketplaceEventLinks = ({
  canSeeMarketplaceEvents,
}: MarketplaceEventLinksProps): RouteLinks[] => {
  if (!canSeeMarketplaceEvents) return [];

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'shared.entity.marketplaceEvent',
          options: { count: 2, ns: 'shared' },
        },
        icon: (props) => <ScheduleIcon {...props} />,
        path: ReportMarketplaceEventRoutePath({ workspaceStoreId: null }).list().path,
        activePath: ReportMarketplaceEventRoutePath({ workspaceStoreId: null }).root().pattern,
      },
    },
  ];
};

type MarketplaceEventLinks = LinkAdapter<MarketplaceEventLinksProps>;

export const marketplaceEventLinks: MarketplaceEventLinks = {
  noWorkspace: getMarketplaceEventLinks,
  withWorkspace: () => [],
};
