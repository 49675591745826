import { HomeFilterInputFilterType } from '../components';
import { useHomeReportSummaryQuery } from '../graphql';

interface UseHomeReportSummaryProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportSummary = ({ filters, skip }: UseHomeReportSummaryProps) => {
  const { dimensions: _dimension, chartTab: _chartTab, metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useHomeReportSummaryQuery({
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportSummary: data?.saleChannelReportSummary,
    loading,
    error,
    refetch,
  };
};
