import { Trans } from 'react-i18next';

import { ATTENTION_COLOR, CrossCircleFill } from '@any-ui-react/core';
import { notifications } from '@any-ui-react/notifications';

export const showSubServiceErrorNotification = (serviceName: string) =>
  notifications.show({
    id: serviceName,
    color: ATTENTION_COLOR.error,
    icon: <CrossCircleFill />,
    title: <Trans ns="gql" i18nKey="gql.notification.error.subServiceError.title" />,
    message: (
      <Trans
        ns="gql"
        i18nKey="gql.notification.error.subServiceError.description"
        values={{ subService: serviceName }}
      />
    ),
  });
