import { ReactNode } from 'react';

export function InstructionListItem({
  index,
  description,
}: {
  index?: number;
  description: ReactNode;
}) {
  return (
    <div className="mb-2 flex">
      {index && (
        <p className="leading-0 text-3xs bg-gray-7 mt-1 flex h-4 w-4 shrink-0 items-center justify-center rounded-full font-semibold text-white">
          {index}
        </p>
      )}
      <p className="ml-2">{description}</p>
    </div>
  );
}
