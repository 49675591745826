import { useMemo } from 'react';

import { useMediaQuery } from '@mantine/hooks';

import preset from '~anyx/root/tailwind';

interface UseScreenBreakpointOptions {
  getInitialValueInEffect: boolean;
}

export enum MediaQueryType {
  MAX = 'max-width',
  MIN = 'min-width',
}

export const useScreenBreakpoint = (
  breakpoint: keyof typeof preset.theme.extend.screens,
  mediaQuery: MediaQueryType = MediaQueryType.MIN,
  initialValue?: boolean,
  options?: UseScreenBreakpointOptions
) => {
  const query = `(${mediaQuery}:${preset.theme.extend.screens[breakpoint]})`;

  /** pass this to useMediaQuery initialValue */
  const initValue = useMemo(() => {
    if (typeof initialValue === 'boolean') return initialValue;

    if ('matchMedia' in window) {
      return window.matchMedia(query).matches;
    }
    return undefined;
  }, [initialValue, query]);

  return useMediaQuery(query, initValue, options);
};
