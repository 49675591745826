import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const ErrorUnauthorizedPage = React.lazy(() =>
  import('./pages/unauthorized').then((module) => ({ default: module.ErrorUnauthorizedPage }))
);
const ErrorNotFoundPage = React.lazy(() =>
  import('./pages/not-found').then((module) => ({ default: module.ErrorNotFoundPage }))
);
const ErrorUnavailablePage = React.lazy(() =>
  import('./pages/unavailable').then((module) => ({ default: module.ErrorUnavailablePage }))
);
const ErrorTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ErrorTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'error',
    element: <ErrorTemplate />,
    children: [
      {
        path: 'notfound',
        element: <ErrorNotFoundPage />,
      },
      {
        path: 'unavailable',
        element: <ErrorUnavailablePage />,
      },
      {
        path: 'unauthorized',
        element: <ErrorUnauthorizedPage />,
      },
      {
        index: true,
        element: <Navigate to="/error/notfound" replace={true} />,
      },
    ],
  },
];
