import { useTranslation } from 'react-i18next';

import { RouteCategory } from '~anyx/shared/utils';

import { navItemStyles } from './styles';

export const ExternalLink = ({ category }: { activePath?: string; category: RouteCategory }) => {
  const { t } = useTranslation();
  const classes = navItemStyles();

  return (
    <a
      className={classes.root()}
      href={category.href}
      key={category.name.key}
      target="_blank"
      rel="noreferrer"
    >
      {category.icon?.({
        className: classes.icon(),
      })}
      <span className={classes.title()}>{t(category.name.key, category.name.options || {})}</span>
      {category.aside}
    </a>
  );
};
