import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { PageLayout, Title } from '../../models';

export interface LayoutStore {
  title: Title;
  withNavigation: boolean;
  withFooter: boolean;
  clamped: boolean;
  fullPage: boolean;
  setTitle: (title: Title) => void;
  setShowSidenav: (visible: boolean) => void;
  setShowFooter: (visible: boolean) => void;
  setClampContent: (clamped: boolean) => void;
  setFullPage: (fullPage: boolean) => void;
  header: () => React.ReactNode;
  setHeader: (header: () => React.ReactNode) => void;
}

export const DEFAULT_LAYOUT: PageLayout = {
  title: {
    text: '',
  },
  withNavigation: true,
  withFooter: true,
  clamped: false,
  fullPage: false,
  header: () => null,
};

export const useLayoutStore = create<LayoutStore>()(
  devtools(
    immer((set) => ({
      ...DEFAULT_LAYOUT,
      withNavigation: false,
      setTitle: (title) => set({ title }, false, '[layout] setTitle'),
      setShowSidenav: (withNavigation) => set({ withNavigation }, false, '[layout] setShowSidenav'),
      setShowFooter: (withFooter) => set({ withFooter }, false, '[layout] setShowFooter'),
      setClampContent: (clamped) => set({ clamped }, false, '[layout] setClampContent'),
      setFullPage: (fullPage) => set({ fullPage }, false, '[layout] setFullPage'),
      setHeader: (header) => set({ header }, false, '[layout] setHeader'),
    })),
    {
      name: 'Layout',
    }
  )
);
