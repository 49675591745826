import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { SkeletonLoader } from '~anyx/shared/ui';

interface RatingBarProps {
  star: number;
  percentage: string;
  loading?: boolean;
  selectedRating?: string;
  onSelectRating?: (star: string) => void;
}

export const RatingBar = ({
  star,
  percentage,
  loading,
  selectedRating,
  onSelectRating,
}: RatingBarProps) => {
  const { t } = useTranslation('report');

  return (
    <div className="flex items-center gap-2">
      <SkeletonLoader loading={loading} className="h-6 max-w-md">
        {typeof onSelectRating === 'function' ? (
          <button
            className={classNames(' w-14 hover:underline', {
              'text-green-6': selectedRating === String(star),
              'text-link': !selectedRating || selectedRating !== String(star),
            })}
            onClick={() => onSelectRating(String(star))}
          >
            {t('report.page.product.star', { number: star })}
          </button>
        ) : (
          <span className="w-14">{t('report.page.product.star', { number: star })}</span>
        )}

        <div className="relative h-6 w-full max-w-80 flex-grow overflow-hidden">
          <div className="border-gray-2 absolute z-0 h-full w-full rounded border bg-white" />
          <div className="bg-warning absolute h-full rounded" style={{ width: `${percentage}%` }} />
        </div>
        {typeof onSelectRating === 'function' ? (
          <button
            className={classNames('w-9 text-right hover:underline', {
              'text-green-6': selectedRating === String(star),
              'text-link': !selectedRating || selectedRating !== String(star),
            })}
            onClick={() => onSelectRating(String(star))}
          >
            {percentage}%
          </button>
        ) : (
          <p className="w-9 text-right">{percentage}%</p>
        )}
      </SkeletonLoader>
    </div>
  );
};
