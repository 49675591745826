import { RouteObject } from 'react-router-dom';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

import { routes as BroadcastRoutes } from './broadcast/routing';
import { routes as RichMenuRoutes } from './richmenu/routing';

const PATH_KEY = CrmRoutePathKey.enum.line;

export const routes: RouteObject[] = [
  {
    path: 'line',
    children: [...ChatRoutes(PATH_KEY), ...BroadcastRoutes, ...RichMenuRoutes],
  },
];
