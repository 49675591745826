import React from 'react';

import { DatasourceType } from '~anyx/shared/graphql';

const YahooSearchEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.YahooSearchEditPage,
  }))
);

const YahooSearchAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.YahooSearchAddPage,
  }))
);

const YahooSearchCampaignAdSetListPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-list').then((module) => ({
    default: module.YahooSearchCampaignAdSetListPage,
  }))
);

const YahooSearchCampaignAdSetEditPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-edit').then((module) => ({
    default: module.YahooSearchCampaignAdSetEditPage,
  }))
);

const YahooSearchTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.YahooSearchTemplate }))
);

export const yahooSearchRouting = [
  {
    path: DatasourceType.YahooSearchAds,
    element: <YahooSearchTemplate />,
    children: [
      {
        index: true,
        element: <YahooSearchAddPage />,
      },
      {
        path: ':datasourceId',
        children: [
          {
            index: true,
            element: <YahooSearchEditPage />,
          },
          {
            path: 'campaign/:campaignId/adSet/:adSetMappingCampaignId',
            children: [
              {
                index: true,
                element: <YahooSearchCampaignAdSetListPage />,
              },
              {
                path: 'set',
                element: <YahooSearchCampaignAdSetEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
