import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/**
 *
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const HomeRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/home' : '/home', {
    workspaceStoreId,
  });

  return {
    root: () => root,
  };
};
