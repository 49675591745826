import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { z } from 'zod';

import { RouterUtils } from '../utils';

/** __useSanitizedSearchParams__
 *
 * Use this hook to get sanitized URL search params using given zod parser.
 *  */
export const useSanitizedSearchParams = <T extends Record<string, unknown>>(
  sanitizer: z.ZodType<T, z.ZodTypeDef, unknown>
): [T, (params: Record<string, unknown>) => void] => {
  const [rawSearchParams, setSearchParams] = useSearchParams();

  const sanitizedParams = useMemo(() => {
    return sanitizer.parse(RouterUtils.decodeSearchParams(rawSearchParams));
  }, [rawSearchParams, sanitizer]);

  const setSearch = (search: Record<string, unknown>) => {
    sanitizer.parse(search);
    setSearchParams(RouterUtils.encodeSearchParams(search));
  };

  return [sanitizedParams, setSearch];
};
