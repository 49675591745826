import { forwardRef } from 'react';

interface CardFooterProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
}

export const CardFooter = forwardRef<HTMLDivElement, CardFooterProps>(
  ({ children, className, justify = 'flex-end' }: CardFooterProps, ref) => (
    <div className={['border-gray-1 -mx-4 -mb-4 border-t lg:-mx-6 lg:-mb-6', className].join(' ')}>
      <div ref={ref} className="flex p-4 lg:px-6" style={{ justifyContent: justify }}>
        {children}
      </div>
    </div>
  )
);
