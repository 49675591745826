import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RakutenSalesReportChartQueryVariables = Types.Exact<{
  input: Types.RakutenSalesReportChartInput;
}>;


export type RakutenSalesReportChartQuery = { readonly rakutenSalesReportChart: { readonly __typename?: 'RakutenSalesReportChartPayload', readonly conversionRate: ReadonlyArray<Record<string, any>>, readonly currency: Types.ReportSupportedCurrency, readonly orderCount: ReadonlyArray<Record<string, any>>, readonly sessions: ReadonlyArray<Record<string, any>>, readonly totalSales: ReadonlyArray<Record<string, any>> } };


export const RakutenSalesReportChartDocument = gql`
    query RakutenSalesReportChart($input: RakutenSalesReportChartInput!) {
  rakutenSalesReportChart(input: $input) {
    conversionRate
    currency
    orderCount
    sessions
    totalSales
  }
}
    `;

/**
 * __useRakutenSalesReportChartQuery__
 *
 * To run a query within a React component, call `useRakutenSalesReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useRakutenSalesReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRakutenSalesReportChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRakutenSalesReportChartQuery(baseOptions: Apollo.QueryHookOptions<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables> & ({ variables: RakutenSalesReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>(RakutenSalesReportChartDocument, options);
      }
export function useRakutenSalesReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>(RakutenSalesReportChartDocument, options);
        }
export function useRakutenSalesReportChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>(RakutenSalesReportChartDocument, options);
        }
export type RakutenSalesReportChartQueryHookResult = ReturnType<typeof useRakutenSalesReportChartQuery>;
export type RakutenSalesReportChartLazyQueryHookResult = ReturnType<typeof useRakutenSalesReportChartLazyQuery>;
export type RakutenSalesReportChartSuspenseQueryHookResult = ReturnType<typeof useRakutenSalesReportChartSuspenseQuery>;
export type RakutenSalesReportChartQueryResult = Apollo.QueryResult<RakutenSalesReportChartQuery, RakutenSalesReportChartQueryVariables>;
export function refetchRakutenSalesReportChartQuery(variables: RakutenSalesReportChartQueryVariables) {
      return { query: RakutenSalesReportChartDocument, variables: variables }
    }