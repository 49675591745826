import React, { ReactNode } from 'react';

import { AlertIcon } from '~anyx/shared/icons';

import { Spinner } from '../../atoms';

interface LoadWrapperProps {
  errorNode?: React.ReactNode;
  loadingTemplate?: React.ReactNode;
  className?: string;
  loading?: boolean;
  error?: boolean;
  loaderId?: string;
  message?: ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}

export const LoadWrapper = ({
  error,
  loading,
  className,
  children,
  loaderId,
  message,
  errorNode = (
    <div className={className}>
      <div className="flex flex-1 items-center justify-center">
        <AlertIcon className="fill-error" />
      </div>
    </div>
  ),
  loadingTemplate = (
    <div className={className}>
      <div data-loader-id={loaderId} className="flex flex-1 items-center justify-center">
        <Spinner />
        {message}
      </div>
    </div>
  ),
}: LoadWrapperProps) => {
  const node = error ? errorNode : children;
  return <>{loading ? loadingTemplate : node}</>;
};
