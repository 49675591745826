import classNames from 'classnames';

import { ArrowDownIcon, ArrowUpIcon } from '~anyx/shared/icons';
import { NumberWithPercentage } from '~anyx/shared/ui';
import { EMPTY_DEFAULT } from '~anyx/shared/utils';

export const ReportPercentageArrow = ({
  value,
  wrapperClassName,
}: {
  value?: string | null;
  wrapperClassName?: string;
}) => {
  return (
    <div className={classNames(wrapperClassName)}>
      {value ? (
        <div
          className={classNames(
            'text-2xs flex w-fit items-center break-all rounded bg-opacity-20 px-1 py-0.5 font-semibold',
            {
              'text-success border-success bg-success': +value >= 0,
              'text-failure border-failure bg-failure': +value < 0,
            }
          )}
        >
          {+value >= 0 ? (
            <>
              <ArrowUpIcon className="mr-0.5 h-2.5 w-2.5 shrink-0 rotate-45 lg:h-3 lg:w-3" />
              <div className="inline-flex flex-wrap gap-1 whitespace-nowrap leading-none">
                <span>
                  +<NumberWithPercentage amount={value} />
                </span>
              </div>
            </>
          ) : (
            <>
              <ArrowDownIcon className="mr-0.5 h-2.5 w-2.5 shrink-0 -rotate-45 lg:h-3 lg:w-3" />
              <div className="inline-flex flex-wrap gap-1 whitespace-nowrap leading-none">
                <span>
                  <NumberWithPercentage amount={value} />
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="text-gray-7 text-2xs bg-gray-1 w-fit rounded px-1 py-0.5 leading-none">
          {EMPTY_DEFAULT} %
        </div>
      )}
    </div>
  );
};
