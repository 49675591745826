import { useMemo } from 'react';

import { DefaultRangeValue, today } from '@any-ui-react/dates';

import { DdiHomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import { ReportDimensions } from '~anyx/shared/graphql';
import { DateUtils, TimezoneUtils, useDateRangeListOptions, useFilters } from '~anyx/shared/utils';

import { DdiHomePageFiltersParser } from './ddiHomePage.filters';
import { DdiHomeFilterInputFilterType } from './ddiHomePage.filters';

export const useDdiHomeFilter = ({
  accountId,
  storeId,
}: {
  accountId?: string;
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();
  const rangeList = useDateRangeListOptions({
    only: [DefaultRangeValue.LAST_7_DAYS],
  });
  const {
    value: { start, end },
  } = rangeList[0] || {
    value: { start: today(timezone), end: today(timezone) },
  };
  const { range } = useReportSyncCalendar();

  const filtersDefault = useMemo(
    () =>
      DdiHomePageFiltersParser.parse({
        startDate: range.startDate ?? DateUtils.toISOStringStartOfDay(start),
        endDate: range.endDate ?? DateUtils.toISOStringEndOfDay(end),
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: DdiHomeMetricOption.GROSS_SALES,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, accountId, storeId]
  );

  const filters = useFilters<DdiHomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: DdiHomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.endDate))
      : '',
    startDate: filters.current?.startDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.startDate))
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  return {
    filters,
    filterInputs,
  };
};
