import { ReactElement, RefAttributes, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupBase,
  Select,
  SelectForwardRef,
  SelectInstance,
  SelectProps,
} from '@any-ui-react/select';
import classNames from 'classnames';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';
import { ReactNodeOption } from '~anyx/shared/utils';

import { useChannelsOptions } from '../../hooks';

import { useSaleChannelsSelect } from './operation';

type ChannelOption = ReactNodeOption<MarketplaceSaleChannelType>;

interface ChannelSelectProps<IsMulti extends boolean = boolean>
  extends SelectProps<ChannelOption, IsMulti, GroupBase<ChannelOption>> {
  accountIds?: string[];
  storeIds?: string[];
  disabledChannels?: MarketplaceSaleChannelType[];
  isMulti?: IsMulti;
}

const ChannelSelectGn = <ChannelOption, IsMulti extends boolean = false>(
  {
    storeIds = undefined,
    accountIds = undefined,
    isMulti = false,
    className,
    disabledChannels,
    ...rest
  }: ChannelSelectProps,
  ref: SelectForwardRef<ChannelOption, IsMulti, GroupBase<ChannelOption>> | undefined
) => {
  const { t } = useTranslation();
  const { channels } = useSaleChannelsSelect({
    storeIds,
    accountIds,
  });
  const { channelSelectProps, channelOptions } = useChannelsOptions(channels, disabledChannels);

  const selectProps = useMemo(() => {
    return isMulti ? channelSelectProps : { options: channelOptions };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, isMulti]);

  return (
    <Select
      isClearable
      isSearchable
      placeholder={t('shared.entity.channel', {
        ns: 'shared',
        count: 1,
      })}
      className={classNames('select-has-min-w-menu', className)}
      {...selectProps}
      {...rest}
      isMulti={isMulti}
      // TODO FIX THIS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
    />
  );
};

type MarketplaceChannelSelect<ChannelOption, IsMulti extends boolean = boolean> = (
  props: ChannelSelectProps &
    RefAttributes<SelectInstance<ChannelOption, IsMulti, GroupBase<ChannelOption>>>
) => ReactElement;

export const MarketplaceChannelSelect = forwardRef(ChannelSelectGn) as <
  ChannelOption,
  IsMulti extends boolean = false
>(
  p: ChannelSelectProps<IsMulti> & {
    ref?: SelectForwardRef<ChannelOption, IsMulti, GroupBase<ChannelOption>>;
  }
) => ReactElement;
