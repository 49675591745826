import React from 'react';

import { googleGa4Routing } from '~anyx/integrations/analytics';

const AnalyticsSelectPage = React.lazy(() =>
  import('./select').then((module) => ({ default: module.AnalyticsSelectPage }))
);

export const analyticsIntegrationRoutes = {
  path: 'analytics-v2',
  children: [
    {
      index: true,
      element: <AnalyticsSelectPage />,
    },
    ...googleGa4Routing,
  ],
};
