import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LazadaSalesChartQueryVariables = Types.Exact<{
  input: Types.LazadaSalesChartInput;
}>;


export type LazadaSalesChartQuery = { readonly lazadaSalesChart: { readonly __typename?: 'LazadaSalesChartPayload', readonly conversionRate: ReadonlyArray<Record<string, any>>, readonly currency: Types.ReportSupportedCurrency, readonly orders: ReadonlyArray<Record<string, any>>, readonly sales: ReadonlyArray<Record<string, any>>, readonly unitsOrdered: ReadonlyArray<Record<string, any>>, readonly visitors: ReadonlyArray<Record<string, any>> } };


export const LazadaSalesChartDocument = gql`
    query LazadaSalesChart($input: LazadaSalesChartInput!) {
  lazadaSalesChart(input: $input) {
    conversionRate
    currency
    orders
    sales
    unitsOrdered
    visitors
  }
}
    `;

/**
 * __useLazadaSalesChartQuery__
 *
 * To run a query within a React component, call `useLazadaSalesChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useLazadaSalesChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLazadaSalesChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLazadaSalesChartQuery(baseOptions: Apollo.QueryHookOptions<LazadaSalesChartQuery, LazadaSalesChartQueryVariables> & ({ variables: LazadaSalesChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>(LazadaSalesChartDocument, options);
      }
export function useLazadaSalesChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>(LazadaSalesChartDocument, options);
        }
export function useLazadaSalesChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>(LazadaSalesChartDocument, options);
        }
export type LazadaSalesChartQueryHookResult = ReturnType<typeof useLazadaSalesChartQuery>;
export type LazadaSalesChartLazyQueryHookResult = ReturnType<typeof useLazadaSalesChartLazyQuery>;
export type LazadaSalesChartSuspenseQueryHookResult = ReturnType<typeof useLazadaSalesChartSuspenseQuery>;
export type LazadaSalesChartQueryResult = Apollo.QueryResult<LazadaSalesChartQuery, LazadaSalesChartQueryVariables>;
export function refetchLazadaSalesChartQuery(variables: LazadaSalesChartQueryVariables) {
      return { query: LazadaSalesChartDocument, variables: variables }
    }