import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';

import { NumberUtils } from '~anyx/shared/utils';

import {
  DEFAULT_Y_AXIS_WIDTH,
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  getXAxisConfig,
} from './chart.config';
import styles from './chart.module.css';
import { style } from './chart.theme';
interface LineChartProps<T extends Record<string, unknown>> {
  dataKeys?: Array<string>;
  colors: Record<string, string>;
  tooltip?: ContentType<ValueType, NameType>;
  data?: Array<T>;
  xAxisInterval?: number;
  yAxisWidth?: number;
  barSize?: number;
  width?: number;
  height?: number;
  theme?: 'light' | 'dark';
  isTimeScale?: boolean;
  legendVerticalAlign?: 'top' | 'middle' | 'bottom';
  legendAlign?: 'left' | 'center' | 'right';
  animate?: boolean;
  dataKey?: string;
}

export const LineChart = <T extends Record<string, unknown>>({
  data,
  dataKeys = [],
  colors,
  tooltip,
  xAxisInterval,
  yAxisWidth = DEFAULT_Y_AXIS_WIDTH,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  theme = 'light',
  isTimeScale = true,
  animate = true,
  dataKey = 'key',
}: LineChartProps<T>) => {
  return (
    <div className={styles['chart-wrapper']}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartLineChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="2 2"
            vertical={false}
            stroke={style[theme].cartesianGrid.stroke}
          />
          <XAxis
            dataKey={dataKey}
            {...getXAxisConfig(isTimeScale, xAxisInterval, theme)}
            tickLine={{ ...style[theme].xAxis.tickLine }}
            axisLine={{ ...style[theme].xAxis.axisLine }}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            domain={['auto', (dataMax: number) => dataMax * 1.2]}
            tickLine={false}
            tick={{ ...style[theme].yAxis.tick }}
            tickFormatter={(value) => NumberUtils.format(value, { notation: 'compact' }) || ''}
            axisLine={false}
            width={yAxisWidth}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none', zIndex: 10 }}
            content={tooltip}
            cursor={{ ...style[theme].tooltip.cursor }}
            isAnimationActive={false}
            allowEscapeViewBox={{ x: false, y: true }}
          />
          {dataKeys.map((dataKey) => {
            return (
              <Line
                isAnimationActive={animate}
                type="monotone"
                key={dataKey}
                dataKey={dataKey}
                stroke={colors[dataKey]}
                dot={false}
                strokeWidth={2}
                activeDot={{
                  stroke: `${colors[dataKey]}`,
                  ...style[theme].activeDot,
                }}
              />
            );
          })}
        </RechartLineChart>
      </ResponsiveContainer>
    </div>
  );
};
