import { ReactElement, RefAttributes, forwardRef, useMemo, useState } from 'react';

import { Input } from '@any-ui-react/core';
import { Select, SelectForwardRef, SelectInstance, SelectProps } from '@any-ui-react/select';

import { BasicOption, TimeRange, TimeUtils } from '~anyx/shared/utils';

interface Props {
  value?: TimeRange;
  defaultValue?: TimeRange;
  onChange?: (data: TimeRange) => void;
  separator?: string;
}

export type TimeRangeOption = BasicOption<number>;
export type TimeRangeSelectProps = Omit<
  SelectProps<TimeRangeOption, false>,
  'value' | 'defaultValue' | 'onChange'
> &
  Props;

type TimeRangeSelectType = (
  props: TimeRangeSelectProps & RefAttributes<SelectInstance<TimeRangeSelectProps>>
) => ReactElement;

export const TimeRangeSelect = forwardRef(
  (
    { value, defaultValue, onChange, separator = '~', ...rest }: TimeRangeSelectProps,
    ref: SelectForwardRef<TimeRangeOption>
  ) => {
    const { defaultFrom, defaultTo } = useMemo(() => {
      const from = defaultValue?.from
        ? {
            value: TimeUtils.getMinutesValueFromTime(defaultValue.from),
            label: TimeUtils.getStringFromRange(defaultValue.from),
          }
        : undefined;

      const to = defaultValue?.to
        ? {
            value: TimeUtils.getMinutesValueFromTime(defaultValue.to),
            label: TimeUtils.getStringFromRange(defaultValue.to),
          }
        : undefined;

      return {
        defaultFrom: from,
        defaultTo: to,
      };
    }, [defaultValue?.from, defaultValue?.to]);

    const [start, setStart] = useState<number | undefined>(defaultFrom?.value);
    const [end, setEnd] = useState<number | undefined>(defaultTo?.value);

    return (
      <Input.Wrapper
        {...rest.wrapper}
        error={rest?.error}
        label={rest.label}
        className={rest.className}
      >
        <div className="mb-1 flex w-full space-x-2">
          <Select
            {...rest}
            ref={ref}
            value={start}
            defaultValue={defaultFrom}
            wrapper={{
              className: 'grow',
            }}
            error={!!rest.error}
            label={undefined} // let the wrapper handle it
            options={TimeUtils.generateTimeRangeOptions()
              .filter((option) => (end !== undefined ? option.value < end : option))
              .map((option) => ({
                label: option.label,
                value: option.value,
              }))}
            onChange={(time) => {
              // Update internal state
              const numValue = Number(time?.value);
              setStart(numValue);

              onChange?.({
                ...value,
                from: time ? TimeUtils.getTimeFromValue(numValue) : undefined,
              });
            }}
          />

          <div className="flex items-center pl-1">{separator}</div>

          <Select
            {...rest}
            value={end}
            defaultValue={defaultTo}
            disabled={start === undefined}
            wrapper={{
              className: 'grow',
            }}
            error={!!rest.error}
            label={undefined} // let the wrapper handle it
            options={TimeUtils.generateTimeRangeOptions()
              .filter((option) => (start !== undefined ? Number(option.value) > start : option))
              .map((option) => ({
                label: option.label,
                value: option.value,
              }))}
            onChange={(time) => {
              // Update internal state
              const numValue = Number(time?.value);
              setEnd(numValue);

              onChange?.({
                ...value,
                to: time ? TimeUtils.getTimeFromValue(numValue) : undefined,
              });
            }}
          />
        </div>
      </Input.Wrapper>
    );
  }
) as TimeRangeSelectType;
