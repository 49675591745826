import { HomeFilterInputFilterType } from '../components';
import { useHomeReportTableQuery } from '../graphql';

interface UseHomeReportTableProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportTable = ({ filters, skip }: UseHomeReportTableProps) => {
  const { metric: _metric, chartTab: _chartTab, ...filterInput } = filters;
  const { data, loading, error } = useHomeReportTableQuery({
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportTable: data?.saleChannelReportTable,
    loading,
    error,
  };
};
