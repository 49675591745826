import { ReactNode, cloneElement } from 'react';

import classNames from 'classnames';

import { Address, AddressPart, AddressUtils } from '~anyx/shared/utils';

import { AddressOneDisplay } from './AddressOneDisplay';
import { AddressTwoDisplay } from './AddressTwoDisplay';
import { BreakDisplay } from './BreakDisplay';
import { CityDisplay } from './CityDisplay';
import { CompanyDisplay } from './CompanyDisplay';
import { CountryDisplay } from './CountryDisplay';
import { FirstNameDisplay } from './FirstNameDisplay';
import { LastNameDisplay } from './LastNameDisplay';
import { PhoneDisplay } from './PhoneDisplay';
import { PostalCodeDisplay } from './PostalCodeDisplay';
import { RegionDisplay } from './RegionDisplay';

const ADDRESS_COMPONENTS: Record<AddressPart, React.ReactElement> = {
  [AddressPart.FIRSTNAME]: <FirstNameDisplay address={{}} hasPrevious={false} />,
  [AddressPart.LASTNAME]: <LastNameDisplay address={{}} hasPrevious={false} />,
  [AddressPart.COMPANY]: <CompanyDisplay address={{}} hasPrevious={false} />,
  [AddressPart.COUNTRY]: <CountryDisplay address={{}} hasPrevious={false} />,
  [AddressPart.POSTAL_CODE]: <PostalCodeDisplay address={{}} hasPrevious={false} />,
  [AddressPart.REGION]: <RegionDisplay address={{}} hasPrevious={false} />,
  [AddressPart.CITY]: <CityDisplay address={{}} hasPrevious={false} />,
  [AddressPart.ADDRESS_FIRST]: <AddressOneDisplay address={{}} hasPrevious={false} />,
  [AddressPart.ADDRESS_SECOND]: <AddressTwoDisplay address={{}} hasPrevious={false} />,
  [AddressPart.PHONE]: <PhoneDisplay address={{}} hasPrevious={false} />,
  [AddressPart.BREAK]: <BreakDisplay address={{}} hasPrevious={false} />,
};

interface AddressDisplayerProps {
  parts?: AddressPart[];
  address?: Address | null;
  emptyTemplate?: ReactNode;
  hasPrevious?: boolean;
  className?: string;
}

export const AddressDisplayer = ({
  parts = [
    AddressPart.COUNTRY,
    AddressPart.POSTAL_CODE,
    AddressPart.REGION,
    AddressPart.CITY,
    AddressPart.ADDRESS_FIRST,
    AddressPart.ADDRESS_SECOND,
  ],
  address,
  emptyTemplate,
  hasPrevious = true,
  className,
}: AddressDisplayerProps) => {
  if (!address) {
    return <>{emptyTemplate}</>;
  }

  // Default to Japanese address format if no country provided
  const localizedAddress = AddressUtils.getLocaleAddress(address?.country || '', parts);

  return (
    <div className={classNames('flex flex-wrap gap-x-1 break-all', className)}>
      {localizedAddress.map((part, i) => {
        const as = cloneElement(ADDRESS_COMPONENTS[part], {
          address,
          hasPrevious,
          key: `${part}-${i}`,
        });
        return as;
      })}
    </div>
  );
};
