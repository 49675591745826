import * as Sentry from '@sentry/react';
import { format, isValid } from 'date-fns';
import { getTimezoneOffset, toZonedTime, fromZonedTime } from 'date-fns-tz';

import { environmentStore } from '../states';

import { TimeUtils } from './time.utils';

export class TimezoneUtils {
  static readonly DEFAULT = 'Asia/Tokyo';

  static setTimezone(timeZone: string) {
    if (TimezoneUtils.isValidTimeZone(timeZone)) {
      environmentStore.getState().setTimezone(timeZone);
    }
  }

  static isValidTimeZone(timeZone: string) {
    if (!timeZone) {
      return false;
    }

    try {
      Intl.DateTimeFormat(undefined, { timeZone });
      return true;
    } catch (e) {
      Sentry.captureException(new Error('Error while checking timeZone', { cause: e }));
      return false;
    }
  }

  static getFormattedUTCTimezoneOffset(timezone: string, prefix = '') {
    const offset = getTimezoneOffset(timezone) / TimeUtils.SECONDS / TimeUtils.MILLISECONDS;
    const hours = ('0' + Math.floor(Math.abs(offset / TimeUtils.MINUTES))).slice(-2);
    const minutes = ('0' + Math.round(offset % TimeUtils.MINUTES)).slice(-2);
    return `${prefix}${offset > 0 ? '+' : offset < 0 ? '-' : ' '}${hours}:${minutes}`;
  }

  static toPreferredTimezone(date: Date | string | number, timezone?: string) {
    if (!date || !isValid(new Date(date))) {
      console.error("Couldn't convert to preferred timezone because the date is invalid");
      return new Date();
    }
    return toZonedTime(date, timezone || TimezoneUtils.getCurrentTimezone());
  }

  static toUtc(date: Date | string | number, timezone?: string) {
    if (!date || !isValid(new Date(date))) {
      console.error("Couldn't convert to utc time because the date is invalid");
      return new Date();
    }
    return fromZonedTime(date, timezone || TimezoneUtils.getCurrentTimezone());
  }

  static getCurrentTimezone() {
    return environmentStore.getState().currentTimezone;
  }

  static getDeviceTimezoneOffset() {
    // return: +09:00
    return format(new Date(), `XXX`);
  }

  static getRecommendedTimezones(
    targetTimezoneOffset: string,
    timezoneOptions: { id: string; name: string; offset: string }[]
  ) {
    const matchedResults = timezoneOptions.filter(({ offset }) =>
      offset.includes(targetTimezoneOffset)
    );
    // try to find the recommended timezone using timezone string
    // if the city is not found then use the first result
    const recommended =
      matchedResults.find(({ name }) =>
        name.includes(Intl.DateTimeFormat().resolvedOptions().timeZone)
      ) ?? matchedResults[0];

    return recommended;
  }
}
