import { Helmet } from 'react-helmet-async';

import { useDocumentHead, useEnvironmentStore, useLayoutStore } from '~anyx/shared/utils';

import { PageTitle } from './PageTitle';

export const Title = () => {
  const title = useLayoutStore((state) => state.title);
  const appName = useEnvironmentStore((s) => s.appName);
  const { documentTitle } = useDocumentHead({
    pageTitle: title.text,
    appName: appName,
  });

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <PageTitle {...title} />
    </>
  );
};
