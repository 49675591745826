import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@any-ui-react/core';

import { CheckIcon, CrossIcon } from '~anyx/shared/icons';

interface CrmConnectedDetailProps {
  /** Connection status */
  connected: boolean;
  /** Connected store name */
  name?: string;
  /** ClassName */
  className?: string;
  customInfo?: { label: string; value: string }[];
}

export const CrmConnectedDetails = ({
  connected,
  name,
  customInfo,
  className,
}: CrmConnectedDetailProps) => {
  const { t } = useTranslation(['crmIntegration', 'shared']);
  return (
    <div className={className}>
      <div className="text-primary grid grid-cols-[max-content_1fr] gap-x-4 gap-y-3 text-sm">
        <span>{t('crmIntegration.shared.connectedInfo.storeName', { ns: 'crmIntegration' })}</span>
        <span>{name}</span>
        {customInfo?.map((info) => (
          <React.Fragment key={info.label}>
            <span>{info.label}</span>
            <span>{info.value}</span>
          </React.Fragment>
        ))}
        <span>{t('shared.field.status.name', { ns: 'shared' })}</span>
        <Badge
          leftSection={
            connected ? (
              <CheckIcon className="text-success h-3 w-3" />
            ) : (
              <CrossIcon className="text-failure h-3 w-3" />
            )
          }
          color={connected ? 'green' : 'red'}
        >
          <span className="text-2xs">
            {connected
              ? t('shared.button.connected', { ns: 'shared' })
              : t('shared.button.disconnected', { ns: 'shared' })}
          </span>
        </Badge>
      </div>
    </div>
  );
};
