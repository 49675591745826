import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ChatEvent, ChatEventRole } from '../modules/ai/type';

export interface AiChatEventStore {
  chatEvents: ChatEvent[];
  updateChatEvents: (newChatEvents: ChatEvent[]) => void;
  removeLastLoadingChatEvent: () => void;
  clearChatEvents: () => void;
}

export const useAiChatEventStore = create<AiChatEventStore>()(
  devtools(
    immer((set) => ({
      chatEvents: [],
      updateChatEvents: (newChatEvents: ChatEvent[]) => {
        set((state) => {
          const prevChatEvent = state.chatEvents.at(-1);

          return {
            chatEvents: prevChatEvent?.loading
              ? [...state.chatEvents.slice(0, -1), ...newChatEvents]
              : [...state.chatEvents, ...newChatEvents],
          };
        });
      },
      removeLastLoadingChatEvent: () => {
        set((state) => {
          const lastChatEvent = state.chatEvents.at(-1);
          if (lastChatEvent?.role === ChatEventRole.ASSISTANT) {
            return {
              chatEvents: lastChatEvent?.loading ? state.chatEvents.slice(0, -1) : state.chatEvents,
            };
          }
          return {
            chatEvents: state.chatEvents,
          };
        });
      },
      clearChatEvents: () => {
        set(() => ({
          chatEvents: [],
        }));
      },
    }))
  )
);
