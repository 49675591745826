import { ReactNode } from 'react';

import classNames from 'classnames';

type NoResultProps = {
  title: string;
  description?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  button?: ReactNode;
};

export const NoResult = ({
  title,
  description,
  wrapperClassName = 'py-40',
  titleClassName,
  descriptionClassName,
  button,
}: NoResultProps) => {
  return (
    <div
      className={classNames('flex flex-col items-center justify-center gap-4', wrapperClassName)}
    >
      <div className={classNames('text-lg', titleClassName)}>{title}</div>
      {description && (
        <div className={classNames('text-gray-7 text-2xs lg:text-sm', descriptionClassName)}>
          {description}
        </div>
      )}
      {button}
    </div>
  );
};
