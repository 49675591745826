import { useTranslation } from 'react-i18next';

interface CrmStepTagProps {
  stepNumber: number;
}

export const CrmStepTag = ({ stepNumber }: CrmStepTagProps) => {
  const { t } = useTranslation('crmIntegration');

  return (
    <p className="text-2xs bg-gray-7 flex w-fit items-center rounded-full px-2 py-1 text-center font-semibold leading-none text-white">
      {t('crmIntegration.shared.step', { stepNumber })}
    </p>
  );
};
