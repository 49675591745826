import { ApolloLink, createHttpLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { IdToken } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { createClient } from 'graphql-ws';
import Cookies from 'js-cookie';

import { environment } from '~anyx/app-core/env';

import { errorLink } from './errorLink';

const wsLink = new GraphQLWsLink(
  createClient({
    url: environment.api.graphqlws ?? '',
    on: {
      error: (err) => {
        Sentry.captureException(err, (ctx) => {
          return ctx.setTransactionName('wsLinkConfiguration');
        });
      },
    },
    connectionParams: () => {
      return {
        authToken: Cookies.get('authorization'),
      };
    },
  })
);

const removeTypenameAndTrimLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables, (name, val) => {
        if (name === '__typename') {
          delete val[name];
        } else {
          if (typeof val === 'string') {
            return val.trim();
          }
          return val;
        }
      })
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const abortController = new AbortController();
const httpLink = createHttpLink({
  uri: environment.api.graphql,
  fetchOptions: {
    signal: abortController.signal, // overwrite the default abort signal: https://sentry-docs-git-fn-replay-apollo.sentry.dev/platforms/javascript/guides/react/session-replay/troubleshooting/?original_referrer=https%3A%2F%2Fgithub.com%2Fgetsentry%2Fsentry-docs%2Fpull%2F7210#response-data-for-apollo-graphql-client-network-requests-is-not-captured
  },
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${environment.api.graphql}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${Cookies.get('authorization')}`,
      },
    };
  });
  return forward(operation);
});

type GetApolloLinkArgs = {
  silentLogin: () => Promise<(string | IdToken)[] | undefined>;
};

const splitLink = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  ApolloLink.from([authLink, wsLink]),
  httpLink
);

export const getApolloLink = ({ silentLogin }: GetApolloLinkArgs) =>
  ApolloLink.from([
    authLink,
    removeTypenameAndTrimLink,
    errorLink({ silentLogin }),
    namedLink,
    splitLink,
  ]);
