export enum ActionState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  APPROVING = 'APPROVING',
  UPLOADING = 'UPLOADING',
  DOWNLOADING = 'DOWNLOADING',
  CREATING = 'CREATING',
  UPDATING = 'UPDATING',
  DELETING = 'DELETING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export interface ActionWithResult<T> {
  state: ActionState;
  result: T | null;
}
