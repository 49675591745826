import { t } from 'i18next';

import {
  AnalyticsMetricOption,
  HomeMetricOption,
  MetricData,
  NumberFormat,
  ReportMetricMappingKey,
  ReportType,
  ReportUtils,
  dimensionList,
} from '~anyx/feature/report';
import {
  MarketplaceCalendarEventPayload,
  ReportChartTab,
  ReportDimensions,
  ReportSupportedCurrency,
  SaleChannelReportTableFilterInput,
} from '~anyx/shared/graphql';
import { NumberUtils, ObjectUtils } from '~anyx/shared/utils';

import { ReportChart, ReportTable } from '../modules/sales-channel/pages/analytics/type';

export const computeChannelChartData = ({
  filterInputs,
  reportChart,
  reportTable: totalData,
  metricList,
}: {
  filterInputs: SaleChannelReportTableFilterInput;
  reportChart?: ReportChart;
  reportTable?: ReportTable;
  metricList: Record<
    AnalyticsMetricOption | HomeMetricOption,
    {
      mappingKey: ReportMetricMappingKey;
      numberFormat: NumberFormat;
    }
  >;
}) => {
  const { dimensions: dimension, chartTab } = filterInputs;

  if (chartTab !== ReportChartTab.CHANNEL) {
    return {
      reportChannelChart: {},
      channelList: [],
    };
  }

  const isDowDimension = dimension === ReportDimensions.DOW;

  const chartList = reportChart?.list || [];

  // Get x-axis data
  const sortedDimensionArray: string[] = [
    ...Array.from(new Set(reportChart?.list.map((item) => item[dimensionList[dimension]]))),
  ].sort((a, b): number => (a < b ? -1 : 1));
  const channelList = ObjectUtils.alphabeticalOrder(reportChart?.channels || []);

  const sortedTotalDimensionArray = (totalData?.list || [])
    .map((item) => item)
    .filter((item) =>
      isDowDimension ? sortedDimensionArray.map((item) => String(item)).includes(item.metric) : true
    )
    .sort((a, b) => (a.metric < b.metric ? -1 : 1));
  const getChannelChartData = (dimension: ReportDimensions) => {
    const isChannelDimension = dimension === ReportDimensions.CHANNELS;

    const getMetricValue = (channel: string, value: string): Record<string, unknown> => {
      const item =
        chartList.find((item) => {
          if (isChannelDimension) {
            return item.channel === channel && item.channel === value;
          }
          return item[dimensionList[dimension]] === value && item.channel === channel;
        }) || {};

      return item;
    };

    // Loop through metricList to get metric data
    const chartData = Object.entries(metricList).reduce(
      (metric, [key, { numberFormat, mappingKey }]) => {
        const metricDataArray = isChannelDimension ? channelList : sortedDimensionArray;

        const getChannelData = (
          value: string,
          index: number
        ): {
          key: string | number;
          name: string;
          numberFormat: NumberFormat;
          currency: ReportSupportedCurrency | undefined;
          total: number;
        } => {
          const metricValueArray: Record<string, unknown>[] = [];
          const totalMetricValue: { readonly [key: string]: string | null } =
            (isChannelDimension
              ? (totalData?.list || [])[index]
              : sortedTotalDimensionArray[index]) || {};

          const channelData = channelList.reduce((channelAcc, channel) => {
            const metricValue = getMetricValue(channel, value);
            metricValueArray.push(metricValue);
            const marketplaceCalendarEvents: MarketplaceCalendarEventPayload[] =
              metricValueArray.reduce((merged: MarketplaceCalendarEventPayload[], item) => {
                merged.push(
                  ...((item['marketplaceCalendarEvents'] ||
                    []) as MarketplaceCalendarEventPayload[])
                );
                return merged;
              }, []);

            return {
              ...channelAcc,
              // Add metric value of specific metric key to each channel
              [channel]: NumberUtils.toNumber(metricValue[mappingKey]),
              marketplaceCalendarEvents,
              totalTarget: ReportUtils.sumArrayNumber([
                ...(marketplaceCalendarEvents || []).map((event) =>
                  event?.target?.revenue ? +event?.target?.revenue : 0
                ),
                NumberUtils.toNumber(metricValue?.['dailySales']),
              ]),
            };
          }, {});

          return {
            ...channelData,
            key: ReportUtils.formatMetric({
              dimensions: dimension,
              value,
              type: ReportType.CHART,
            }),
            name: t('gql.enum.reportSummary', { ns: 'gql', metric: key }),
            numberFormat,
            currency: reportChart?.currency,
            total: NumberUtils.toNumber(totalMetricValue?.[mappingKey]),
          };
        };

        // Date or Channel array
        const metricData = metricDataArray.map((value, index) => {
          return getChannelData(value, index);
        });
        return {
          ...metric,
          [key]: metricData,
        };
      },
      {}
    );

    return chartData;
  };

  const reportChannelChart: Record<AnalyticsMetricOption | HomeMetricOption, MetricData> =
    getChannelChartData(dimension);

  return {
    reportChannelChart,
    channelList,
  };
};
