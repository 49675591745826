import { AccountFunctionApolloFieldPolicies } from '~anyx/function/account';
import {
  ChatFunctionApolloQueryPolicies,
  ChatFunctionApolloFieldPolicies,
} from '~anyx/function-chat/apollo';
import { ApolloClientHelpers } from '~anyx/shared/graphql';

export const typePolicies: ApolloClientHelpers.StrictTypedTypePolicies = {
  SkuForSetDetailed: {
    keyFields: ['gtin'],
  },
  InboundProductDetailed: {
    keyFields: ['gtin'],
  },
  SkuDetail: {
    keyFields: ['gtin'],
  },
  ...AccountFunctionApolloFieldPolicies,
  ...ChatFunctionApolloFieldPolicies,
  Query: {
    fields: {
      ...ChatFunctionApolloQueryPolicies,
    },
  },
};
