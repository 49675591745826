import { t } from 'i18next';

import { ErrorFactory } from '~anyx/shared/utils';

import { WorkflowErrorCode, WORKFLOW_ERROR_DESCRIPTION_MAP } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorParams {}

export const WORKFLOW_ERROR_FACTORY = new ErrorFactory<WorkflowErrorCode, ErrorParams>(
  'workflow',
  WORKFLOW_ERROR_DESCRIPTION_MAP,
  t('workflow.core.notify.save.error', { ns: 'workflow' })
);
