import { Helmet } from 'react-helmet-async';

import classNames from 'classnames';

import { PageTitle, PageTitleProps, Spinner, UnavailableMessage } from '~anyx/shared/ui';
import { useDocumentHead, useEnvironmentStore } from '~anyx/shared/utils';

export interface WorkflowPageBuilderRootProps {
  children: React.ReactNode;
  responsive?: boolean;
  width?: 'fluid' | 'fit';
  center?: boolean;
}

const Root = ({ children, responsive = true, center = false }: WorkflowPageBuilderRootProps) => {
  return (
    <div
      className={classNames('relative h-full', {
        'lg:px-6 lg:pt-4': responsive,
        'px-6 pt-4': !responsive,
        'mx-auto': center,
      })}
    >
      {children}
    </div>
  );
};

export interface WorkflowPageBuilderDocumentHeadProps {
  pageTitle?: string;
}

const DocumentHead = ({ pageTitle }: WorkflowPageBuilderDocumentHeadProps) => {
  const appName = useEnvironmentStore((s) => s.appName);
  const { documentTitle } = useDocumentHead({
    pageTitle,
    appName: appName,
  });

  return (
    <Helmet>
      <title>{documentTitle}</title>
    </Helmet>
  );
};

export type WorkflowPageBuilderTitleProps = PageTitleProps;

const Title = (props: WorkflowPageBuilderTitleProps) => {
  return <PageTitle {...props} />;
};

export interface WorkflowPageBuilderContentProps {
  loading?: boolean;
  error?: boolean;
  children: React.ReactNode;
}

const Content = ({ loading, error, children }: WorkflowPageBuilderContentProps) => {
  if (loading) {
    return (
      <div className="grid h-full w-full grow place-items-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <UnavailableMessage />;
  }

  return <>{children}</>;
};

export type WorkflowPageBuilderTableContentProps = WorkflowPageBuilderContentProps;

const TableContent = ({ children, ...rest }: WorkflowPageBuilderTableContentProps) => {
  return (
    <Content {...rest}>
      <div className="flex flex-col gap-y-4 [&>nav]:px-4 [&>nav]:py-0 lg:[&>nav]:px-0">
        {children}
      </div>
    </Content>
  );
};

export const WorkflowPageBuilder = {
  Root,
  DocumentHead,
  Title,
  Content,
  TableContent,
};
