import React, { ReactElement, Ref, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, NumberInput, NumberInputProps, Box, ANY_UI_THEME } from '@any-ui-react/core';
import { useUncontrolled } from '@mantine/hooks';

import { ObjectUtils, CurrenciesEnum, CurrencyUtils } from '~anyx/shared/utils';

export interface InputCurrencyData<T extends string | null> {
  currency: T;
  amount: string;
}

export type InputCurrencyProps<T extends string | null> = Omit<
  NumberInputProps,
  'value' | 'defaultValue' | 'onChange'
> & {
  currencies?: CurrenciesEnum[];
  // Override
  defaultValue?: InputCurrencyData<T> | null;
  value?: InputCurrencyData<T> | null;
  onChange?: (data: InputCurrencyData<T>) => void;
  min?: number | string;
  max?: number | string;
  clearable?: boolean 
};

const InputCurrencyFn = <T extends string | null>(
  {
    value,
    defaultValue,
    disabled,
    error,
    min = 0,
    max,
    onChange,
    clearable = true,
    currencies = ObjectUtils.arrayFromEnum<CurrenciesEnum>(CurrenciesEnum),
    ...rest
  }: InputCurrencyProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const { t } = useTranslation();

  const [_value, handleChange] = useUncontrolled<InputCurrencyData<T> | null>({
    value,
    defaultValue,
    finalValue: null,
    onChange: (v) => onChange?.(v as InputCurrencyData<T>),
  });

  const defaultCurrency = useMemo(() => {
    return (
      defaultValue?.currency ||
      (currencies.includes(CurrenciesEnum.JPY) ? CurrenciesEnum.JPY : currencies[0]) ||
      CurrenciesEnum.JPY
    );
  }, [currencies, defaultValue]);

  const emptyValue = useMemo(() => {
    return clearable ? '' : 0;
  }, [clearable])

  return (
    <NumberInput
      {...rest}
      ref={ref}
      fixedDecimalScale
      styles={{ section: { width: 'auto' }, controls: { display: 'none' } }}
      error={error}
      disabled={disabled}
      placeholder="0"
      value={_value?.amount ? Number(_value?.amount) : emptyValue}
      min={min ? Number(min) : undefined}
      max={min ? Number(max) : undefined}
      decimalScale={CurrencyUtils.getRelevantDecimals(value?.currency as unknown as string)}
      onChange={(amount) => {
        handleChange({
          amount: (amount || emptyValue).toString(),
          currency: (value?.currency || defaultCurrency) as T,
        });
      }}
      rightSection={
        <Box
          h={30}
          pl={8}
          className="flex items-center"
          style={{
            borderLeft: `1px solid ${ANY_UI_THEME.colors.gray[2]}`,
          }}
        >
          <Select
            searchable
            value={(_value?.currency as T) || defaultCurrency}
            disabled={disabled}
            styles={{
              input: { width: 70 },
            }}
            classNames={{
              wrapper: 'm-0',
              input: 'p-0 border-0 shadow-none bg-transparent',
            }}
            data={currencies.map((option) => ({
              label: t('shared.enum.currency', {
                ns: 'shared',
                currency: option,
                compact: true,
              }),
              value: option,
            }))}
            onChange={(currency) => {
              if (!currency) return;
              handleChange({
                amount: Number(value?.amount).toFixed(CurrencyUtils.getRelevantDecimals(currency)),
                currency: currency as T,
              });
            }}
          />
        </Box>
      }
    />
  );
};

export const InputCurrency = forwardRef(InputCurrencyFn) as <T extends string | null>(
  p: InputCurrencyProps<T> & {
    ref?: Ref<HTMLInputElement>;
  }
) => ReactElement;
