import { useCallback } from 'react';

import { MdCountryEnum } from '~anyx/shared/graphql';
import { AnyXLanguage, CountryUtils } from '~anyx/shared/utils';

// For Simplicity as the usage is same and I don't really want to add graphql and mock
// to the hook folder I break the rule of export here.
import { useProvinceOptions } from '../components/dynamic-address-fields/hooks';

interface UseFindProvinceNameProps {
  country?: MdCountryEnum | string;
  language?: AnyXLanguage;
}

export const useFindProvinceName = ({
  country,
  language = AnyXLanguage.EN_US,
}: UseFindProvinceNameProps = {}) => {
  const { provinceOptions } = useProvinceOptions({
    country: CountryUtils.asCountry(country),
    language,
  });

  const getProvinceName = useCallback(
    (provinceId?: string) => {
      if (!provinceId) {
        return '';
      }
      return provinceOptions.find((p) => p.value === provinceId)?.label ?? provinceId;
    },
    [provinceOptions]
  );

  return { getProvinceName };
};
