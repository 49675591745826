import { ReactNode, useCallback } from 'react';

import { GroupBase, OptionsOrGroups, LoadOptions } from '@any-ui-react/select';
import { LazyQueryExecFunction, OperationVariables, QueryHookOptions } from '@apollo/client';

import { InputMaybe, Scalars } from '..';

const ANYLOGI_DEFAULT_OPTION_SIZE = 99;

export type LoadAnylogiAdditional = {
  page: number;
};

export interface LoadAnylogiDefaultOption<T> {
  label: ReactNode;
  value: T;
}

interface RequiredResponse<I> {
  [key: string]: {
    total: number;
    records: readonly I[];
  };
}

interface RequiredVariables extends OperationVariables {
  page?: InputMaybe<number>;
  perPage?: InputMaybe<number>;
  filters?: InputMaybe<{
    readonly keyword?: InputMaybe<Scalars['String']['input']>;
    [key: string]: unknown;
  }>;
}

interface LoadSelectOptionsProps<I, V, LQ, LV extends OperationVariables> {
  labelFormatter: (item: I) => ReactNode;
  valueFormatter: (item: I) => V;
  optionMapper?: (item: I) => LoadAnylogiDefaultOption<V>;
  listQuery: {
    lazyQuery: LazyQueryExecFunction<LQ, LV>;
    options?: QueryHookOptions<LQ, LV>;
    variables?: Omit<LV, 'page' | 'perPage'>;
    scope: string;
    hasMoreChecker?: (
      loadedOptions: LoadAnylogiDefaultOption<unknown>[],
      options: OptionsOrGroups<
        LoadAnylogiDefaultOption<unknown>,
        GroupBase<LoadAnylogiDefaultOption<unknown>>
      >,
      data?: LQ
    ) => boolean;
  };
}

export const useLoadAnylogiOptions = <
  I, // Type used in formatter Functions,
  V, // Type used has select value
  LQ extends RequiredResponse<I>, // Type of List Query
  LV extends RequiredVariables // Variables of List Query
>({
  labelFormatter,
  valueFormatter,
  optionMapper = (item) => ({
    label: labelFormatter(item),
    value: valueFormatter(item),
  }),
  listQuery: {
    options: listOptions,
    variables: listVariables,
    scope: listScope,
    lazyQuery: listQuery,
    hasMoreChecker = (loadedOptions, newOptions, data) => {
      return loadedOptions.length + newOptions.length < (data?.[listScope]?.total || 0);
    },
  },
}: LoadSelectOptionsProps<I, V, LQ, LV>) => {
  const loadOptions: LoadOptions<
    LoadAnylogiDefaultOption<V>,
    GroupBase<LoadAnylogiDefaultOption<V>>,
    LoadAnylogiAdditional
  > = useCallback(
    async (keyword, loadedOptions = [], additional = { page: 1 }) => {
      try {
        const requiredVariables: Pick<LV, 'page' | 'perPage' | 'filters'> = {
          page: additional.page,
          perPage: ANYLOGI_DEFAULT_OPTION_SIZE,
          filters: { ...(keyword ? { keyword } : {}) },
        };

        const { data } = await listQuery({
          ...listOptions,
          variables: {
            ...listVariables,
            ...requiredVariables,
            filters: {
              ...requiredVariables.filters,
              ...listVariables?.['filters'],
            },
          } as LV,
        });

        const newItems = data?.[listScope]?.records || [];

        const mappedOptions = newItems.map((item) => optionMapper(item));
        const hasMore = hasMoreChecker(mappedOptions, loadedOptions, data);

        return {
          options: mappedOptions,
          hasMore,
          additional: { page: (Number(requiredVariables?.page) || 0) + 1 },
        };
      } catch (_) {
        return {
          options: [],
          hasMore: false,
          additional,
        };
      }
    },
    [hasMoreChecker, listOptions, listQuery, listScope, listVariables, optionMapper]
  );

  return { loadOptions };
};
