import { FacebookLoginClient } from '../../components';

import { FbPageData, UserPermission } from './types';

export const fetchUserPermissions = (): Promise<UserPermission[]> => {
  const FB = FacebookLoginClient.getFB();

  return new Promise((resolve) => {
    /** https://developers.facebook.com/docs/graph-api/reference/permission */
    FB?.api('/me/permissions', { fields: '' }, function (response) {
      const { data } = response as {
        data?: UserPermission[];
      };

      resolve(data || []);
    });
  });
};

export const fetchUserAccounts = (): Promise<FbPageData[]> => {
  const FB = FacebookLoginClient.getFB();

  return new Promise((resolve) => {
    /**https://developers.facebook.com/docs/graph-api/reference/user/accounts#example */
    FB?.api('/me/accounts', { fields: '' }, function (response) {
      const { data = [] } = response as {
        data?: FbPageData[];
        error?: unknown;
      };

      resolve(data || []);
    });
  });
};
