import { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionMeta,
  MultiValue,
  SingleValue,
  AsyncPaginateSelectProps,
  GroupBase,
  OnChangeValue,
  PropsValue,
  SelectForwardRef,
  useAsyncPaginateMultiSelect,
} from '@any-ui-react/select';
import useAsyncEffect from 'use-async-effect';

import {
  LoadOptionAdditional,
  LoadOptionDefaultOption,
  MdStoreFilterInput,
} from '~anyx/shared/graphql';
import { LazySelectV2 } from '~anyx/shared/ui';
import { BasicOption, EMPTY_DEFAULT } from '~anyx/shared/utils';

import { useStoreSelect, useStoresSelect } from './operation';

interface StoreSelectProps<T = string, IsMulti extends boolean = boolean>
  extends Omit<
    AsyncPaginateSelectProps<
      LoadOptionDefaultOption<T>,
      GroupBase<LoadOptionDefaultOption<T>>,
      LoadOptionAdditional,
      IsMulti
    >,
    'loadOptions' | 'value'
  > {
  filters?: MdStoreFilterInput;
  isMulti?: IsMulti;
  value?: PropsValue<LoadOptionDefaultOption<T> | T>;
  onChange?: (
    newValue: OnChangeValue<LoadOptionDefaultOption<T>, IsMulti>,
    actionMeta: ActionMeta<LoadOptionDefaultOption<T> | T>
  ) => void;
}

const StoreSelectGn = <T = string, IsMulti extends boolean = false>(
  {
    value: masterDataStoreIds,
    filters,
    isMulti,
    isClearable = true,
    ...rest
  }: StoreSelectProps<T, IsMulti>,
  ref: SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
) => {
  const arrayValue = useMemo(() => {
    const value = masterDataStoreIds ? masterDataStoreIds : [];
    return Array.isArray(value) ? value : [value];
  }, [masterDataStoreIds]);
  const [values, setValues] = useState<BasicOption<T>[]>([]);
  const { t } = useTranslation();
  const paginateMultiSelectProps = useAsyncPaginateMultiSelect<
    LoadOptionDefaultOption<T>,
    GroupBase<LoadOptionDefaultOption<T>>
  >({
    getSelectedLabel: (selectedCount) =>
      t('shared.select.optionSelected', { ns: 'shared', amount: selectedCount }),
  });
  const { getStoreDetails } = useStoreSelect();
  const { loadOptions } = useStoresSelect<T>({ filters });

  useAsyncEffect(
    async (isMounted) => {
      if (!arrayValue || arrayValue.length === 0) {
        setValues([]);
        return;
      }

      // TODO Currently we will show the name for one value, and X selected when more,
      // That s why we only care to load the first value name and ignore the other names
      try {
        if (!isMounted()) return;
        const storeDetails = await getStoreDetails({ storeId: arrayValue[0] });
        const displayValue = storeDetails?.name || EMPTY_DEFAULT;
        const selectedOption = arrayValue?.map((value, i) => ({
          value: value,
          label: i === 0 ? displayValue : EMPTY_DEFAULT,
        }));
        setValues(selectedOption);
      } catch (e) {
        if (!isMounted()) return;
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [arrayValue]
  );

  const multiProps = isMulti ? paginateMultiSelectProps : {};

  /**
   * Only use inner state if the selector is multi or is clearable
   * if not, leave the value to the parent component
   * to avoid setting the state when it's not necessary
   */
  const setNewValues = (
    value: MultiValue<LoadOptionDefaultOption<T>> | SingleValue<LoadOptionDefaultOption<T>>
  ) => {
    if (!isMulti && !isClearable) return;
    let valueToSet: BasicOption<T>[] = [];

    if (value !== null) {
      valueToSet = Array.isArray(value) ? value : [value];
    }

    setValues(valueToSet);
  };

  return (
    <LazySelectV2
      {...multiProps}
      className="select-has-min-w-menu [&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
      classNames={{ control: () => 'truncate' }}
      isClearable={isClearable}
      isSearchable
      // TODO this line should not be needed
      // It is currently used to redraw the component so it can trigger the query again
      // This whole MdStore/Supplier/Account selector need to be cleaned up
      key={`${JSON.stringify(filters)}`}
      {...rest}
      onChange={(value, meta) => {
        rest?.onChange?.(value, meta);
        setNewValues(value);
      }}
      value={values}
      ref={ref}
      loadOptions={loadOptions}
    />
  );
};

export const StoreSelect = forwardRef(StoreSelectGn) as <
  T = string,
  IsMulti extends boolean = boolean
>(
  p: StoreSelectProps<T, IsMulti> & {
    ref?: Ref<
      SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
    >;
  }
) => ReactElement;
