import { useCallback, useState } from 'react';

import {
  ApolloQueryResult,
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client';

interface WatchQueryProps<TData, TVariables extends OperationVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options?: LazyQueryHookOptions<TData, TVariables>;
}

export function useWatchLazyQuery<TData, TVariables extends OperationVariables>({
  query,
  options,
}: WatchQueryProps<TData, TVariables>) {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);

  const listenToWatchedLazyQuery = useCallback(
    async (
      variables: TVariables,
      onNext: (_data: ApolloQueryResult<TData>) => boolean,
      onError: (error: unknown) => void,
      onComplete?: () => void
    ) => {
      setLoading(true);
      try {
        const watchQueryOptions = {
          ...options,
          variables,
          query,
        };

        const observableQuery = apolloClient.watchQuery<TData, TVariables>(watchQueryOptions);

        observableQuery.subscribe((data: ApolloQueryResult<TData>) => {
          const stopPolling = onNext(data);
          if (stopPolling) {
            observableQuery.stopPolling();
            onComplete?.();
            setLoading(false);
          }
        });
      } catch (error: unknown) {
        onError(error);
        setLoading(false);
      }
    },
    [options, query, apolloClient]
  );

  return { listenToWatchedLazyQuery, loading };
}
