import { Address, AddressUtils, CountriesEnum } from '~anyx/shared/utils';

export const PostalCodeDisplay = ({ address }: { address: Address; hasPrevious: boolean }) => {
  function getPostalCode(zipcode: string, country?: CountriesEnum) {
    const link = AddressUtils.zipCodeLinkDetails(zipcode, country);
    if (link) {
      return (
        <a target="_blank" rel="noreferrer" className="text-link underline" href={link}>
          {zipcode}
        </a>
      );
    }
    return <span>{zipcode}</span>;
  }

  return address?.zipcode ? getPostalCode(address?.zipcode, address?.country || undefined) : null;
};
