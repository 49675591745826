import * as Sentry from '@sentry/react';
import z from 'zod';

import { useFeatureFlag } from '~anyx/external/firebase';

const FBLoginConfig = z.object({
  appId: z.string(),
  configId: z.object({
    instagram: z.string(),
    messenger: z.string(),
    whatsapp: z.string(),
  }),
});
type FBLoginConfig = z.infer<typeof FBLoginConfig>;

const DEFAULT_CONFIG = {
  appId: '',
  configId: '',
};

export const useAnyChatFaceBookLoginConfig = ({
  type,
}: {
  type: 'instagram' | 'messenger' | 'whatsapp';
}): {
  appId: string;
  configId: string;
} => {
  const rawConfig = useFeatureFlag('ac_facebook_login', { as: 'string' });

  if (!rawConfig?.value) return DEFAULT_CONFIG;

  try {
    const data = FBLoginConfig.parse(JSON.parse(rawConfig?.value));

    const appId = data.appId;
    const configId = data.configId[type];

    return {
      appId,
      configId,
    };
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        data: `Parsing FB login config v3: ${rawConfig}`,
      },
    });
    return DEFAULT_CONFIG;
  }
};
