import { useMemo } from 'react';

import { isValid, differenceInYears } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { TimezoneUtils } from '../utils';

export const useBirthAge = (birthday?: string) => {
  const timezone = TimezoneUtils.getCurrentTimezone();

  const age = useMemo(() => {
    const birthdayDate = birthday ? new Date(birthday) : undefined;

    if (birthdayDate && isValid(birthdayDate)) {
      return differenceInYears(
        toZonedTime(new Date(), timezone),
        toZonedTime(birthdayDate, timezone)
      );
    }

    return undefined;
  }, [birthday, timezone]);

  return age;
};
