import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { z } from 'zod';

import { environmentStore } from '../states';

export enum AnyXLanguage {
  /** English (United States) */
  EN_US = 'en-US',
  /** Japanese; 日本語 */
  JA_JP = 'ja-JP',
  /** Chinese (Simplified); 简体中文 */
  ZH_CN = 'zh-CN',
  /** Chinese (Traditional); 繁體中文 */
  KO_KR = 'ko-KR',
}

export const AnyXLanguageSchema = z.nativeEnum(AnyXLanguage).catch(AnyXLanguage.EN_US);

export class LanguageUtils {
  static readonly DEFAULT: AnyXLanguage = AnyXLanguage.EN_US;

  static asLanguageTranslationKey = (
    language: AnyXLanguage | string | null | undefined = AnyXLanguage.EN_US
  ) => {
    return language?.replace('-', '_').toUpperCase();
  };

  static setLanguage = (language?: string) => {
    if (LanguageUtils.isAnyxLanguage(language)) {
      i18n.changeLanguage(language).then(() => {
        environmentStore
          .getState()
          .setCurrentLanguage(LanguageUtils.fromI18nLanguage(i18n.language));
      });
    } else {
      console.error(`Invalid language: ${language}, Backup to ${LanguageUtils.DEFAULT}`);
      Sentry.captureException(`Invalid language: ${language}`);
      i18n.changeLanguage(LanguageUtils.DEFAULT).then(() => {
        environmentStore
          .getState()
          .setCurrentLanguage(LanguageUtils.fromI18nLanguage(i18n.language));
      });
    }
  };

  static fromI18nLanguage(language?: string): AnyXLanguage {
    return AnyXLanguageSchema.parse(language);
  }

  static toI18nLanguage(language: AnyXLanguage): string {
    return language.toString();
  }

  static isAnyxLanguage = (language = ''): language is AnyXLanguage => {
    return (Object.values(AnyXLanguage) as string[]).includes(language);
  };

  static toAnyXLanguage = (lg = ''): AnyXLanguage => {
    if (LanguageUtils.isAnyxLanguage(lg)) {
      return lg;
    }
    return AnyXLanguage.EN_US;
  };

  static getDefaultLanguage(lg: string) {
    return (
      (Object.keys(AnyXLanguage).find((l) => lg.toUpperCase().indexOf(l) !== -1) as AnyXLanguage) ||
      AnyXLanguage.EN_US
    );
  }

  static isFirstNameFirst(lg?: AnyXLanguage) {
    if (
      [AnyXLanguage.JA_JP, AnyXLanguage.ZH_CN, AnyXLanguage.KO_KR].some((value) => value === lg)
    ) {
      return false;
    }
    return true;
  }

  static localizeName = (
    firstName = '',
    lastName = '',
    lg = LanguageUtils.getCurrentLanguage()
  ) => {
    const fullNameArr = [firstName, lastName];

    if (fullNameArr.some((name) => !!name)) {
      if (LanguageUtils.isFirstNameFirst(lg)) {
        return fullNameArr.join(' ');
      }

      return [...fullNameArr].reverse().join(' ');
    }

    return '';
  };

  static localizeNodeName = (
    firstName: React.ReactNode,
    lastName: React.ReactNode,
    lg = LanguageUtils.getCurrentLanguage()
  ) => {
    if (LanguageUtils.isFirstNameFirst(lg)) {
      return (
        <>
          {firstName}
          {lastName}
        </>
      );
    }
    return (
      <>
        {lastName}
        {firstName}
      </>
    );
  };

  static getTwoLettersCode = (language: AnyXLanguage | null | undefined) =>
    language?.split('-')[0]?.toLowerCase() || undefined;

  static getAvailableLanguages() {
    return environmentStore.getState().languages;
  }

  static getCurrentLanguage() {
    return environmentStore.getState().currentLanguage;
  }
}
