import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { camelCase } from 'change-case';

import { AlertType } from '../models';

interface ApiErrorMessagePath {
  namespace?: string;
  path?: string | string[];
  type?: AlertType;
  message?: string;
  options?: Record<string, unknown>;
}

// This method will build an error message based on the error path and type
// basically, it will first look if a translation exist in your desired namespace according
// to alert type , path and message,
// If doesn't exist, it will try to fallback on a default translation inside gql i18n using
// alert type path and message,
// If still no translation find, it will just display a default message that is always present
// from the shared i18n file.

export const useApiTranslationMapper = () => {
  const { t, i18n } = useTranslation();

  const getErrorMessage = (e: unknown, defaultMessage = 'default') => {
    if (e instanceof ApolloError) {
      const code = e.graphQLErrors[e.graphQLErrors.length - 1]?.message;
      if (typeof code === 'string') {
        return code;
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(e, 'message') &&
      typeof (e as { message: unknown }).message === 'string'
    ) {
      return (e as { message: string }).message;
    }

    return defaultMessage;
  };

  const getTranslationKey = (
    {
      namespace,
      path,
      type = AlertType.ERROR,
      message = '',
      options = undefined,
    }: ApiErrorMessagePath,
    defaultMessage?: string
  ) => {
    // check if a error is handled in the customized path
    // fe, account.alert.error.createAccount.unAuthorized
    let keys = [];
    if (typeof path === 'string') {
      keys = [`${namespace}.alert.${type.toLowerCase()}.${path}.${camelCase(message)}`];
    } else {
      keys = (path || []).map(
        (p) => `${namespace}.alert.${type.toLowerCase()}.${p}.${camelCase(message)}`
      );
    }
    const existingKey = keys.find((key) => i18n.exists(key, { ns: namespace }));

    if (existingKey) {
      return t(existingKey, { ns: namespace, ...options });
    }

    // check if a error has default message in gql namespace
    // fe, gql.alert.error.unAuthorized
    const defaultKey = `gql.alert.${type.toLowerCase()}.${camelCase(message)}`;
    if (i18n.exists(defaultKey, { ns: 'gql' })) {
      return t(defaultKey, { ns: 'gql', ...options });
    }

    // If error is not handled at all return default error message
    // fe, shared.alert.error.default
    return (
      defaultMessage ||
      t(`shared.alert.${type.toLowerCase()}.default`, { ns: 'shared', ...options })
    );
  };

  return {
    getTranslationKey,
    getErrorMessage,
  };
};
