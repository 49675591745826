import { cloneElement, SVGProps } from 'react';
import { Translation } from 'react-i18next';

import { DatasourceType } from '~anyx/shared/graphql';
import {
  FacebookFaIcon,
  GoogleAdsFaIcon,
  GoogleAnalyticsFaIcon,
  LogoFacebookAdsIcon,
  LogoGoogleAdsIcon,
  LogoGoogleAnalytics4Icon,
  LogoYahooAdsIcon,
  TikTokFaIcon,
  TikTokIcon,
  YahooFaIcon,
} from '~anyx/shared/icons';

import { AdvertisementDatasources, AnalyticsDatasources } from './datasource.config';

const DATASOURCE_ICON: Record<
  DatasourceType,
  { fa: React.ReactElement; logo: React.ReactElement }
> = {
  [DatasourceType.FacebookAds]: {
    fa: <FacebookFaIcon />,
    logo: <LogoFacebookAdsIcon />,
  },
  [DatasourceType.GoogleAds]: {
    fa: <GoogleAdsFaIcon />,
    logo: <LogoGoogleAdsIcon />,
  },
  [DatasourceType.TikTokAds]: {
    fa: <TikTokFaIcon />,
    logo: <TikTokIcon />,
  },
  [DatasourceType.GoogleAnalytics4]: {
    fa: <GoogleAnalyticsFaIcon />,
    logo: <LogoGoogleAnalytics4Icon />,
  },
  [DatasourceType.YahooSearchAds]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooSearchAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
  [DatasourceType.YahooDisplayAds]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooDisplayAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
};

export const getDatasourceIcon = (
  datasource: DatasourceType,
  type: 'fa' | 'logo',
  props?: SVGProps<SVGElement>
) => {
  return cloneElement(DATASOURCE_ICON[datasource][type], props);
};

export const isAdvertisementDatasource = (value: DatasourceType) => {
  return AdvertisementDatasources.includes(value);
};

export const isAnalyticsDatasource = (value: DatasourceType) => {
  return AnalyticsDatasources.includes(value);
};
