import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultRangeValue, GetDefaultDateRangeOptions, today } from '@any-ui-react/dates';

import { LocaleUtils, TimezoneUtils } from '../utils';

enum CustomRangeValue {
  LAST_90_DAYS = 'LAST_90_DAYS',
}
const RangeValue = { ...DefaultRangeValue, ...CustomRangeValue } as const;
type RangeValue = (typeof RangeValue)[keyof typeof RangeValue];

export const useDateRangeListOptions = ({
  locale = LocaleUtils.getCurrentLocale(),
  timezone = TimezoneUtils.getCurrentTimezone(),
  only,
}: {
  locale?: string;
  timezone?: string;
  only?: RangeValue[];
} = {}) => {
  const { t } = useTranslation();

  const { LAST_7_DAYS, LAST_30_DAYS } = GetDefaultDateRangeOptions(locale, timezone);
  const yesterday = today(timezone).subtract({ days: 1 });

  const customOptions = {
    [RangeValue.LAST_7_DAYS]: {
      ...LAST_7_DAYS,
      end: yesterday,
    },
    [RangeValue.LAST_30_DAYS]: {
      ...LAST_30_DAYS,
      end: yesterday,
    },
    [RangeValue.LAST_90_DAYS]: {
      start: today(timezone).subtract({ days: 91 }),
      end: yesterday,
    },
  };

  const rangeOptions = {
    ...GetDefaultDateRangeOptions(locale, timezone),
    ...customOptions,
  };

  return useMemo(
    () =>
      [
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.TODAY }),
          value: rangeOptions.TODAY,
          key: RangeValue.TODAY,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.YESTERDAY }),
          value: rangeOptions.YESTERDAY,
          key: RangeValue.YESTERDAY,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_7_DAYS }),
          value: rangeOptions.LAST_7_DAYS,
          key: RangeValue.LAST_7_DAYS,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_30_DAYS }),
          value: rangeOptions.LAST_30_DAYS,
          key: RangeValue.LAST_30_DAYS,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_90_DAYS }),
          value: rangeOptions.LAST_90_DAYS,
          key: RangeValue.LAST_90_DAYS,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_WEEK }),
          value: rangeOptions.LAST_WEEK,
          key: RangeValue.LAST_WEEK,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_MONTH }),
          value: rangeOptions.LAST_MONTH,
          key: RangeValue.LAST_MONTH,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.LAST_YEAR }),
          value: rangeOptions.LAST_YEAR,
          key: RangeValue.LAST_YEAR,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.WEEK_TO_DATE }),
          value: rangeOptions.WEEK_TO_DATE,
          key: RangeValue.WEEK_TO_DATE,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.MONTH_TO_DATE }),
          value: rangeOptions.MONTH_TO_DATE,
          key: RangeValue.MONTH_TO_DATE,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.QUARTER_TO_DATE }),
          value: rangeOptions.QUARTER_TO_DATE,
          key: RangeValue.QUARTER_TO_DATE,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.YEAR_TO_DATE }),
          value: rangeOptions.YEAR_TO_DATE,
          key: RangeValue.YEAR_TO_DATE,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.QUARTER_1 }),
          value: rangeOptions.QUARTER_1,
          key: RangeValue.QUARTER_1,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.QUARTER_2 }),
          value: rangeOptions.QUARTER_2,
          key: RangeValue.QUARTER_2,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.QUARTER_3 }),
          value: rangeOptions.QUARTER_3,
          key: RangeValue.QUARTER_3,
        },
        {
          label: t('shared.date.range', { ns: 'shared', range: RangeValue.QUARTER_4 }),
          value: rangeOptions.QUARTER_4,
          key: RangeValue.QUARTER_4,
        },
      ].filter((range) => ((only || []).length > 0 ? only?.includes(range.key) : true)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [only, t]
  );
};
