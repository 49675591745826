import { IdToken } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

export class AuthTokenUtils {
  static set(authorization: string, idToken?: IdToken) {
    const { __raw, exp = 0 } = idToken || { __raw: '' };

    Cookies.set('authorization', authorization, {
      expires: exp * 1000,
    });

    Cookies.set('identity', __raw, {
      expires: exp * 1000,
    });
  }

  static clear() {
    Cookies.remove('identity');
    Cookies.remove('authorization');
  }

  static get() {
    return {
      authorization: Cookies.get('authorization'),
      identity: Cookies.get('identity'),
    };
  }
}
