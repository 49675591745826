import { t } from 'i18next';
import z from 'zod';

const ERROR_NAME = 'AnyXError';

export type ErrorMap<ErrorCode extends string> = {
  readonly [K in ErrorCode]: string;
};

export interface ErrorData {
  [key: string]: unknown;
}

export class AnyXError extends Error {
  /** The custom name for all AnyXError. */
  override readonly name: string = ERROR_NAME;

  constructor(
    /** The error code for this error. */
    readonly code: string,
    /** The error message for this error. */
    message?: string,
    /** Custom data for this error. */
    public customData?: Record<string, unknown>
  ) {
    super(message || code);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AnyXError);
    }
  }
}

export class ErrorFactory<
  ErrorCode extends string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ErrorParams extends { readonly [K in ErrorCode]?: ErrorData } = {}
> {
  constructor(
    /** i18n namespace */
    private readonly namespace: string,
    private readonly errors: Partial<ErrorMap<ErrorCode>>,
    private readonly fallbackError?: string
  ) {}

  create<K extends ErrorCode>(
    code: K,
    ...data: K extends keyof ErrorParams ? [ErrorParams[K]] : []
  ): AnyXError {
    const customData = (data[0] as ErrorData) || {};
    const key = this.errors[code];

    const translatedMessage = key ? translateKey(key, this.namespace, customData) : code;
    const errorMessage = this.fallbackError ?? code;

    const message = translatedMessage === key ? errorMessage : translatedMessage;

    return new AnyXError(code, message, customData);
  }
}

const i18nOptionsSchema = z.object({
  i18nOptions: z.record(z.any()),
});

function translateKey(key: string, ns: string, data: ErrorData): string {
  try {
    const options = i18nOptionsSchema.parse(data);

    return t(key, { ns, ...options.i18nOptions });
  } catch (error) {
    return t(key, { ns });
  }
}
