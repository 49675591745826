import { useTranslation } from 'react-i18next';

import { Address } from '~anyx/shared/utils';

export const CountryDisplay = ({ address }: { address: Address; hasPrevious: boolean }) => {
  const { t } = useTranslation();
  return address.country ? (
    <span>{t('shared.enum.country', { ns: 'shared', country: address.country })}</span>
  ) : null;
};
