import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLFormattedError } from 'graphql';

import { toast } from '~anyx/shared/ui';

import { AlertType } from '../models';
import { GqlErrors } from '../utils';

import { useApiTranslationMapper } from './useApiTranslationMapper';

/**
 * Transforms all arrays in the given metadata object into comma-separated strings.
 *
 * @param {Record<string, unknown>} metadata - The object containing key-value pairs, where some values may be arrays.
 * @returns {Record<string, unknown>} - A new object with arrays transformed into comma-separated strings.
 *
 * @example
 * const metadata = { productCodes: ['P001', 'P002'], otherField: 'value' };
 * const result = transformArraysToString(metadata);
 * result: { productCodes: 'P001, P002', otherField: 'value' }
 */
export const transformArraysToString = (
  metadata: Record<string, unknown>
): Record<string, unknown> => {
  return Object.keys(metadata).reduce((acc, key) => {
    acc[key] = Array.isArray(metadata[key])
      ? (metadata[key] as unknown[]).join(', ')
      : metadata[key];
    return acc;
  }, {} as Record<string, unknown>);
};

export const useGraphQLValidationError = (key = 'VALIDATION_ERROR') => {
  const { getTranslationKey, getErrorMessage } = useApiTranslationMapper();

  const getGraphQLValidationError = useCallback(
    (
      graphQLErrors: readonly GraphQLFormattedError[],
      path: string,
      formatter: (
        errorCode: string,
        metadata: Record<string, unknown>
      ) => Record<string, unknown> = (e, m) => m
    ) => {
      const validationError = GqlErrors.extractGraphqlError(graphQLErrors, key);

      if (validationError) {
        const extensionsErrors = validationError.extensions?.['errors'];
        const gqlValidationErrors = Array.isArray(extensionsErrors) ? extensionsErrors : [];

        const validationErrors = gqlValidationErrors.map(({ message, metadata }) =>
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path,
            message,
            options: formatter(message, metadata),
          })
        );

        return validationErrors;
      }
      return [];
    },
    [getTranslationKey, key]
  );

  // Retrieve validation errors from unknown error, translate and return success or failure with the mapping
  // could be put somewhere else but for now I put here
  function handleValidationErrors(
    e: unknown,
    path: string,
    formatter: (errorCode: string, metadata: Record<string, unknown>) => Record<string, unknown> = (
      e,
      m
    ) => m
  ) {
    if (e instanceof ApolloError) {
      const { graphQLErrors } = e;
      const validationErrors = getGraphQLValidationError(graphQLErrors, path, formatter);

      if (validationErrors.length > 0) {
        return {
          success: false,
          validationErrors,
        };
      } else {
        graphQLErrors.map((error) =>
          toast.error(
            getTranslationKey({
              namespace: 'gql',
              type: AlertType.ERROR,
              path,
              message: error?.message,
            })
          )
        );

        return {
          success: false,
        };
      }
    } else {
      Sentry.captureException(e);
      toast.error(
        getTranslationKey({
          namespace: 'gql',
          type: AlertType.ERROR,
          path,
          message: getErrorMessage(e),
        })
      );
      return { success: false };
    }
  }

  return { getGraphQLValidationError, handleValidationErrors };
};
