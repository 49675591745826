import i18n from 'i18next';

export class GeoUtils {
  static shouldShowInEnglish(country: string, countryLocale?: string) {
    // Ideally the country should always have a locale,
    // but BE for some reason might not returning it so fallback as "en"
    if (!countryLocale) {
      return true;
    }
    // Convert any locale to country code based format
    // e.g. zh or zh-Hant to zh-CN
    const provinceLang = `${countryLocale.split('-')[0]?.toLowerCase()}-${country}`;
    // If it is domestic, return original locale
    if (provinceLang === i18n.language) {
      return false;
    }
    // If it is international, return English
    return true;
  }
}
