import { createSearchParams, Params } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import isURL from 'validator/lib/isURL';

export class RouterUtils {
  static getAllSearchParams(url: URLSearchParams) {
    const params: Record<string, string> = {};
    for (const [key, value] of url.entries()) {
      params[key] = value;
    }
    return params;
  }

  static encodeSearchParams = (params: Record<string, unknown>) => {
    const newFilter: Record<string, string> = {};
    Object.keys(params).forEach((key) => {
      // Remove undefined keys
      if (!params[key]) {
        return;
      }

      try {
        // we don't stringify strings with quotes unless for numeric-like
        // for example:
        // "abc" => param=abc
        // "123" => param="123"
        // 123 => param=123
        if (typeof params[key] === 'string' && !/^\d+$/.test(String(params[key]))) {
          newFilter[key] = String(params[key]);
        } else {
          newFilter[key] = JSON.stringify(params[key]);
        }
      } catch {
        console.error(`Filters : Skipped ${key} with content "${params[key]}"`);
      }
    });
    return createSearchParams(newFilter);
  };

  static decodeSearchParams = (searchParams: URLSearchParams) => {
    return [...searchParams.entries()].reduce((acc, [key, val]) => {
      try {
        return {
          ...acc,
          [key]: JSON.parse(val),
        };
      } catch {
        return {
          ...acc,
          [key]: val,
        };
      }
    }, {});
  };

  // from a list of path for exemple /store/1060/integrations
  // replace params with the id, so we can match to our path
  // /store/:masterDataStoreId/integrations
  static replaceParamsWithId = (path: string, params: Params<string>) => {
    let pathName = path;
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        pathName = pathName.replace(value, `:${key}`);
      }
    });
    return pathName;
  };

  static externalRedirect = (url: string | null | undefined, inNewTab = false) => {
    if (typeof url !== 'string') {
      const error = new Error('URL is undefined in external redirect');
      Sentry.captureException(error);
      throw error;
    }
    const isValidUrl = isURL(url, {
      protocols: ['https'],
      require_protocol: true,
    });
    if (!isValidUrl) {
      const error = new Error(`URL is invalid for external redirection: ${url}`);
      Sentry.captureException(error);
      throw error;
    }
    if (inNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  };
}
