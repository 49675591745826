import { useCallback } from 'react';

import { LazyQueryHookOptions } from '@apollo/client';

import {
  MdAccountSelectQuery,
  MdAccountSelectQueryVariables,
  useMdAccountSelectLazyQuery,
} from '../graphql';

export type MdAccountSelectQueryOptions = LazyQueryHookOptions<
  MdAccountSelectQuery,
  MdAccountSelectQueryVariables
>;
export const useAccountSelect = () => {
  const [queryMdAccount, { loading }] = useMdAccountSelectLazyQuery();

  const getAccountDetails = useCallback(
    async ({ accountId }: { accountId: string }) => {
      if (!accountId) return;

      const { data } = await queryMdAccount({
        variables: {
          id: accountId,
        },
      });
      return data?.MdAccount;
    },
    [queryMdAccount]
  );

  return {
    loading,
    getAccountDetails,
  };
};
