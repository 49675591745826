import React from 'react';

import {
  facebookRouting,
  googleAdsRouting,
  tikTokRouting,
  yahooDisplayRouting,
  yahooSearchRouting,
} from '~anyx/integrations/advertisement';

const AdvertisementSelectPage = React.lazy(() =>
  import('./select').then((module) => ({ default: module.AdvertisementSelectPage }))
);

export const advertisementIntegrationRoutes = {
  path: 'advertisement-v2',
  children: [
    {
      index: true,
      element: <AdvertisementSelectPage />,
    },
    ...facebookRouting,
    ...googleAdsRouting,
    ...tikTokRouting,
    ...yahooSearchRouting,
    ...yahooDisplayRouting,
  ],
};
