import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';

import { TextInputProps, useCombobox, Combobox, TextInput } from '@any-ui-react/core';
import i18n from 'i18next';

import {
  AddressPart,
  GeoUtils,
  GoogleLocationServiceUtils,
  ILocationServiceUtils,
} from '~anyx/shared/utils';

import { buildAddress } from './buildAddress';
import classes from './InputPostalCode.module.css';
import { InputPostalCodeFooter } from './InputPostalCodeFooter';
import { InputPostalCodeNoOptions } from './InputPostalCodeNoOptions';

export interface AutoCompleteResult {
  [AddressPart.POSTAL_CODE]: string;
  [AddressPart.REGION]: string;
  [AddressPart.CITY]: string;
  [AddressPart.ADDRESS_FIRST]: string;
  [AddressPart.ADDRESS_SECOND]: string;
}

export interface InputPostalCodeProps extends TextInputProps {
  country?: string;
  withAutoComplete?: boolean;
  onAutoComplete?: (result: AutoCompleteResult) => void;
  utils?: ILocationServiceUtils;
}

export const InputPostalCode = forwardRef<HTMLInputElement, InputPostalCodeProps>(
  (
    {
      country,
      utils = new GoogleLocationServiceUtils(),
      withAutoComplete,
      onChange,
      onAutoComplete,
      value,
      ...rest
    },
    ref
  ) => {
    const combobox = useCombobox();
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState<string | null>(null);
    const [predictionResults, setPredictionResults] = useState<
      google.maps.places.AutocompletePrediction[]
    >([]);

    const init = useCallback(async () => {
      if (!country) return;

      try {
        const [province] = await utils.getProvinceOptions(country);
        if (!province) return;

        const showInEnglish = await GeoUtils.shouldShowInEnglish(country, province.lang);

        setLanguage(showInEnglish ? 'en-US' : i18n.language);
      } catch (e) {
        setLanguage('en-US');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    const fetchPredictions = useCallback(
      async (inputValue: string) => {
        if (!inputValue || !country || !language) {
          setPredictionResults([]);
          return;
        }

        setLoading(true);

        const predictions = await utils.fetchPredictions(country, inputValue, language);
        if (predictions) {
          setPredictionResults(predictions);
        }

        setLoading(false);
      },
      [country, language, utils]
    );

    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        fetchPredictions(e.target.value);
      },
      [fetchPredictions, onChange]
    );

    const onSelectOption = useCallback(
      async (placeId: string) => {
        if (!country || !language) {
          return;
        }

        const result = await utils.handleAutoComplete(placeId, language);
        if (result) {
          const completion = buildAddress(country, result);
          onAutoComplete?.(completion);
        }
      },
      [country, language, onAutoComplete, utils]
    );

    useEffect(() => {
      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Combobox store={combobox}>
        <Combobox.Target>
          <TextInput
            {...rest}
            ref={ref}
            type="text"
            value={value}
            onClick={() => combobox.toggleDropdown()}
            onChange={(e) => onInputChange(e)}
          />
        </Combobox.Target>
        <Combobox.Dropdown hidden={!withAutoComplete}>
          {!loading && predictionResults.length === 0 && <InputPostalCodeNoOptions />}
          {!loading && predictionResults.length > 0 && (
            <>
              <Combobox.Options className="overflow-x-auto">
                {predictionResults.map(({ description, place_id: placeId }) => (
                  <Combobox.Option
                    key={placeId}
                    value={placeId}
                    onClick={() => {
                      onSelectOption(placeId);
                      combobox.closeDropdown();
                    }}
                    className={classes['option']}
                  >
                    {description}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
              <InputPostalCodeFooter />
            </>
          )}
        </Combobox.Dropdown>
      </Combobox>
    );
  }
);
