import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';

import { HomePage } from './pages';
import { HomeTemplate } from './templates';

export const routes: RouteObject[] = [
  {
    path: 'home',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<Navigate to={'/'} />}
        >
          <HomeTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      { element: <Navigate to="/home" replace={true} /> },
    ],
  },
];
