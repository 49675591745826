import { useState } from 'react';

import i18n from 'i18next';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _jipt: unknown[];
  }
}

export interface CrowdinInContextProps {
  projectId: string;
  pseudoLanguage: string;
}

export const CrowdinInContext = ({ projectId, pseudoLanguage }: CrowdinInContextProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadAndTranslate = () => {
    if (isLoaded) {
      return null;
    }
    window._jipt = [];
    window._jipt.push(['project', projectId]);
    const script = document.createElement('script');
    script.src = '//cdn.crowdin.com/jipt/jipt.js';
    script.async = true;
    document.body.appendChild(script);
    setIsLoaded(true);
    script.onload = () => {
      // Forced change language because it's a pseudo language and not part of supported languages
      i18n.changeLanguage(pseudoLanguage);
    };

    return () => {
      document.body?.removeChild(script);
    };
  };

  return (
    <button className="absolute right-0 top-0" onClick={() => loadAndTranslate()}>
      Translate
    </button>
  );
};
