import { cloneElement } from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';

import { AddressPart } from '~anyx/shared/utils';

import {
  CountryFieldProps,
  RegionFieldProps,
  PhoneFieldProps,
  PostalCodeFieldProps,
  LastNameFieldProps,
  FirstNameFieldProps,
  CompanyFieldProps,
  CityFieldProps,
  AddressFirstFieldProps,
  AddressSecondFieldProps,
  ADDRESS_COMPONENTS,
} from './fields';

export type FunctionalAddressPart = Exclude<AddressPart, AddressPart.BREAK>;

export type AddressMappings<TFieldValues extends FieldValues> = {
  [k in FunctionalAddressPart]?: FieldPath<TFieldValues>;
} & {
  [AddressPart.COUNTRY]: FieldPath<TFieldValues>;
};

export type AddressPartToPropsMap = {
  [AddressPart.COUNTRY]: CountryFieldProps;
  [AddressPart.REGION]: RegionFieldProps;
  [AddressPart.PHONE]: PhoneFieldProps;
  [AddressPart.POSTAL_CODE]: PostalCodeFieldProps;
  [AddressPart.LASTNAME]: LastNameFieldProps;
  [AddressPart.FIRSTNAME]: FirstNameFieldProps;
  [AddressPart.COMPANY]: CompanyFieldProps;
  [AddressPart.CITY]: CityFieldProps;
  [AddressPart.ADDRESS_FIRST]: AddressFirstFieldProps;
  [AddressPart.ADDRESS_SECOND]: AddressSecondFieldProps;
};

type EditableProps = Omit<
  AddressPartToPropsMap[FunctionalAddressPart],
  'handleInputChange' | 'selectedCountry' | 'language' | 'countries'
>;

export type ComponentsProps = {
  [K in FunctionalAddressPart]?: EditableProps;
};

export type ComponentsOverride = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in FunctionalAddressPart]?: JSX.Element;
};

export function renderComponent(
  part: FunctionalAddressPart,
  specificProps?: Record<string, unknown>,
  componentsOverride?: ComponentsOverride
) {
  const override = componentsOverride?.[part];
  if (override) {
    return cloneElement(override, { key: part });
  }
  const Component = ADDRESS_COMPONENTS[part];
  return <Component {...specificProps} key={part} />;
}
