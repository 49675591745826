import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCsvUploadTemplateQueryVariables = Types.Exact<{
  language: Types.Language;
  resource: Types.Resource;
  operation: Types.CsvImportOperation;
}>;


export type GetCsvUploadTemplateQuery = { readonly getCsvUploadTemplate: { readonly __typename?: 'CsvTemplate', readonly columns: ReadonlyArray<string>, readonly filename: string } };


export const GetCsvUploadTemplateDocument = gql`
    query GetCsvUploadTemplate($language: Language!, $resource: Resource!, $operation: CsvImportOperation!) {
  getCsvUploadTemplate(
    language: $language
    resource: $resource
    operation: $operation
  ) {
    columns
    filename
  }
}
    `;

/**
 * __useGetCsvUploadTemplateQuery__
 *
 * To run a query within a React component, call `useGetCsvUploadTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvUploadTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvUploadTemplateQuery({
 *   variables: {
 *      language: // value for 'language'
 *      resource: // value for 'resource'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useGetCsvUploadTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables> & ({ variables: GetCsvUploadTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>(GetCsvUploadTemplateDocument, options);
      }
export function useGetCsvUploadTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>(GetCsvUploadTemplateDocument, options);
        }
export function useGetCsvUploadTemplateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>(GetCsvUploadTemplateDocument, options);
        }
export type GetCsvUploadTemplateQueryHookResult = ReturnType<typeof useGetCsvUploadTemplateQuery>;
export type GetCsvUploadTemplateLazyQueryHookResult = ReturnType<typeof useGetCsvUploadTemplateLazyQuery>;
export type GetCsvUploadTemplateSuspenseQueryHookResult = ReturnType<typeof useGetCsvUploadTemplateSuspenseQuery>;
export type GetCsvUploadTemplateQueryResult = Apollo.QueryResult<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>;
export function refetchGetCsvUploadTemplateQuery(variables: GetCsvUploadTemplateQueryVariables) {
      return { query: GetCsvUploadTemplateDocument, variables: variables }
    }