import { workflowPaths } from '~anyx/function-workflow/shared';
import { WorkflowIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

export type WithWorkspaceWorkflow = {
  canUseWorkflow: boolean;
  storeId?: string;
};

/*
 *
 * Get Workflow links when a workspace is selected
 * */
const getWorkflowWithWorkspace = ({
  canUseWorkflow,
  storeId,
}: WithWorkspaceWorkflow): RouteLinks[] => {
  if (!canUseWorkflow) return [];

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'shared.entity.workflow',
          options: { count: 1, ns: 'shared' },
        },
        icon: (props) => <WorkflowIcon {...props} />,
        path: workflowPaths(storeId).core().list().path,
        activePath: workflowPaths().core().path,
      },
    },
  ];
};

type WorkflowLinks = {
  withWorkspace: (props: WithWorkspaceWorkflow) => RouteLinks[];
};

export const workflowLinks: WorkflowLinks = {
  withWorkspace: getWorkflowWithWorkspace,
};
