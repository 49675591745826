import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshFacebookTokenConnectionMutationVariables = Types.Exact<{
  input: Types.ConnectToFacebookRequestV2Input;
}>;


export type RefreshFacebookTokenConnectionMutation = { readonly RefreshFacebookTokenConnection?: { readonly __typename?: 'NoInstagramAccountRefreshTokenError', readonly message: string } | { readonly __typename?: 'RefreshFacebookTokenResponseSuccess', readonly connectionId: string } | { readonly __typename?: 'UnknownErrorRefreshTokenError', readonly message: string } | { readonly __typename?: 'WrongPageSelectedRefreshTokenError', readonly message: string, readonly pageId: string } | null };


export const RefreshFacebookTokenConnectionDocument = gql`
    mutation RefreshFacebookTokenConnection($input: ConnectToFacebookRequestV2Input!) {
  RefreshFacebookTokenConnection(input: $input) {
    ... on NoInstagramAccountRefreshTokenError {
      message
    }
    ... on RefreshFacebookTokenResponseSuccess {
      connectionId
    }
    ... on UnknownErrorRefreshTokenError {
      message
    }
    ... on WrongPageSelectedRefreshTokenError {
      message
      pageId
    }
  }
}
    `;
export type RefreshFacebookTokenConnectionMutationFn = Apollo.MutationFunction<RefreshFacebookTokenConnectionMutation, RefreshFacebookTokenConnectionMutationVariables>;

/**
 * __useRefreshFacebookTokenConnectionMutation__
 *
 * To run a mutation, you first call `useRefreshFacebookTokenConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshFacebookTokenConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshFacebookTokenConnectionMutation, { data, loading, error }] = useRefreshFacebookTokenConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshFacebookTokenConnectionMutation(baseOptions?: Apollo.MutationHookOptions<RefreshFacebookTokenConnectionMutation, RefreshFacebookTokenConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshFacebookTokenConnectionMutation, RefreshFacebookTokenConnectionMutationVariables>(RefreshFacebookTokenConnectionDocument, options);
      }
export type RefreshFacebookTokenConnectionMutationHookResult = ReturnType<typeof useRefreshFacebookTokenConnectionMutation>;
export type RefreshFacebookTokenConnectionMutationResult = Apollo.MutationResult<RefreshFacebookTokenConnectionMutation>;
export type RefreshFacebookTokenConnectionMutationOptions = Apollo.BaseMutationOptions<RefreshFacebookTokenConnectionMutation, RefreshFacebookTokenConnectionMutationVariables>;