import { MdAccountProduct } from '~anyx/shared/graphql';

import { Theme } from '../themes';

export class ThemeUtils {
  static getProductTheme = (product: MdAccountProduct) => {
    switch (product) {
      case MdAccountProduct.ANYLOGI:
        return Theme.ANYLOGI;
      case MdAccountProduct.ANYCHAT:
        return Theme.ANYCHAT;
      default:
        return Theme.ANYX;
    }
  };

  static getAppName = (product: MdAccountProduct) => {
    switch (product) {
      case MdAccountProduct.ANYLOGI:
        return 'AnyLogi';
      case MdAccountProduct.ANYCHAT:
        return 'AnyChat';
      default:
        return 'AnyX';
    }
  };
}
