import { PropsWithChildren, ReactElement, useMemo } from 'react';

import { TableLayout } from '../Table';

export interface TableMobileProps<T extends Record<string, unknown>> {
  data?: ReadonlyArray<T>;
  layout?: ReadonlyArray<TableLayout<T>>;
  onClick?: (row: T) => void;
  mobileTemplate: (element: T, rowIndex: number) => ReactElement;
  mobileTemplateFooter?: () => ReactElement;
}

export function TableMobile<T extends Record<string, unknown>>({
  data,
  layout,
  mobileTemplate,
  mobileTemplateFooter,
}: PropsWithChildren<TableMobileProps<T>>): ReactElement {
  const tableData = useMemo(() => data || [], [data]);
  const hasFooter = layout?.some((item) => item.footer);

  return (
    <div className="border-t">
      {tableData.map((row, i) => mobileTemplate(row, i))}
      {hasFooter && mobileTemplateFooter?.()}
    </div>
  );
}
