import isPostalCodeValidator, { PostalCodeLocale } from 'validator/lib/isPostalCode';

import { CountriesEnum } from '../enums';

export class PostalCodeUtils {
  static SUPPORTED_POSTAL_CODE_LOCALE: PostalCodeLocale[] = [
    'AD',
    'AT',
    'AU',
    'BE',
    'BG',
    'BR',
    'CA',
    'CH',
    'CN',
    'CZ',
    'DE',
    'DK',
    'DZ',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'ID',
    'IE',
    'IL',
    'IN',
    'IS',
    'IT',
    'JP',
    'KE',
    'KR',
    'LI',
    'LT',
    'LU',
    'LV',
    'MX',
    'MT',
    'NL',
    'NO',
    'NZ',
    'PL',
    'PR',
    'PT',
    'RO',
    'RU',
    'SA',
    'SE',
    'SI',
    'SK',
    'TN',
    'TW',
    'UA',
    'US',
    'ZA',
    'ZM',
  ];

  static isSupportedPostalCode = (country = ''): country is PostalCodeLocale =>
    PostalCodeUtils.SUPPORTED_POSTAL_CODE_LOCALE.includes(country as PostalCodeLocale);

  static asSupportedPostalCode = (country?: string) =>
    country && this.isSupportedPostalCode(country) ? country : 'any';

  static isPostalCode(code?: string | null, country?: string | null | CountriesEnum) {
    if (!code || !country) return false;
    if (!this.isSupportedPostalCode(country)) return true;

    try {
      return isPostalCodeValidator(code, PostalCodeUtils.asSupportedPostalCode(country));
    } catch {
      return false;
    }
  }
}
