import { useMemo, useState } from 'react';

import classNames from 'classnames';
import {
  PieChart as RechartPieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
  SectorProps,
  PieProps,
} from 'recharts';

import { DEFAULT_ANIMATION_DURATION } from '~anyx/shared/utils';

import styles from './chart.module.css';
import { SingleValueTooltip } from './SingleValueTooltip';

const DEFAULT_WIDTH = 50;
const DEFAULT_HEIGHT = 50;
const DEFAULT_START_ANGLE = 90;
const DEFAULT_END_ANGLE = 450;

const renderActiveShape = (props: SectorProps) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius && innerRadius - 0.5}
        outerRadius={outerRadius && outerRadius + 0.5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius && outerRadius + 12}
        opacity={0.2}
        fill={fill}
      />
    </g>
  );
};

interface PieChartProps<T extends Record<string, unknown>> {
  data?: Array<T>;
  dataKeys?: Array<string>;
  colors: Record<string, string>;
  width?: number;
  height?: number;
  isAnimationActive?: boolean;
  innerRadius: number;
  outerRadius: number;
  onClickPie?: (data: unknown) => void;
  dataKeyTranslation?: (dataKey?: string | number) => string;
  className?: string;
}

export const PieChart = <T extends Record<string, unknown>>({
  data,
  dataKeys,
  colors,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  innerRadius,
  outerRadius,
  isAnimationActive = false,
  onClickPie,
  dataKeyTranslation,
  className,
}: PieChartProps<T>) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const onPieEnter = (_: PieProps, index: number) => {
    setActiveIndex(index);
  };
  const onPieLeave = () => {
    setActiveIndex(undefined);
  };

  const pieData = useMemo(() => data || [], [data]);
  return (
    <div className={classNames(styles['chart-wrapper'], className)}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartPieChart width={width} height={height}>
          <Pie
            dataKey="value"
            data={pieData}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            startAngle={DEFAULT_START_ANGLE}
            endAngle={DEFAULT_END_ANGLE}
            animationDuration={DEFAULT_ANIMATION_DURATION}
            isAnimationActive={isAnimationActive}
            onMouseDown={onClickPie}
            className={classNames({
              'cursor-pointer': onClickPie,
            })}
          >
            {dataKeys?.map((dataKey, i) => (
              <Cell key={`cell-${i}`} stroke={colors[dataKey]} fill={colors[dataKey]} />
            ))}
          </Pie>
          <Tooltip
            cursor={{
              stroke: '#DEE5EC',
              strokeWidth: 1,
              opacity: 0.2,
            }}
            wrapperStyle={{
              outline: 'none',
            }}
            allowEscapeViewBox={{ x: false, y: true }}
            content={<SingleValueTooltip dataKeyTranslation={dataKeyTranslation} />}
          />
        </RechartPieChart>
      </ResponsiveContainer>
    </div>
  );
};
