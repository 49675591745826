import { ReportAiRoutePath } from '~anyx/common/routing';
import { AiChatIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

export type WithWorkspaceAi = {
  canSeeAi: boolean;
  storeId?: string;
};

const buildAiLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'report.page.aiChat.menu.title',
    options: { ns: 'report' },
  },
  icon: (props) => <AiChatIcon {...props} />,
  path: ReportAiRoutePath({ workspaceStoreId: storeId }).chat().path,
  activePath: ReportAiRoutePath({ workspaceStoreId: null }).chat().pattern,
});

const getAiLinks = ({ canSeeAi, storeId }: WithWorkspaceAi): RouteLinks[] => {
  if (!canSeeAi) return [];

  const aiLink = buildAiLink(storeId);

  return [
    {
      category: {
        ...aiLink,
      },
    },
  ];
};

type AiLinks = LinkAdapter<WithWorkspaceAi>;

export const aiLinks: AiLinks = {
  noWorkspace: () => [],
  withWorkspace: getAiLinks,
};
