import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { UserRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const UserListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.UserListPage }))
);
const UserAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.UserAddPage }))
);
const UserEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.UserEditPage }))
);
const UserTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.UserTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'user',
    element: (
      <Auth0Guard>
        <Restricted
          restrictions={[[Auth0Permissions.RESTRICTION_SEE_USERS]]}
          fallback={<UnauthorizedMessage />}
        >
          <UserTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <UserListPage />,
      },
      {
        path: 'add',
        element: (
          <Restricted
            permissions={[[Auth0Permissions.CAN_MANAGE_USERS]]}
            fallback={<UnauthorizedMessage />}
          >
            <UserAddPage />
          </Restricted>
        ),
      },
      {
        path: 'self',
        element: <UserEditPage isOnSelfPage />,
      },
      {
        path: ':masterDataUserId',
        element: <UserEditPage />,
      },
      {
        index: true,
        element: <Navigate to={UserRoutePath().list().path} replace={true} />,
      },
    ],
  },
];
