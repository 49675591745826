import { RouteObject } from 'react-router-dom';

import { routes as CourierModuleRoutes } from './modules/couriers/routing';
import { routes as PickupModuleRoutes } from './modules/pickups/routing';
import { routes as ShippingModuleRoutes } from './modules/settings/routing';
import { routes as ShipmentsModuleRoutes } from './modules/shipments/routing';
import { routes as ShipperModuleRoutes } from './modules/shipper/routing';

export const routes: RouteObject[] = [
  ...ShippingModuleRoutes,
  ...ShipperModuleRoutes,
  ...ShipmentsModuleRoutes,
  ...PickupModuleRoutes,
  ...CourierModuleRoutes,
];
