import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RakutenSalesReportTableQueryVariables = Types.Exact<{
  input: Types.RakutenSalesReportTableInput;
}>;


export type RakutenSalesReportTableQuery = { readonly rakutenSalesReportTable: { readonly __typename?: 'RakutenSalesReportTablePayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'RakutenSalesReportTablePayload', readonly conversionRate: string, readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly orderCount: number, readonly sessions: number, readonly totalSales: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'RakutenSalesReportTotalPayload', readonly conversionRate: string, readonly orderCount: number, readonly sessions: number, readonly totalSales: string } } };


export const RakutenSalesReportTableDocument = gql`
    query RakutenSalesReportTable($input: RakutenSalesReportTableInput!) {
  rakutenSalesReportTable(input: $input) {
    items {
      conversionRate
      currency
      date
      orderCount
      sessions
      totalSales
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      conversionRate
      orderCount
      sessions
      totalSales
    }
  }
}
    `;

/**
 * __useRakutenSalesReportTableQuery__
 *
 * To run a query within a React component, call `useRakutenSalesReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRakutenSalesReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRakutenSalesReportTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRakutenSalesReportTableQuery(baseOptions: Apollo.QueryHookOptions<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables> & ({ variables: RakutenSalesReportTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>(RakutenSalesReportTableDocument, options);
      }
export function useRakutenSalesReportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>(RakutenSalesReportTableDocument, options);
        }
export function useRakutenSalesReportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>(RakutenSalesReportTableDocument, options);
        }
export type RakutenSalesReportTableQueryHookResult = ReturnType<typeof useRakutenSalesReportTableQuery>;
export type RakutenSalesReportTableLazyQueryHookResult = ReturnType<typeof useRakutenSalesReportTableLazyQuery>;
export type RakutenSalesReportTableSuspenseQueryHookResult = ReturnType<typeof useRakutenSalesReportTableSuspenseQuery>;
export type RakutenSalesReportTableQueryResult = Apollo.QueryResult<RakutenSalesReportTableQuery, RakutenSalesReportTableQueryVariables>;
export function refetchRakutenSalesReportTableQuery(variables: RakutenSalesReportTableQueryVariables) {
      return { query: RakutenSalesReportTableDocument, variables: variables }
    }