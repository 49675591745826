import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { ReportSalesChannelRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const SalesChannelReportPage = React.lazy(() =>
  import('./pages').then((module) => ({ default: module.SalesChannelReportPage }))
);

const SalesChannelTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.SalesChannelTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'sales-channel',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<UnauthorizedMessage />}
        >
          <SalesChannelTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <SalesChannelReportPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportSalesChannelRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
