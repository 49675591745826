import React from 'react';

import { DatasourceType } from '~anyx/shared/graphql';

const FacebookEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.FacebookEditPage,
  }))
);

const FacebookAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.FacebookAddPage,
  }))
);

const FacebookCampaignAdSetListPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-list').then((module) => ({
    default: module.FacebookCampaignAdSetListPage,
  }))
);

const FacebookCampaignAdSetEditPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-edit').then((module) => ({
    default: module.FacebookCampaignAdSetEditPage,
  }))
);

const FacebookTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.FacebookTemplate }))
);

export const facebookRouting = [
  {
    path: DatasourceType.FacebookAds,
    element: <FacebookTemplate />,
    children: [
      {
        index: true,
        element: <FacebookAddPage />,
      },
      {
        path: ':datasourceId',
        children: [
          {
            index: true,
            element: <FacebookEditPage />,
          },
          {
            path: 'campaign/:campaignId/adSet/:adSetMappingCampaignId',
            children: [
              {
                index: true,
                element: <FacebookCampaignAdSetListPage />,
              },
              {
                path: 'set',
                element: <FacebookCampaignAdSetEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
