import { MINIMAL_VIEWPORTS } from '@storybook/addon-viewport';

/**
 *
 * Unfortunately, addon-viewport does not export the valid sizes, so we need to enforce it manually.
 * These are the valid MINIMAL_VIEWPORTS keys:
 * https://github.com/storybookjs/storybook/blob/next/code/addons/viewport/src/defaults.ts#L231
 *
 * We also need to add a default (desktop) viewport, since the addon does not have one.
 * mobile1 (Small mobile) = 320 x 568
 * mobile2 (Large mobile) = 414 x 896
 * tablet = 1112 x 834
 * */

type Size = 'mobile1' | 'mobile2' | 'tablet' | 'desktop';

const desktopViewport = {
  viewport: 1200,
};

export const VIEWPORTS = Object.keys(MINIMAL_VIEWPORTS).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: {
        viewport: key,
      },
    };
  },
  {
    desktop: desktopViewport,
  } as { [k in Size]: { viewport: Size | number } }
);

export class ChromaticUtils {
  static getViewport(size: Size) {
    return VIEWPORTS[size];
  }

  static getDesktopViewport() {
    return {
      desktop: VIEWPORTS.desktop,
    };
  }

  static getMobileViewport() {
    return {
      mobile: VIEWPORTS.mobile1,
    };
  }

  static getTabletViewport() {
    return {
      tablet: VIEWPORTS.tablet,
    };
  }
}
