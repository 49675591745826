import { DdiHomeFilterInputFilterType } from '../components';
import { useDdiHomeReportChartQuery } from '../graphql';

interface UseDdiHomeReportChartProps {
  filters: DdiHomeFilterInputFilterType;
  skip: boolean;
}

export const useDdiHomeReportChart = ({ filters, skip }: UseDdiHomeReportChartProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useDdiHomeReportChartQuery({
    variables: {
      input: filterInput,
    },
    skip,
  });

  return {
    reportChart: data?.ddiHomeReportChart,
    loading,
    error,
    refetch,
  };
};
