import { AlertIcon } from '~anyx/shared/icons';

import { Card } from '../card';

export interface ErrorCardProps {
  title: string;
  guideline?: string;
  note?: string;
  content?: React.ReactNode;
  iconColor?: string;
}

export const ErrorCard = ({
  title,
  guideline,
  note,
  content,
  iconColor = 'fill-warning',
}: ErrorCardProps) => {
  return (
    <Card className="m-auto flex max-w-lg flex-col items-center">
      <AlertIcon className={iconColor} />
      <h2 className="pb-4 pt-5 font-semibold">{title}</h2>
      {guideline && <div className="pb-5 pt-4">{guideline}</div>}
      {note && <small className="mt-2 pb-5 font-semibold">{note}</small>}
      {content}
    </Card>
  );
};
