import { Language } from '~anyx/shared/graphql';
import { AnyXLanguage } from '~anyx/shared/utils';

export class GqlLanguages {
  static asLgsLanguage = (
    language: AnyXLanguage | string | null | undefined = AnyXLanguage.EN_US
  ): Language => {
    switch (language) {
      case AnyXLanguage.JA_JP:
        return Language.JA_JP;
      case AnyXLanguage.ZH_CN:
        return Language.ZH_CN;
      case AnyXLanguage.KO_KR:
        return Language.KO_KR;
      default:
        return Language.EN_US;
    }
  };
}

// TODO Move this somehwere but for now it make sense to keep it here
// as it is related to logistic language
export const shippingLocalizer = (lg: AnyXLanguage) => {
  return `/v/${lg.toLowerCase().replace('-', '_')}`;
};
