/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from 'react';

import { WorkspaceMode } from '../models';
import { useWorkspaceCtx } from '../store';

interface RestrictedWorkspaceModeProps {
  skip?: boolean;
  restrictedTo: WorkspaceMode[];
  fallback?: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

export const RestrictedWorkspaceMode = ({
  skip,
  restrictedTo,
  fallback,
  children,
}: React.PropsWithChildren<RestrictedWorkspaceModeProps>) => {
  const mode = useWorkspaceCtx((state) => state.mode);

  const isAllowed = useMemo(() => {
    return restrictedTo.includes(mode);
  }, [mode, restrictedTo]);

  if (skip || isAllowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
