import { useEffect, useMemo } from 'react';

import { DefaultRangeValue, today } from '@any-ui-react/dates';

import { HomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import {
  AdsMetricsType,
  ReportChartTab,
  ReportDimensions,
  ReportOrderStatus,
  ReportTab,
} from '~anyx/shared/graphql';
import { DateUtils, TimezoneUtils, useDateRangeListOptions, useFilters } from '~anyx/shared/utils';

import { HomeFilterInputFilterType, HomePageFiltersParser } from './homePage.filters';

export const useHomeFilter = ({
  accountIds,
  storeId,
}: {
  accountIds: string[];
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();
  const rangeList = useDateRangeListOptions({
    only: [DefaultRangeValue.LAST_7_DAYS],
  });
  const {
    value: { start, end },
  } = rangeList[0] || {
    value: { start: today(timezone), end: today(timezone) },
  };
  const { range } = useReportSyncCalendar();

  const filtersDefault = useMemo(
    () =>
      HomePageFiltersParser.parse({
        startDate: range.startDate ?? DateUtils.toISOStringStartOfDay(start),
        endDate: range.endDate ?? DateUtils.toISOStringEndOfDay(end),
        orderStatus: ReportOrderStatus.ORDERED,
        masterDataAccountIds: accountIds,
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: HomeMetricOption.GROSS_SALES,
        adsMetricsType: AdsMetricsType.GROSS,
        chartTab: ReportChartTab.CHANNEL,
        tab: ReportTab.ALL,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, storeId]
  );

  const filters = useFilters<HomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: HomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.endDate))
      : '',
    startDate: filters.current?.startDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.startDate))
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  // Reset the filter when default values are changed
  useEffect(() => {
    filters.resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDefault]);

  return {
    filters,
    filterInputs,
  };
};
