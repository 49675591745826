import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';

const ShopeeAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ShopeeAddPage }))
);

const ShopeeEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ShopeeEditPage }))
);

const ShopeeManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.ShopeeManualCostEditPage,
  }))
);

const ShopeeManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.ShopeeManualCostAddPage,
  }))
);

const ShopeeTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ShopeeTemplate }))
);

export const shopeeRouting = [
  {
    path: MarketplaceSaleChannelType.Shopee,
    element: <ShopeeTemplate />,
    children: [
      {
        index: true,
        element: <ShopeeAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <ShopeeManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <ShopeeManualCostEditPage />,
          },
          {
            index: true,
            element: <ShopeeEditPage />,
          },
        ],
      },
    ],
  },
];
