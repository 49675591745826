import { useState, useEffect, useCallback } from 'react';

import { useFetchCtx } from './FetchContext';

export const useFetchQuery = <T,>(
  url: string,
  {
    variables = {},
    lazy = false,
    options,
  }: {
    variables?: Record<string, unknown>;
    lazy?: boolean;
    options?: RequestInit & { responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer' };
  }
) => {
  const { fetcher } = useFetchCtx();
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const fetchData = useCallback(
    async ({ variables: fetchVariables }: { variables?: Record<string, unknown> } = {}) => {
      setLoading(true);
      setError(null);
      const runVariables = fetchVariables || variables;
      const queryString = new URLSearchParams(runVariables as Record<string, string>).toString();

      try {
        const response = await fetcher(`${url}${queryString ? `?${queryString}` : ''}`, options);

        let result;
        switch (options?.responseType) {
          case 'text':
            result = await response.text();
            break;
          case 'blob':
            result = await response.blob();
            break;
          case 'arrayBuffer':
            result = await response.arrayBuffer();
            break;
          case 'json':
          default:
            result = await response.json();
            break;
        }
        setData(result);
        return {
          headers: response.headers,
          status: response.status,
          data: result,
        };
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [fetcher, options, url, variables]
  );

  useEffect(() => {
    if (!lazy) {
      fetchData();
    }
  }, [fetchData, lazy]);

  return [fetchData, { data, loading, error }] as const;
};
