import React from 'react';
import { RouteObject } from 'react-router-dom';

const RichMenuListPage = React.lazy(() => import('./pages/list'));
const RichMenuAddPage = React.lazy(() => import('./pages/add'));
const RichMenuEditPage = React.lazy(() => import('./pages/edit'));
const RichMenuConnectLine = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.RichMenuConnectLine }))
);

export const routes: RouteObject[] = [
  {
    path: 'rich-menu',
    element: <RichMenuConnectLine />,
    children: [
      {
        index: true,
        element: <RichMenuListPage />,
      },
      {
        path: 'add',
        element: <RichMenuAddPage />,
      },
      {
        path: ':richMenuId',
        element: <RichMenuEditPage />,
      },
    ],
  },
];
