import { useCallback, useEffect, useState } from 'react';

interface UseDocumentHeadProps {
  appName?: string;
  pageTitle?: string;
}
export function useDocumentHead({ appName, pageTitle }: UseDocumentHeadProps) {
  const [documentTitle, setDocumentTitle] = useState(appName);

  useEffect(() => {
    if (pageTitle) {
      setDocumentTitle(`${pageTitle} | ${appName}`);
    } else {
      setDocumentTitle(appName);
    }
  }, [appName, pageTitle]);

  /**
   * getDocumentTitle - this function is for using inside render props component
   */
  const getDocumentTitle = useCallback(
    (title: string) => {
      if (title) {
        return `${title} | ${appName}`;
      }

      return appName;
    },
    [appName]
  );

  return { documentTitle, getDocumentTitle };
}
