import { t } from 'i18next';

import {
  AdsCostType,
  MarketplaceSaleChannelType,
  ReportDimensions,
  SaleChannelType,
} from '~anyx/shared/graphql';
import {
  AnyXLanguage,
  DateUtils,
  EMPTY_DEFAULT,
  FileRestriction,
  FilesUtils,
  NumberUtils,
  UploadDocumentType,
} from '~anyx/shared/utils';

export const REPORT_RESTRICTIONS: Record<MarketplaceSaleChannelType, FileRestriction> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: FilesUtils.getFileRestrictions({
    documentTypes: [UploadDocumentType.CSV, UploadDocumentType.OFFICE_DOCUMENT_SPREADSHEET],
  }),
  [MarketplaceSaleChannelType.Rakuten]: FilesUtils.getFileRestrictions({
    documentTypes: [UploadDocumentType.CSV],
  }),
  [MarketplaceSaleChannelType.Lazada]: FilesUtils.getFileRestrictions({
    documentTypes: [UploadDocumentType.OFFICE_DOCUMENT_SPREADSHEET, UploadDocumentType.MS_EXCEL],
  }),
  [MarketplaceSaleChannelType.Shopee]: FilesUtils.getFileRestrictions({
    documentTypes: [UploadDocumentType.CSV, UploadDocumentType.OFFICE_DOCUMENT_SPREADSHEET],
  }),
  [MarketplaceSaleChannelType.Shopify]: {},
  [MarketplaceSaleChannelType.TikTok]: {},
  [MarketplaceSaleChannelType.Tokopedia]: {},
  [MarketplaceSaleChannelType.Yahoo]: {},
  [MarketplaceSaleChannelType.Zozotown]: {},
  [MarketplaceSaleChannelType.Manual]: {},
  [MarketplaceSaleChannelType.AuPayMarket]: {},
  [MarketplaceSaleChannelType.Magaseek]: {},
  [MarketplaceSaleChannelType.Qoo10]: {},
  [MarketplaceSaleChannelType.RakutenFashion]: {},
  [MarketplaceSaleChannelType.Shoplist]: {},
  [MarketplaceSaleChannelType.SuperDelivery]: {},
  [MarketplaceSaleChannelType.Momo]: {},
  [MarketplaceSaleChannelType.MakeshopByGmo]: {},
  [MarketplaceSaleChannelType.Stores]: {},
  [MarketplaceSaleChannelType.FacebookShop]: {},
};

export type Instructions = {
  instructions?: {
    step: number;
    itemsLength: number;
  }[];
  note?: number;
};

export const REPORT_UPLOAD_INSTRUCTIONS: Record<MarketplaceSaleChannelType, Instructions> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: {
    instructions: [
      {
        step: 1,
        itemsLength: 2,
      },
      {
        step: 2,
        itemsLength: 2,
      },
    ],
    note: 1,
  },
  [MarketplaceSaleChannelType.Rakuten]: {
    instructions: [
      {
        step: 1,
        itemsLength: 2,
      },
      {
        step: 2,
        itemsLength: 2,
      },
    ],
    note: 1,
  },
  [MarketplaceSaleChannelType.Lazada]: {
    instructions: [
      {
        step: 1,
        itemsLength: 2,
      },
      {
        step: 2,
        itemsLength: 2,
      },
    ],
    note: 1,
  },
  [MarketplaceSaleChannelType.Shopee]: {
    instructions: [
      {
        step: 1,
        itemsLength: 2,
      },
      {
        step: 2,
        itemsLength: 2,
      },
    ],
    note: 1,
  },
  [MarketplaceSaleChannelType.Shopify]: {},
  [MarketplaceSaleChannelType.TikTok]: {},
  [MarketplaceSaleChannelType.Tokopedia]: {},
  [MarketplaceSaleChannelType.Yahoo]: {},
  [MarketplaceSaleChannelType.Zozotown]: {},
  [MarketplaceSaleChannelType.Manual]: {},
  [MarketplaceSaleChannelType.AuPayMarket]: {},
  [MarketplaceSaleChannelType.Magaseek]: {},
  [MarketplaceSaleChannelType.Qoo10]: {},
  [MarketplaceSaleChannelType.RakutenFashion]: {},
  [MarketplaceSaleChannelType.Shoplist]: {},
  [MarketplaceSaleChannelType.SuperDelivery]: {},
  [MarketplaceSaleChannelType.Momo]: {},
  [MarketplaceSaleChannelType.MakeshopByGmo]: {},
  [MarketplaceSaleChannelType.Stores]: {},
  [MarketplaceSaleChannelType.FacebookShop]: {},
};

export const REPORT_CSV_UPLOAD_HELP: Record<
  MarketplaceSaleChannelType,
  Partial<Record<AnyXLanguage, string>> | undefined
> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: {
    [AnyXLanguage.EN_US]:
      '/data-linkages/integrations-and-data-linkages/connection/amazon/amazon-integrating-csv-data-from-advertising-and-sales-and-traffic-reports',
    [AnyXLanguage.JA_JP]: '/integrations/integrations/sales-channel/amazon/csv',
  },
  [MarketplaceSaleChannelType.Lazada]: {
    [AnyXLanguage.EN_US]:
      '/data-linkages/integrations-and-data-linkages/connection/lazada/lazada-integrating-csv-data-from-sponsored-discovery-ads-and-traffic-reports',
    [AnyXLanguage.JA_JP]: '/integrations/integrations/sales-channel/lazada/csv',
  },
  [MarketplaceSaleChannelType.Shopee]: {
    [AnyXLanguage.EN_US]:
      '/data-linkages/integrations-and-data-linkages/connection/shopee/shopee-integrating-csv-data-from-all-ads-and-traffic-reports',
    [AnyXLanguage.JA_JP]: '/integrations/integrations/sales-channel/shopee/csv',
  },
  [MarketplaceSaleChannelType.Zozotown]: {
    [AnyXLanguage.EN_US]:
      'https://anymind-group.gitbook.io/any-x/v/ja-jp/integrations/integrations/sales-channel/zozotown/csv',
    [AnyXLanguage.JA_JP]:
      'https://anymind-group.gitbook.io/any-x/data-linkages/integrations-and-data-linkages/connection/amazon/amazon-integrating-csv-data-from-advertising-and-sales-and-traffic-reports',
  },
  [MarketplaceSaleChannelType.Tokopedia]: {
    [AnyXLanguage.EN_US]:
      'https://anymind-group.gitbook.io/any-x/v/ja-jp/integrations/integrations/sales-channel/tokopedia/csv',
    [AnyXLanguage.JA_JP]:
      'https://anymind-group.gitbook.io/any-x/data-linkages/integrations-and-data-linkages/connection/tokopedia/tokopedia-integrating-csv-data-from-ads-and-traffic-reports',
  },
  [MarketplaceSaleChannelType.Shopify]: undefined,
  [MarketplaceSaleChannelType.TikTok]: undefined,
  [MarketplaceSaleChannelType.Yahoo]: undefined,
  [MarketplaceSaleChannelType.Manual]: undefined,
  [MarketplaceSaleChannelType.AuPayMarket]: undefined,
  [MarketplaceSaleChannelType.Magaseek]: undefined,
  [MarketplaceSaleChannelType.Rakuten]: undefined,
  [MarketplaceSaleChannelType.Qoo10]: undefined,
  [MarketplaceSaleChannelType.RakutenFashion]: undefined,
  [MarketplaceSaleChannelType.Shoplist]: undefined,
  [MarketplaceSaleChannelType.SuperDelivery]: undefined,
  [MarketplaceSaleChannelType.Momo]: undefined,
  [MarketplaceSaleChannelType.MakeshopByGmo]: undefined,
  [MarketplaceSaleChannelType.Stores]: undefined,
  [MarketplaceSaleChannelType.FacebookShop]: undefined,
};

export const ADS_COST_TYPES: Record<SaleChannelType, AdsCostType[]> = {
  [SaleChannelType.AmazonSellerCentral]: [
    AdsCostType.AMAZON_SPONSOR_BRAND,
    AdsCostType.AMAZON_SPONSOR_DISPLAY,
    AdsCostType.AMAZON_SPONSOR_PRODUCT,
  ],
  [SaleChannelType.Lazada]: [AdsCostType.LAZADA_SPONSOR_DISCOVERY],
  [SaleChannelType.Rakuten]: [AdsCostType.RAKUTEN_COUPON_ADVANCED, AdsCostType.RAKUTEN_RPP],
  [SaleChannelType.Shopee]: [AdsCostType.SHOPEE_ADS],
  [SaleChannelType.Tokopedia]: [AdsCostType.TOKOPEDIA_ADS],
  [SaleChannelType.Yahoo]: [AdsCostType.YAHOO_ITEM_MATCH_AD],
  [SaleChannelType.Zozotown]: [AdsCostType.ZOZOTOWN_ADS],
  [SaleChannelType.Shopify]: [AdsCostType.UNKNOWN],
  [SaleChannelType.TikTok]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Manual]: [AdsCostType.UNKNOWN],
  [SaleChannelType.AuPayMarket]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Magaseek]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Qoo10]: [AdsCostType.UNKNOWN],
  [SaleChannelType.RakutenFashion]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Shoplist]: [AdsCostType.UNKNOWN],
  [SaleChannelType.SuperDelivery]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Momo]: [AdsCostType.UNKNOWN],
  [SaleChannelType.MakeshopByGmo]: [AdsCostType.UNKNOWN],
  [SaleChannelType.Stores]: [AdsCostType.UNKNOWN],
  [SaleChannelType.FacebookShop]: [AdsCostType.UNKNOWN],
};

// TMP this will get removed anyway
export const CsvType = {
  AMAZON_SESSION_REPORT: 'AMAZON_SESSION_REPORT',
  AMAZON_SPONSOR_BRAND: 'AMAZON_SPONSOR_BRAND',
  AMAZON_SPONSOR_DISPLAY: 'AMAZON_SPONSOR_DISPLAY',
  AMAZON_SPONSOR_PRODUCT: 'AMAZON_SPONSOR_PRODUCT',
  AUPAYMARKET_ORDERS: 'AUPAYMARKET_ORDERS',
  AUPAYMARKET_PRODUCTS: 'AUPAYMARKET_PRODUCTS',
  FACEBOOK_SHOP_ORDERS: 'FACEBOOK_SHOP_ORDERS',
  FACEBOOK_SHOP_PRODUCTS: 'FACEBOOK_SHOP_PRODUCTS',
  LAZADA_SESSION_REPORT: 'LAZADA_SESSION_REPORT',
  LAZADA_SPONSOR_DISCOVERY: 'LAZADA_SPONSOR_DISCOVERY',
  MAGASEEK_ORDERS: 'MAGASEEK_ORDERS',
  MAGASEEK_PRODUCTS: 'MAGASEEK_PRODUCTS',
  MAKESHOP_ORDERS: 'MAKESHOP_ORDERS',
  MAKESHOP_PRODUCTS: 'MAKESHOP_PRODUCTS',
  MOMO_ORDERS: 'MOMO_ORDERS',
  MOMO_PRODUCTS: 'MOMO_PRODUCTS',
  QOO10_ORDERS: 'QOO10_ORDERS',
  QOO10_PRODUCTS: 'QOO10_PRODUCTS',
  RAKUTEN_COUPON_ADVANCED: 'RAKUTEN_COUPON_ADVANCED',
  RAKUTEN_FASHION_ORDERS: 'RAKUTEN_FASHION_ORDERS',
  RAKUTEN_FASHION_PRODUCTS: 'RAKUTEN_FASHION_PRODUCTS',
  RAKUTEN_PRODUCTS: 'RAKUTEN_PRODUCTS',
  RAKUTEN_RPP: 'RAKUTEN_RPP',
  RAKUTEN_SESSION_REPORT: 'RAKUTEN_SESSION_REPORT',
  SHOPEE_ADS: 'SHOPEE_ADS',
  SHOPEE_SESSION_REPORT: 'SHOPEE_SESSION_REPORT',
  SHOPIFY_ORDERS: 'SHOPIFY_ORDERS',
  SHOPLIST_ORDERS: 'SHOPLIST_ORDERS',
  SHOPLIST_PRODUCTS: 'SHOPLIST_PRODUCTS',
  STORES_ORDERS: 'STORES_ORDERS',
  STORES_PRODUCTS: 'STORES_PRODUCTS',
  SUPER_DELIVERY_ORDERS: 'SUPER_DELIVERY_ORDERS',
  SUPER_DELIVERY_PRODUCTS: 'SUPER_DELIVERY_PRODUCTS',
  TIK_TOK_SHOP_SESSION_REPORT: 'TIK_TOK_SHOP_SESSION_REPORT',
  TOKOPEDIA_ADS: 'TOKOPEDIA_ADS',
  TOKOPEDIA_INVENTORIES: 'TOKOPEDIA_INVENTORIES',
  TOKOPEDIA_SALES: 'TOKOPEDIA_SALES',
  TOKOPEDIA_SESSION_REPORT: 'TOKOPEDIA_SESSION_REPORT',
  YAHOO_ITEM_MATCH_AD: 'YAHOO_ITEM_MATCH_AD',
  YAHOO_SESSION_REPORT: 'YAHOO_SESSION_REPORT',
  ZOZOTOWN_ADS: 'ZOZOTOWN_ADS',
  ZOZOTOWN_ORDERS: 'ZOZOTOWN_ORDERS',
  ZOZOTOWN_PRODUCTS: 'ZOZOTOWN_PRODUCTS',
} as const;

export type CsvType = (typeof CsvType)[keyof typeof CsvType];

export const CSV_TYPES: Record<MarketplaceSaleChannelType, CsvType[]> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: [
    CsvType.AMAZON_SPONSOR_BRAND,
    CsvType.AMAZON_SPONSOR_DISPLAY,
    CsvType.AMAZON_SPONSOR_PRODUCT,
    CsvType.AMAZON_SESSION_REPORT,
  ],
  [MarketplaceSaleChannelType.Rakuten]: [
    CsvType.RAKUTEN_COUPON_ADVANCED,
    CsvType.RAKUTEN_RPP,
    CsvType.RAKUTEN_SESSION_REPORT,
  ],
  [MarketplaceSaleChannelType.Lazada]: [
    CsvType.LAZADA_SPONSOR_DISCOVERY,
    CsvType.LAZADA_SESSION_REPORT,
  ],
  [MarketplaceSaleChannelType.Shopee]: [CsvType.SHOPEE_ADS, CsvType.SHOPEE_SESSION_REPORT],
  [MarketplaceSaleChannelType.Shopify]: [],
  [MarketplaceSaleChannelType.TikTok]: [],
  [MarketplaceSaleChannelType.Tokopedia]: [
    CsvType.TOKOPEDIA_ADS,
    CsvType.TOKOPEDIA_SESSION_REPORT,
    CsvType.TOKOPEDIA_SALES,
  ],
  [MarketplaceSaleChannelType.Yahoo]: [],
  [MarketplaceSaleChannelType.Zozotown]: [CsvType.ZOZOTOWN_ADS],
  // <--- Manual Channels
  [MarketplaceSaleChannelType.AuPayMarket]: [],
  [MarketplaceSaleChannelType.Manual]: [],
  [MarketplaceSaleChannelType.Magaseek]: [],
  [MarketplaceSaleChannelType.Qoo10]: [],
  [MarketplaceSaleChannelType.RakutenFashion]: [],
  [MarketplaceSaleChannelType.Shoplist]: [],
  [MarketplaceSaleChannelType.SuperDelivery]: [],
  [MarketplaceSaleChannelType.Momo]: [],
  [MarketplaceSaleChannelType.MakeshopByGmo]: [],
  [MarketplaceSaleChannelType.Stores]: [],
  [MarketplaceSaleChannelType.FacebookShop]: [],
  // -->
};

type DateType = number | Date | string;

export enum ReportType {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

export class ReportUtils {
  static formatDate = (date?: DateType) => {
    return date ? DateUtils.formatDate(date, DateUtils.FORMATS.P) : EMPTY_DEFAULT;
  };

  static formatMetric = ({
    dimensions,
    value,
    type,
    compact,
  }: {
    dimensions: ReportDimensions;
    value: string;
    type: ReportType;
    compact?: boolean;
  }) => {
    switch (dimensions) {
      case ReportDimensions.DAY:
      case ReportDimensions.WEEK: {
        if (type === ReportType.TABLE) {
          return this.formatDate(value);
        }
        return new Date(value).valueOf();
      }
      case ReportDimensions.MONTH: {
        if (type === ReportType.TABLE) {
          return this.formatDate(new Date(value));
        }
        return new Date(value).valueOf();
      }
      case ReportDimensions.DOW: {
        return t('shared.date.weekday', {
          ns: 'shared',
          day: NumberUtils.toNumber(value),
          compact,
        });
      }
      case ReportDimensions.CHANNELS: {
        const [channel, region] = value.split('-');
        const name = t('gql.enum.channel', { ns: 'gql', channel });
        return region ? name + EMPTY_DEFAULT + region : name;
      }
      default: {
        return value;
      }
    }
  };

  static getBarChartPadding = ({ length = 0, minPadding = 20, maxPadding = 300 }) => {
    if (length === 0) {
      return undefined;
    }
    const updatedPadding = maxPadding / length;
    return length >= 10
      ? { left: minPadding, right: minPadding }
      : { left: updatedPadding, right: updatedPadding };
  };

  static sumArrayNumber = (numbers?: number[] | undefined) => {
    try {
      if (numbers === null || numbers === undefined || !numbers.length) {
        return;
      }
      const sum = numbers.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      return sum;
    } catch (error) {
      console.error('Error occurred while summing the array:', error);
      return 0;
    }
  };
}
