import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { InventoryRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const InventoryListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.InventoryListPage }))
);
const InventoryTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.InventoryTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'inventory',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_INVENTORY]]}
          fallback={<UnauthorizedMessage />}
        >
          <InventoryTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <InventoryListPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={InventoryRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
