import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ModalFooter } from '@any-ui-react/core';

import { VariantSelector } from './components';
import { VariantSetDetail } from './type';

export const ProductSetVariantsSelectorDialog = ({
  currentProductVariantId,
  onClose,
  onSubmit,
  storeId,
  value,
}: {
  currentProductVariantId?: string;
  onClose: () => void;
  onSubmit: (products: VariantSetDetail[]) => void;
  storeId: string;
  value: VariantSetDetail[];
}) => {
  const [selectedProducts, setSelectedProducts] = useState<VariantSetDetail[]>(value);
  const { t } = useTranslation();

  const onSubmitHandler = useCallback(() => {
    onSubmit(selectedProducts);
  }, [onSubmit, selectedProducts]);

  return (
    <>
      <div className="pb-4">
        {t('logistic.component.productInput.dialog.description', {
          ns: 'logistic',
        })}
      </div>
      <VariantSelector
        currentProductVariantId={currentProductVariantId}
        storeId={storeId}
        currentSelection={selectedProducts}
        onSelectionChange={(selection) => {
          setSelectedProducts(() => [
            ...selection.map((selectedProduct) => {
              const alreadySelected = value.find(
                (product) => product.productVariantId === selectedProduct.productVariantId
              );
              return alreadySelected ? alreadySelected : selectedProduct;
            }),
          ]);
        }}
      />
      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          {t('shared.button.cancel', { ns: 'shared' })}
        </Button>
        <Button type="submit" onClick={onSubmitHandler}>
          {t('shared.button.set', { ns: 'shared' })}
        </Button>
      </ModalFooter>
    </>
  );
};
