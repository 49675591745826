import { createContext, useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import { AddressPart, AnyXLanguage, ObjectUtils, CountriesEnum } from '~anyx/shared/utils';

import { AddressMappings } from '../DynamicAddressFields.type';

export interface AddressFormFieldProps<TFieldValues extends FieldValues> {
  selectedCountry?: CountriesEnum;
  language?: AnyXLanguage;
  countries?: CountriesEnum[];
  mappings: AddressMappings<TFieldValues>;
  useRegionId: boolean;
}

export const AddressFormContext = createContext<AddressFormFieldProps<Record<string, unknown>>>({
  mappings: {
    [AddressPart.COUNTRY]: AddressPart.COUNTRY,
    [AddressPart.POSTAL_CODE]: AddressPart.POSTAL_CODE,
    [AddressPart.REGION]: AddressPart.REGION,
    [AddressPart.CITY]: AddressPart.CITY,
    [AddressPart.ADDRESS_FIRST]: AddressPart.ADDRESS_FIRST,
    [AddressPart.ADDRESS_SECOND]: AddressPart.ADDRESS_SECOND,
    [AddressPart.FIRSTNAME]: AddressPart.FIRSTNAME,
    [AddressPart.LASTNAME]: AddressPart.LASTNAME,
    [AddressPart.COMPANY]: AddressPart.COMPANY,
    [AddressPart.PHONE]: AddressPart.PHONE,
  },
  selectedCountry: CountriesEnum.JP,
  countries: ObjectUtils.arrayFromEnum<CountriesEnum>(CountriesEnum),
  language: AnyXLanguage.EN_US,
  useRegionId: true,
});

export const useAddressFormContext = () => {
  const { ...context } = useContext(AddressFormContext);
  return context;
};
