import { DdiHomeFilterInputFilterType } from '../components';
import { useDdiHomeReportPieChartQuery } from '../graphql';

interface UseDdiHomeReportPieChartProps {
  filters: DdiHomeFilterInputFilterType;
  skip: boolean;
}

export const useDdiHomeReportPieChart = ({ filters, skip }: UseDdiHomeReportPieChartProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useDdiHomeReportPieChartQuery({
    variables: {
      input: filterInput,
    },
    skip,
  });

  return {
    reportPieChart: data?.ddiHomeReportPieChart,
    loading,
    error,
    refetch,
  };
};
