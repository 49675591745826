interface UseOptimizeQueryDataProps<TData> {
  data?: TData;
  previousData?: TData;
  loading?: boolean;
}

/** __useOptimizeQueryData__
 *
 * Use this hook to optimize result data from useQuery
 * While query is loading, if previousData is available, it will return the previousData
 * https://www.apollographql.com/docs/react/data/queries/#result
 *  */
export const useOptimizeQueryData = <TData>({
  data,
  previousData,
  loading = false,
}: UseOptimizeQueryDataProps<TData>) => {
  const optimizedData = data || (loading ? previousData : undefined);
  const optimizedLoading = optimizedData ? false : loading;

  return {
    optimizedData,
    optimizedLoading,
  };
};
