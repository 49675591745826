import { PropsWithChildren, ReactNode } from 'react';

import classNames from 'classnames';

interface CrmLayoutMainProps {
  logo?: ReactNode;
  title?: string;
  className?: string;
}

export const CrmLayoutMain = ({
  logo,
  children,
  title,
  className,
}: PropsWithChildren<CrmLayoutMainProps>) => {
  return (
    <div className={classNames('col-span-1 lg:col-span-4 2xl:col-span-8', className)}>
      <div className="shadow-card flex flex-col bg-white px-4 pb-4 pt-7 lg:rounded lg:px-6">
        {logo}
        {title && <h2 className="text-gray-9 mt-7 text-lg font-semibold">{title}</h2>}
        {children}
      </div>
    </div>
  );
};
