import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const ReportProductRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/products' : '/products', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    top: ({ productId = '' }) =>
      createPath(`${root.path}/:productId`, { productId }, ['productId']),
    sales: ({ productId = '' }) =>
      createPath(`${root.path}/:productId/sales`, { productId }, ['productId']),
    review: ({ productId = '' }) =>
      createPath(`${root.path}/:productId/review`, { productId }, ['productId']),
  };
};
