export const ANYX_DOMAIN_NAME = 'any-x.com';
export const ANYX_PROD_APP_DOMAIN = 'https://app.any-x.com';
export const ANYX_STG_APP_DOMAIN = 'https://stg.app.any-x.com';

export const ANYLOGI_DOMAIN_NAME = 'anylogi.com';
export const ANYLOGI_PROD_APP_DOMAIN = 'https://app2.anylogi.com';
export const ANYLOGI_STG_APP_DOMAIN = 'https://stg.app.anylogi.com';

export const ANYCHAT_DOMAIN_NAME = 'anychat.tech';
export const ANYCHAT_PROD_APP_DOMAIN = 'https://app.anychat.tech';
export const ANYCHAT_STG_APP_DOMAIN = 'https://stg.app.anychat.tech';
