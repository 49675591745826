import { getDate, getHours, getMinutes, getMonth, getYear, isValid, set } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';

export const mergeDateInTimeZone = ({
  currentDate,
  newDate,
  timezone,
  mergeValues,
}: {
  currentDate: string;
  newDate: string;
  timezone: string;
  mergeValues?: {
    year?: boolean;
    month?: boolean;
    date?: boolean;
    hours?: boolean;
    minutes?: boolean;
  };
}) => {
  const currentZoned = toZonedTime(new Date(currentDate), timezone);
  const newZoned = toZonedTime(new Date(newDate), timezone);

  const finalZoned = set(isValid(currentZoned) && isValid(newZoned) ? currentZoned : newZoned, {
    year: mergeValues?.year ? getYear(newZoned) : undefined,
    month: mergeValues?.month ? getMonth(newZoned) : undefined,
    date: mergeValues?.date ? getDate(newZoned) : undefined,
    hours: mergeValues?.hours ? getHours(newZoned) : undefined,
    minutes: mergeValues?.minutes ? getMinutes(newZoned) : undefined,
    seconds: 59,
    milliseconds: 999,
  });
  return fromZonedTime(finalZoned, timezone).toISOString();
};
