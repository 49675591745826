import { useTranslation } from 'react-i18next';

import { UnstyledButton } from '@any-ui-react/core';
import { Menu, MenuItem } from '@szhsin/react-menu';

import { ChevronDownIcon } from '~anyx/shared/icons';
import { LanguageUtils, useEnvironmentStore } from '~anyx/shared/utils';

export const LanguageMenu = () => {
  const { t } = useTranslation();
  const currentLanguage = useEnvironmentStore((s) => s.currentLanguage);
  const languageOptions = LanguageUtils.getAvailableLanguages().map((language) => ({
    label: t('shared.enum.language', {
      ns: 'shared',
      language: LanguageUtils.asLanguageTranslationKey(language),
    }),
    value: language,
  }));

  return (
    <Menu
      menuButton={
        <UnstyledButton className="border-gray-2 text-2xs after:text-gray-7 text-gray-7 flex w-fit cursor-pointer items-center border bg-white p-2 hover:underline sm:border-none sm:bg-transparent sm:p-0">
          {t('shared.enum.language', {
            ns: 'shared',
            language: LanguageUtils.asLanguageTranslationKey(currentLanguage),
          })}
          <ChevronDownIcon height={16} className="ml-2 opacity-50" />
        </UnstyledButton>
      }
    >
      {languageOptions.map((option) => (
        <MenuItem
          key={option.value}
          className="text-2xs text-gray-7 hover:bg-gray-0 flex h-10 cursor-pointer items-center px-4"
          onClick={() => LanguageUtils.setLanguage(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};
