import { useTranslation } from 'react-i18next';

import { CurrenciesEnum } from '../enums';
import { AnyXLanguage, LanguageUtils } from '../utils';
import { NumberUtils } from '../utils/number.utils';

interface UseFormatCurrency {
  amount?: number | string | null;
  currency?: CurrenciesEnum | string;
  language?: AnyXLanguage;
}

export const useFormatCurrency = () => {
  const { t } = useTranslation();
  const formatAmount = ({ amount, currency, language }: UseFormatCurrency) => {
    const number = Number(amount) || 0;
    let value: string;

    try {
      value = `${NumberUtils.format(number, { currency }, language)}`;
    } catch {
      console.error(`currency code: '${currency}' is not supported, falling back to USD`);
      value = `${NumberUtils.format(number, { currency: 'USD' }, language)}`;
    }

    return value;
  };

  const formatPrice = ({
    amount,
    currency,
    language = LanguageUtils.getCurrentLanguage(),
  }: UseFormatCurrency) => {
    const price = formatAmount({ amount, currency, language });

    return `${price} ${t('shared.enum.currency', {
      currency,
      ns: 'shared',
      compact: true,
    })}`;
  };

  return { formatAmount, formatPrice };
};
