import { PropsWithChildren } from 'react';

import { Collapse } from '@any-ui-react/core';
import { TrBody } from '@any-ui-react/table';

interface TableCollapseRowProps {
  colSpan: number;
  isOpen: boolean;
}

export const TableCollapseRow = ({
  colSpan,
  children,
  isOpen,
}: PropsWithChildren<TableCollapseRowProps>) => {
  return (
    <TrBody className="h-fit border-t-0 border-none">
      <td colSpan={colSpan} className="p-0">
        <Collapse in={isOpen}>{children}</Collapse>
      </td>
    </TrBody>
  );
};
