import { AddressPart } from '~anyx/shared/utils';

import { AddressFirstField } from './AddressFirstField';
import { AddressSecondField } from './AddressSecondField';
import { CityField } from './CityField';
import { CompanyField } from './CompanyField';
import { CountryField } from './CountryField';
import { FirstNameField } from './FirstNameField';
import { LastNameField } from './LastNameField';
import { PhoneField } from './PhoneField';
import { PostalCodeField } from './PostalCodeField';
import { RegionField } from './RegionField';

export * from './AddressFirstField';
export * from './AddressSecondField';
export * from './CityField';
export * from './CountryField';
export * from './CompanyField';
export * from './PostalCodeField';
export * from './RegionField';
export * from './FirstNameField';
export * from './LastNameField';
export * from './PhoneField';

export const ADDRESS_COMPONENTS = {
  [AddressPart.ADDRESS_FIRST]: AddressFirstField,
  [AddressPart.ADDRESS_SECOND]: AddressSecondField,
  [AddressPart.CITY]: CityField,
  [AddressPart.COUNTRY]: CountryField,
  [AddressPart.POSTAL_CODE]: PostalCodeField,
  [AddressPart.REGION]: RegionField,
  [AddressPart.FIRSTNAME]: FirstNameField,
  [AddressPart.LASTNAME]: LastNameField,
  [AddressPart.PHONE]: PhoneField,
  [AddressPart.COMPANY]: CompanyField,
};
