import { Auth0Permissions } from '~anyx/common/permission';

import { PermissionProperties } from './types';

export const USER_PERMISSION_PROPS: PermissionProperties = {
  restrictions: [[Auth0Permissions.RESTRICTION_SEE_USERS]],
};

export const USE_ORDER_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_ORDER]],
};

export const ANALYTICS_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_ANALYTICS]],
};

export const CRM_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_CRM]],
};

export const DATASOURCE_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_DATASOURCE]],
};

export const INVENTORY_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_INVENTORY]],
};

export const PRODUCT_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_PRODUCT]],
};

export const SHIPPING_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_SHIPPING]],
};

export const WORKFLOW_PERMISSION_PROPS: PermissionProperties = {
  permissions: [[Auth0Permissions.CAN_USE_WORKFLOW]],
};
