import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted } from '~anyx/common/permission';
import { AccountRoutePath } from '~anyx/common/routing';
import { RestrictedWorkspaceMode, WorkspaceMode, useWorkspaceCtx } from '~anyx/common/workspace';
import { MdUserRole } from '~anyx/shared/graphql';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const AccountListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.AccountListPage }))
);
const AccountAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.AccountAddPage }))
);
const AccountEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.AccountEditPage }))
);

const AccountTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.AccountTemplate }))
);

const RedirectToDefaultAccountRoutes = () => {
  const { workspace } = useWorkspaceCtx();
  return (
    <RestrictedWorkspaceMode
      restrictedTo={[WorkspaceMode.ON, WorkspaceMode.ACCOUNT_ONLY]}
      fallback={<Navigate to={AccountRoutePath().list().path} replace={true} />}
    >
      <Navigate
        to={
          AccountRoutePath().edit({
            accountId: workspace?.accountId,
          }).path
        }
        replace={true}
      />
    </RestrictedWorkspaceMode>
  );
};

export const routes: RouteObject[] = [
  {
    path: 'account',
    element: (
      <Auth0Guard>
        <AccountTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: (
          <Restricted
            roles={[MdUserRole.ADMIN, MdUserRole.STAFF]}
            fallback={<UnauthorizedMessage />}
          >
            <AccountListPage />
          </Restricted>
        ),
      },
      {
        path: 'add',
        element: (
          <Restricted
            roles={[MdUserRole.ADMIN, MdUserRole.STAFF]}
            fallback={<UnauthorizedMessage />}
          >
            <AccountAddPage />
          </Restricted>
        ),
      },
      {
        path: ':accountId',
        element: <AccountEditPage />,
      },
      {
        index: true,
        element: <RedirectToDefaultAccountRoutes />,
      },
    ],
  },
];
