import { createContext, useContext } from 'react';

import { TableSelection } from '~anyx/shared/utils';

export type TableSelectionContextType<T> = {
  currentPageSelection: T[];
  selectableData: T[];
  checkAll: () => void;
  check: (item: T) => void;
  checkMultiple: (items: T[]) => void;
  uncheckMultiple: (items: T[]) => void;
  handleRowSelectionToggle: (
    shouldBeChecked: boolean,
    targetRow: T,
    isRangeSelection?: boolean
  ) => void;
  uncheckAll: () => void;
  uncheck: (item: T) => void;
  isChecked: (item: T) => boolean;
  toggleSelection: () => void;
  toggleSelectAll: () => void;
  selection?: TableSelection<T>;
  selectable?: {
    byField: keyof T;
    aside?: React.ReactNode;
    total: number;
    rowDisabled?: (row: T) => boolean;
    allPagesSelectable?: boolean;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultSelf: TableSelectionContextType<any> = {
  currentPageSelection: [],
  selectableData: [],
  checkAll: () => null,
  check: () => null,
  checkMultiple: () => null,
  uncheckMultiple: () => null,
  handleRowSelectionToggle: () => null,
  uncheckAll: () => null,
  uncheck: () => null,
  toggleSelection: () => null,
  toggleSelectAll: () => null,
  isChecked: () => false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableSelectionContext = createContext<TableSelectionContextType<any>>(defaultSelf);

export function useTableSelectionCtx<T>() {
  const context = useContext(TableSelectionContext) as TableSelectionContextType<T>;

  return context;
}
