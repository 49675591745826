import { useTranslation } from 'react-i18next';

import { ErrorCode, FileRejection } from '@any-ui-react/dropzone';

import { FileRestriction } from '../models';
import { FilesUtils } from '../utils';

interface FileUploadRestrictions extends FileRestriction {
  max?: number;
}

export const useFileUploadErrorTranslationMapper = () => {
  const { t } = useTranslation();

  const getErrorMessages = (errorCodes: ErrorCode[], restrictions: FileUploadRestrictions) => {
    const messages = errorCodes.map((errorCode) => {
      switch (errorCode) {
        case ErrorCode.FileInvalidType:
          return t('shared.field.media.error.fileInvalidType', {
            ns: 'shared',
            types: Object.keys(restrictions.types || {})
              .map((fileType) => fileType.split('/')[1]?.toUpperCase())
              .join(', '),
          });
        case ErrorCode.FileTooLarge:
          return t('shared.field.media.error.fileTooLarge', {
            ns: 'shared',
            size: FilesUtils.formatBytes(restrictions.size || 0, 0),
          });
        case ErrorCode.FileTooSmall:
          return t('shared.field.media.error.fileTooSmall', {
            ns: 'shared',
            size: FilesUtils.formatBytes(restrictions.size || 0, 0),
          });
        case ErrorCode.TooManyFiles:
          return t('shared.field.media.error.tooManyFiles', {
            ns: 'shared',
            max: restrictions.max,
          });
        default:
          return t('shared.field.media.error.generic', { ns: 'shared' });
      }
    });

    return messages.join('\n');
  };

  const getErrorCodes = (fileRejections: FileRejection[]): ErrorCode[] => {
    const errorCodes = fileRejections
      .flatMap((rejection) => rejection.errors.map((error) => error.code))
      .filter((code): code is ErrorCode => code !== undefined);

    return [...new Set(errorCodes)]; // Deduplicate error codes
  };

  return { getErrorMessages, getErrorCodes };
};
