import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifySalesReportChartQueryVariables = Types.Exact<{
  input: Types.ShopifySalesReportChartInput;
}>;


export type ShopifySalesReportChartQuery = { readonly shopifySalesReportChart: { readonly __typename?: 'ShopifySalesReportChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly discounts: ReadonlyArray<Record<string, any>>, readonly grossSales: ReadonlyArray<Record<string, any>>, readonly netSales: ReadonlyArray<Record<string, any>>, readonly orderCount: ReadonlyArray<Record<string, any>>, readonly returns: ReadonlyArray<Record<string, any>>, readonly shipping: ReadonlyArray<Record<string, any>>, readonly taxes: ReadonlyArray<Record<string, any>>, readonly totalSales: ReadonlyArray<Record<string, any>> } };


export const ShopifySalesReportChartDocument = gql`
    query ShopifySalesReportChart($input: ShopifySalesReportChartInput!) {
  shopifySalesReportChart(input: $input) {
    currency
    discounts
    grossSales
    netSales
    orderCount
    returns
    shipping
    taxes
    totalSales
  }
}
    `;

/**
 * __useShopifySalesReportChartQuery__
 *
 * To run a query within a React component, call `useShopifySalesReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifySalesReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifySalesReportChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifySalesReportChartQuery(baseOptions: Apollo.QueryHookOptions<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables> & ({ variables: ShopifySalesReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>(ShopifySalesReportChartDocument, options);
      }
export function useShopifySalesReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>(ShopifySalesReportChartDocument, options);
        }
export function useShopifySalesReportChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>(ShopifySalesReportChartDocument, options);
        }
export type ShopifySalesReportChartQueryHookResult = ReturnType<typeof useShopifySalesReportChartQuery>;
export type ShopifySalesReportChartLazyQueryHookResult = ReturnType<typeof useShopifySalesReportChartLazyQuery>;
export type ShopifySalesReportChartSuspenseQueryHookResult = ReturnType<typeof useShopifySalesReportChartSuspenseQuery>;
export type ShopifySalesReportChartQueryResult = Apollo.QueryResult<ShopifySalesReportChartQuery, ShopifySalesReportChartQueryVariables>;
export function refetchShopifySalesReportChartQuery(variables: ShopifySalesReportChartQueryVariables) {
      return { query: ShopifySalesReportChartDocument, variables: variables }
    }