import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PoweredByGoogleIcon } from '~anyx/shared/icons';

export const InputPostalCodeNoOptions = forwardRef<HTMLDivElement, unknown>((_, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="text-gray-7 -my-2 text-center">
      <div className="py-2">{t('shared.select.noOption', { ns: 'shared' })}</div>
      <div className="pointer-events-none flex justify-end border-t px-2">
        <PoweredByGoogleIcon className="w-28" />
      </div>
    </div>
  );
});
