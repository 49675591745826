import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';

const AmazonAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.AmazonAddPage }))
);

const AmazonEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.AmazonEditPage }))
);

const AmazonManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.AmazonManualCostEditPage,
  }))
);

const AmazonManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.AmazonManualCostAddPage,
  }))
);

const AmazonMappingEditPage = React.lazy(() =>
  import('./pages/mapping/set').then((module) => ({
    default: module.AmazonMappingEditPage,
  }))
);

const AmazonTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.AmazonTemplate }))
);

export const amazonRouting = [
  {
    path: MarketplaceSaleChannelType.AmazonSellerCentral,
    element: <AmazonTemplate />,
    children: [
      {
        index: true,
        element: <AmazonAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <AmazonManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <AmazonManualCostEditPage />,
          },
          {
            path: 'mapping/edit',
            element: <AmazonMappingEditPage />,
          },
          {
            index: true,
            element: <AmazonEditPage />,
          },
        ],
      },
    ],
  },
];
