export enum ProductListTab {
  PRODUCTS = 'products',
  VARIANTS = 'variants',
}

export enum AdvertisingListTab {
  OVERVIEW = 'overview',
  DETAILED = 'detailed',
}
export enum InventoryStockValueListTab {
  DAILY = 'daily',
  VARIANTS = 'variants',
}
