import {
  ReportAdvertisingRoutePath,
  ReportEventCalendarRoutePath,
  ReportStockValueRoutePath,
  ReportInventoryTrackerRoutePath,
  ReportProductRoutePath,
  ReportSalesChannelAnalyticsRoutePath,
  ReportSalesChannelRoutePath,
} from '~anyx/common/routing';
import { ReportSalesChannelType } from '~anyx/feature/marketplace';
import {
  AmazonSellerCentralFaIcon,
  ChevronRightIcon,
  EqualizerIcon,
  LazadaFaIcon,
  RakutenFaIcon,
  ShopeeFaIcon,
  ShopifyFaIcon,
  TikTokFaIcon,
  TokopediaFaIcon,
  YahooFaIcon,
} from '~anyx/shared/icons';
import { RouteLink, RouteLinks, RouteMenu } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

export type NoWorkspaceAnalytics = {
  canUseAnalytics: boolean;
  canSeeInventoryTracker: boolean;
};

export type WithWorkspaceAnalytics = {
  canUseAnalytics: boolean;
  canSeeInventoryStockValue: boolean;
  channels: ReportSalesChannelType[];
  storeId?: string;
};

const buildAnalyticsLink = (storeId?: string): RouteLink => {
  return {
    type: 'link',
    name: {
      key: 'shared.entity.analytic',
      options: { count: 2, ns: 'shared' },
    },
    icon: (props) => <EqualizerIcon {...props} />,
    path: ReportSalesChannelRoutePath({ workspaceStoreId: storeId }).list().path,
    activePath: ReportSalesChannelRoutePath({ workspaceStoreId: null }).root().pattern,
  };
};

const buildProductAnalyticsLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.productAnalytic',
    options: { count: 2, ns: 'shared' },
  },
  path: ReportProductRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: ReportProductRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildAdvertisingLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.advertising',
    options: { count: 2, ns: 'shared' },
  },
  path: ReportAdvertisingRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: ReportAdvertisingRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildEventCalendarLink = (storeId?: string): RouteLink => {
  return {
    type: 'link',
    name: {
      key: 'shared.entity.eventCalendar',
      options: { count: 2, ns: 'shared' },
    },
    path: ReportEventCalendarRoutePath({ workspaceStoreId: storeId }).list().path,
    activePath: ReportEventCalendarRoutePath({ workspaceStoreId: null }).root().pattern,
  };
};

const buildInventoryStockValueLink = ({
  storeId,
  canSeeInventoryStockValue,
}: {
  storeId?: string;
  canSeeInventoryStockValue?: boolean;
}): RouteLink[] => {
  if (!canSeeInventoryStockValue) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'shared.entity.inventoryStockValue',
        options: { count: 2, ns: 'shared' },
      },
      path: ReportStockValueRoutePath({ workspaceStoreId: storeId }).list().path,
      activePath: ReportStockValueRoutePath({ workspaceStoreId: null }).root().pattern,
    },
  ];
};

const buildInventoryTrackerLink = ({
  canSeeInventoryTracker,
}: {
  canSeeInventoryTracker?: boolean;
}): RouteLink[] => {
  if (!canSeeInventoryTracker) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'shared.entity.inventoryTracker',
        options: { count: 2, ns: 'shared' },
      },
      path: ReportInventoryTrackerRoutePath({ workspaceStoreId: null }).list().path,
      activePath: ReportInventoryTrackerRoutePath({ workspaceStoreId: null }).root().pattern,
    },
  ];
};

const buildAmazonReportLink = (
  storeId?: string,
  isReportAmazonPageActive?: boolean
): RouteLink[] => {
  if (!isReportAmazonPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.AmazonSellerCentral },
      },
      icon: (props) => <AmazonSellerCentralFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .amazon().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .amazon().path,
    },
  ];
};

const buildLazadaReportLink = (
  storeId?: string,
  isReportLazadaPageActive?: boolean
): RouteLink[] => {
  if (!isReportLazadaPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Lazada },
      },
      icon: (props) => <LazadaFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .lazada().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .lazada().path,
    },
  ];
};

const buildRakutenReportLink = (
  storeId?: string,
  isReportRakutenPageActive?: boolean
): RouteLink[] => {
  if (!isReportRakutenPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Rakuten },
      },
      icon: (props) => <RakutenFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .rakuten().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .rakuten().path,
    },
  ];
};

const buildYahooReportLink = (storeId?: string, isReportYahooPageActive?: boolean): RouteLink[] => {
  if (!isReportYahooPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Yahoo },
      },
      icon: (props) => <YahooFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .yahoo().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .yahoo().path,
    },
  ];
};

const buildShopifyReportLink = (
  storeId?: string,
  isReportShopifyPageActive?: boolean
): RouteLink[] => {
  if (!isReportShopifyPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Shopify },
      },
      icon: (props) => <ShopifyFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .shopify().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .shopify().path,
    },
  ];
};

const buildShopeeReportLink = (
  storeId?: string,
  isReportShopeePageActive?: boolean
): RouteLink[] => {
  if (!isReportShopeePageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Shopee },
      },
      icon: (props) => <ShopeeFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .shopee().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .shopee().path,
    },
  ];
};

const buildTokopediaReportLink = (
  storeId?: string,
  isReportTokopediaPageActive?: boolean
): RouteLink[] => {
  if (!isReportTokopediaPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.Tokopedia },
      },
      icon: (props) => <TokopediaFaIcon {...props} />,

      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .tokopedia().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .tokopedia().path,
    },
  ];
};

const buildTikTokShopReportLink = (
  storeId?: string,
  isReportTikTokShopPageActive?: boolean
): RouteLink[] => {
  if (!isReportTikTokShopPageActive) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'gql.enum.channel',
        options: { ns: 'gql', channel: ReportSalesChannelType.TikTok },
      },
      icon: (props) => <TikTokFaIcon {...props} />,
      path: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: storeId })
        .salesChannel()
        .tikTok().path,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .tikTok().path,
    },
  ];
};

const buildSalesChannelRouteMenu = (
  storeId?: string,
  channels: ReportSalesChannelType[] = []
): RouteMenu[] => {
  if (channels.length === 0) return [];
  const isMarketplaceActive = (channel: ReportSalesChannelType) => channels.includes(channel);
  return [
    {
      type: 'menu',
      name: {
        key: 'shared.entity.salesChannel',
        options: { count: 2, ns: 'shared' },
      },
      aside: <ChevronRightIcon className="text-gray-7 -ml-3 h-4 w-4" />,
      activePath: ReportSalesChannelAnalyticsRoutePath({ workspaceStoreId: null })
        .salesChannel()
        .root().path,
      children: [
        ...buildAmazonReportLink(
          storeId,
          isMarketplaceActive(ReportSalesChannelType.AmazonSellerCentral)
        ),
        ...buildLazadaReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Lazada)),
        ...buildRakutenReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Rakuten)),
        ...buildShopifyReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Shopify)),
        ...buildShopeeReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Shopee)),
        ...buildTokopediaReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Tokopedia)),
        ...buildTikTokShopReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.TikTok)),
        ...buildYahooReportLink(storeId, isMarketplaceActive(ReportSalesChannelType.Yahoo)),
      ],
    },
  ];
};

/**
 *
 * Get Analytics links when No workspace is selected
 * */
export const getAnalyticsNoWorkspaceLinks = ({
  canUseAnalytics,
  canSeeInventoryTracker,
}: NoWorkspaceAnalytics): RouteLinks[] => {
  if (!canUseAnalytics) return [];

  return [
    {
      category: {
        ...buildAnalyticsLink(),
        children: [
          buildProductAnalyticsLink(),
          buildAdvertisingLink(),
          ...buildInventoryTrackerLink({ canSeeInventoryTracker }),
        ],
      },
    },
  ];
};

/**
 *
 * Get Analytic links when the workspace is selected
 * */
export const getAnalyticsWithWorkspaceLinks = ({
  canUseAnalytics,
  canSeeInventoryStockValue,
  channels,
  storeId,
}: WithWorkspaceAnalytics): RouteLinks[] => {
  if (!canUseAnalytics) return [];

  const analyticsChildren: Array<RouteLink | RouteMenu> = [
    ...buildSalesChannelRouteMenu(storeId, channels),
    buildProductAnalyticsLink(storeId),
    buildAdvertisingLink(storeId),
    buildEventCalendarLink(storeId),
    ...buildInventoryStockValueLink({ storeId, canSeeInventoryStockValue }),
  ];

  return [
    {
      category: {
        ...buildAnalyticsLink(storeId),
        children: analyticsChildren,
      },
    },
  ];
};

type AnalyticsLinks = LinkAdapter<NoWorkspaceAnalytics, WithWorkspaceAnalytics>;

export const analyticsLinks: AnalyticsLinks = {
  noWorkspace: getAnalyticsNoWorkspaceLinks,
  withWorkspace: getAnalyticsWithWorkspaceLinks,
};
