import { RouteLinks } from '~anyx/shared/utils';

import {
  accountLinks,
  analyticsLinks,
  shippingLinks,
  helperLinks,
  homeLinks,
  marketplaceEventLinks,
  orderLinks,
  productLinks,
  inventoryLinks,
} from './route-links';
import { NoWorkspaceLinkProps } from './types';

export const getNoWorkspaceLinks = ({
  workspaceAccountId,
  accountId,
  isAdmin,
  canSeeUserList,
  canUseOrder,
  canUseAnalytics,
  canUseProduct,
  canUseShipping,
  canUseInventory,
  showPathToList,
  canSeeMarketplaceEvents,
  canSeeInventoryTracker,
}: NoWorkspaceLinkProps): RouteLinks[] => {
  return [
    // Home
    ...homeLinks.noWorkspace({ canUseAnalytics }),
    // Analytics
    ...analyticsLinks.noWorkspace({ canUseAnalytics, canSeeInventoryTracker }),
    // Order
    ...orderLinks.noWorkspace({ canUseOrder }),
    // Shipping
    ...shippingLinks.noWorkspace({
      canUseShipping,
      accountId,
      showPathToList,
    }),
    // Product
    ...productLinks.noWorkspace({ canUseProduct }),
    // Inventories
    ...inventoryLinks.noWorkspace({ canUseInventory, isAdmin }),
    // Account
    ...accountLinks.noWorkspace({
      workspaceAccountId,
      showPathToList,
      canSeeUserList,
    }),
    // Marketplace Event
    ...marketplaceEventLinks.noWorkspace({ canSeeMarketplaceEvents }),
    // Help
    ...helperLinks.noWorkspace(),
  ];
};
