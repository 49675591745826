import { t } from 'i18next';

const requiredField = (field: string) => {
  return t('shared.form.validation.error.required', {
    ns: 'shared',
    field,
  });
};

export const setValueOrNull = (v: unknown) => {
  if (typeof v === 'string' && v === '') return null;
  else return v;
};

export const FormUtils = {
  requiredField,
  setValueOrNull,
};
