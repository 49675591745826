import React from 'react';
import { RouteObject } from 'react-router-dom';

const WorkflowListPage = React.lazy(() => import('./pages/list'));
const WorkflowAddPage = React.lazy(() => import('./pages/add'));
const WorkflowEditPage = React.lazy(() => import('./pages/edit'));

export const routes: RouteObject[] = [
  {
    path: 'list',
    element: <WorkflowListPage />,
  },
  {
    path: 'add',
    element: <WorkflowAddPage />,
  },
  {
    path: ':workflowId',
    element: <WorkflowEditPage />,
  },
];
