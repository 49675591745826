import { FirebaseError } from 'firebase/app';

/**
 * Remote Config Error Code - https://github.com/firebase/firebase-js-sdk/blob/master/packages/remote-config/src/errors.ts#L20
 */
export const enum RemoteConfigErrorCode {
  REGISTRATION_WINDOW = 'registration-window',
  REGISTRATION_PROJECT_ID = 'registration-project-id',
  REGISTRATION_API_KEY = 'registration-api-key',
  REGISTRATION_APP_ID = 'registration-app-id',
  STORAGE_OPEN = 'storage-open',
  STORAGE_GET = 'storage-get',
  STORAGE_SET = 'storage-set',
  STORAGE_DELETE = 'storage-delete',
  FETCH_NETWORK = 'fetch-client-network',
  FETCH_TIMEOUT = 'fetch-timeout',
  FETCH_THROTTLE = 'fetch-throttle',
  FETCH_PARSE = 'fetch-client-parse',
  FETCH_STATUS = 'fetch-status',
  INDEXED_DB_UNAVAILABLE = 'indexed-db-unavailable',
}

/**
 * Skip some of the firebase error, not to spam sentry
 * @param error FirebaseError
 * @returns boolean
 */
export const shouldCaptureFirebaseError = (error: FirebaseError) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_service, code] = error.code.split('/');

  switch (code) {
    case RemoteConfigErrorCode.FETCH_NETWORK:
      return false;
    default:
      return true;
  }
};
