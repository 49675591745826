import { Workspace, WorkspaceMode } from '../models';

import { useWorkspaceCtx } from './WorkspaceContext';

export const setWorkspace = (workspace?: Workspace) => {
  const getMode = () => {
    if (workspace?.storeId) return WorkspaceMode.ON;
    if (workspace?.accountId) return WorkspaceMode.ACCOUNT_ONLY;

    return WorkspaceMode.OFF;
  };

  useWorkspaceCtx.setState((state) => {
    state.workspace = workspace;
    state.mode = getMode();
  });
};
