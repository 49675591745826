export const MIN_SELECTED_ACCOUNTS = 1;
export const MAX_SELECTED_ACCOUNTS = 30;

export const DefaultAccountSelection = {
  'AnyMind Japan': '12',
  'AnyMind Indonesia': '1248',
  'AnyMind Thailand': '1247',
  'AnyMind Japan（Cross Border）': '1141',
  'AnyMind Malaysia': '1139',
  'AnyMind Shop Indonesia': '1118',
  'AnyMind Taiwan': '25',
  'AnyMind Singapore': '21',
  'AnyMind Philippines': '20',
};
