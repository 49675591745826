import { HttpResponse, delay, graphql } from 'msw';

import { HomeMetricOption, MetricData, NumberFormat } from '~anyx/feature/report';

export const MockDailyChart = {
  GROSS_SALES: [
    {
      AmazonSellerCentral: 0,
      Lazada: 16886,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1682035200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 16886,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681948800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681862400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 19322,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 16000,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681776000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 35322,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681689600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681603200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 566,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681516800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 566,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 3822,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 2002,
      key: 1681430400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 5824,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 1820,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 5460,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 1820,
      key: 1681344000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 9100,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 3640,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 7462,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681257600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 11102,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1681171200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 55977,
      Lazada: 73016,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 51640,
      Shopee: 6513,
      Shopify: 59188,
      TikTok: 37746,
      Tokopedia: 6773,
      Yahoo: 50724,
      Zozotown: 0,
      key: 1681084800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 341577,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680998400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680912000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 96795,
      Lazada: 118429,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 77802,
      Shopee: 134727,
      Shopify: 76672,
      TikTok: 146573,
      Tokopedia: 170064,
      Yahoo: 120279,
      Zozotown: 0,
      key: 1680825600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 941341,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680739200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680652800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 110100,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680566400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 110100,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 2860,
      Zozotown: 0,
      key: 1680480000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 2860,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680393600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680307200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 111245,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 5953,
      Zozotown: 0,
      key: 1680220800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 117198,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680134400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1680048000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 23170,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679961600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 23170,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679875200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679788800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679702400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679616000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679529600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679443200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679356800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679270400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679184000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679097600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 150,
      Shopify: 180240,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1679011200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 180390,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 399201,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678924800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 399201,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 34160,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 13085,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678838400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 47245,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 3785,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 200,
      Shopee: 0,
      Shopify: 289420,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678752000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 293406,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678665600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678579200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678492800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 3909,
      Shopee: 0,
      Shopify: 19960,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678406400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 23869,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 27364,
      Shopee: 0,
      Shopify: 99800,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678320000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 127164,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 49900,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678233600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 49900,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 121856,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678147200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 121856,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1678060800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677974400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677888000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 11978,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677801600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 11978,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 43810,
      Magaseek: 0,
      Manual: 2494,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 25287,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677715200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 71591,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 182,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 3779,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 3640,
      key: 1677628800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 7601,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677542400000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677456000000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677369600000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677283200000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
    {
      AmazonSellerCentral: 0,
      Lazada: 0,
      Magaseek: 0,
      Manual: 0,
      Momo: 0,
      Qoo10: 0,
      Rakuten: 0,
      Shopee: 0,
      Shopify: 0,
      TikTok: 0,
      Tokopedia: 0,
      Yahoo: 0,
      Zozotown: 0,
      key: 1677196800000,
      name: 'Revenue',
      numberFormat: NumberFormat.PRICE,
      currency: 'JPY',
      total: 0,
    },
  ],
} satisfies Record<HomeMetricOption, MetricData>;

export const MockDailyChartQuery = {
  default: () => {
    return graphql.query('DailyChart', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: MockDailyChart });
    });
  },
};
