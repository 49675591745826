import {
  getCountryCallingCode,
  isSupportedCountry,
  formatNumber,
  CountryCode,
} from 'libphonenumber-js';

import { PhoneData } from './InputPhone';

export const getIsd = (countryCode: CountryCode) => `+${getCountryCallingCode(countryCode)}`;

export const formatPhone = (phoneData?: PhoneData | null): PhoneData => {
  const countryCode = phoneData?.countryCode;

  let isd = undefined;
  let number = '';

  if (isSupportedCountry(countryCode || '')) {
    isd = getIsd(countryCode as CountryCode);
    number = formatNumber(phoneData?.number || '', countryCode as CountryCode, 'NATIONAL');
  }

  return {
    countryCode,
    isd,
    number,
  };
};
