import Papa, { UnparseConfig, UnparseObject } from 'papaparse';

export class CsvUtils {
  static unparseWithBOM<T>(data: T[] | UnparseObject<T>, config?: UnparseConfig) {
    return `\ufeff${Papa.unparse(data, config)}`;
  }

  static unparseWithBOMAndGetBlob<T>(data: T[] | UnparseObject<T>, config?: UnparseConfig) {
    return new Blob([CsvUtils.unparseWithBOM([data], config)], {
      type: 'text/csv;charset=UTF-8',
    });
  }
}
