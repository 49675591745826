import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionIcon, NumberInput } from '@any-ui-react/core';
import classNames from 'classnames';

import { TrashIcon } from '~anyx/shared/icons';
import { NoResult, Table, TableLayout, Image } from '~anyx/shared/ui';

import { VariantSetDetail } from '../../type';

import { ProductQuantityTableMobileTable } from './ProductQuantityTableMobileTable';

export interface ProductQuantityTableProps {
  value: VariantSetDetail[];
  onQuantityChange?: (product: VariantSetDetail) => void;
  onRemoveProduct?: (product: VariantSetDetail) => void;
  error: boolean;
  loading?: boolean;
}

export const ProductQuantityTable = ({
  value = [],
  onQuantityChange,
  onRemoveProduct,
  error,
  loading,
}: ProductQuantityTableProps) => {
  const { t } = useTranslation();
  const layout = useMemo(
    () =>
      [
        {
          accessor: 'name',
          truncated: false,
          maxWidth: '25rem',
          minWidth: '15rem',
          headerRender: () =>
            t('logistic.component.productQuantityTable.table.column.name', { ns: 'logistic' }),
          cellRender: ({ productName, media }) => {
            return (
              <div
                className="flex w-full items-center justify-between truncate py-2"
                title={productName}
              >
                <div className="flex flex-1 items-center truncate">
                  <Image
                    alt={t('shared.action.image', {
                      ns: 'shared',
                      entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
                    })}
                    className="mr-2 h-10 w-10"
                    noImageClassNameWrapper="mr-2 h-10 w-10"
                    src={media}
                  />
                  <div className="truncate" title={productName}>
                    {productName}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          headerRender: () =>
            t('logistic.component.productQuantityTable.table.column.code', { ns: 'logistic' }),
          accessor: 'productCode',
          cellRender: ({ productCode }) => {
            return productCode;
          },
        },
        {
          accessor: 'quantity',
          headerRender: () =>
            t('logistic.component.productQuantityTable.table.column.quantity', { ns: 'logistic' }),
          cellRender: ({ quantity, ...rest }) => {
            return (
              <NumberInput
                value={quantity}
                max={4294967295}
                min={0}
                className="min-w-[4rem]"
                onChange={(e) =>
                  onQuantityChange?.({
                    ...rest,
                    quantity: Number(e),
                  })
                }
              />
            );
          },
        },
        {
          accessor: 'delete',
          headerRender: () => (
            <div className="mr-6 flex justify-end">
              {t('logistic.component.productQuantityTable.table.column.edit', { ns: 'logistic' })}
            </div>
          ),

          cellRender: ({ ...rest }) => (
            <div className="mr-3 flex justify-end">
              <ActionIcon
                className="card fill-gray-9 m-1 mr-2 shadow-none"
                onClick={() => onRemoveProduct?.(rest)}
                size="sm"
                variant="default"
              >
                <TrashIcon className="h-4 w-4" />
              </ActionIcon>
            </div>
          ),
        },
      ] as TableLayout<VariantSetDetail>[],
    [onQuantityChange, onRemoveProduct, t]
  );

  return (
    <Table<VariantSetDetail>
      layout={layout}
      data={value}
      loading={loading}
      wrapperClassName={classNames('border-x border-b shadow-none rounded', {
        'border-failure border-y': error,
      })}
      mobileTemplate={(row) => (
        <ProductQuantityTableMobileTable
          key={row.productVariantId}
          product={row}
          onQuantityChange={onQuantityChange}
          onRemoveProduct={onRemoveProduct}
        />
      )}
      notFoundTemplate={
        <NoResult
          title={t('shared.table.empty', {
            ns: 'shared',
            element: t('shared.entity.product', { ns: 'shared', count: 1 }),
          })}
        />
      }
    />
  );
};
