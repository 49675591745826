import styles from './AnyXCSSSpinner.module.css';

export const AnyXCSSSpinner = () => (
  <div className={styles['spinner']}>
    <span />
    <span />
    <span />
    <span />
  </div>
);
