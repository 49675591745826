import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MenuItem, MenuDivider, Menu, MenuButton } from '@szhsin/react-menu';
import classNames from 'classnames';

import { useAuthCtx } from '~anyx/common/auth';
import { UserRoutePath } from '~anyx/common/routing';
import { useSelfCtx } from '~anyx/common/self';
import { UserSettingsIcon, SignOutIcon } from '~anyx/shared/icons';

export const UserMenu = () => {
  const { self: { role, name, email, picture } = {} } = useSelfCtx();
  const { logout } = useAuthCtx();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton
          className={classNames(
            'hover:bg-gray-0 group flex w-full cursor-pointer items-center px-4 py-3 text-xs font-semibold lg:p-4',
            {
              'bg-gray-0': open,
            }
          )}
        >
          <img loading="lazy" alt={name} className="h-6 w-6 rounded-full" src={picture} />
          <span className="ml-2 hidden first-letter:uppercase lg:block">{name}</span>
        </MenuButton>
      )}
      direction="top"
      viewScroll="auto"
      align="start"
      shift={0}
      portal={true}
      menuClassName="border-l-0 border-r-0 border-b-0 shadow-user rounded-none w-56"
    >
      <div className="p-4">
        <p className="text-gray-7 text-3xs font-normal">
          {t('gql.enum.userRole', { role: role, ns: 'gql' })}
        </p>
        <p className="text-2xs mt-2 font-semibold">{name}</p>
        <p className="text-main text-3xs mt-1 block overflow-hidden overflow-ellipsis whitespace-nowrap font-normal">
          {email}
        </p>
      </div>
      <MenuDivider className="m-0" />
      <MenuItem
        onClick={() => navigate(UserRoutePath().self().path)}
        className={
          'text-main hover:bg-gray-0 m-2 flex items-center rounded px-2 py-1 font-semibold'
        }
      >
        <UserSettingsIcon className="fill-gray-7 h-5.5 w-5.5" />
        <span className="ml-2 text-xs">{`
          ${t('shared.action.settings', {
            ns: 'shared',
            entity: `${t('shared.entity.user', { ns: 'shared', count: 1 })}`,
          })}`}</span>
      </MenuItem>
      <MenuItem
        onClick={() => logout()}
        className={
          'text-main hover:bg-gray-0 m-2 flex items-center rounded px-2 py-1 font-semibold'
        }
      >
        <SignOutIcon className="fill-gray-7 h-5.5 w-5.5" />
        <span className="ml-2 text-xs">{`${t('shared.button.signOut', { ns: 'shared' })}`}</span>
      </MenuItem>
      <MenuDivider className="m-0" />
    </Menu>
  );
};
