import { ServerParseError, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { IdToken } from '@auth0/auth0-react';
import { FormattedExecutionResult, GraphQLFormattedError } from 'graphql';
import { ObjMap } from 'graphql/jsutils/ObjMap';

import { environment } from '~anyx/app-core/env';

import { ErrorsRoutePath } from '~anyx/common/routing';
import { ObjectUtils } from '~anyx/shared/utils';

import { showSubServiceErrorNotification } from './subserviceErrorNotifier';

type ErrorSetting = {
  handleError: ({
    error,
    response,
  }: {
    error: GraphQLFormattedError;
    response: FormattedExecutionResult<ObjMap<unknown>, ObjMap<unknown>> | undefined;
  }) => void;
};

type MessageErrorSetting = Record<string, ErrorSetting>;

/**
 * If we can get the service name from the error,
 * we show the notification.
 */
const handleSubServiceError = ({ error }: { error: GraphQLFormattedError }) => {
  const serviceName = (error?.extensions?.['serviceName'] as string) || '';

  if (serviceName === 'anychat-graphql') return;

  if (serviceName) showSubServiceErrorNotification(serviceName);
};

const errorSetting: Partial<MessageErrorSetting> = {
  UNAUTHORIZED: {
    handleError: ({ response = {}, error }) => {
      if (!ObjectUtils.isEmpty(response.data)) {
        handleSubServiceError({ error });

        return;
      }

      if (window.location.pathname !== ErrorsRoutePath().unauthorized().path) {
        window.location.href = ErrorsRoutePath().unauthorized().path;
      }
    },
  },
};

type GetErrorSettingByMessage = (message: string) => ErrorSetting;
/**
 * Use the error message to find the related error handler from ErrorSetting.
 * If we can't find it, we returns a default one to make it more defensive,
 * in case BE response the error message that FE doesn't know it or doesn't handle it.
 */
const getErrorSettingByMessage: GetErrorSettingByMessage = (message) =>
  errorSetting[message] || {
    handleError: ({ response = {}, error }) => {
      if (!ObjectUtils.isEmpty(response.data)) {
        handleSubServiceError({ error });

        return;
      }
    },
  };

type ErrorLinkProps = {
  silentLogin: () => Promise<(string | IdToken)[] | undefined>;
};
/**
 * The middleware decides what to do when getting errors
 */
export const errorLink = ({ silentLogin }: ErrorLinkProps) =>
  onError(({ graphQLErrors, networkError, response, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const message = error.message;
        const locations = error.locations;
        const path = error.path;

        if (environment.enableDebug) {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }

        getErrorSettingByMessage(message).handleError({ error, response });
      });

      return;
    }

    if (networkError) {
      if (Object.prototype.hasOwnProperty.call(networkError, 'response')) {
        const serverError = networkError as ServerParseError;
        const serverUrl = new URL(serverError.response.url);
        const graphQLUrl = new URL(environment.api.graphql);

        if (serverUrl.origin === graphQLUrl.origin && serverError.statusCode === 401) {
          return fromPromise(silentLogin())
            .map((tokens) => {
              const authorization = tokens && tokens[0];
              const oldHeaders = operation.getContext()['headers'];

              return operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${authorization}`,
                },
              });
            })
            .flatMap(() => forward(operation));
        }
      }

      if (environment.enableDebug) {
        console.error(`[Network error]: ${networkError}`);
      }

      return;
    }

    return;
  });
