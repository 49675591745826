import { useCheckOnboardingQuery } from '../graphql';

export const useOnboarding = ({
  storeId,
  skip = false,
}: {
  storeId?: string | null;
  skip?: boolean;
}) => {
  const { data, loading, error, refetch } = useCheckOnboardingQuery({
    skip: !storeId || skip,
    variables: {
      // After refactoring, Reporting BE using $input: OnboardingInput! instead of $storeId: Long!
      input: {
        storeId: storeId || '',
      },
      // Marketplace BE is using $storeId: Long!
      storeId: storeId || '',
    },
  });

  return {
    onboarding: {
      hasShopifyChannel: data?.settingGuide?.hasShopifyChannel ?? skip,
      hasInputUnitCost: data?.settingGuide?.hasInputUnitCost ?? skip,
      hasConnectedSaleChannel: data?.hasConnectedSaleChannel ?? skip,
      hasConnectedDatasourceChannel: data?.settingGuide?.hasConnectedDatasourceChannel ?? skip,
      hasSaleChannelCostIndex: data?.settingGuide?.hasSaleChannelCostIndex ?? skip,
      hasAnalyticsReport: data?.hasAnalyticsReport ?? skip,
      hasAdvertisingDatasource: data?.hasAdvertisingConnection ?? skip,
      hasRegisteredOrder: data?.onboarding?.hasRegisteredOrder ?? skip,
      inactiveSaleChannelCount: data?.inactiveIntegrationCount || 0,
    },
    loading,
    error,
    refetch,
  };
};
