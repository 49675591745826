import { Time } from '../models';

export class TimeUtils {
  static readonly MILLISECONDS = 1000;
  static readonly SECONDS = 60;
  static readonly MINUTES = 60;
  static readonly HOURS = 24;

  static formatDuration(duration: number | null) {
    return duration !== null ? TimeUtils.formatNumberToMss(duration) : '';
  }

  static formatNumberToMss(duration: number) {
    const minutes = Math.floor(duration / TimeUtils.MINUTES);
    const seconds = (duration - minutes * TimeUtils.MINUTES).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  static getHourMinuteFromString(time: string): Time {
    const splitTime = (time || '').split(':');
    return {
      hour: Number((splitTime[0] || '').trim()),
      minute: Number((splitTime[1] || '').trim()),
    };
  }

  static getStringFromRange(time: Time): string {
    const formattedHours = time.hour.toString().padStart(2, '0');
    const formattedMinutes = time.minute.toString().padStart(2, '0');
    return [formattedHours, formattedMinutes].join(':');
  }

  static secondsFromMilliseconds(milliseconds: number | string, decimals?: number): number {
    const result = +milliseconds / TimeUtils.MILLISECONDS;
    if (decimals !== undefined) {
      return +result.toFixed(decimals);
    }

    return result;
  }

  static generateTimeRangeOptions = () => {
    // Time ranges multiples of 30 minutes
    return Array.from({ length: 24 }, (_, hours) => {
      return Array.from({ length: 2 }, (_, index) => {
        const minutes = index * 30;
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const label = `${formattedHours}:${formattedMinutes}`;
        const value = hours * 60 + minutes;
        return { label, value };
      });
    }).flat();
  };

  static getMinutesValueFromTime = (time: Time): number => {
    return time.hour * 60 + time.minute;
  };

  static getTimeFromValue = (value: number): Time => {
    return { hour: Math.floor(value / 60), minute: value % 60 };
  };

  static getTimeFromTimestamp = (milliseconds: number): Time => {
    return {
      hour: Math.floor(
        milliseconds / (TimeUtils.SECONDS * TimeUtils.MINUTES * TimeUtils.MILLISECONDS)
      ),
      minute: Math.floor(
        (milliseconds % (TimeUtils.SECONDS * TimeUtils.MINUTES * TimeUtils.MILLISECONDS)) /
          (TimeUtils.SECONDS * TimeUtils.MILLISECONDS)
      ),
      second: Math.floor(
        (milliseconds % (TimeUtils.SECONDS * TimeUtils.MILLISECONDS)) / TimeUtils.MILLISECONDS
      ),
      millisecond: milliseconds % TimeUtils.MILLISECONDS,
    };
  };
}
