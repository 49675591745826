import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const ReportMarketplaceEventRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(
    workspaceStoreId ? '/:workspaceStoreId/marketplace-event' : '/marketplace-event',
    {
      workspaceStoreId,
    }
  );

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
  };
};
