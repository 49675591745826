import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { ReportSalesChannelRoutePath } from '~anyx/common/routing';
import { WorkspaceMode } from '~anyx/common/workspace';
import { ChannelUtils } from '~anyx/feature/marketplace';
import { CHANNEL_COLOR, getChannelColors } from '~anyx/feature/report';
import { ReportDimensions } from '~anyx/shared/graphql';
import { ArrowRightIcon } from '~anyx/shared/icons';
import { RouterUtils } from '~anyx/shared/utils';

import {
  DdiPieChartProps,
  DdiSalesChannelPieChart,
  DdiSummaryCard,
  DdiProductRankingTable,
  useDdiHomeFilter,
  DdiHomeFilters,
  DdiDailyChartProps,
  DdiDailyChart,
} from './components';
import { MockSalesChannels, MockDdiProductRankingTable, DdiMockPieChart } from './mocks';
import {
  useDdiHomeReportSummary,
  useDdiHomeReportPieChart,
  useDdiHomeReportProductTable,
  useDdiHomeReportChart,
} from './operation';
import { computeDDIChartData } from './utils';

interface ReportSectionProps {
  storeId?: string;
  accountId?: string;
  mode?: WorkspaceMode;
  hasSalesChannels: boolean;
  shouldShowMock: boolean;
}

export const DDIReportSection = ({
  storeId,
  accountId,
  mode,
  hasSalesChannels,
  shouldShowMock,
}: ReportSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const allSearchParams = useMemo(() => {
    return RouterUtils.getAllSearchParams(searchParams);
  }, [searchParams]);

  const { dimensions: _dimensions, ...search } = allSearchParams;
  const { filterInputs, filters } = useDdiHomeFilter({
    storeId,
    accountId,
  });
  const { metric } = filters.current;

  const shouldSkip = !hasSalesChannels && mode === WorkspaceMode.ON;

  const { reportSummary, loading: summaryLoading } = useDdiHomeReportSummary({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportPieChart: channelPieChart, loading: channelPieLoading } = useDdiHomeReportPieChart({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportProductTable, loading: productRankingLoading } = useDdiHomeReportProductTable({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportChart, loading: chartLoading } = useDdiHomeReportChart({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportChannelChart, channelList } = computeDDIChartData({
    metric,
    data: reportChart,
  });

  const channelTranslation = (dataKey?: string | number) =>
    t('gql.enum.channel', { ns: 'gql', channel: dataKey });

  const channelColors = getChannelColors(channelList);

  const dailyChartProps: DdiDailyChartProps = {
    shouldShowMock,
    shouldShowNoData: false,
    filters,
    metric,
    loading: chartLoading || productRankingLoading,
    ...{
      data: reportChannelChart,
      colors: channelColors,
      dataKeys: channelList,
      dataKeyTranslation: channelTranslation,
    },
    ...(shouldShowMock
      ? {
          data: reportChannelChart,
          colors: getChannelColors(MockSalesChannels),
          dataKeys: MockSalesChannels,
        }
      : {}),
  };

  const channelPieDataKeys = ChannelUtils.sortChannels({
    channels: channelPieChart?.grossSales,
    byField: 'saleChannelType',
  }).map((item) => item.saleChannelType as string);

  const pieChartProps = {
    filters,
    shouldShowMock,
    loading: channelPieLoading || chartLoading,
    currency: channelPieChart?.currency,
    ...{
      data: channelPieChart,
      dataKeys: channelPieDataKeys,
      colors: channelColors,
    },
    ...(shouldShowMock
      ? {
          data: DdiMockPieChart.DEFAULT.ddiHomeReportPieChart,
          colors: getChannelColors(MockSalesChannels),
          dataKeys: MockSalesChannels,
        }
      : {}),
  } as DdiPieChartProps;

  return (
    <div data-test-id="report-section">
      <div className="card mb-4 mt-4 rounded-none bg-white lg:mt-0 lg:rounded">
        <DdiHomeFilters {...filters} />
      </div>
      <div>
        {hasSalesChannels && (
          <DdiSummaryCard
            loading={summaryLoading}
            data={reportSummary}
            currency={reportSummary?.currency}
          />
        )}
        <div className="card mb-4 flex flex-wrap lg:grid lg:grid-cols-7">
          <div className="border-gray-1 mb-4 w-full border-r lg:col-span-5 lg:mb-0">
            <DdiDailyChart {...dailyChartProps} />
          </div>
          <div className="w-full lg:col-span-2">
            <DdiSalesChannelPieChart {...pieChartProps} />
          </div>
          <div className="border-gray-1 flex w-full border-t px-4 py-3 leading-8 lg:col-span-7">
            <div className="flex flex-wrap">
              {channelList?.map((channel, i) => {
                return (
                  <div key={i} className="text-4xs mr-2 flex items-center font-semibold">
                    <div
                      style={{
                        background: CHANNEL_COLOR[channel]?.['default'],
                      }}
                      className="mr-1 h-2.5 w-2.5 shrink-0 rounded-full"
                    />
                    <p className="truncate">{channelTranslation(channel)}</p>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              onClick={() =>
                navigate({
                  pathname: ReportSalesChannelRoutePath().list().path,
                  search: createSearchParams({
                    dimensions: ReportDimensions.CHANNELS,
                    ...search,
                  }).toString(),
                })
              }
              className="link-basic text-2xs ml-auto inline-flex flex-shrink-0 items-center pl-6"
            >
              {t('core.page.home.report.table.link.seeMore', { ns: 'core' })}
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
      <DdiProductRankingTable
        shouldShowMock={shouldShowMock}
        data={
          shouldShowMock && !productRankingLoading
            ? MockDdiProductRankingTable.DEFAULT.ddiHomeReportTopProducts
            : reportProductTable
        }
        loading={productRankingLoading}
      />
    </div>
  );
};
