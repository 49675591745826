import { Outlet } from 'react-router-dom';

import { OnboardingProvider } from '~anyx/common/onboarding';
import { useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';

export const HomeTemplate = () => {
  const { workspace, mode } = useWorkspaceCtx();
  return (
    <div className="w-full">
      <OnboardingProvider storeId={workspace?.storeId} skip={mode !== WorkspaceMode.ON}>
        <Outlet />
      </OnboardingProvider>
    </div>
  );
};
