import { ReactNode, useEffect, useState } from 'react';

import { SortDownIcon, SortUpIcon } from '~anyx/shared/icons';

import classes from './SortButton.module.css';

export type SortingType = 'OFF' | 'ASC' | 'DESC';

export type SortButtonV2Props = {
  disabled?: boolean;
  label?: ReactNode;
  sorting?: SortingType;
  align?: 'left' | 'right';
  disabledSorting?: SortingType[];
  onClick?: (sorting: SortingType) => void;
};

const getSymmetricDifference = <T,>(array1: T[], array2: T[]): T[] => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  return [...array1, ...array2].filter(
    (item) => (set1.has(item) && !set2.has(item)) || (!set1.has(item) && set2.has(item))
  );
};

export const SortButtonV2 = ({
  disabled: disabledFromProps,
  label,
  align,
  sorting: sortingFromProps,
  disabledSorting = [],
  onClick,
}: SortButtonV2Props) => {
  const sortingOrder = getSymmetricDifference<SortingType>(['OFF', 'ASC', 'DESC'], disabledSorting);
  const disabled = disabledFromProps || sortingOrder.length === 0;
  const [sorting, setSorting] = useState<SortingType>('OFF');

  const getNextSorting = (sorting: SortingType = 'OFF') => {
    const currentIndex = sortingOrder.indexOf(sorting);

    return sortingOrder[(currentIndex + 1) % sortingOrder.length];
  };

  const handleClick = () => {
    const nextSorting = getNextSorting(sorting) || 'OFF';

    if (disabledSorting.includes(nextSorting)) return;

    setSorting(nextSorting);
    onClick?.(nextSorting);
  };

  useEffect(() => {
    if (sortingFromProps) setSorting(sortingFromProps);
  }, [sortingFromProps]);

  return (
    <button
      className={classes['button']}
      data-sorting={sorting}
      data-disabled={disabled}
      data-active={sorting !== 'OFF'}
      data-align={align}
      onClick={disabled ? undefined : handleClick}
    >
      {label}
      {!disabled && (
        <span className={`icons ${classes['icons']}`}>
          <SortUpIcon data-active={sorting === 'ASC'} className={`${classes['icon']} iconSortUp`} />
          <SortDownIcon
            data-active={sorting === 'DESC'}
            className={`${classes['icon']} iconSortDown`}
          />
        </span>
      )}
    </button>
  );
};
