import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '~anyx/shared/ui';
import { AnyXError } from '~anyx/shared/utils';

import { type FbPageData } from '../components';

import { type PlatformType } from './useConnectFBToAnyChat';
import { useRefreshFBToken } from './useRefreshFBToken';

interface UseRefreshFBPermissionProps {
  onRefreshed?: () => void;
  pageId: string;
  storeId: string;
  platform: PlatformType;
}

export const useRefreshFBPermission = ({
  onRefreshed,
  pageId,
  storeId,
  platform,
}: UseRefreshFBPermissionProps) => {
  const { t } = useTranslation(['crmIntegration', 'shared']);
  const [refreshing, setRefreshing] = useState(false);

  const { refreshFBToken } = useRefreshFBToken();

  const refreshFBPermission = async (fbPages: FbPageData[]) => {
    const connectedFbPage = fbPages.find((pages) => pages.id === pageId);
    if (!connectedFbPage) {
      toast.error(t('crmIntegration.shared.notify.refreshPermission.noPermission'));
      return;
    }

    try {
      setRefreshing(true);

      await refreshFBToken({
        pageId: connectedFbPage.id,
        accessToken: connectedFbPage.access_token,
        storeId,
        platform,
      });

      toast.success(t('crmIntegration.shared.notify.refreshPermission.success'));

      onRefreshed?.();
    } catch (error) {
      let message = t('crmIntegration.shared.notify.refreshPermission.error');

      if (error instanceof AnyXError) {
        message = error.message;
      }

      toast.error(message);
    } finally {
      setRefreshing(false);
    }
  };

  return {
    refreshing,
    refreshFBPermission,
  };
};
