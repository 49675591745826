import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { NumberFormat, PieChart } from '~anyx/shared/ui';
import { NumberUtils, useScreenBreakpoint } from '~anyx/shared/utils';

interface ReviewPieChartProps {
  data: readonly {
    key: string;
    percentage: string;
    reviewIds: ReadonlyArray<string>;
  }[];
  colors: Record<string, string>;
  onClickPie?: (data: unknown) => void;
}

export const ReviewPieChart = ({ data, colors, onClickPie }: ReviewPieChartProps) => {
  const { t } = useTranslation('report');
  const isDoubleExtraLargeScreen = useScreenBreakpoint('2xl');
  const isExtraLargeScreen = useScreenBreakpoint('xl');

  const formattedData = data.map((item) => {
    return {
      key: item.key,
      value: NumberUtils.toNumber(item.percentage),
      reviewIds: item.reviewIds,
      numberFormat: NumberFormat.PERCENTAGE,
    };
  });

  const dataKeyTranslation = (dataKey?: string | number) => {
    return t('report.page.product.reviewSentiment', { sentiment: dataKey });
  };

  const getOuterRadius = () => {
    if (isDoubleExtraLargeScreen) return 130;
    if (isExtraLargeScreen) return 110;
    return 100;
  };

  return (
    <div className="flex flex-wrap gap-8 py-2">
      <div className="min-w-56 flex-1">
        <PieChart
          className={classNames({
            '!h-72': isDoubleExtraLargeScreen,
          })}
          dataKeyTranslation={dataKeyTranslation}
          colors={colors}
          data={formattedData}
          dataKeys={data.map((item) => item.key)}
          innerRadius={0}
          outerRadius={getOuterRadius()}
          onClickPie={onClickPie}
        />
      </div>
      <div className="flex min-w-56 flex-1 items-center justify-center">
        <div className="flex flex-col items-start justify-center gap-3">
          {data.map((item) => {
            return (
              <div className="flex items-center gap-1" key={item.key}>
                <div className="flex items-center gap-1">
                  <div
                    className="h-4 w-4 rounded"
                    style={{ background: colors[item.key as keyof typeof colors] }}
                  />
                  <p>{dataKeyTranslation(item.key)}</p>
                </div>
                <p className="font-semibold">{`: ${NumberUtils.toNumber(item.percentage).toFixed(
                  1
                )}%`}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
