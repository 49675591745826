import React from 'react';
import { RouteObject } from 'react-router-dom';

import { ChatPagePathKey } from '~anyx/common/routing';

const ChatPage = React.lazy(() => import('./pages/chat'));

const PATH_KEY = ChatPagePathKey.enum.chat;

export const routes = (pathKey: ChatPagePathKey = PATH_KEY): RouteObject[] => [
  {
    index: true,
    element: <ChatPage key={pathKey} pathKey={pathKey} />,
  },
  {
    path: 't?/:chatId?',
    element: <ChatPage key={pathKey} pathKey={pathKey} />,
  },
];
