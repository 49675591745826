import classNames from 'classnames';

import { RestrictedWorkspaceMode, WorkspaceMode } from '~anyx/common/workspace';
import { RouteLinks } from '~anyx/shared/utils';

import { WorkspaceSelect } from '../workspace-select';

import { Header } from './Header';
import { NavigationLinks } from './nav-links';
import styles from './sidenav.module.css';
import { UserMenu } from './UserMenu';

export interface SidenavProps {
  links: Array<RouteLinks>;
}

export const Sidenav = ({ links }: SidenavProps) => {
  return (
    <aside
      className={classNames(
        'shadow-card sticky top-0 h-dvh w-56 shrink-0 overflow-y-auto bg-white',
        styles
      )}
      aria-label="Sidebar"
    >
      <div className="flex h-dvh flex-col">
        <Header />

        {/* Workspace Select */}
        <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.ON]}>
          <div className="mb-3 px-2">
            <WorkspaceSelect />
          </div>
        </RestrictedWorkspaceMode>

        {/* Navigation */}
        <NavigationLinks links={links} />
        {/* User Menu */}
        <hr className="mt-auto" />

        <UserMenu />
      </div>
    </aside>
  );
};
