import { createContext, useContext } from 'react';

import { AppState, IdToken, LogoutOptions, RedirectLoginOptions, User } from '@auth0/auth0-react';

type AuthContextType = {
  error: Error | undefined;
  authenticating: boolean;
  authenticated: boolean;
  logout: (options?: LogoutOptions) => void;
  login: (options?: RedirectLoginOptions<AppState>) => void;
  silentLogin: () => Promise<(string | IdToken)[] | undefined>;
  user: User | undefined;
};

const defaultAuth: AuthContextType = {
  error: undefined,
  authenticated: false,
  authenticating: false,
  logout: () => null,
  login: () => null,
  silentLogin: () => Promise.resolve(undefined),
  user: undefined,
};

export const AuthContext = createContext<AuthContextType>(defaultAuth);

export const useAuthCtx = () => {
  const { logout, authenticated, authenticating, error, user, login, silentLogin } =
    useContext(AuthContext);

  return { logout, authenticated, authenticating, error, user, login, silentLogin };
};
