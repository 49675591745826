import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/**
 *
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const StoreRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/store' : '/store', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    edit: ({ id = '' } = {}) =>
      workspaceStoreId
        ? root
        : createPath(`${root.path}/:id`, { id }, workspaceStoreId ? [] : ['id']),
  };
};
