import { useTranslation } from 'react-i18next';

import { Badge } from '@any-ui-react/core';

import { SkeletonLoader } from '~anyx/shared/ui';

import { RatingBar } from '../rating-bar';
import { RatingStar } from '../rating-star';

export interface RatingSummaryProps {
  ratingDistribution?: Record<number, string>;
  loading?: boolean;
  totalRatings?: number;
  averageRating?: number;
  selectedRating?: string;
  onSelectRating?: (rating: string) => void;
}

export const RatingSummary = ({
  totalRatings,
  averageRating,
  ratingDistribution,
  selectedRating,
  onSelectRating,
  loading,
}: RatingSummaryProps) => {
  const { t } = useTranslation('report');
  const sortedRatings = Object.keys(ratingDistribution || {})
    .map(Number)
    .sort((a, b) => b - a);

  return (
    <div>
      <div className="mb-2 flex h-6 items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-x-2">
          <SkeletonLoader loading={loading} className="h-5 w-40">
            <RatingStar averageRating={averageRating} />
            <p className="font-semibold leading-10">
              {t('report.page.product.outOfFive', {
                ns: 'report',
                amount: averageRating,
                compact: false,
              })}
            </p>
          </SkeletonLoader>
        </div>
        {selectedRating && <Badge color="green">Review Filtering</Badge>}
      </div>
      <SkeletonLoader loading={loading} className="mb-4 h-4 w-40">
        <p className="text-2xs mb-4 leading-8">
          {t('report.page.product.globalRatings', { ns: 'report', amount: totalRatings })}
        </p>
      </SkeletonLoader>

      <div className="flex flex-col gap-y-2">
        {sortedRatings.map((star) => (
          <RatingBar
            key={star}
            star={star}
            percentage={ratingDistribution?.[star] || '0'}
            selectedRating={selectedRating}
            onSelectRating={onSelectRating}
            loading={loading}
          />
        ))}
      </div>
    </div>
  );
};
