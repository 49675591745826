import { RouteObject } from 'react-router-dom';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

import { routes as BroadcastRoutes } from './broadcast/routing';

const PATH_KEY = CrmRoutePathKey.enum.whatsapp;

export const routes: RouteObject[] = [
  {
    path: 'whatsapp',
    children: [...ChatRoutes(PATH_KEY), ...BroadcastRoutes],
  },
];
