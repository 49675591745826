import { useEffect, useRef } from 'react';

import './GoogleTranslateWrapper.css';
import Cookies from 'js-cookie';

import { useUserLanguage } from '~anyx/common/self';
import { LanguageUtils } from '~anyx/shared/utils';

const GOOGLE_TRANSLATE_SCRIPT_SRC =
  'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

export const GoogleTranslateWrapper = ({
  children,
  reset,
  translate,
  isTranslated,
  loading,
}: {
  children?: React.ReactNode | React.ReactNode[];
  isTranslated?: boolean;
  translate?: () => void;
  reset?: () => void;
  loading?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Window = window as any;
  const scriptLoadedRef = useRef(false);
  const scriptReInitRef = useRef(false);
  const language = useUserLanguage();
  const currentLanguage = LanguageUtils.getTwoLettersCode(language);
  const src = GOOGLE_TRANSLATE_SCRIPT_SRC;

  useEffect(() => {
    const loadScript = async () => {
      Cookies.set('googtrans', `/auto/${currentLanguage}`);
      try {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (!scriptLoadedRef.current) {
          if (!existingScript) {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
            Window.googleTranslateElementInit = () => {
              new Window.google.translate.TranslateElement({}, 'google_translate_element');
            };
            scriptLoadedRef.current = true;
          }
        } else {
          if (scriptReInitRef.current && !loading) {
            isTranslated ? translate?.() : reset?.();
          }
          scriptReInitRef.current = true;
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadScript();
    return () => {
      const iframe = document.querySelector('#\\:1\\.container') as HTMLIFrameElement;
      const button = iframe?.contentDocument?.querySelector('#\\:1\\.close') as HTMLButtonElement;
      if (button) {
        button.click();
      }
      const script = document.querySelector(`script[src="${src}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <div id="google_translate_element" />
      {children}
    </>
  );
};
