import { useTranslation } from 'react-i18next';

import { SegmentedButtonGroup } from '@any-ui-react/core';
import { Select } from '@any-ui-react/select';
import classNames from 'classnames';

import { RestrictedWorkspaceMode, WorkspaceMode } from '~anyx/common/workspace';
import { HomeMetricOption, MetricData, ReportUtils } from '~anyx/feature/report';
import { ReportChartTab, SaleChannelType } from '~anyx/shared/graphql';
import {
  MultiValueTooltip,
  LineChart,
  SkeletonChartWrapper,
  StackedBarChart,
  NoResult,
  ContentColumn,
} from '~anyx/shared/ui';
import { FilterProps, ObjectUtils, useScreenBreakpoint } from '~anyx/shared/utils';

import { HomeFilterInputFilterType } from '../home-filters';
import { NoSalesChannelConnection } from '../no-sales-channel-connection';

const stackedBarChartMetrics = [
  HomeMetricOption.GROSS_SALES,
  HomeMetricOption.ORDERS,
  HomeMetricOption.MARGINAL_PROFIT,
  HomeMetricOption.SESSIONS,
  HomeMetricOption.GROSS_PROFIT,
];

export interface DailyChartProps {
  shouldShowMock: boolean;
  shouldShowNoData: boolean;
  filters: FilterProps<HomeFilterInputFilterType>;
  metric: HomeMetricOption;
  colors: Record<string, string>;
  dataKeys: Array<string | SaleChannelType>;
  data: Record<HomeMetricOption, MetricData>;
  dataKeyTranslation: (dataKey?: string | number) => string;
  loading?: boolean;
  theme?: string;
}

export const DailyChart = ({
  data,
  filters,
  shouldShowMock,
  shouldShowNoData,
  loading,
  metric,
  dataKeys,
  dataKeyTranslation,
  colors,
}: DailyChartProps) => {
  const { t } = useTranslation();
  const { current, changeFilter } = filters;
  const { chartTab } = current;
  const isLargeScreen = useScreenBreakpoint('lg');
  const options = ObjectUtils.arrayFromEnum<HomeMetricOption>(HomeMetricOption);
  const getInterval = (length = 0) => {
    return isLargeScreen ? Math.floor(length / 14) : Math.floor(length / 10);
  };

  return (
    <div data-test-id="report-daily-chart" className="relative h-full rounded-none lg:rounded">
      <div className={classNames('mb-2 px-4 pt-3', { 'blur-sm': shouldShowMock })}>
        <div className="mb-2 flex justify-start gap-2">
          <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.OFF, WorkspaceMode.ACCOUNT_ONLY]}>
            <SegmentedButtonGroup
              className="grid grid-cols-2 [&>button]:col-span-1"
              onChange={(value) => changeFilter('chartTab', value)}
              value={chartTab}
              options={[
                {
                  value: ReportChartTab.CHANNEL,
                  label: t('shared.button.channel', { ns: 'shared' }),
                },
                {
                  value: ReportChartTab.STORE,
                  label: t('shared.button.store', { ns: 'shared' }),
                },
              ]}
            />
          </RestrictedWorkspaceMode>
          <div className="w-1/2">
            <Select
              onChange={(option) => {
                if (option?.value) {
                  changeFilter('metric', option.value);
                }
              }}
              options={options.map((metric) => ({
                label: t('gql.enum.reportSummary', { ns: 'gql', metric }),
                value: metric,
              }))}
              value={metric}
            />
          </div>
        </div>
        <SkeletonChartWrapper
          loading={loading}
          error={!data?.[metric]?.length || shouldShowNoData}
          errorNode={
            <NoResult
              wrapperClassName="py-20 my-4 text-center !gap-2"
              titleClassName="text-sm"
              descriptionClassName="lg:!text-2xs"
              title={t('shared.table.empty', {
                ns: 'shared',
                element: t('shared.entity.data', { ns: 'shared', count: 1 }),
              })}
              description={t('shared.chart.guideline', { ns: 'shared' })}
            />
          }
        >
          {stackedBarChartMetrics.includes(metric) ? (
            <StackedBarChart
              legendVerticalAlign="bottom"
              tooltip={
                <MultiValueTooltip
                  position="center"
                  dataKeyTranslation={dataKeyTranslation}
                  columns={ContentColumn.TWO}
                />
              }
              colors={colors}
              data={data[metric]}
              dataKeys={dataKeys}
              xAxisInterval={getInterval(data[metric]?.length)}
              xAxisPadding={ReportUtils.getBarChartPadding({
                length: data[metric]?.length,
                maxPadding: isLargeScreen ? 250 : 200,
              })}
            />
          ) : (
            <LineChart
              legendVerticalAlign="bottom"
              tooltip={<MultiValueTooltip dataKeyTranslation={dataKeyTranslation} />}
              colors={colors}
              data={data[metric]}
              dataKeys={dataKeys}
              xAxisInterval={getInterval(data[metric]?.length)}
            />
          )}
        </SkeletonChartWrapper>
      </div>
      {shouldShowMock && <NoSalesChannelConnection />}
    </div>
  );
};
