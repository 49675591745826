import { NoWorkspaceLinkProps, WithWorkspaceLinkProps } from '../types';

import { usePermissionsNoWorkspace } from './no-workspace';
import { usePermissionsWithWorkspace } from './with-workspace';

type WorkspacePermissions = {
  withWorkspace: () => WithWorkspaceLinkProps;
  noWorkspace: () => NoWorkspaceLinkProps;
};

export const useWorkspacePermissions = (): WorkspacePermissions => {
  const { getPermissionsWithWorkspace } = usePermissionsWithWorkspace();
  const { getPermissionsNoWorkplace } = usePermissionsNoWorkspace();

  return {
    withWorkspace: getPermissionsWithWorkspace,
    noWorkspace: getPermissionsNoWorkplace,
  };
};
