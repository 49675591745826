import React from 'react';
import { RouteObject } from 'react-router-dom';

const BroadcastListPage = React.lazy(() => import('./pages/list'));
const BroadcastAddPage = React.lazy(() => import('./pages/add'));
const BroadcastEditPage = React.lazy(() => import('./pages/edit'));
const GreetingMessagePage = React.lazy(() => import('./pages/greeting'));
const GreetingMessageConnectLine = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.GreetingMessageConnectLine }))
);
const BroadcastConnectLine = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.BroadcastConnectLine }))
);

export const routes: RouteObject[] = [
  {
    path: 'greeting-message',
    element: <GreetingMessageConnectLine />,
    children: [
      {
        index: true,
        element: <GreetingMessagePage />,
      },
    ],
  },
  {
    path: 'broadcast',
    element: <BroadcastConnectLine />,
    children: [
      {
        index: true,
        element: <BroadcastListPage />,
      },
      {
        path: 'add',
        element: <BroadcastAddPage />,
      },
      {
        path: ':broadcastId',
        element: <BroadcastEditPage />,
      },
    ],
  },
];
