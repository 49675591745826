import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const ReportSalesChannelAnalyticsRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId' : '/', {
    workspaceStoreId,
  });

  return {
    salesChannel: () => {
      const salesChannelRoot = createPath(`${root.path}/sales-channel-analytic`);

      return {
        root: () => salesChannelRoot,
        amazon: () => createPath(`${salesChannelRoot.path}/amazon`),
        lazada: () => createPath(`${salesChannelRoot.path}/lazada`),
        rakuten: () => createPath(`${salesChannelRoot.path}/rakuten`),
        shopify: () => createPath(`${salesChannelRoot.path}/shopify`),
        shopee: () => createPath(`${salesChannelRoot.path}/shopee`),
        tokopedia: () => createPath(`${salesChannelRoot.path}/tokopedia`),
        tikTok: () => createPath(`${salesChannelRoot.path}/tik-tok`),
        yahoo: () => createPath(`${salesChannelRoot.path}/yahoo`),
      };
    },
  };
};
