import React from 'react';
import { RouteObject } from 'react-router-dom';

const WelcomePage = React.lazy(() => import('./pages/welcome-page'));

export const routes: RouteObject[] = [
  {
    path: 'welcome',
    element: <WelcomePage />,
  },
];
