import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';

import { NumberUtils } from '~anyx/shared/utils';

import { DEFAULT_HEIGHT, DEFAULT_WIDTH, DEFAULT_Y_AXIS_WIDTH } from './chart.config';
import styles from './chart.module.css';
import { style } from './chart.theme';
import { CustomXAxisTick } from './CustomXAxisTick';

interface LineChartProps<T extends Record<string, unknown>> {
  dataKey?: string;
  tooltip?: ContentType<ValueType, NameType>;
  color?: string;
  data?: T[];
  xAxisInterval?: number;
  yAxisWidth?: number;
  width?: number;
  height?: number;
  theme?: 'light' | 'dark';
  isTimeScale?: boolean;
  legendVerticalAlign?: 'top' | 'middle' | 'bottom';
  legendAlign?: 'left' | 'center' | 'right';
}

export const SingleLineChart = <T extends Record<string, unknown>>({
  data,
  tooltip,
  xAxisInterval,
  yAxisWidth = DEFAULT_Y_AXIS_WIDTH,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  theme = 'light',
}: LineChartProps<T>) => {
  return (
    <div className={styles['chart-wrapper']}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartLineChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="2 2"
            vertical={false}
            stroke={style[theme].cartesianGrid.stroke}
          />
          <XAxis
            dataKey="key"
            type={'number'}
            scale={'time'}
            domain={['dataMin', 'dataMax']}
            interval={xAxisInterval}
            tick={<CustomXAxisTick theme={theme} />}
            tickLine={{ ...style[theme].xAxis.tickLine }}
            axisLine={{ ...style[theme].xAxis.axisLine }}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            domain={['auto', (dataMax: number) => dataMax * 1.2]}
            tickLine={false}
            tick={{ ...style[theme].yAxis.tick }}
            tickFormatter={(value) => NumberUtils.format(value, { notation: 'compact' }) || ''}
            axisLine={false}
            width={yAxisWidth}
          />
          <Tooltip
            wrapperStyle={{ outline: 'none', zIndex: 10 }}
            content={tooltip}
            cursor={{ ...style[theme].tooltip.cursor }}
            isAnimationActive={false}
            allowEscapeViewBox={{ x: false, y: true }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={style[theme].line.stroke}
            dot={false}
            strokeWidth={2}
            activeDot={{
              stroke: style[theme].line.activeDot.stroke,
              ...style[theme].activeDot,
            }}
          />
        </RechartLineChart>
      </ResponsiveContainer>
    </div>
  );
};
