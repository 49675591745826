import { AnyXLanguage, LanguageUtils } from './language.utils';

interface GitbookLinkOptions {
  /**
   * A function that takes a language and returns a localized path string.
   * Defaults to `defaultLocalizer` which generates a versioned path based on the provided language.
   */
  localization?: (lg: AnyXLanguage) => string;
  /**
   * The namespace to use in the URL. Defaults to 'any-x'.
   */
  namespace?: string;
  /**
   * The current language to use. Defaults to the result of `LanguageUtils.getCurrentLanguage()`.
   */
  language?: AnyXLanguage;
  /**
   * An array of allowed languages. If the current language is not in this array,
   * the `defaultLanguage` will be used instead. Defaults to all languages in `AnyXLanguage`.
   */
  allowedLanguages?: AnyXLanguage[];
  /**
   * The default language to use if the current language is not in the allowed languages.
   * Defaults to `AnyXLanguage.EN_US`.
   */
  defaultLanguage?: AnyXLanguage;
}

export class DocUtils {
  static DOCUMENT_URL = 'https://anymind-group.gitbook.io/';

  /**
   * Default localization function that generates a versioned path based on the provided language.
   * @param lg - The language for which the localization path is to be generated.
   * @returns The versioned path for the given language.
   */
  static defaultLocalizer = (lg: AnyXLanguage) => {
    return `/v/${lg.toLowerCase()}`;
  };

  /**
   * Generates a GitBook documentation link based on the provided base path, language-specific overrides, and options.
   * @param basePath - The default path to use if no language-specific override is provided.
   * @param overrides - An optional record of language-specific path overrides.
   * @param options - Optional configuration for namespace, localization, current language, allowed languages, and default language.
   * @returns The complete URL to the GitBook documentation.
   */
  static getGitbookLink(
    basePath: string,
    overrides: Partial<Record<AnyXLanguage, string>> = {},
    {
      localization = this.defaultLocalizer,
      namespace = 'any-x',
      language = LanguageUtils.getCurrentLanguage(),
      allowedLanguages = Object.values(AnyXLanguage),
      defaultLanguage = AnyXLanguage.EN_US,
    }: GitbookLinkOptions = {}
  ): string {
    let languageToUse = language;

    // Ensure the current language is within the allowed languages, otherwise fallback to the default language
    if (!allowedLanguages.includes(languageToUse)) {
      languageToUse = defaultLanguage;
    }

    // Determine the path to use based on language-specific overrides or the default path
    const pathToUse = overrides?.[languageToUse] || basePath;

    // Generate the localization path for the determined language
    const lg = localization(languageToUse);

    // Construct and return the full GitBook documentation URL
    return `${DocUtils.DOCUMENT_URL}${namespace}${lg}${pathToUse}`;
  }
}
