import { ForwardedRef, ReactElement, forwardRef, ReactNode } from 'react';
import { HiChevronUpDown } from 'react-icons/hi2';

import { InputProps } from '@any-ui-react/core';
import { Input } from '@any-ui-react/core';
import cx from 'classnames';

interface SelectButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'placeholder' | 'value' | 'style'>,
    Omit<InputProps, 'component' | 'ref'> {
  placeholder?: ReactNode;
  value?: ReactNode;
  active?: boolean;
  style?: React.CSSProperties;
}

type SelectButton = <HTMLButtonElement, SelectButtonProps>(
  props: SelectButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => ReactElement;

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ placeholder, value, active, error, classNames, disabled, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        type="button"
        component="button"
        classNames={{
          ...classNames,
          input: cx('truncate leading-loose items-center block pl-3 pr-10 py-0', {
            '!border-blue-6': !error && active,
            '!text-gray-9': disabled,
          }),
          section: cx('w-10', {
            'text-gray-6': disabled,
          }),
        }}
        rightSection={<HiChevronUpDown className="h-4 w-4" />}
        error={error}
        disabled={disabled}
        {...props}
      >
        {placeholder && !value && <Input.Placeholder>{placeholder}</Input.Placeholder>}
        {value}
      </Input>
    );
  }
);
