import {
  InboundRoutePath,
  InventoryRoutePath,
  SupplierRoutePath,
  WarehouseRoutePath,
} from '~anyx/common/routing';
import { LoaderIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

type GetWarehouseLinkProps = {
  isAdmin: boolean;
  storeId?: string;
};

export type WithWorkspaceInventory = {
  canUseInventory: boolean;
  isAdmin: boolean;
  storeId?: string;
};

export type NoWorkspaceInventory = {
  canUseInventory: boolean;
  isAdmin: boolean;
};

const buildInventoryLinkProperties = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.inventory',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <LoaderIcon {...props} />,
  path: InventoryRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: InventoryRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildInboundLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.inbound',
    options: { count: 2, ns: 'shared' },
  },
  path: InboundRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: InboundRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildSupplierLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.supplier',
    options: { count: 2, ns: 'shared' },
  },
  path: SupplierRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: SupplierRoutePath({ workspaceStoreId: null }).root().pattern,
});

/*
 *
 * Get warehouse link if it's admin
 * */
const buildWarehouseLink = ({ isAdmin, storeId }: GetWarehouseLinkProps): RouteLink[] => {
  if (!isAdmin) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'shared.entity.warehouse',
        options: { count: 2, ns: 'shared' },
      },
      path: WarehouseRoutePath({ workspaceStoreId: storeId }).list().path,
      activePath: WarehouseRoutePath({ workspaceStoreId: null }).root().pattern,
    },
  ];
};

/*
 *
 * Build inventory link if the workplace is selected
 * */
const getInventoryWithWorkspaceLinks = ({
  isAdmin,
  canUseInventory,
  storeId,
}: WithWorkspaceInventory): RouteLinks[] => {
  if (!canUseInventory) return [];

  const inventoryLinkProperties = buildInventoryLinkProperties(storeId);
  const warehouseLink = buildWarehouseLink({ isAdmin, storeId });
  const inboundLink = buildInboundLink(storeId);
  const supplierLink = buildSupplierLink(storeId);

  return [
    {
      category: {
        ...inventoryLinkProperties,
        children: [inboundLink, supplierLink, ...warehouseLink],
      },
    },
  ];
};

/*
 *
 * Build inventory link if the workplace is selected
 * */
const getInventoryWithNoWorkspaceLinks = ({
  canUseInventory,
  isAdmin,
}: NoWorkspaceInventory): RouteLinks[] => {
  if (!canUseInventory) return [];

  const inventoryLinkProperties = buildInventoryLinkProperties();
  const warehouseLink = buildWarehouseLink({ isAdmin });
  const inboundLink = buildInboundLink();
  const supplierLink = buildSupplierLink();

  return [
    {
      category: {
        ...inventoryLinkProperties,
        children: [inboundLink, supplierLink, ...warehouseLink],
      },
    },
  ];
};

type InventoryLinks = LinkAdapter<NoWorkspaceInventory, WithWorkspaceInventory>;

export const inventoryLinks: InventoryLinks = {
  withWorkspace: getInventoryWithWorkspaceLinks,
  noWorkspace: getInventoryWithNoWorkspaceLinks,
};
