import { useTranslation } from 'react-i18next';

import { DdiHomeMetricOption, NumberFormat } from '~anyx/feature/report';

import { DdiHomeReportSummaryComparison } from '../type';

export const useDdiMetricCardData = (data?: DdiHomeReportSummaryComparison) => {
  const { t } = useTranslation();
  const groupedMetricCardData = data
    ? [
        {
          numberFormat: NumberFormat.PRICE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: DdiHomeMetricOption.GROSS_SALES,
          }),
          value: {
            current: data.grossSales.value,
            percentage: data.grossSales.diffPercentage,
          },
        },
        {
          numberFormat: NumberFormat.PRICE,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: DdiHomeMetricOption.NET_SALES,
          }),
          value: {
            current: data.netSales?.value,
            percentage: data.netSales?.diffPercentage,
          },
        },
        {
          numberFormat: NumberFormat.NUMBER,
          title: t('gql.enum.reportSummary', {
            ns: 'gql',
            metric: DdiHomeMetricOption.ORDERS,
          }),
          value: {
            current: data.orders?.value,
            percentage: data.orders?.diffPercentage,
          },
        },
      ]
    : new Array(3).fill({ title: '', value: 0 });

  return {
    groupedMetricCardData,
  };
};
