import { createContext, useContext } from 'react';

export type FetchContextType = {
  fetcher: (url: string, options?: RequestInit) => Promise<Response>;
};

export const FetchContext = createContext<FetchContextType>({
  fetcher: async () => new Response(),
});

export const useFetchCtx = () => useContext(FetchContext);
