import { FileContent, FileTypes, UploadDocumentType } from '../models/files.model';

export class FilesUtils {
  static readonly MEGABYTE = 1048576;
  static readonly EXTENSIONS = {
    video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
    image: ['jpg', 'gif', 'bmp', 'png', 'tiff', 'jpeg', 'webp'],
  };

  static isVideo(fileName: string) {
    const extension = FilesUtils.getExtension(fileName);
    return FilesUtils.EXTENSIONS.video.includes(extension);
  }

  static isImage(fileName: string) {
    const extension = FilesUtils.getExtension(fileName);
    return FilesUtils.EXTENSIONS.image.includes(extension);
  }

  static async uploadFile(file: File, url: string) {
    try {
      const { status, url: imageUrl } = await fetch(url, {
        body: file,
        method: 'PUT',
      });

      if (status !== 200) {
        throw new Error('Upload failed');
      }

      return imageUrl;
    } catch {
      throw new Error('Upload failed');
    }
  }

  static getFileFromURL$(url: string, name = 'newFile'): Promise<File> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        resolve(new File([xhr.response], name, { type: xhr.response.type }));
      };

      xhr.onerror = (e) => {
        xhr.abort();
        reject(e);
      };
      xhr.send();
    });
  }

  static getFileFromDropEntry$(entry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
      entry.file(resolve, reject);
    });
  }

  /**
   * @param {number}  size - size of the file in MB
   */
  static getFileRestrictions({
    documentTypes,
    size = 10,
  }: {
    documentTypes: UploadDocumentType[];
    size?: number;
  }) {
    const fileFormat = {
      [UploadDocumentType.CSV]: ['.csv'],
      [UploadDocumentType.OFFICE_DOCUMENT_SPREADSHEET]: ['.xlsx'],
      [UploadDocumentType.MS_EXCEL]: ['.xls'],
    };

    const fileExtension = {
      [UploadDocumentType.CSV]: 'CSV',
      [UploadDocumentType.OFFICE_DOCUMENT_SPREADSHEET]: 'XLSX',
      [UploadDocumentType.MS_EXCEL]: 'XLS',
    };

    const types = documentTypes.reduce((acc, type) => {
      return {
        ...acc,
        [type]: fileFormat[type],
      };
    }, {} as { [k: string]: string[] });

    const supportedExtensions = documentTypes.map((type) => fileExtension[type]);

    return {
      types,
      supportedExtensions,
      size: size * FilesUtils.MEGABYTE,
    };
  }

  static getExtension(fileName: string) {
    return fileName.toLocaleLowerCase().split('.').pop() || '';
  }

  static getName(fileName: string) {
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }

  static formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  static readFile$(file: File) {
    return new Promise<FileContent>((release, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        release(e.target?.result);
      };

      reader.onerror = () => {
        reject(new Error(`FileReader failed on file ${file.name}.`));
      };

      if (!file) {
        reject(new Error(`No file to read.`));
        return;
      }

      reader.readAsDataURL(file);
    });
  }

  static getFileType(fileName: string) {
    return FilesUtils.isImage(fileName)
      ? FileTypes.IMAGE
      : FilesUtils.isVideo(fileName)
      ? FileTypes.VIDEO
      : FileTypes.FILE;
  }

  static isExtension(fileName: string, extensions: Array<string>) {
    extensions = extensions.map((f) => f.replace('.', ''));
    const extension = FilesUtils.getExtension(fileName);

    return extensions.includes(extension);
  }

  static download(blob: Blob, name: string) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = name;
    a.click();
  }

  static async getImageSize(file: File) {
    return new Promise<{ width: number; height: number }>((resolve) => {
      const img = new Image();
      img.onload = () =>
        resolve({
          height: img.height,
          width: img.width,
        });
      img.src = window.URL.createObjectURL(file);
    });
  }

  static open(filepath: string, inNewTab = true) {
    if (!filepath) {
      return;
    }

    const link = document.createElement('a');
    link.href = filepath;

    if (inNewTab) {
      link.target = '_blank';
      link.rel = 'noreferrer';
    }

    link.click();
    link.remove();
  }
}
