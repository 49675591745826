import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateAnylogiUploadUrlMutationVariables = Types.Exact<{
  filename: Types.Scalars['String']['input'];
  resource: Types.Resource;
  filetype: Types.FileType;
  storeId?: Types.InputMaybe<Types.Scalars['Long']['input']>;
}>;


export type GenerateAnylogiUploadUrlMutation = { readonly generateUploadUrl: { readonly __typename?: 'Path', readonly url: string } };


export const GenerateAnylogiUploadUrlDocument = gql`
    mutation GenerateAnylogiUploadUrl($filename: String!, $resource: Resource!, $filetype: FileType!, $storeId: Long) {
  generateUploadUrl(
    filename: $filename
    resource: $resource
    filetype: $filetype
    storeId: $storeId
  ) {
    url
  }
}
    `;
export type GenerateAnylogiUploadUrlMutationFn = Apollo.MutationFunction<GenerateAnylogiUploadUrlMutation, GenerateAnylogiUploadUrlMutationVariables>;

/**
 * __useGenerateAnylogiUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateAnylogiUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAnylogiUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAnylogiUploadUrlMutation, { data, loading, error }] = useGenerateAnylogiUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      resource: // value for 'resource'
 *      filetype: // value for 'filetype'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGenerateAnylogiUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAnylogiUploadUrlMutation, GenerateAnylogiUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAnylogiUploadUrlMutation, GenerateAnylogiUploadUrlMutationVariables>(GenerateAnylogiUploadUrlDocument, options);
      }
export type GenerateAnylogiUploadUrlMutationHookResult = ReturnType<typeof useGenerateAnylogiUploadUrlMutation>;
export type GenerateAnylogiUploadUrlMutationResult = Apollo.MutationResult<GenerateAnylogiUploadUrlMutation>;
export type GenerateAnylogiUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateAnylogiUploadUrlMutation, GenerateAnylogiUploadUrlMutationVariables>;