import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

import { WorkspaceMode } from '../models';

import { WorkspaceCtx } from './types';

export const initWorkspaceCtx: WorkspaceCtx = {
  workspace: undefined,
  mode: WorkspaceMode.ON,
  isSet: false,
};

export const useWorkspaceCtx = createWithEqualityFn<WorkspaceCtx>()(
  immer(
    devtools(() => initWorkspaceCtx, {
      name: 'WorkspaceCtx',
    })
  ),
  Object.is
);
