import { RouteObject } from 'react-router-dom';

import { InboundModuleRoutes } from './modules/inbound';
import { InventoryModuleRoutes } from './modules/inventory';
import { SupplierModuleRoutes } from './modules/supplier';
import { WarehouseModuleRoutes } from './modules/warehouse';

export const routes: RouteObject[] = [
  ...InventoryModuleRoutes,
  ...SupplierModuleRoutes,
  ...WarehouseModuleRoutes,
  ...InboundModuleRoutes,
];
