import { CardLoader, CardLoaderProps } from './CardLoader';

export interface CardProps extends Omit<CardLoaderProps, 'children'> {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  withPadding?: boolean;
}

export const Card = ({ children, className, withPadding = true, ...rest }: CardProps) => (
  <CardLoader {...rest} loaderClassNames={{ ...rest.loaderClassNames, error: className }}>
    <div
      className={[
        `card rounded-none lg:rounded ${withPadding ? 'px-4 py-6 lg:p-6' : ''}`,
        className,
      ].join(' ')}
    >
      {children}
    </div>
  </CardLoader>
);
