import { useCallback } from 'react';

import { LogisticFeatureFlag } from '~anyx/shared/graphql';

import { useSelfLogisticStoreStatusLazyQuery } from '../graphql';

export const useSelfLogisticStoreStatus = ({ isAdminOrStaff }: { isAdminOrStaff: boolean }) => {
  const defaultAccess = isAdminOrStaff
    ? LogisticFeatureFlag.ADVANCED_LOGISTICS
    : LogisticFeatureFlag.NO_LOGISTICS;

  const [getLogisticStoreStatus, { loading, data, error }] = useSelfLogisticStoreStatusLazyQuery();

  const getLogisticStoreStatusHandler = useCallback(
    async ({ userId }: { userId: string }) => {
      if (!userId) return LogisticFeatureFlag.NO_LOGISTICS;
      if (isAdminOrStaff) return LogisticFeatureFlag.ADVANCED_LOGISTICS;
      // By default admin will always have full access

      const { data: result } = await getLogisticStoreStatus({
        variables: {
          userIds: [userId],
        },
      });

      return defaultAccess || result?.getLogisticStoreStatus;
    },
    [defaultAccess, getLogisticStoreStatus, isAdminOrStaff]
  );

  return {
    getLogisticStoreStatusHandler,
    loading,
    logisticStoreAccess: data?.getLogisticStoreStatus || defaultAccess,
    error,
  };
};
