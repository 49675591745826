import { MarketplaceSaleChannelType } from '~anyx/shared/graphql';
import { TitledIcon } from '~anyx/shared/ui';
import { EMPTY_DEFAULT } from '~anyx/shared/utils';

import { ChannelUtils, getChannelIcon, singleMarketplaceChannel } from '../../utils';

type ChannelSorterProps = {
  id?: string | null;
  country?: string | null;
  type?: MarketplaceSaleChannelType | null;
};

export const ChannelSorter = ({
  channels,
  ignoreChannel = [],
}: {
  channels: ChannelSorterProps[];
  ignoreChannel?: MarketplaceSaleChannelType[];
}) => {
  return (
    <div className="flex">
      {ChannelUtils.sortChannels({
        channels,
        byField: 'type',
        disabled: ignoreChannel,
      }).map((channel, idx) => {
        return (
          <span key={`${channel.id}-${idx}`} className="relative mr-2 mt-1">
            {channel?.type ? (
              <TitledIcon
                icon={getChannelIcon(channel.type, 'fa')}
                text={!singleMarketplaceChannel(channel.type) ? channel.country : null}
              />
            ) : (
              EMPTY_DEFAULT
            )}
          </span>
        );
      })}
    </div>
  );
};
