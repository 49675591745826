import { today } from '@any-ui-react/dates';
import { z } from 'zod';

import { DdiHomeMetricOption } from '~anyx/feature/report';
import { DdiHomeReportInput, SaleChannelType } from '~anyx/shared/graphql';
import { DateUtils } from '~anyx/shared/utils';

type Constraints = DdiHomeReportInput & {
  metric: DdiHomeMetricOption;
};

export const DdiHomePageFiltersParser = z.object({
  endDate: z
    .string()
    .catch(
      DateUtils.toISOStringEndOfDay(
        today(Intl.DateTimeFormat().resolvedOptions().timeZone),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    ),
  startDate: z
    .string()
    .catch(
      DateUtils.toISOStringStartOfDay(
        today(Intl.DateTimeFormat().resolvedOptions().timeZone),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    ),
  saleChannelTypes: z.array(z.nativeEnum(SaleChannelType)).catch([]),
  masterDataStoreIds: z.array(z.string()).catch([]),
  metric: z.nativeEnum(DdiHomeMetricOption).catch(DdiHomeMetricOption.GROSS_SALES),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type DdiHomeFilterInputFilterType = z.infer<typeof DdiHomePageFiltersParser>;
