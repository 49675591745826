import { DatasourceType } from '~anyx/shared/graphql';
import { ObjectUtils } from '~anyx/shared/utils';

export const AdvertisementDatasourceType = {
  [DatasourceType.FacebookAds]: DatasourceType.FacebookAds,
  [DatasourceType.GoogleAds]: DatasourceType.GoogleAds,
  [DatasourceType.TikTokAds]: DatasourceType.TikTokAds,
  [DatasourceType.YahooSearchAds]: DatasourceType.YahooSearchAds,
  [DatasourceType.YahooDisplayAds]: DatasourceType.YahooDisplayAds,
};
export const AnalyticsDatasourceType = {
  [DatasourceType.GoogleAnalytics4]: DatasourceType.GoogleAnalytics4,
};

export type AdvertisementDatasourceType =
  (typeof AdvertisementDatasourceType)[keyof typeof AdvertisementDatasourceType];

export type AnalyticsDatasourceType =
  (typeof AnalyticsDatasourceType)[keyof typeof AnalyticsDatasourceType];

export const AdvertisementDatasources = ObjectUtils.arrayFromEnum<DatasourceType>(
  AdvertisementDatasourceType
);

export const AnalyticsDatasources =
  ObjectUtils.arrayFromEnum<DatasourceType>(AnalyticsDatasourceType);

export const Datasources = ObjectUtils.arrayFromEnum<DatasourceType>(DatasourceType);
