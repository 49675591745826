import { RouteObject } from 'react-router-dom';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

import { routes as AutomationRoutes } from './automation';

const PATH_KEY = CrmRoutePathKey.enum.instagram;

export const routes: RouteObject[] = [
  {
    path: 'instagram',
    children: [...ChatRoutes(PATH_KEY), ...AutomationRoutes],
  },
];
