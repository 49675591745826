import { CurrencyCode, AnylogiCurrencyInput, ReportSupportedCurrency } from '../index';

export class GqlCurrency {
  // Marketplace Enum Default Currrency
  static DEFAULT_MKP = CurrencyCode.JPY;
  // Logistic Enum Default Currrency
  static DEFAULT_LGS = AnylogiCurrencyInput.JPY;
  // Report Enum Default Currrency
  static DEFAULT_RPT = ReportSupportedCurrency.JPY;

  static isMkpCurrency = (currency = ''): currency is CurrencyCode => currency in CurrencyCode;

  static asMkpCurrency = (currency?: string | null) =>
    currency && this.isMkpCurrency(currency) ? currency : GqlCurrency.DEFAULT_MKP;

  static isRptCurrency = (currency = ''): currency is ReportSupportedCurrency =>
    currency in ReportSupportedCurrency;

  static asRptCurrency = (currency?: string | null) =>
    currency && this.isRptCurrency(currency) ? currency : GqlCurrency.DEFAULT_RPT;

  static isLgsCurrency = (currency = ''): currency is AnylogiCurrencyInput =>
    currency in AnylogiCurrencyInput;

  static asLgsCurrency = (currency?: string | null) =>
    currency && this.isLgsCurrency(currency) ? currency : GqlCurrency.DEFAULT_LGS;
}
