import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { LanguageMenu } from '../language-menu';

export interface FooterProps {
  showLanguageMenu?: boolean;
  className?: string;
  withPadding?: boolean;
  privacyPolicyLink: string;
  termsOfServiceLink: string;
}
export const Footer = ({
  showLanguageMenu,
  className,
  withPadding = true,
  privacyPolicyLink,
  termsOfServiceLink,
}: FooterProps) => {
  const { t } = useTranslation();

  const notLastChildClassName = showLanguageMenu
    ? "sm:after:mx-3 sm:after:content-['|'] mb-2 sm:mb-0"
    : '';
  return (
    <footer
      className={classNames(
        'my-3 flex flex-col items-center justify-between lg:my-6 lg:flex-row',
        withPadding ? 'px-6' : '',
        className
      )}
    >
      <small className="text-gray-7 mb-2 lg:mb-0">
        {t('shared.footer.copyright', { ns: 'shared' })}
      </small>
      <div className="flex flex-wrap justify-center">
        <a
          className="text-gray-7 text-2xs after:text-gray-7 after:mx-3 after:content-['|'] hover:underline"
          href={privacyPolicyLink}
          rel="noreferrer"
          target="_blank"
        >
          {t('shared.footer.privacyPolicy', { ns: 'shared' })}
        </a>
        <a
          className="text-gray-7 text-2xs after:text-gray-7 after:mx-3 after:content-['|'] hover:underline"
          href={termsOfServiceLink}
          rel="noreferrer"
          target="_blank"
        >
          {t('shared.footer.termsOfService', { ns: 'shared' })}
        </a>
        <a
          className={classNames(
            'text-gray-7 text-2xs after:text-gray-7 hover:underline',
            notLastChildClassName
          )}
          href="https://anymindgroup.com/contact/"
          rel="noreferrer"
          target="_blank"
        >
          {t('shared.footer.contact', { ns: 'shared' })}
        </a>
        {showLanguageMenu && (
          <div className="flex w-full justify-center sm:w-fit">
            <LanguageMenu />
          </div>
        )}
      </div>
    </footer>
  );
};
