import { LazyQueryHookOptions } from '@apollo/client';

import { MdStoreFilterInput, MdStoreSortByFieldEnum, useLoadOptions } from '~anyx/shared/graphql';

import {
  MdStoresSelectQuery,
  MdStoresSelectQueryVariables,
  useMdStoresSelectLazyQuery,
} from '../graphql';

export type MultiQuery = LazyQueryHookOptions<MdStoresSelectQuery, MdStoresSelectQueryVariables>;

export const useStoresSelect = <T = string>({ filters }: { filters?: MdStoreFilterInput }) => {
  const [queryMdStoreList] = useMdStoresSelectLazyQuery();
  const { loadOptions } = useLoadOptions<
    MdStoresSelectQuery['MdStores']['items'][0],
    T,
    MdStoresSelectQuery,
    MdStoresSelectQueryVariables
  >({
    labelFormatter: (item) => item.name,
    valueFormatter: (item) => item.id as unknown as T,
    listQuery: {
      variables: {
        sortBy: {
          descending: false,
          field: MdStoreSortByFieldEnum.NAME,
        },
        filter: {
          ...filters,
        },
      },
      scope: 'MdStores',
      lazyQuery: queryMdStoreList,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  });
  return {
    loadOptions,
  };
};
