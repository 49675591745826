import { ReactElement, RefAttributes, forwardRef } from 'react';

import {
  GroupBase,
  Select,
  SelectForwardRef,
  SelectInstance,
  SelectProps,
} from '@any-ui-react/select';

import { BasicOption, useTagsOptions } from '~anyx/shared/utils';

import { useProductTagSelect } from './operation';

type TagOptions = BasicOption<string>;

interface ProductTagsSelectProps<IsMulti extends boolean = boolean>
  extends SelectProps<TagOptions, IsMulti> {
  storeIds?: string[] | null;
  accountId?: string | null;
  isMulti?: IsMulti;
}

const ProductTagsSelectGn = <IsMulti extends boolean = false>(
  { storeIds = null, accountId = null, isMulti = true, ...rest }: ProductTagsSelectProps,
  ref: SelectForwardRef<TagOptions, IsMulti, GroupBase<TagOptions>>
) => {
  const { tags } = useProductTagSelect({ storeIds, accountId });
  const { tagSelectProps, tagOptions } = useTagsOptions(tags);

  return (
    <Select
      isClearable
      {...rest}
      {...(isMulti ? tagSelectProps : { options: tagOptions })}
      isMulti={isMulti}
      // TODO FIX THIS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
    />
  );
};

type ProductTagsSelect<IsMulti extends boolean = boolean> = (
  props: ProductTagsSelectProps &
    RefAttributes<SelectInstance<TagOptions, IsMulti, GroupBase<TagOptions>>>
) => ReactElement;

export const ProductTagsSelect = forwardRef(ProductTagsSelectGn) as <
  IsMulti extends boolean = boolean
>(
  p: ProductTagsSelectProps<IsMulti> & {
    ref?: SelectForwardRef<TagOptions, IsMulti, GroupBase<TagOptions>>;
  }
) => ReactElement;
