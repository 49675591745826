import { PropsWithChildren } from 'react';

import { t } from 'i18next';

import { SkeletonLoader } from '~anyx/shared/ui';

import { NoResult } from '../../molecules/no-result';

export interface ChartContainerProps {
  loading?: boolean;
  error?: boolean;
  children: React.ReactNode;
  errorNode?: React.ReactNode;
  skeletonClassNames?: string;
  wrapperClassNames?: string;
  theme?: string;
}

export function SkeletonChartWrapper({
  error,
  children,
  loading,
  skeletonClassNames,
  theme,
  errorNode = (
    <NoResult
      wrapperClassName="py-20 my-4 text-center !gap-2"
      titleClassName="text-sm"
      descriptionClassName="text-gray-7 text-2xs lg:text-sm"
      title={t('shared.table.empty', {
        ns: 'shared',
        element: t('shared.entity.data', { ns: 'shared', count: 1 }),
      })}
      description={t('shared.chart.guideline', { ns: 'shared' })}
    />
  ),
}: PropsWithChildren<ChartContainerProps>) {
  const node = error ? errorNode : children;

  return (
    <>
      {loading ? (
        <SkeletonLoader
          variant={theme}
          loading={loading}
          className={skeletonClassNames}
          height={256}
        />
      ) : (
        node
      )}
    </>
  );
}
