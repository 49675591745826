import { PropsWithChildren, ReactElement } from 'react';

import { TdBody, TrBody } from '@any-ui-react/table';
import classNames from 'classnames';

import { DEFAULT_COLUMN_MAX_WIDTH, useScreenBreakpoint } from '~anyx/shared/utils';

import { SkeletonLoader } from '../../atoms';

import { TableDesktopContainer } from './desktop';
import { TableProps } from './Table';

export function TableSkeleton<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProps<T> & { skeletonCount?: number }>
): ReactElement {
  const isLargeScreen = useScreenBreakpoint('lg');

  const {
    mobileTemplate,
    mobileSkeletonTemplate,
    layout,
    rowClassName,
    skeletonCount,
    loading,
    children,
    data = [],
    numOfStickyCols = 1,
  } = props;

  if (loading) {
    if (mobileTemplate && !isLargeScreen) {
      return (
        <>
          {Array(5)
            .fill(null)
            .map((_, index) => {
              return (
                mobileSkeletonTemplate?.(index) || (
                  <div key={index} className="flex h-40 flex-col gap-2 border-t p-4">
                    <SkeletonLoader loading className="w-48 flex-grow" />
                    <SkeletonLoader loading className="h-4 w-40" />
                    <SkeletonLoader loading className="h-4 w-32" />
                    <SkeletonLoader loading className="h-4 w-48" />
                  </div>
                )
              );
            })}
        </>
      );
    }
    return (
      <TableDesktopContainer<T> numOfStickyCols={numOfStickyCols} data={data} {...props}>
        {Array(skeletonCount)
          .fill(null)
          .map((_, index) => {
            return (
              <TrBody key={index} className={classNames('h-10', rowClassName)}>
                {layout.map(
                  ({ accessor, tdClassName, minWidth, maxWidth, width, skeletonTemplate }, i) => {
                    const isLastColTd = i === layout.length - 1;
                    return (
                      <TdBody
                        key={accessor}
                        className={classNames(rowClassName, tdClassName, {
                          'overflow-hidden': isLastColTd,
                        })}
                        style={{
                          minWidth: minWidth || 'none',
                          maxWidth: maxWidth || `${DEFAULT_COLUMN_MAX_WIDTH}px`,
                          width: width,
                          background: 'inherit',
                          zIndex: i < numOfStickyCols ? 1 : 0,
                        }}
                      >
                        {skeletonTemplate || <SkeletonLoader loading className="h-4 w-36" />}
                      </TdBody>
                    );
                  }
                )}
              </TrBody>
            );
          })}
      </TableDesktopContainer>
    );
  }
  return <>{children}</>;
}
