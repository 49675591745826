import { ReactNode } from 'react';

import classNames from 'classnames';

export function FormFieldLabel({
  withGap,
  required,
  label,
  description,
}: {
  withGap?: boolean;
  required?: boolean;
  label: ReactNode;
  description?: ReactNode;
}) {
  return (
    <div className="flex flex-col">
      <div>
        <span>{label}</span>
        {required ? (
          <span className="text-red-6 mantine-InputWrapper-required mantine-Select-required ml-1">
            *
          </span>
        ) : null}
      </div>
      {description ? (
        <span
          className={classNames(
            'mantine-Text-root mantine-InputWrapper-description mantine-Textarea-description text-2xs text-gray-7 block leading-tight',
            {
              'mb-[3px]': withGap,
            }
          )}
        >
          {description}
        </span>
      ) : null}
    </div>
  );
}
