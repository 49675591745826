import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, MantineColor } from '@any-ui-react/core';

export interface StatusPillowProps {
  asText?: boolean;
  color?: MantineColor;
  i18nKey?: string;
  icon?: ReactNode;
}
export const StatusPillow = ({ asText, color, i18nKey, icon }: StatusPillowProps) => {
  const { t } = useTranslation();

  if (asText)
    return (
      <div className="flex items-center">
        {icon}
        <span className="text-2xs">{t(i18nKey || '', { ns: 'shared' })}</span>
      </div>
    );

  return (
    <Badge leftSection={icon} color={color}>
      <span className="text-2xs">{t(i18nKey || '', { ns: 'shared' })}</span>
    </Badge>
  );
};
