import React from 'react';

import {
  tokopediaRouting,
  manualRouting,
  lazadaRouting,
  amazonRouting,
  yahooRouting,
  zozotownRouting,
  shopeeRouting,
  rakutenRouting,
  tikTokRouting,
  shopifyRouting,
  qoo10Routing,
} from '~anyx/integrations/sales-channels';

const SalesChannelSelectPage = React.lazy(() =>
  import('./select').then((module) => ({ default: module.SalesChannelSelectPage }))
);

export const marketplaceIntegrationRoutes = {
  path: 'sales-channel',
  children: [
    {
      index: true,
      element: <SalesChannelSelectPage />,
    },
    ...tokopediaRouting,
    ...lazadaRouting,
    ...amazonRouting,
    ...yahooRouting,
    ...amazonRouting,
    ...zozotownRouting,
    ...shopeeRouting,
    ...rakutenRouting,
    ...tikTokRouting,
    ...shopifyRouting,
    ...qoo10Routing,
    ...manualRouting,
  ],
};
