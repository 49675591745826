import { useScreenBreakpoint } from '~anyx/shared/utils';

import { useNavigationLinks } from '../../../hooks';

import { Sidenav } from './Sidenav';
import { Topnav } from './Topnav';

export const Navigation = () => {
  const isLargeScreen = useScreenBreakpoint('lg');

  const { links } = useNavigationLinks();

  return isLargeScreen ? <Sidenav links={links} /> : <Topnav links={links} />;
};
