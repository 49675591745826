import React from 'react';

import { WarehouseManagementSystem } from '~anyx/shared/graphql';

const JubelioEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.JubelioEditPage,
  }))
);

const JubelioTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.JubelioTemplate }))
);

export const jubelioRouting = {
  path: WarehouseManagementSystem.JUBELIO,
  element: <JubelioTemplate />,
  children: [
    {
      index: true,
      element: <JubelioEditPage />,
    },
  ],
};
