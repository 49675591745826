import { useCallback } from 'react';

import { LazyQueryHookOptions } from '@apollo/client';

import {
  MdStoreSelectQuery,
  MdStoreSelectQueryVariables,
  useMdStoreSelectLazyQuery,
} from '../graphql';

export type MdStoreSelectQueryOptions = LazyQueryHookOptions<
  MdStoreSelectQuery,
  MdStoreSelectQueryVariables
>;
export const useStoreSelect = () => {
  const [queryMdStore, { loading }] = useMdStoreSelectLazyQuery();

  const getStoreDetails = useCallback(
    async ({ storeId }: { storeId: string }) => {
      if (!storeId) return;

      const { data } = await queryMdStore({
        variables: {
          mdStoreId: storeId,
        },
      });
      return data?.MdStore;
    },
    [queryMdStore]
  );

  return {
    loading,
    getStoreDetails,
  };
};
