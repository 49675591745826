import classNames from 'classnames';

import { AnyXLanguage, EMPTY_DEFAULT, LanguageUtils, NumberUtils } from '~anyx/shared/utils';

interface NumberWithFormatProps {
  amount?: number | string | null;
  option?: Intl.NumberFormatOptions;
  language?: AnyXLanguage;
  className?: string;
}

export const NumberWithFormat = ({
  amount,
  option,
  language = LanguageUtils.getCurrentLanguage(),
  className,
}: NumberWithFormatProps) => {
  if (typeof amount === 'bigint' || typeof amount === 'number' || typeof amount === 'string') {
    return (
      <span className={classNames(className, 'tabular-nums')}>
        {NumberUtils.format(amount, option, language)}
      </span>
    );
  }

  return <>{EMPTY_DEFAULT}</>;
};
