import { AnyXLanguage } from './language.utils';
const { EN_US, ZH_CN, JA_JP, KO_KR } = AnyXLanguage;

export const getCorporateSiteLink = (language: AnyXLanguage, page: string) => {
  switch (language) {
    case EN_US:
      return `https://anymindgroup.com${page}`;
    case ZH_CN:
      return `https://anymindgroup.com/cn${page}`;
    case JA_JP:
      return `https://anymindgroup.com/ja${page}`;
    case KO_KR:
      return `https://anymindgroup.com/ko${page}`;
    default:
      return `https://anymindgroup.com/${language}${page}`;
  }
};
