import { CurrenciesEnum } from '../enums';
import { environmentStore } from '../states';

import { AnyXLanguage } from './language.utils';
import { NumberUtils } from './number.utils';

export class CurrencyUtils {
  static DEFAULT = CurrenciesEnum.JPY;

  static setCurrency(currency?: string) {
    const currentCurrency = CurrencyUtils.asCurrency(currency);
    if (!currentCurrency) return;
    environmentStore.getState().setCurrency(currentCurrency);
  }

  static getCurrentCurrency() {
    return environmentStore.getState().currentCurrency;
  }

  static isCurrency = (currency = ''): currency is CurrenciesEnum => currency in CurrenciesEnum;

  static asCurrency = (currency?: string | null) =>
    currency && this.isCurrency(currency) ? currency : CurrencyUtils.DEFAULT;

  static convertByCurrencyPrecision = (
    amount: number,
    currency: CurrenciesEnum | string = CurrencyUtils.DEFAULT
  ) => {
    try {
      const precision = CurrencyUtils.getRelevantDecimals(currency);
      const exponential = Number(`1e${precision}`);
      return Math.round(amount * exponential) / exponential;
    } catch (e) {
      return amount;
    }
  };

  static getRelevantDecimals(currency: CurrenciesEnum | string = CurrencyUtils.DEFAULT) {
    const format = NumberUtils.format(1000, { currency, style: 'currency' }, AnyXLanguage.EN_US);
    return format?.split('.')?.[1]?.length || 0;
  }
}
