// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from '~anyx/shared/utils';

import { CoreEnvironment } from './types';

export const environment: CoreEnvironment = {
  appName: 'AnyX',
  env: Environment.STAGING,
  enableDebug: true,
  crowdin: {
    default: 'en-US',
    pseudoLanguage: 'af-ZA',
    supported: ['en-US', 'ja-JP', 'zh-CN', 'ko-KR'],
    project: 'anyxmono',
    allowTranslation: true,
  },
  google: {
    locationServiceEndpoint: 'https://anyx-address-service-3a6t7henia-an.a.run.app',
  },
  auth0: {
    clientId: {
      ANYLOGI: 'FoHBr68QYdWtGA6d0QfAhrBiCEWZd8Fz',
      ANYX: 'c83jD9Unzh54sP9oPj7AKIXWcqdzVldF',
      ANYCHAT: 'r1iqVftfMRVQZqPZWqXvpxfOMUJufAa9',
    },
    domain: 'login.stg.app.any-x.com',
    audience: 'https://gateway.stg.app.any-x.com',
    permissions: 'https://gateway.stg.app.any-x.com/permissions',
  },
  api: {
    graphql: 'https://gateway.stg.app.any-x.com/graphql',
    graphqlws: 'wss://api.stg.anychat.tech/ws/graphql',
    rest: 'https://api.stg.app.any-x.com/rest',
  },
  help: {
    gitbook: 'https://anymind-group.gitbook.io/',
  },
  sentry: {
    dns: 'https://d958a612fb2a43b4a0c9231ccfcf1f80@o160373.ingest.sentry.io/6656999',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyBslSE0P5dRm16Bv58TPp8HBX-nrnSMO8g',
      authDomain: 'anyx-staging.firebaseapp.com',
      projectId: 'anyx-staging',
      storageBucket: 'anyx-staging.appspot.com',
      messagingSenderId: '358171324515',
      appId: '1:358171324515:web:9f3671076c7508bcfe8856',
      measurementId: 'G-3WJBK7X0SY',
    },
    remoteConfig: {
      minimumFetchIntervalMillis: 900000, // 15mins
    },
  },
};
