import React from 'react';

const ManualAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ManualAddPage }))
);

const ManualEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ManualEditPage }))
);

const ManualTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ManualTemplate }))
);

const ManualCostEditPage = React.lazy(() =>
  import('./pages/manual-cost/edit').then((module) => ({
    default: module.ManualCostEditPage,
  }))
);

const ManualCostAddPage = React.lazy(() =>
  import('./pages/manual-cost/add').then((module) => ({
    default: module.ManualCostAddPage,
  }))
);


export const manualRouting = [
  {
    path: ':channelType',
    element: <ManualTemplate />,
    children: [
      {
        index: true,
        element: <ManualAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'manual-cost/add',
            element: <ManualCostAddPage />,
          },
          {
            path: 'manual-cost/:manualCostId',
            element: <ManualCostEditPage />,
          },
          {
            index: true,
            element: <ManualEditPage />,
          },
        ],
      },
    ],
  },
];
