import { useCallback } from 'react';

import { MarketplaceSaleChannelType, MdStoreFilterInput, SaleChannel } from '~anyx/shared/graphql';
import { DEFAULT_PAGE_SIZE, ObjectUtils } from '~anyx/shared/utils';

import { useMdStoresChannelsLazyQuery } from './graphql';

export const useStoresChannels = () => {
  const [storesChannelsLazyQuery, { loading }] = useMdStoresChannelsLazyQuery();

  const fetchStoresChannels = useCallback(
    async ({ accountIds, storeIds }: MdStoreFilterInput) => {
      const { data } = await storesChannelsLazyQuery({
        variables: {
          filter: {
            storeIds,
            accountIds,
          },
          pageNumber: 1,
          pageSize: DEFAULT_PAGE_SIZE,
        },
      });

      return (data?.MdStores.items?.flatMap((item) => item.channels) || []) as SaleChannel[];
    },
    [storesChannelsLazyQuery]
  );

  const getStoresChannels = useCallback(
    async ({
      accountIds = [],
      storeIds = [],
      picks,
    }: MdStoreFilterInput & { picks: (keyof SaleChannel)[] }) => {
      const storesChannels = await fetchStoresChannels({ accountIds, storeIds });

      return storesChannels.map((c) => ObjectUtils.pick(c, picks));
    },
    [fetchStoresChannels]
  );

  const getStoresChannelsTypes = useCallback(
    async ({ accountIds = [], storeIds = [] }: MdStoreFilterInput) => {
      if (accountIds?.length === 0 && storeIds?.length === 0) {
        return ObjectUtils.arrayFromEnum<MarketplaceSaleChannelType>(MarketplaceSaleChannelType);
      }

      const storesChannels = await fetchStoresChannels({ accountIds, storeIds });

      return [
        ...new Set(
          (storesChannels.map((c) => ObjectUtils.pick(c, ['type'])) as SaleChannel[]).map(
            ({ type }) => type
          )
        ),
      ] as MarketplaceSaleChannelType[];
    },
    [fetchStoresChannels]
  );

  return { getStoresChannels, getStoresChannelsTypes, loading };
};
