import { HttpResponse, delay, graphql } from 'msw';

import { DdiHomeReportProductTableQuery } from '../../graphql';

export const MockDdiProductRankingTable = {
  DEFAULT: {
    ddiHomeReportTopProducts: {
      __typename: 'DdiHomeReportTopProductsPayload',
      currency: 'USD',
      products: [
        {
          __typename: 'DdiHomeReportTopProductPayload',
          productImageUrl:
            'https://storage.googleapis.com/anyx-staging-storage/cache_media/1300/import/20230704113131_1427385163/imgrc0117968330.jpg',
          orders: '489',
          netSales: '123',
          productId: '489',
          productName:
            '7/1-15限定★5%OFF+P10倍】【公式ボディインストラクター監修】【新色ミントグリーン登場】ナイトブラ 新色登場 送料無料  360°構造で、お椀型まんまるバストへ ナイトブラブラジャー 大きいサイズ ノンワイヤー 黒 白 谷間 脇高 設計 脇肉 背肉 すっきり構造 美バスト',
          grossSales: '1902',
        },
        {
          __typename: 'DdiHomeReportTopProductPayload',
          productImageUrl:
            'https://storage.googleapis.com/anyx-staging-storage/cache_media/571/import/20230613054026_0885853011/61jAyRJdl0L._AC_SL1500.jpg',
          orders: '738',
          netSales: '234',
          productId: '738',
          productName: 'This is Dragon T shirt',
          grossSales: '4980',
        },
        {
          __typename: 'DdiHomeReportTopProductPayload',
          productImageUrl:
            'https://storage.googleapis.com/anyx-staging-storage/cache_media/1300/import/20230704113145_1903090096/imgrc0117968353.jpg',
          orders: '169',
          netSales: '569',
          productId: '2302',
          productName:
            '7/1-15限定★5%OFF+P10倍】【公式ボディインストラクター監修!!】 選べる2枚セット セットでお得 360°構造で、お椀型まんまるバストへ エッセンシャル ナイトブラ ブラジャー 大きいサイズ ノンワイヤー 黒 ピンク 白 谷間 脇高 設計 脇肉 背肉 副乳 美バスト',
          grossSales: '5678',
        },
        {
          __typename: 'DdiHomeReportTopProductPayload',
          productImageUrl:
            'https://storage.googleapis.com/anyx-staging-storage/cache_media/1300/import/20230704113056_1128602661/imgrc0116599894.jpg',
          orders: '634',
          netSales: '634',
          productId: '634',
          productName:
            '【7/1-15限定★5%OFF+P10倍】【公式ボディインストラクター監修】 miour(ミアー) 着て寝るだけ 美胸 リフトアップ ノンワイヤー ブラキャミ ブラトップ 大きいサイズ ふんわり ノンワイヤーブラ カップ 美盛り ブラキャミソール キャミ 補正 バストメイク キャミソール',
          grossSales: '923',
        },
        {
          __typename: 'DdiHomeReportTopProductPayload',
          productImageUrl:
            'https://storage.googleapis.com/anyx-staging-storage/cache_media/573/import/20230601001323_0138634564/960.jpg',
          orders: '902',
          netSales: '902',
          productId: '902',
          productName: 'ILPALADINO New Design Men Cycling Long sleeve ttexttttttttttttttttttt',
          grossSales: '890',
        },
      ],
    },
  },
  EMPTY: {
    ddiHomeReportTopProducts: {
      currency: 'USD',
      products: [],
    },
  },
} satisfies Record<string, DdiHomeReportProductTableQuery>;

export const MockDdiProductRankingTableQuery = {
  default: () => {
    return graphql.query('DdiProductRankingTable', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: MockDdiProductRankingTable.DEFAULT });
    });
  },
  empty: () => {
    return graphql.query('DdiProductRankingTable', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: MockDdiProductRankingTable.EMPTY });
    });
  },
};
