import { useTranslation } from 'react-i18next';

import { Checkbox } from '@any-ui-react/core';

import { Image } from '~anyx/shared/ui';
import { EMPTY_DEFAULT } from '~anyx/shared/utils';

import { VariantSetDetail } from '../../type';

export const VariantSelectorMobileTemplate = ({
  productName,
  media,
  productCode,
  currentProductVariantId,
  onCheckedChange,
  isChecked,
  variantName,
  productVariantId,
}: VariantSetDetail & {
  isChecked: boolean;
  currentProductVariantId?: string;
  onCheckedChange: (e: boolean) => void;
}) => {
  const { t } = useTranslation();
  const disabled = currentProductVariantId === productVariantId;
  return (
    <div className="flex flex-1 flex-col border-b p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center truncate">
          <Checkbox
            checked={isChecked}
            onChange={(e) => onCheckedChange(e.currentTarget.checked)}
            disabled={disabled}
          />
          <Image
            alt={t('shared.action.image', {
              ns: 'shared',
              entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
            })}
            className="ml-2 h-12 w-12"
            noImageClassNameWrapper="h-12 w-12 ml-2"
            src={media}
          />
          <div className="ml-2 flex flex-col justify-center truncate">
            <p className="truncate" title={productName || EMPTY_DEFAULT}>
              {productName || EMPTY_DEFAULT}
            </p>
            <p className="text-3xs text-gray-7 truncate" title={variantName || EMPTY_DEFAULT}>
              {variantName || EMPTY_DEFAULT}
            </p>
            <p className="text-2xs truncate" title={productCode || EMPTY_DEFAULT}>
              {productCode || EMPTY_DEFAULT}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
