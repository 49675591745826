import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ATTENTION_COLOR,
  CheckCircleFill,
  CrossCircleFill,
  InfoCircleFill,
  WarningFill,
} from '@any-ui-react/core';
import { NotificationData, notifications } from '@any-ui-react/notifications';

enum AlertType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

type ToastOptions = Omit<NotificationData, 'message'>;
type UpdateToastOptions = Omit<ToastOptions, 'id'>;
type ToastCommonProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
};

const Title = ({ type, title }: { type: AlertType; title?: React.ReactNode }) => {
  const { t } = useTranslation('shared');

  if (title === null) return null;

  return <>{title ? title : t('shared.alert.title', { type })}</>;
};

const Message = ({ type, message }: { type: AlertType; message?: React.ReactNode }) => {
  const { t } = useTranslation('shared');

  if (message === null) return null;

  return <>{message ? message : t(`shared.alert.${type.toLowerCase()}.default`)}</>;
};

const getInfoToastProps = ({ title, message }: ToastCommonProps) => ({
  color: ATTENTION_COLOR.info,
  icon: <InfoCircleFill />,
  title: <Title type={AlertType.INFO} title={title} />,
  message: <Message type={AlertType.INFO} message={message} />,
});

const getSuccessToastProps = ({ title, message }: ToastCommonProps) => ({
  color: ATTENTION_COLOR.success,
  icon: <CheckCircleFill />,
  title: <Title type={AlertType.SUCCESS} title={title} />,
  message: <Message type={AlertType.SUCCESS} message={message} />,
});

const getWarningToastProps = ({ title, message }: ToastCommonProps) => ({
  color: ATTENTION_COLOR.warning,
  icon: <WarningFill />,
  title: <Title type={AlertType.WARNING} title={title} />,
  message: <Message type={AlertType.WARNING} message={message} />,
});

const getErrorToastProps = ({ title, message }: ToastCommonProps) => ({
  color: ATTENTION_COLOR.error,
  icon: <CrossCircleFill />,
  title: <Title type={AlertType.ERROR} title={title} />,
  message: <Message type={AlertType.ERROR} message={message} />,
});

const toast = (message: React.ReactNode, options?: ToastOptions) => {
  notifications.show({
    message,
    ...options,
  });
};

toast.info = (message?: React.ReactNode, options?: ToastOptions) => {
  const { title, ...others } = options || {};

  notifications.show({
    ...getInfoToastProps({ title, message }),
    ...others,
  });
};

toast.success = (message?: React.ReactNode, options?: ToastOptions) => {
  const { title, ...others } = options || {};

  notifications.show({
    ...getSuccessToastProps({ title, message }),
    ...others,
  });
};

toast.warning = (message?: React.ReactNode, options?: ToastOptions) => {
  const { title, ...others } = options || {};

  notifications.show({
    ...getWarningToastProps({ title, message }),
    ...others,
  });
};

toast.error = (message?: React.ReactNode, options?: ToastOptions) => {
  const { title, ...others } = options || {};

  notifications.show({
    ...getErrorToastProps({ title, message }),
    ...others,
  });
};

toast.update = (id: string) => ({
  blank: (message: React.ReactNode, options?: UpdateToastOptions) => {
    notifications.update({
      id,
      message,
      ...options,
    });
  },
  info: (message?: React.ReactNode, options?: UpdateToastOptions) => {
    const { title, ...others } = options || {};
    notifications.update({
      id,
      ...getInfoToastProps({ title, message }),
      ...others,
    });
  },
  success: (message?: React.ReactNode, options?: UpdateToastOptions) => {
    const { title, ...others } = options || {};
    notifications.update({
      id,
      ...getSuccessToastProps({ title, message }),
      ...others,
    });
  },
  warning: (message?: React.ReactNode, options?: UpdateToastOptions) => {
    const { title, ...others } = options || {};
    notifications.update({
      id,
      ...getWarningToastProps({ title, message }),
      ...others,
    });
  },
  error: (message?: React.ReactNode, options?: UpdateToastOptions) => {
    const { title, ...others } = options || {};
    notifications.update({
      id,
      ...getErrorToastProps({ title, message }),
      ...others,
    });
  },
});
toast.hide = notifications.hide;
toast.clean = notifications.clean;
toast.cleanQueue = notifications.cleanQueue;

export { toast };
