import classNames from 'classnames';

import { EMPTY_DEFAULT, NumberUtils } from '~anyx/shared/utils';

interface NumberWithPercentageProps {
  amount?: unknown;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  symbolClassName?: string;
  amountClassName?: string;
  containerClassName?: string;
}

export const NumberWithPercentage = ({
  amount,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  symbolClassName,
  containerClassName,
  amountClassName,
}: NumberWithPercentageProps) => {
  return (
    <>
      {NumberUtils.isNumber(amount, false) ? (
        <span className={containerClassName}>
          <span className={classNames(amountClassName, 'tabular-nums')}>
            {NumberUtils.format(amount, { minimumFractionDigits, maximumFractionDigits })}
          </span>
          <span className={symbolClassName}>%</span>
        </span>
      ) : (
        EMPTY_DEFAULT
      )}
    </>
  );
};
