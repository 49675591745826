import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import classNames from 'classnames';

import { QuestionCircleIcon } from '~anyx/shared/icons';

interface CrmTipProps {
  ns?: string;
  i18nKey?: string;
  label?: string;
  emphasis?: boolean;
  href?: string;
  className?: string;
}

const Label = ({ label, href }: { label?: string; href?: string }) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <p className="text-link hover:underline">{label}</p>
      </a>
    );
  }

  return <p>{label}</p>;
};
export function CrmTip({
  label,
  href,
  className,
}: {
  label: string;
  href?: string;
  className?: string;
}): ReactElement;
export function CrmTip({
  ns,
  emphasis,
  i18nKey,
  className,
}: {
  ns: string;
  i18nKey: string;
  emphasis: true;
  className?: string;
}): ReactElement;
export function CrmTip({ label, emphasis, href, className, ...props }: CrmTipProps) {
  return (
    <div className={classNames('flex gap-x-1 text-sm leading-tight', className)}>
      <QuestionCircleIcon className="text-gray-5 h-4 w-4 shrink-0" />
      {emphasis ? (
        <Trans {...props} parent="p" components={[<span className="font-semibold" />]} />
      ) : (
        <Label label={label} href={href} />
      )}
    </div>
  );
}
