import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, ModalFooter, Radio } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';

export type FbPageData = {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  access_token: string;
};

export interface FacebookPageSelectProps {
  fbPages?: FbPageData[];
  platform: 'INSTAGRAM' | 'MESSENGER';
  onSelectPage?: (data: FbPageData) => Promise<void>;
  onClose?: () => void;
}

const MODAL_ID = 'facebook-page-select-modal';

const FacebookPageSelect = ({
  fbPages = [],
  onSelectPage,
  platform = 'INSTAGRAM',
  onClose,
}: FacebookPageSelectProps) => {
  const { t } = useTranslation(['crmIntegration', 'shared']);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<{ fbPageId: string }>({
    defaultValues: {
      fbPageId: '',
    },
  });

  const isPageSelected = !!watch('fbPageId');

  const onSubmit = handleSubmit(async (data) => {
    const selectedPage = fbPages.find(({ id }: { id: string }) => id === data.fbPageId);

    if (!selectedPage) return;

    try {
      await onSelectPage?.(selectedPage);
      onClose?.();
    } catch (error) {
      // keep modal open
    }
  });

  return (
    <form noValidate onSubmit={onSubmit}>
      <p className="mb-5 text-sm">{t('crmIntegration.shared.fbPageSelect.desc', { platform })}</p>
      <div className="border-gray-2 divide-y rounded border">
        <div className="text-2xs text-gray-9 bg-gray-0 flex h-12 items-center px-4 font-semibold">
          {t('crmIntegration.shared.fbPageSelect.connectable')}
        </div>
        <Controller
          control={control}
          name="fbPageId"
          render={({ field }) => (
            <Radio.Group {...field} className="max-h-56 divide-y overflow-y-auto">
              {fbPages.map(({ id, name }) => (
                <Radio
                  key={id}
                  classNames={{
                    root: 'px-4 py-2',
                    inner: 'self-center',
                  }}
                  label={
                    <div className="flex flex-col gap-y-1">
                      <span className="text-gray-9 text-sm font-semibold">{name}</span>
                      <span className="text-gray-7 text-2xs">{id}</span>
                    </div>
                  }
                  value={id}
                />
              ))}
            </Radio.Group>
          )}
        />
      </div>

      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          {t('shared.button.cancel', { ns: 'shared' })}
        </Button>
        <Button type="submit" loading={isSubmitting} disabled={!isPageSelected}>
          {t('shared.button.connect', { ns: 'shared' })}
        </Button>
      </ModalFooter>
    </form>
  );
};

export const useFacebookPageSelectModal = () => {
  const { t } = useTranslation('crmIntegration');
  const { openModal, closeModal } = useModals();

  const openFacebookPageSelectModal = (props: Omit<FacebookPageSelectProps, 'onClose'>) => {
    openModal({
      modalId: MODAL_ID,
      title: t('crmIntegration.shared.fbPageSelect.title'),
      children: (
        <FacebookPageSelect
          {...props}
          onClose={() => {
            closeModal(MODAL_ID);
          }}
        />
      ),
    });
  };

  return { openFacebookPageSelectModal };
};
