import { useState } from 'react';

import useAsyncEffect from 'use-async-effect';

import { ChannelUtils, ReportSalesChannelType, useStoresChannels } from '~anyx/feature/marketplace';

interface UseConnectedReportChannelsProps {
  storeId: string;
}

export const useConnectedReportChannels = ({ storeId }: UseConnectedReportChannelsProps) => {
  const { getStoresChannelsTypes } = useStoresChannels();
  const [reportChannels, setReportChannels] = useState<ReportSalesChannelType[]>([]);

  useAsyncEffect(async () => {
    if (!storeId) {
      setReportChannels([]);
      return;
    }
    const channels = await getStoresChannelsTypes({ storeIds: [storeId] });
    setReportChannels(ChannelUtils.asReportSalesChannelTypes(channels) || []);
  }, [storeId]);

  return { reportChannels };
};
