import { WorkflowStore } from './types';
import { useWorkflowStore } from './useWorkflowStore';

export const setWorkflowStore = ({
  storeId,
  storeName,
}: {
  storeId: WorkflowStore['storeId'];
  storeName: WorkflowStore['storeName'];
}) => {
  useWorkflowStore.setState((state) => {
    state.storeId = storeId;
    state.storeName = storeName;
  });
};
