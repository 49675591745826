import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { FALLBACK_ROUTE } from '~anyx/common/routing';

const OauthRedirectPage = React.lazy(() =>
  import('./pages/default').then((module) => ({ default: module.OauthRedirectPage }))
);

const OauthRedirectShopifyPage = React.lazy(() =>
  import('./pages/sale-channel/shopify').then((module) => ({
    default: module.OauthRedirectShopifyPage,
  }))
);

const OauthDatasourceGoogleRedirect = React.lazy(() =>
  import('./pages/datasources/google').then((module) => ({
    default: module.OauthDatasourceGoogleRedirectPage,
  }))
);
const OauthDatasourceFacebookRedirect = React.lazy(() =>
  import('./pages/datasources/facebook').then((module) => ({
    default: module.OauthDatasourceFacebookRedirectPage,
  }))
);
const OauthDatasourceYahooRedirect = React.lazy(() =>
  import('./pages/datasources/yahoo').then((module) => ({
    default: module.OauthDatasourceYahooRedirectPage,
  }))
);
const OauthDatasourceTikTokRedirect = React.lazy(() =>
  import('./pages/datasources/tikTok').then((module) => ({
    default: module.OauthDatasourceTikTokRedirectPage,
  }))
);

const OauthRedirectUpsPage = React.lazy(() =>
  import('./pages/couriers/ups').then((module) => ({
    default: module.OauthRedirectUpsPage,
  }))
);

const IntegrationTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.IntegrationTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'integrations',
    element: (
      <Auth0Guard>
        <IntegrationTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        path: 'oauth-redirect',
        element: <OauthRedirectPage />,
      },
      {
        path: 'shopify/oauth-redirect',
        element: <OauthRedirectShopifyPage />,
      },
      {
        path: 'datasource/google/oauth-redirect',
        element: <OauthDatasourceGoogleRedirect />,
      },
      {
        path: 'datasource/yahoo/oauth-redirect',
        element: <OauthDatasourceYahooRedirect />,
      },
      {
        path: 'datasource/facebook/oauth-redirect',
        element: <OauthDatasourceFacebookRedirect />,
      },
      {
        path: 'datasource/tikTok/oauth-redirect',
        element: <OauthDatasourceTikTokRedirect />,
      },
      {
        path: 'ups/oauth-redirect',
        element: <OauthRedirectUpsPage />,
      },
      {
        index: true,
        element: <Navigate to={FALLBACK_ROUTE} replace={true} />,
      },
    ],
  },
];
