import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePagination } from '@any-ui-react/core';

import { Image, NoResult, Table, TableLayout } from '~anyx/shared/ui';
import {
  EMPTY_DEFAULT,
  MODAL_PAGE_SIZE,
  NumberUtils,
  TableSelection,
  useFilters,
  useScreenBreakpoint,
} from '~anyx/shared/utils';

import { useProductSetVariantsVariantSelector } from '../../operation';
import { VariantSetDetail } from '../../type';

import {
  VariantSelectorFiltersType,
  VariantSelectorFilters,
  VariantSelectorFiltersParser,
} from './filter';
import { VariantSelectorMobileTemplate } from './VariantSelectorMobileTemplate';

interface VariantSelectorProps {
  currentProductVariantId?: string;
  storeId: string;
  title?: string;
  description?: string;
  currentSelection: VariantSetDetail[];
  onSelectionChange?: (selection: VariantSetDetail[]) => void;
}

export const VariantSelector = ({
  currentProductVariantId,
  storeId,
  currentSelection,
  onSelectionChange,
}: VariantSelectorProps) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenBreakpoint('lg');
  const [selection, setSelection] = useState<TableSelection<VariantSetDetail>>({
    items: currentSelection,
    allPages: false,
  });

  const { ...filters } = useFilters<VariantSelectorFiltersType>(
    {
      storeId,
      page: 1,
    },
    {
      withLocation: false,
      withLocalStorage: false,
      parser: VariantSelectorFiltersParser.parse,
    }
  );
  const { products, total, loading } = useProductSetVariantsVariantSelector({
    filters: filters.current,
  });

  useEffect(() => {
    onSelectionChange?.(selection.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const { page } = filters.current;

  const onToggleVariantSelection = useCallback((checked: boolean, variant: VariantSetDetail) => {
    if (checked) {
      setSelection((prevState) => ({
        ...prevState,
        items: [...prevState.items, variant],
      }));
    } else {
      setSelection((prevState) => ({
        ...prevState,
        items: prevState.items.filter((item) => item.productVariantId !== variant.productVariantId),
      }));
    }
  }, []);

  const layout = useMemo(
    () =>
      [
        {
          accessor: 'name',
          truncated: false,
          maxWidth: '25rem',
          minWidth: '15rem',
          headerRender: () =>
            t('logistic.component.variantSelector.table.column.name', { ns: 'logistic' }),
          cellRender: ({ media, productName, variantName }) => {
            return (
              <div className="flex items-center justify-between truncate py-2">
                <div className="flex items-center truncate">
                  <Image
                    alt={t('shared.action.image', {
                      ns: 'shared',
                      entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
                    })}
                    className="ml-4 mr-2 h-10 w-10"
                    noImageClassNameWrapper="mr-2 h-10 w-10 ml-4"
                    src={media}
                  />
                  <div className="flex flex-col truncate">
                    <p className="truncate" title={productName}>
                      {productName || EMPTY_DEFAULT}
                    </p>
                    <p className="text-3xs text-gray-7 truncate" title={variantName}>
                      {variantName || EMPTY_DEFAULT}
                    </p>
                  </div>
                </div>
              </div>
            );
          },
          checkboxTooltip: () => {
            return {
              label: t('marketplace.page.product.edit.dialog.mergeVariant.tooltip.disableReason', {
                ns: 'marketplace',
                reason: 'SELF',
              }),
            };
          },
        },

        {
          headerRender: () =>
            t('logistic.component.variantSelector.table.column.code', { ns: 'logistic' }),
          accessor: 'productCode',
          cellRender: ({ productCode }) => {
            return productCode;
          },
        },
      ] as TableLayout<VariantSetDetail>[],
    [t]
  );

  return (
    <>
      <VariantSelectorFilters {...filters} />
      <Table<VariantSetDetail>
        layout={layout}
        data={products}
        loading={loading}
        page={page}
        selection={selection}
        onSelectionChange={(newSelection) => setSelection(newSelection)}
        selectable={{
          byField: 'productVariantId',
          total: total,
          allPagesSelectable: false,
          rowDisabled: (row) => row.productVariantId === currentProductVariantId,
        }}
        notFoundTemplate={
          <NoResult
            title={t('shared.table.empty', {
              ns: 'shared',
              element: t('shared.entity.product', { ns: 'shared', count: 1 }),
            })}
          />
        }
        wrapperClassName="border-x border-b shadow-none max-h-[40vh] lg:max-h-96 overflow-auto"
        mobileTemplate={(row, index) => (
          <VariantSelectorMobileTemplate
            key={index}
            currentProductVariantId={currentProductVariantId}
            onCheckedChange={(e) => onToggleVariantSelection(e, row)}
            isChecked={selection.items.some(
              (item) => item.productVariantId === row.productVariantId
            )}
            {...row}
          />
        )}
      />
      <TablePagination
        className="px-0 py-3"
        value={page}
        rowsPerPage={MODAL_PAGE_SIZE}
        withControls={isLargeScreen}
        siblings={isLargeScreen ? 0 : 1}
        count={total}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
        }}
        localeFormatter={(totalCount) => NumberUtils.format(totalCount)}
      />
    </>
  );
};
