import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput, TextInputProps } from '@any-ui-react/core';

import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';

export type AddressSecondFieldProps = Partial<TextInputProps>;

export const AddressSecondField = ({ ...rest }: AddressSecondFieldProps) => {
  const { t } = useTranslation();
  const { register, getFieldState } = useFormContext();
  const { mappings } = useAddressFormContext();

  const fieldName = mappings[AddressPart.ADDRESS_SECOND];
  if (!fieldName) {
    return;
  }

  return (
    <TextInput
      {...register(fieldName)}
      label={t('shared.field.address2.name', { ns: 'shared' })}
      placeholder={t('shared.field.address2.placeholder', { ns: 'shared' })}
      error={getFieldState(fieldName)?.error?.message?.toString()}
      {...rest}
    />
  );
};
