/** ISO 4217 currency code */
export const CurrenciesEnum = {
  /** United Arab Emirates dirham */
  AED: 'AED',
  /** Afghan afghani */
  AFN: 'AFN',
  /** Albanian lek */
  ALL: 'ALL',
  /** Armenian dram */
  AMD: 'AMD',
  /** Netherlands Antillean guilder */
  ANG: 'ANG',
  /** Angolan kwanza */
  AOA: 'AOA',
  /** Argentine peso */
  ARS: 'ARS',
  /** Australian dollar */
  AUD: 'AUD',
  /** Aruban florin */
  AWG: 'AWG',
  /** Azerbaijani manat */
  AZN: 'AZN',
  /** Bosnia and Herzegovina convertible mark */
  BAM: 'BAM',
  /** Barbadian dollar */
  BBD: 'BBD',
  /** Bangladeshi taka */
  BDT: 'BDT',
  /** Bulgarian lev */
  BGN: 'BGN',
  /** Burundian franc */
  BIF: 'BIF',
  /** Bermudian dollar */
  BHD: 'BHD',
  /** Baharaini Dinar */
  BMD: 'BMD',
  /** Brunei dollar */
  BND: 'BND',
  /** Bolivian boliviano */
  BOB: 'BOB',
  /** Brazilian real */
  BRL: 'BRL',
  /** Bahamian dollar */
  BSD: 'BSD',
  /** Botswana pula */
  BWP: 'BWP',
  /** Belize dollar */
  BZD: 'BZD',
  /** Canadian dollar */
  CAD: 'CAD',
  /** Congolese franc */
  CDF: 'CDF',
  /** Swiss franc */
  CHF: 'CHF',
  /** Chilean peso */
  CLP: 'CLP',
  /** Chinese yuan (Renminbi) */
  CNY: 'CNY',
  /** Colombian peso */
  COP: 'COP',
  /** Costa Rican colon */
  CRC: 'CRC',
  /** Cape Verdean escudo */
  CVE: 'CVE',
  /** Czech koruna */
  CZK: 'CZK',
  /** Djiboutian franc */
  DJF: 'DJF',
  /** Danish krone */
  DKK: 'DKK',
  /** Dominican peso */
  DOP: 'DOP',
  /** Algerian dinar */
  DZD: 'DZD',
  /** Egyptian pound */
  EGP: 'EGP',
  /** Ethiopian birr */
  ETB: 'ETB',
  /** Euro */
  EUR: 'EUR',
  /** Fijian dollar */
  FJD: 'FJD',
  /** Falkland Islands pound */
  FKP: 'FKP',
  /** United Kingdom Pound sterling */
  GBP: 'GBP',
  /** Georgian lari */
  GEL: 'GEL',
  /** Gibraltar pound */
  GIP: 'GIP',
  /** Gambian dalasi */
  GMD: 'GMD',
  /** Guinean franc */
  GNF: 'GNF',
  /** Guatemalan quetzal */
  GTQ: 'GTQ',
  /** Guyanese dollar */
  GYD: 'GYD',
  /** Hong Kong dollar */
  HKD: 'HKD',
  /** Honduran lempira */
  HNL: 'HNL',
  /** Croatian kuna */
  HRK: 'HRK',
  /** Haitian gourde */
  HTG: 'HTG',
  /** Hungarian forint */
  HUF: 'HUF',
  /** Indonesian rupiah */
  IDR: 'IDR',
  /** Israeli new shekel */
  ILS: 'ILS',
  /** Indian rupee */
  INR: 'INR',
  /** Icelandic krona */
  ISK: 'ISK',
  /** Jamaican dollar */
  JMD: 'JMD',
  /** Japanese yen */
  JPY: 'JPY',
  /**Jordanian Dinar */
  JOD: 'JOD',
  /** Kenyan shilling */
  KES: 'KES',
  /** Kyrgyz som */
  KGS: 'KGS',
  /** Cambodian riel */
  KHR: 'KHR',
  /** Comorian franc */
  KMF: 'KMF',
  /** South Korean won */
  KRW: 'KRW',
  /** Kuwaiti dinar */
  KWD: 'KWD',
  /** Cayman Islands dollar */
  KYD: 'KYD',
  /** Kazakhstani tenge */
  KZT: 'KZT',
  /** Lao kip */
  LAK: 'LAK',
  /** Lebanese pound */
  LBP: 'LBP',
  /** Sri Lankan rupee */
  LKR: 'LKR',
  /** Liberian dollar */
  LRD: 'LRD',
  /** Lesotho loti */
  LSL: 'LSL',
  /** Moroccan dirham */
  MAD: 'MAD',
  /** Moldovan leu */
  MDL: 'MDL',
  /** Malagasy ariary */
  MGA: 'MGA',
  /** Macedonian denar */
  MKD: 'MKD',
  /** Myanmar kyat */
  MMK: 'MMK',
  /** Mongolian togrog */
  MNT: 'MNT',
  /** Macanese pataca */
  MOP: 'MOP',
  /** Mauritian rupee */
  MUR: 'MUR',
  /** Maldivian rufiyaa */
  MVR: 'MVR',
  /** Malawian kwacha */
  MWK: 'MWK',
  /** Mexician peso */
  MXN: 'MXN',
  /** Malaysian ringgit */
  MYR: 'MYR',
  /** Mozambican metical */
  MZN: 'MZN',
  /** Namibian dollar */
  NAD: 'NAD',
  /** Nigerian naira */
  NGN: 'NGN',
  /** Nicaraguan cordoba */
  NIO: 'NIO',
  /** Norwegian krone */
  NOK: 'NOK',
  /** Nepalese rupee */
  NPR: 'NPR',
  /** New Zealand dollar */
  NZD: 'NZD',
  /** Omani Rial */
  OMR: 'OMR',
  /** Panamanian balboa */
  PAB: 'PAB',
  /** Peruvian sol */
  PEN: 'PEN',
  /** Papua New Guinean kina */
  PGK: 'PGK',
  /** Philippine peso */
  PHP: 'PHP',
  /** Pakistani rupee */
  PKR: 'PKR',
  /** Polish zloty */
  PLN: 'PLN',
  /** Paraguayan guarani */
  PYG: 'PYG',
  /** Qatari riyal */
  QAR: 'QAR',
  /** Romanian leu */
  RON: 'RON',
  /** Serbian dinar */
  RSD: 'RSD',
  /** Russian ruble */
  RUB: 'RUB',
  /** Rwandan franc */
  RWF: 'RWF',
  /** Saudi riyal */
  SAR: 'SAR',
  /** Solomon Islands dollar */
  SBD: 'SBD',
  /** Seychellois rupee */
  SCR: 'SCR',
  /** Swedish krona */
  SEK: 'SEK',
  /** Singapore dollar */
  SGD: 'SGD',
  /** Saint Helena pound */
  SHP: 'SHP',
  /** Sierra Leonean leone */
  SLL: 'SLL',
  /** Surinamese dollar */
  SRD: 'SRD',
  /** Sao Tome and Principe dobra */
  STD: 'STD',
  /** Swazi lilangeni */
  SZL: 'SZL',
  /** Thai baht */
  THB: 'THB',
  /** Tajikistani somoni */
  TJS: 'TJS',
  /** Tongan paʻanga */
  TOP: 'TOP',
  /** Turkish lira */
  TRY: 'TRY',
  /** Trinidad and Tobago dollar */
  TTD: 'TTD',
  /** New Taiwan dollar */
  TWD: 'TWD',
  /** Tanzanian shilling */
  TZS: 'TZS',
  /** Ukrainian hryvnia */
  UAH: 'UAH',
  /** Ugandan shilling */
  UGX: 'UGX',
  /** United States dollar */
  USD: 'USD',
  /** Uruguayan peso */
  UYU: 'UYU',
  /** Uzbekistani som */
  UZS: 'UZS',
  /** Vietnamese dong */
  VND: 'VND',
  /** Vanuatu vatu */
  VUV: 'VUV',
  /** Samoan tala */
  WST: 'WST',
  /** Central African CFA franc */
  XAF: 'XAF',
  /** Eastern Caribbean dollar */
  XCD: 'XCD',
  /** West African CFA franc */
  XOF: 'XOF',
  /** CFP franc */
  XPF: 'XPF',
  /** Yemeni rial */
  YER: 'YER',
  /** South African rand */
  ZAR: 'ZAR',
  /** Zambian kwacha */
  ZMW: 'ZMW',
} as const;

export type CurrenciesEnum = (typeof CurrenciesEnum)[keyof typeof CurrenciesEnum];
