import { useCallback } from 'react';

import { AlertType, useApiTranslationMapper, MdCreateStoreInput } from '~anyx/shared/graphql';
import { toast } from '~anyx/shared/ui';

import { useOnboardingCreateStoreMutation } from '../graphql';

export const useOnboardingCreateStore = () => {
  const { getErrorMessage, getTranslationKey } = useApiTranslationMapper();
  const [createStore, { loading: creating }] = useOnboardingCreateStoreMutation();

  const handleCreateStore = useCallback(
    async ({ accountId, name }: MdCreateStoreInput) => {
      try {
        const { data } = await createStore({
          refetchQueries: ['WorkspaceSelect'],
          variables: {
            input: {
              accountId,
              name,
            },
          },
        });
        toast.success(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.SUCCESS,
            path: 'store',
            message: 'createStore',
          })
        );
        return data;
      } catch (e: unknown) {
        toast.error(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path: 'store',
            message: getErrorMessage(e),
          })
        );
        return null;
      }
    },
    [createStore, getTranslationKey, getErrorMessage]
  );

  return { handleCreateStore, creating };
};
