import { SVGProps, cloneElement } from 'react';

import { MdAccountProduct } from '~anyx/shared/graphql';
import {
  AnyXLogoIcon,
  AnyXIcon,
  LogoAnychatIcon,
  AnychatFaIcon,
  AnylogiFaIcon,
  LogoAnylogiIcon,
} from '~anyx/shared/icons';

const PRODUCT_ICONS: Record<
  MdAccountProduct,
  { fa: React.ReactElement; logo: React.ReactElement }
> = {
  [MdAccountProduct.ANYX]: {
    fa: <AnyXLogoIcon />,
    logo: <AnyXIcon />,
  },
  [MdAccountProduct.ANYCHAT]: {
    fa: <AnychatFaIcon />,
    logo: <LogoAnychatIcon />,
  },
  [MdAccountProduct.ANYLOGI]: {
    fa: <AnylogiFaIcon />,
    logo: <LogoAnylogiIcon />,
  },
};

export const getAccountProductIcon = (
  product: MdAccountProduct | undefined,
  type: 'fa' | 'logo',
  props?: SVGProps<SVGElement>
) => {
  return product && PRODUCT_ICONS[product]
    ? cloneElement(PRODUCT_ICONS[product][type], props)
    : null;
};
