export class BrowserUtils {
  private static USER_AGENT = navigator.userAgent;

  static isChrome() {
    return /chrome|chromium|crios/i.test(BrowserUtils.USER_AGENT);
  }

  static isSafari() {
    return /^((?!chrome|android).)*safari/i.test(BrowserUtils.USER_AGENT);
  }

  static isFirefox() {
    return /firefox|fxios/i.test(BrowserUtils.USER_AGENT);
  }

  static isEdge() {
    return /opr\//i.test(BrowserUtils.USER_AGENT);
  }

  static isOpera() {
    return /edg/i.test(BrowserUtils.USER_AGENT);
  }
}
