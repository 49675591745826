import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronRightIcon } from '~anyx/shared/icons';
import { toast } from '~anyx/shared/ui';

import { useWorkspaceModeSwitcher } from '../../../../hooks';

export const WorkspaceModeSwitcher = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { toggleMode, nextMode } = useWorkspaceModeSwitcher();

  const switchMode = useCallback(() => {
    toggleMode();
    toast.info(t('core.workspace.toast.description', { ns: 'core', mode: nextMode }), {
      title: t('core.workspace.toast.title', { ns: 'core' }),
    });
  }, [nextMode, t, toggleMode]);

  return (
    <button className={className} onClick={switchMode} type="button">
      {t('core.workspace.switchView', {
        ns: 'core',
        mode: nextMode,
      })}
      <ChevronRightIcon className="text-gray-7 h-4 w-4" />
    </button>
  );
};
