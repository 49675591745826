import { RouteObject } from 'react-router-dom';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

const PATH_KEY = CrmRoutePathKey.enum.lazada;

export const routes: RouteObject[] = [
  {
    path: 'lazada',
    children: [...ChatRoutes(PATH_KEY)],
  },
];
