import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

export const WorkflowRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId || '',
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/workflow' : '/workflow', {
    workspaceStoreId,
  });

  return {
    root: () => root,
  };
};
