import { Trans, useTranslation } from 'react-i18next';

import { Button, ModalFooter } from '@any-ui-react/core';
interface RecommendedTimezoneDialogProps {
  deviceTimeZone: string;
  selfTimeZone: string;
  onApply: () => void;
  onIgnoreOnce: () => void;
  onDismiss: () => void;
}

export const RecommendedTimezoneDialog = ({
  deviceTimeZone,
  selfTimeZone,
  onApply,
  onIgnoreOnce,
  onDismiss,
}: RecommendedTimezoneDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Trans
        i18nKey="shared.timezone.dialog.description"
        ns="shared"
        values={{ deviceTimeZone, selfTimeZone }}
      />
      <ModalFooter>
        <Button variant="default" type="button" onClick={onIgnoreOnce}>
          {t('shared.button.ignoreOnce', { ns: 'shared' })}
        </Button>
        <Button variant="default" type="button" onClick={onDismiss}>
          {t('shared.button.dismiss', { ns: 'shared' })}
        </Button>
        <Button type="submit" onClick={onApply}>
          {t('shared.button.apply', { ns: 'shared' })}
        </Button>
      </ModalFooter>
    </>
  );
};
