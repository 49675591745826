import { useCoreAppDomain } from '~anyx/common/domain-product';
import { MdAccountProduct } from '~anyx/shared/graphql';
import { useEnvironmentStore } from '~anyx/shared/utils';

export const usePolicyDomainLink = ({
  product,
  overrideDomain,
}: {
  product: MdAccountProduct;
  overrideDomain?: string;
}) => {
  const { coreAppDomain } = useCoreAppDomain({ product });
  const language = useEnvironmentStore((s) => s.currentLanguage);

  const privacyPolicyLink = `${
    overrideDomain || coreAppDomain
  }/policy/privacy-policy?lg=${language}`;
  const termsOfServiceLink = `${overrideDomain || coreAppDomain}/policy/terms?lg=${language}`;

  return {
    privacyPolicyLink,
    termsOfServiceLink,
  };
};
