import { Rating } from '@any-ui-react/core';

interface RatingStarProps {
  averageRating?: number;
  size?: 'sm' | 'md';
}

export const RatingStar = ({ averageRating, size = 'md' }: RatingStarProps) => {
  return (
    <Rating
      value={averageRating}
      readOnly
      fractions={4}
      size={size === 'md' ? '1rem' : '0.75rem'}
      color="#FFB543"
    />
  );
};
