import { components, GroupBase, OptionProps } from '@any-ui-react/select';
import classNames from 'classnames';

import { WorkspaceOption } from '../WorkspaceSelect';

export const Option = ({
  data,
  isSelected,
  className,
  innerProps,
  ...props
}: OptionProps<WorkspaceOption, false, GroupBase<WorkspaceOption>>) => {
  return (
    <components.Option
      data={data}
      isSelected={isSelected}
      className={`${className} !h-10`}
      innerProps={{
        ...innerProps,
        title: data.label,
      }}
      {...props}
    >
      <div className="flex h-full items-center">
        <span
          className={classNames('truncate', {
            'font-semibold': isSelected,
          })}
        >
          {data.label}
        </span>
      </div>
    </components.Option>
  );
};
