import { useCallback, useMemo } from 'react';

import {
  CrmRoutePaths,
  HomeRoutePath,
  IntegrationRoutePath,
  AccountRoutePath,
  OrderRoutePath,
  ProductRoutePath,
  InventoryRoutePath,
  WorkflowRoutePath,
  ShipmentRoutePath,
} from '~anyx/common/routing';
import { useSelfCtx } from '~anyx/common/self';
import { useWorkspaceCtx, Workspace, WorkspaceMode } from '~anyx/common/workspace';
import { MdAnyXModuleEnum } from '~anyx/shared/graphql';

export const useWorkspaceHomepage = () => {
  const {
    self: { availableModules },
  } = useSelfCtx();
  const { workspace, mode } = useWorkspaceCtx();

  const homepagesLinks = useCallback(
    (ws: Workspace | undefined = workspace) => {
      return {
        [MdAnyXModuleEnum.ANALYTICS]: {
          [WorkspaceMode.ON]: HomeRoutePath({ workspaceStoreId: null }).root().path,
          [WorkspaceMode.OFF]: HomeRoutePath({ workspaceStoreId: null }).root().path,
          [WorkspaceMode.ACCOUNT_ONLY]: HomeRoutePath({ workspaceStoreId: null }).root().path,
        },
        [MdAnyXModuleEnum.ORDERS]: {
          [WorkspaceMode.ON]: OrderRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.OFF]: OrderRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.ACCOUNT_ONLY]: OrderRoutePath({ workspaceStoreId: null }).list().path,
        },
        [MdAnyXModuleEnum.SHIPPING_LABELS]: {
          [WorkspaceMode.ON]: ShipmentRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.OFF]: ShipmentRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.ACCOUNT_ONLY]: ShipmentRoutePath({ workspaceStoreId: null }).list().path,
        },
        [MdAnyXModuleEnum.PRODUCTS]: {
          [WorkspaceMode.ON]: ProductRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.OFF]: ProductRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.ACCOUNT_ONLY]: ProductRoutePath({ workspaceStoreId: null }).list().path,
        },
        [MdAnyXModuleEnum.INVENTORIES]: {
          [WorkspaceMode.ON]: InventoryRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.OFF]: InventoryRoutePath({ workspaceStoreId: null }).list().path,
          [WorkspaceMode.ACCOUNT_ONLY]: InventoryRoutePath({ workspaceStoreId: null }).list().path,
        },
        [MdAnyXModuleEnum.CRM]: {
          [WorkspaceMode.ON]: CrmRoutePaths().home().path,
          [WorkspaceMode.OFF]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
          [WorkspaceMode.ACCOUNT_ONLY]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
        },
        [MdAnyXModuleEnum.WORKFLOW]: {
          [WorkspaceMode.ON]: WorkflowRoutePath({ workspaceStoreId: null }).root().path,
          [WorkspaceMode.OFF]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
          [WorkspaceMode.ACCOUNT_ONLY]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
        },
        [MdAnyXModuleEnum.DATASOURCES]: {
          [WorkspaceMode.ON]: IntegrationRoutePath().list().path,
          [WorkspaceMode.OFF]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
          [WorkspaceMode.ACCOUNT_ONLY]: AccountRoutePath({ workspaceStoreId: null }).edit({
            accountId: ws?.accountId,
          }).path,
        },
      } as Record<MdAnyXModuleEnum, Record<WorkspaceMode, string>>;
    },
    [workspace]
  );

  const homepageLink = useMemo(() => {
    const modulePath = availableModules.find((m) => homepagesLinks()[m]);

    return modulePath
      ? homepagesLinks()[modulePath][mode]
      : AccountRoutePath({ workspaceStoreId: null }).edit({ accountId: workspace?.accountId }).path;
  }, [availableModules, homepagesLinks, mode, workspace]);

  const getHomepageForMode = useCallback(
    (targetMode: WorkspaceMode) => {
      const modulePath = availableModules.find((m) => homepagesLinks()[m]);

      return modulePath
        ? homepagesLinks()[modulePath][targetMode]
        : AccountRoutePath({ workspaceStoreId: null }).edit({ accountId: workspace?.accountId })
            .path;
    },
    [availableModules, homepagesLinks, workspace?.accountId]
  );

  return { homepageLink, getHomepageForMode };
};
