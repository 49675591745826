import { ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/react';

import {
  DocumentDetailDocument,
  DocumentDetailQuery,
  DocumentDetailQueryVariables,
} from '../graphql';
import { DocumentStatus } from '../index';

import { useWatchLazyQuery } from './useWatchLazyQuery';

interface DownloadDocumentProps {
  onProcessStart?: () => void;
  onSuccess?: () => void;
  onComplete?: () => void;
  onFailure?: (error?: unknown) => void;
  handleDocumentDownloadUrl: (url: string) => void;
  pollIntervalInMs?: number;
}

export function useDownloadDocument({
  onProcessStart,
  onSuccess,
  onComplete,
  onFailure,
  handleDocumentDownloadUrl,
  pollIntervalInMs = 5000,
}: DownloadDocumentProps) {
  const onSuccessCallback = ({ data: queryResult }: ApolloQueryResult<DocumentDetailQuery>) => {
    const {
      document: { id, status, url },
    } = queryResult;

    switch (status) {
      case DocumentStatus.SUCCESS:
        if (!url) {
          Sentry.captureException(`Encountered Success State with Empty URL for Document Id ${id}`);
          onFailure?.();
          return true;
        }

        handleDocumentDownloadUrl(url);
        onSuccess?.();
        return true;

      case DocumentStatus.FAIL:
        onFailure?.();
        return true;

      default:
        return false;
    }
  };

  const onErrorCallback = (error: unknown) => {
    onFailure?.(error);
  };

  const { listenToWatchedLazyQuery, loading } = useWatchLazyQuery<
    DocumentDetailQuery,
    DocumentDetailQueryVariables
  >({
    query: DocumentDetailDocument,
    options: {
      pollInterval: pollIntervalInMs,
    },
  });

  const handleDownloadDocument = (queryVariables: DocumentDetailQueryVariables) => {
    onProcessStart?.();
    listenToWatchedLazyQuery(queryVariables, onSuccessCallback, onErrorCallback, () => {
      onComplete?.();
    });
  };

  return {
    handleDownloadDocument,
    loading,
  };
}
